import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { actions } from './redux/actions';
import '../Widgets.scss';
import Strings from '../../../../Strings';

function PendingCallsMessages(props) {
  const { isLoading, pendingMessages, orgFilter, getData, onClick } = props;

  React.useEffect(() => {
    getData(orgFilter);
  }, [orgFilter]);

  return !isLoading && (
    <div className="boxWithVl-box">
      <div className="boxWithVl-valueBox black">{pendingMessages}</div>
      <div onClick={() => onClick('/messages/inbox')} className="boxWithVl-labelBox clickable">
        {Strings.widgets.unreadMessages}
      </div>
    </div>
  );
}

PendingCallsMessages.widgetId = 'PendingCallsMessagesWidget';

PendingCallsMessages.propTypes = {
  getData: PropTypes.func,
  pendingCalls: PropTypes.any,
  pendingMessages: PropTypes.any,
  isLoading: PropTypes.any,
  onClick: PropTypes.func,
  orgFilter: PropTypes.string,
};

const mapStateToProps = state => {
  const { widgetPendingCallsMessagesReducer } = state.dashboardWidgets;
  const userId = state.auth?.profile?.id;
  return {
    pendingCalls: widgetPendingCallsMessagesReducer && widgetPendingCallsMessagesReducer?.pendingCalls,
    pendingMessages: widgetPendingCallsMessagesReducer && widgetPendingCallsMessagesReducer?.pendingMessages,
    isLoading: widgetPendingCallsMessagesReducer && widgetPendingCallsMessagesReducer?.isLoading,
    orgFilter: state.superUser?.patients?.orgFilter?.[userId],
  };
};

const mapDispatchToProps = dispatch => ({
  getData: orgId => dispatch(actions.getPendingCallsMessages(orgId)),
  onClick: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingCallsMessages);
