import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { openModalAction } from '../../../../actions/modal';
import TableWithLocalPagination from '../../../../containers/Table/TableWithLocalPagination';
import { Column } from '../../../../containers/Table/TableWithPagination';
import Strings from '../../../../Strings';
import { actions } from '../../redux/actions';
import { getOrgUrlToNavigate } from '../../Organization';

function GroupOrganizations(props) {
  const { groupDetails, groupUsers, onRefresh, openConfirmModal, onNavigate } = props;

  const removeOrgFromGrp = (_, org) => {
    const data = {
      title: Strings.deleteOrgFromGroupWarningarning(org.name, groupDetails.name),

      onConfirmAction: actions.editOrganizationGroup(
        groupDetails.id,
        {
          ...groupDetails,
          organizations: groupDetails.organizations.map(o => o.id).filter(o => o !== org.id),
          maintainers: groupDetails.maintainers.map(o => o.id),
        },
      ),
      onSuccessNotification: Strings.formatString(Strings.itemSaved, groupDetails.name),
      confirmPostAction: onRefresh,
    };
    openConfirmModal(data);
  };

  return (
    <TableWithLocalPagination
      name="organizations-in-group"
      data={groupDetails?.organizations || []}
      buttons={(groupDetails?.role === 'owner' || groupDetails?.role === 'maintainer') ? [
        {
          icon: 'delete',
          onClick: removeOrgFromGrp,
          text: Strings.delete,
          disabled: (_, org) => {return groupDetails.maintainers?.some(m => m.org_uuid === org.id) || groupUsers?.some(u => u.org_uuid === org.id)},
          disabledTooltip: Strings.cantDeleteOrgFromGroup,
        }] : undefined}
      onRowSelection={(_, org) => onNavigate(getOrgUrlToNavigate(org.id, org.name))}
      infiniteScroll
      columns={[
        <Column key="id" className="clickable" title={Strings.id} value={d => d.id} />,
        <Column key="name" title={Strings.name} value={d => d.name} />,
      ]}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  editOrganizationGroup: data => dispatch(openModalAction('edit-organization-group', data)),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  onNavigate: path => dispatch(push(path)),
});

GroupOrganizations.propTypes = {
  groupDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    organizations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    maintainers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  groupUsers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    org_id: PropTypes.string.isRequired,
  })).isRequired,
  onRefresh: PropTypes.func.isRequired,
  openConfirmModal: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(GroupOrganizations);
