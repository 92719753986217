const isTableAction = action => action.table !== undefined;

export const tableActionsEnum = {
  setColumnsVisibility: 'TABLE_SET_COLUMNS_VISIBILITY',
  setColumnsSizing: 'TABLE_SET_COLUMNS_SIZING',
  setOrder: 'TABLE_SET_ORDER',
  setFilter: 'TABLE_SET_FILTER',
  refreshTable: 'TABLE_REFRESH_TABLE',
};

const table = (state = {}, action) => {
  switch (action.type) {
    case tableActionsEnum.setColumnsVisibility:
      return {
        ...state,
        columnVisibility: action.table.columnVisibility,
      };

    case tableActionsEnum.setColumnsSizing:
      return {
        ...state,
        columnSizing: action.table.columnSizing,
      };
    case tableActionsEnum.refreshTable:
      return {};
    default:
      return state;
  }
}

export const tableActions = {
  setColumnsVisibility: (id, columnVisibility) => {
    return {
      type: tableActionsEnum.setColumnsVisibility,
      table: {
        id,
        columnVisibility,
      },
    };
  },
  setColumnsSizing: (id, columnSizing) => {
    return {
      type: tableActionsEnum.setColumnsSizing,
      table: {
        id,
        columnSizing,
      },
    };
  },
};

export default (state = {}, action) => {
  if (isTableAction(action)) {
    return {
      ...state,
      [action.table.id]: table(state[action.table.id], action),
    };
  }
  return state;
};
