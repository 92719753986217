import React from 'react';
import PropTypes from 'prop-types';

import './graph.scss';
import GenericCategoryGraph, { chartTypeEnum } from './GenericCategoryGraph';
import { getFiltersDefinition } from '../AdvancedFilters/FiltersDefinition';

const seriesForStateType = (stateType, colorPalette) => {
  const states = getFiltersDefinition()[stateType].values.filter(f => f.value !== 'all');
  return states.map((f, i) => ({
    name: f.text,
    color: colorPalette?.interpolate(states.length > 0 ? i / states.length : 0.5),
    selector: item => item[f.text],
  }));
};

export const GetOrgsStateDataSeries = colorPalette => [
  ...seriesForStateType('org_administrative_state', colorPalette),
  ...seriesForStateType('org_operational_state', colorPalette),
  ...seriesForStateType('org_business_state', colorPalette),
];

export default function OrganizationsState(props) {
  const { graphData, title, dataSelector, colorPalette, graphType } = props;
  return (
    <GenericCategoryGraph
      graphData={graphData}
      hasBorder
      forceCondensed
      xAxisTopPosition={false}
      yAxisWidth={graphType === chartTypeEnum.horizontalStackedBar || graphType === chartTypeEnum.horizontalBar ? 100 : 40}
      yAxisUnit=""
      tooltipTitle={title || 'Organizations States'}
      dataSeries={
        dataSelector ? [{ name: title, selector: item => item[dataSelector] }] : GetOrgsStateDataSeries(colorPalette)
      }
      categorySelector={item => item.type}
      hasReferenceAreas
      graphType={graphType}
      colorPalette={colorPalette}
    />
  );
}

OrganizationsState.propTypes = { graphData: PropTypes.array };
