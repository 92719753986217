import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import Wizard from '../containers/Modal/Wizard';
import { validateEmail, validateFirstName, validateLastName, validatePhoneNo } from '../utils/validators/rpmPatient';
import Strings from '../Strings';
import { Input, PhoneInputField, SelectField } from '../containers/Form';
import { notificationActions } from '../components/Notification/redux/actions';
import { closeModal } from '../actions/modal';
import { CAP_MANAGER, DOCTOR, ORGANIZATION_MANAGER } from '../utils/userRoles';
import { actions } from '../pages/Organizations/redux/actions';
import { GET_ROLES_DETAIL_ERROR } from '../pages/Organizations/redux/constants';

export const userTypes = {
  ADMIN: DOCTOR,
  CAP_MANAGER: CAP_MANAGER,
  ORGANIZATION_MANAGER: ORGANIZATION_MANAGER,
};

function EditUserModal(props) {
  const getUser = (user, organization_id) => {
    const ret = user
      ? {
          ...user,
          first_name: user.first_name ? user.first_name : user.name ? user.name.split(' ')[0] : '',
          last_name: user.last_name ? user.last_name : user.name ? user.name.split(' ')[1] : '',
          role: props.data?.userType,
          id: user.masked_id || user.id,
        }
      : {
          timezone: moment.tz.guess(),
          anonymize: props.data?.userType !== userTypes.ADMIN,
        };
    if (organization_id) {
      ret.org_id = organization_id;
    }
    return ret;
  };

  const [user, setUser] = React.useState(getUser(props.data?.user, props.data?.organization_id));
  const [roles, setRoles] = React.useState([]);
  const [highlightInvalidFields, setHighlightMissingFields] = React.useState(false);
  const [validationErrors, setValidationErrors] = React.useState([]);
  const [saveBtnClicked, setSaveBtnClicked] = React.useState(false);

  const userType = props.data?.userType;

  useEffect(() => {
    if (props.data?.organization_id && !props.data?.user?.id) {
      props.getOrgRoles({organizationId: props.data?.organization_id}).then(response => {
        if (response.type !== GET_ROLES_DETAIL_ERROR && response?.response) {
          const r = response.response.filter(r => r.archetype === userType);
          setRoles(r);
          if (r.length === 1) {
            setUser(u => ({
              ...u,
              role: r[0].role_uuid,
            }));
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    const serverErrors = validationErrors.filter(v => v.errorType === 'server');
    const errors = validateFirstName(user.first_name)
      .concat(validateLastName(user.last_name))
      .concat(validatePhoneNo(user.phone, 'phone', true))
      .concat(validateEmail(user.email, true));

    if (!user.username) {
      errors.push({ property: 'username', missing: true, errors: [] });
    }

    if (!user.role) {
      errors.push({ property: 'role', missing: true, errors: [] });
    }
    setValidationErrors(errors.concat(serverErrors));
  }, [user]);

  const onSubmit = () => {
    if (saveBtnClicked) {
      return;
    }
    setSaveBtnClicked(true);

    props.onSubmit(user).then(response => {
      if (response) {
        if (response.type === `${props.data.actionType}/error`) {
          const error = response.response?.data?.standard_error?.message;
          props.showNotification(error, 5000, true);
          if (response.response?.data?.standard_error?.params_errors) {
            const errors = [];
            Object.entries(response.response?.data?.standard_error?.params_errors).forEach(([key, value]) => {
              errors.push({ property: key, missing: false, errors: [value], errorType: 'server' });
            });
            setValidationErrors(errors);
          }
        } else {
          props.showNotification(Strings.formatString(Strings.userSaved, user.username));
          props.closeModalWithNextAction();
        }
      }
      return response;
    });
    setSaveBtnClicked(false);
  };

  const onDataChange = (name, value) => {
    setUser(u => ({
      ...u,
      [name]: value,
    }));
    if (validationErrors.find(v => v.property === name)) {
      setValidationErrors(errors => errors.filter(v => v.property !== name));
    }
  };

  const pages = [
    {
      id: 'rpm-user-page',
      title: props.data?.user?.id ? Strings.editUser : Strings.addUser,
      content: (
        <React.Fragment>
          <div className="horizontal-flex">
            <Input
              name="first_name"
              id="first_name"
              label={Strings.firstName}
              type="text"
              value={user.first_name || ''}
              onChange={e => onDataChange('first_name', e.target.value)}
              isRequired
              highlightInvalid={highlightInvalidFields}
              errorsForTooltip={validationErrors.find(v => v.property === 'firstName')?.errors || []}
            />
            <Input
              name="last_name"
              id="last_name"
              label={Strings.lastName}
              type="text"
              value={user.last_name || ''}
              onChange={e => onDataChange('last_name', e.target.value)}
              isRequired
              highlightInvalid={highlightInvalidFields}
              errorsForTooltip={validationErrors.find(v => v.property === 'lastName')?.errors || []}
            />
          </div>
          <Input
            name="username"
            id="username"
            label={Strings.userName}
            type="text"
            value={user.username || ''}
            onChange={e => onDataChange('username', e.target.value)}
            isRequired
            highlightInvalid={highlightInvalidFields}
            errorsForTooltip={validationErrors.find(v => v.property === 'username')?.errors || []}
          />
          <PhoneInputField
            align="left"
            name="phone"
            id="phone"
            value={user.phone}
            onChange={e => onDataChange('phone', e.target.value)}
            label={Strings.phoneNumber}
            isRequired
            highlightInvalid={highlightInvalidFields}
            errorsForTooltip={validationErrors.find(v => v.property === 'phone')?.errors || []}
          />
          <Input
            name="email"
            id="email"
            label={Strings.email}
            type="text"
            value={user.email || ''}
            onChange={e => onDataChange('email', e.target.value)}
            isRequired
            highlightInvalid={highlightInvalidFields}
            errorsForTooltip={validationErrors.find(v => v.property === 'email')?.errors || []}
          />
          {roles.length > 1 && (
            <SelectField
              name="role"
              id="role"
              label={Strings.selectRole}
              value={user.role}
              onChange={o => onDataChange('role', o.value)}
              data={roles.map(r => ({ value: r.role_uuid, label: r.title }))}
              isRequired
              highlightInvalid={highlightInvalidFields}
              errorsForTooltip={validationErrors.find(v => v.property === 'role')?.errors || []}
            />
          )}
          {userType === userTypes.ADMIN && (
            <div className="horizontal-flex">
              <div className="checkbox-container border">
                <Input
                  type="checkbox"
                  name="writePrivileges"
                  id="writePrivileges"
                  label={Strings.writePrivileges}
                  checked={user.read_only ? false : true}
                  onChange={e => onDataChange('read_only', !e.target.checked)}
                />
              </div>
              <div className="checkbox-container border">
                <Input
                  type="checkbox"
                  name="timerTrigger"
                  id="timerTrigger"
                  label={Strings.adminTimerRights}
                  checked={user.timer_off ? false : true}
                  onChange={e => onDataChange('timer_off', !e.target.checked)}
                />
              </div>
            </div>
          )}
          <div className="checkbox-container border">
            <Input
              type="checkbox"
              name="anonymize"
              id="anonymize"
              label={Strings.maskPHIData}
              checked={user.anonymize}
              onChange={e => onDataChange('anonymize', e.target.checked)}
            />
          </div>
        </React.Fragment>
      ),
      emptyFieldsCount: validationErrors.filter(v => v.missing).length,
      canGoNext: validationErrors.length === 0 && !saveBtnClicked,
    },
  ];

  return (
    <Wizard
      name="edit-user"
      pages={pages}
      onNextButtonHover={e => setHighlightMissingFields(e)}
      onSubmit={onSubmit}
      showPagesFilter={false}
    />
  );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  getOrgRoles: pageRequest => dispatch(actions.getOrgRole(pageRequest)),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
  onCancel: () => dispatch(closeModal(ownProps.name)),
  onSubmit: data => dispatch(ownProps.data.action(data)),
  closeModalWithNextAction: () => {
    dispatch(closeModal('edit-user'));
    if (ownProps.data.onSuccess) ownProps.data.onSuccess();
  },
});

export default connect(null, mapDispatchToProps)(EditUserModal);
