import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { PureComponent, useEffect, useState } from 'react';
import {
  Area,
  ComposedChart,
  Customized,
  Line,
  Rectangle,
  ReferenceArea,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { DATE_FORMAT_YEAR_MONTH_DAY, TIME_FORMAT_12, TIME_FORMAT_12_LOWERCASE } from '../../constants';
import Strings from '../../Strings';
import {
  GRAPH_AXIS_LINE_COLOR,
  GRAPH_AXIS_LINE_WIDTH,
  MIN_GRAPH_HEIGHT,
  OPTIMUM_PIXELS_PER_DAY,
} from './GraphConstants';
import { CustomizedXAxisProportional, calcXAxisParams_Time, calcXAxisParams_TimeMobile } from './CompositeGraph/Axes';
import { CustomReferenceArea, CustomReferenceLine, MobileTooltipReferenceLine, calcDays } from './GraphComponents';
import { X_AXIS_CHART_HEIGHT_TINY, Y_AXIS_WIDTH_TINY } from './CompositeGraph/Constants';
import { isMobile } from 'react-device-detect';
import CompositeGraphXAxis from './CompositeGraph/CompositeGraphXAxis';
import { getColorsForColorTheme } from '../../utils/colorTheme';
import { scatterDotShapeEnum, symbolDotSizeEnum } from './CompositeGraph/Formatting';

const Y_AXIS_WIDTH = 60;

const graphAlternateRowBackground = '#F4F8FC';
const graphRowBackground = '#fff';
const graphAxisFontSize = 12;
const graphAxisFontColor = '#000';
const graphYAxisFontColor = '#4F4F4F';
const graphAxisFontColorWeekend = '#bbb';

const graphValidTakeBarColor = '#d5e8c2';
const graphValidTakeDotColor = '#75b333';
const graphUnscheduledTakeDotColor = '#f9a452';
const graphMissedTakeDotColor = '#db537e';
const graphTakeDotRadius = 6;

// for plot with selecting refHour
//const graphReferenceLineColor = '#7CD1EF';
//const graphReferenceLineColorSelected = '#0460A9';
const graphReferenceLineColor = '#0460A9';
const graphReferenceLineWidth = 2;
const graphReferenceVerticalLineColor = '#eeeeee';

const scheduledTake = 'scheduled_take';
const missedTake = 'missed_take';
const unscheduledTake = 'unscheduled_take';

export const takesFormat = [
  {
    id: Strings.takeTypes.scheduled,
    label: Strings.takeTypes.scheduled,
    format: {
      stroke: graphValidTakeDotColor,
      dotShape: scatterDotShapeEnum.circle,
      dotSize: symbolDotSizeEnum.small,
    },
  },
  {
    id: Strings.takeTypes.unscheduled,
    label: Strings.takeTypes.unscheduled,
    format: {
      stroke: graphUnscheduledTakeDotColor,
      dotShape: scatterDotShapeEnum.circle,
      dotSize: symbolDotSizeEnum.small,
    },
  },
  {
    id: Strings.takeTypes.missed,
    label: Strings.takeTypes.missed,
    format: {
      stroke: graphMissedTakeDotColor,
      dotShape: scatterDotShapeEnum.circle,
      dotSize: symbolDotSizeEnum.small,
    },
  },
];
class CustomizedYAxisTick extends PureComponent {
  render() {
    const {
      x,
      y,
      height,
      width,
      payload,
      axisLine,
      dateMin,
      dateMax,
      onlyHour,
      withoutHeader,
      extraPaddingTB,
      isRight,
    } = this.props; // stroke
    const bkHeight = Math.floor(height / 12);
    const bkY = Math.floor(height / 24);
    const textDx = isRight ? (width === undefined ? 35 : width / 2 - 2) : width === undefined ? -35 : -width / 2;

    // console.log(this.props.index, height, y, bkHeight, bkY);

    return (
      <g transform={`translate(${x},${y})`}>
        {!withoutHeader && payload.value === 0 && (
          <g>
            <rect
              x={-x}
              y={0 - payload.offset - axisLine.strokeWidth / 2 - extraPaddingTB}
              width={x + 2}
              height={axisLine.strokeWidth}
              fill={axisLine.stroke}
            />
            <rect
              y={-y}
              x={0 - payload.offset - axisLine.strokeWidth / 2 + GRAPH_AXIS_LINE_WIDTH}
              height={y + 1}
              width={axisLine.strokeWidth}
              fill={axisLine.stroke}
            />
            <text
              className="span"
              fontSize={graphAxisFontSize}
              dy={-35}
              dx={-width / 2}
              textAnchor="middle"
              fill={graphAxisFontColorWeekend}
            >
              {dateMin.format('MMM D -').toUpperCase()}
            </text>
            <text
              className="span"
              fontSize={graphAxisFontSize}
              dy={-20}
              dx={-width / 2}
              textAnchor="middle"
              fill={graphAxisFontColorWeekend}
            >
              {dateMax.format('MMM D').toUpperCase()}
            </text>
          </g>
        )}

        {/* <rect
          height={bkHeight}
          y={bkY}
          dx={-20}
          x={-width - 2}
          width={width + 3}
          fill={payload.value % 4 === 0 && payload.value < 24 ? graphAlternateRowBackground : graphRowBackground}
          opacity={payload.value % 4 === 0 && payload.value < 24 ? 1 : 0}
        /> */}
        <text
          className="span"
          fontSize={graphAxisFontSize}
          dy={5}
          dx={textDx}
          textAnchor="middle"
          fill={graphYAxisFontColor}
        >
          {payload.value < 2 || payload.value > 22
            ? ''
            : payload.value < 10
            ? `0${payload.value}${onlyHour ? '' : ':00'}`
            : `${payload.value}${onlyHour ? '' : ':00'}`}
        </text>
      </g>
    );
  }
}
CustomizedYAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  stroke: PropTypes.any,
  payload: PropTypes.any,
  axisLine: PropTypes.any,
  dateMin: PropTypes.any,
  dateMax: PropTypes.any,
  onlyHour: PropTypes.bool,
  withoutHeader: PropTypes.bool,
  extraPaddingTB: PropTypes.number,
};

const isValidMoment = date => {
  return date && date instanceof moment;
};

function getSeries_RefLine(data, refHour, minDate, maxDate) {
  const ret = [];
  refHour.refHours.forEach(rh => {
    ret.push({
      x:
        moment(rh.hour.unix() * 1000)
          .startOf('day')
          .unix() +
        12 * 60 * 60,
      y: rh.hour.hours() + rh.hour.minutes() / 60,
      marginPre: rh.marginPre,
      marginPost: rh.marginPost,
    });
  });
  if (refHour.startDate == null && refHour.refHours.length > 0 && data.length > 0) {
    const firstHour = refHour.refHours[0];
    ret.splice(0, 0, {
      x: minDate.unix(),
      y: firstHour.hour.hours() + firstHour.hour.minutes() / 60,
      marginPre: firstHour.marginPre,
      marginPost: firstHour.marginPost,
    });
  }
  if (refHour.endDate == null && refHour.refHours.length > 0 && data.length > 0) {
    const lastHour = refHour.refHours[refHour.refHours.length - 1];
    ret.push({
      x: maxDate.unix(),
      y: lastHour.hour.hours() + lastHour.hour.minutes() / 60,
      marginPre: lastHour.marginPre,
      marginPost: lastHour.marginPost,
    });
  }

  //console.log('getSeries_RefLine', ret);

  return ret;
}

function getSeries_RefLineMargins(data, refHour, minDate, maxDate) {
  if (refHour?.refHours?.length < 2) {
    return;
  }
  const refLine = getSeries_RefLine(data, refHour, minDate, maxDate);
  const ret = refLine.map(r => {
    return { x: r.x, y: [Math.max(r.y - r.marginPre, 0), Math.min(r.y + r.marginPost, 24)] };
  });

  return ret;
}

function getSeries_RefLineJoint(refHourJoint) {
  const ret = [];
  ret.push({
    x:
      moment(refHourJoint.startDate.unix() * 1000)
        .startOf('day')
        .unix() +
      12 * 60 * 60,
    y: refHourJoint.startDate.hours() + refHourJoint.startDate.minutes() / 60,
  });
  ret.push({
    x:
      moment(refHourJoint.endDate.unix() * 1000)
        .startOf('day')
        .unix() +
      12 * 60 * 60,
    y: refHourJoint.endDate.hours() + refHourJoint.endDate.minutes() / 60,
  });
  return ret;
}

function getSeries_All(data) {
  const ret = [];
  data.forEach(day => {
    ret.push({
      x: day.x,
      y: 0,
      all: day,
    });
  });

  return ret;
}

function getSeries_ValidTakes(data) {
  const ret = [];
  data.forEach(day => {
    if (day.validTakes != null && day.validTakes.length > 0) {
      day.validTakes.forEach(val => {
        ret.push({
          x: day.x,
          y: val,
        });
      });
    } else {
      ret.push({ x: day.x });
    }
  });
  return ret;
}

function getSeries_InvalidTakes(data) {
  const ret = [];
  data.forEach(day => {
    if (day.unscheduledTakes != null && day.unscheduledTakes.length > 0) {
      day.unscheduledTakes.forEach(val => {
        ret.push({
          x: day.x,
          y: val,
        });
      });
    } else {
      ret.push({ x: day.x });
    }
  });

  return ret;
}

function getSeries_EmptyDays(data) {
  const ret = [];
  data.forEach(day => {
    if (day.missedTakes != null && day.missedTakes.length > 0) {
      day.missedTakes.forEach(val => {
        ret.push({
          x: day.x,
          y: val,
        });
      });
    } else {
      ret.push({ x: day.x });
    }
  });

  return ret;
}

function dtMinusHourImpl(dt, refDtHour) {
  const h = dt.hours();
  const m = dt.minutes();
  const hm = h + m / 60;

  const refh = refDtHour.hours();
  const refm = refDtHour.minutes();
  const refhm = refh + refm / 60;

  const isMinus = hm - refhm < 0;
  const diff = Math.abs(hm - refhm);
  const diffH = Math.floor(diff);
  const diffM = Math.round((diff - diffH) * 60);

  return {
    isMinus,
    diffH,
    diffM,
  };
}

function dtMinusHour(dt, refDtHour) {
  const diff = dtMinusHourImpl(dt, refDtHour);
  const ret = diff.diffH + diff.diffM / 60;
  return diff.isMinus ? -ret : ret;
}

function dtMinusHourFormatted(dt, refDtHour) {
  const diff = dtMinusHourImpl(dt, refDtHour);
  const ret = `${diff.isMinus ? '-' : '+'}${diff.diffH}h ${diff.diffM}m`;
  return ret;
}
function dayWithinSchedule(day, selectedRefHour) {
  if (selectedRefHour.startDate == null && selectedRefHour.endDate == null) return true;
  if (
    selectedRefHour.startDate == null &&
    selectedRefHour.endDate != null &&
    day.clone().startOf('day') <= selectedRefHour.endDate.clone().startOf('day')
  )
    return true;
  if (
    selectedRefHour.startDate != null &&
    selectedRefHour.endDate == null &&
    day.clone().startOf('day') >= selectedRefHour.startDate.clone().startOf('day')
  )
    return true;
  if (
    selectedRefHour.startDate != null &&
    selectedRefHour.endDate != null &&
    day.clone().startOf('day') >= selectedRefHour.startDate.clone().startOf('day') &&
    day.clone().startOf('day') <= selectedRefHour.endDate.clone().startOf('day')
  )
    return true;
  return false;
}

export default function CapEventsGraph(props) {
  const PrepareData = (capTakes, selectedRefHour, minDate, maxDate, showWeekLines) => {
    const data = [];

    // extract subsets by take type
    const validTakesCurrentPeriod = capTakes
      .filter(d => d.type === scheduledTake)
      .map(d => {
        return {
          Date: d.timestamp,
          ScheduledDate: d.scheduledTimestamp,
        };
      });
    const unscheduledTakesCurrentPeriod = capTakes.filter(d => d.type === unscheduledTake).map(d => d.timestamp);
    const missedTakesCurrentPeriod = capTakes.filter(d => d.type === missedTake).map(d => d.scheduledTimestamp);

    const constData = {
      halfHeight: 1,
      height: 2,
      heightAll: 24,
      bars: [],
    };

    const isSameDay = (d1, d2) => {
      return d1.format(DATE_FORMAT_YEAR_MONTH_DAY) === d2.format(DATE_FORMAT_YEAR_MONTH_DAY);
    };

    for (let index = minDate; index < maxDate; index = moment(index).add(1, 'days')) {
      const validTakesDay = validTakesCurrentPeriod.filter(d => isSameDay(d.Date, index));

      const unscheduledTakesDay = unscheduledTakesCurrentPeriod
        .filter(d => isSameDay(d, index))
        .map(t => t.hours() + t.minutes() / 60);
      const unscheduledTakesDayTimestmaps = unscheduledTakesCurrentPeriod.filter(d => isSameDay(d, index));

      const missedTakesDay = missedTakesCurrentPeriod
        .filter(d => isSameDay(d, index))
        .map(t => t.hours() + t.minutes() / 60);
      const missedTakesDayTimestmaps = missedTakesCurrentPeriod.filter(d => isSameDay(d, index));

      let barTake;
      if (selectedRefHour !== undefined && validTakesDay.length > 0 && dayWithinSchedule(index, selectedRefHour))
        barTake = validTakesDay.find(
          t => t.ScheduledDate.hours() + t.ScheduledDate.minutes() / 60 === selectedRefHour.refHour,
        );

      const bars = [];
      let barHeight = 0;
      validTakesDay.forEach(t => {
        const h1 = t.Date.hours() + t.Date.minutes() / 60 - barHeight;
        const h2 = -dtMinusHour(t.Date, t.ScheduledDate);
        bars.push(h1);
        bars.push(h2);
        barHeight = barHeight + h1 + h2;
      });

      data.push({
        x: index.unix() + 12 * 60 * 60,
        moment: index,
        dayOfWeek: index.day(),

        validTakes: validTakesDay.length > 0 ? validTakesDay.map(t => t.Date.hours() + t.Date.minutes() / 60) : null,
        validTakesTimestamps: validTakesDay.length > 0 ? validTakesDay : null,
        validTakesDeltas:
          validTakesDay.length > 0 ? validTakesDay.map(t => dtMinusHourFormatted(t.Date, t.ScheduledDate)) : null,

        unscheduledTakes: unscheduledTakesDay.length > 0 ? unscheduledTakesDay : null,
        unscheduledTakesTimestamps: unscheduledTakesDayTimestmaps.length > 0 ? unscheduledTakesDayTimestmaps : null,

        missedTakes: missedTakesDay.length > 0 ? missedTakesDay : null,
        missedTakesTimestamps: missedTakesDayTimestmaps.length > 0 ? missedTakesDayTimestmaps : null,

        // For plot with selecting refHour
        // bar1: barTake !== undefined ? barTake.Date.hours() + barTake.Date.minutes() / 60 : null,
        // bar2: barTake !== undefined ? -dtMinusHour(barTake.Date, barTake.ScheduledDate) : null,

        ...constData,
        bars,
      });
    }

    // normalize bar counts
    const maxBarCount = Math.max(...data.filter(d => d.bars && d.bars.length > 0).map(d => d.bars.length), 0);
    if (maxBarCount > 0) {
      data.forEach(d => {
        if (d.bars && d.bars.length < maxBarCount)
          d.bars.push(Array.from({ length: maxBarCount - d.bars.length }, (_, i) => null));
      });
    }

    return data;
  };

  const _unused_PrepareRefHours_SelectingRefHour = (refHours, minDate, maxDate) => {
    const ret = [];
    refHours.forEach(rh => {
      const startDate = rh.startDate == null || rh.startDate <= minDate ? null : rh.startDate;
      const endDate = rh.endDate == null || rh.endDate >= maxDate ? null : rh.endDate;

      ret.push({
        startDate,
        endDate,
        refHour: rh.refHour.hours() + rh.refHour.minutes() / 60,
        selected: rh.selected,
      });
    });

    return ret;
  };

  const PrepareRefHours = (refHours, minDate, maxDate) => {
    const ret = refHours.map(rh => {
      const startDate = rh.start_date == null || rh.start_date <= minDate ? null : rh.start_date;
      const endDate = rh.end_date == null || rh.end_date >= maxDate ? null : rh.end_date;
      return {
        scheduleId: rh.scheduleId,
        startDate,
        endDate,
        refHours: rh.refHours,
        marginPre: rh.marginPre,
        marginPost: rh.marginPost,
        continuationOf: rh.continuationOf,
      };
    });
    return ret;
  };
  const PrepareRefHoursJoints = refHours => {
    const ret = [];
    const scheduleIds = _.uniq(refHours.map(r => r.scheduleId)).sort((a, b) => a - b);
    for (let i = 0; i < scheduleIds.length - 1; i += 1) {
      const rhPrev = refHours
        .filter(rh => rh.scheduleId === scheduleIds[i])
        .sort(
          (a, b) => a.refHours[0].hours() + a.refHours[0].minutes() - b.refHours[0].hours() + b.refHours[0].minutes(),
        );
      const rhNext = refHours
        .filter(rh => rh.scheduleId === scheduleIds[i + 1])
        .sort(
          (a, b) => a.refHours[0].hours() + a.refHours[0].minutes() - b.refHours[0].hours() + b.refHours[0].minutes(),
        );
      if (rhPrev.length === rhNext.length) {
        for (let indexRh = 0; indexRh < rhPrev.length; indexRh += 1) {
          const startDate = rhPrev[indexRh].refHours[rhPrev[indexRh].refHours.length - 1];
          const endDate = rhNext[indexRh].refHours[0];
          ret.push({ startDate, endDate });
        }
      }
    }
    return ret;
  };

  const PrepareRefHoursForMargins = refHours => {
    const ret = [];
    refHours.forEach(rh => {
      const refHours1 = rh.refHours.map(hour => {
        return {
          hour,
          marginPre: rh.marginPre,
          marginPost: rh.marginPost,
        };
      });
      const previousRefHour = rh.continuationOf
        ? ret.find(r => r.scheduleId === rh.continuationOf && !r.nextRhMatched)
        : undefined;
      if (previousRefHour) {
        previousRefHour.refHours = previousRefHour.refHours.concat(refHours1);
        previousRefHour.endDate = rh.endDate;
        previousRefHour.nextRhMatched = true;
      } else {
        ret.push({
          scheduleId: rh.scheduleId,
          startDate: rh.startDate,
          endDate: rh.endDate,
          refHours: refHours1,
        });
      }
    });
    return ret;
  };

  const calcXAxisParams_PseudoCat = (dateMinDay, dateMaxDay, graphWidth) => {
    const ret = calcXAxisParams_Time(
      dateMinDay,
      dateMaxDay,
      false,
      graphWidth,
      OPTIMUM_PIXELS_PER_DAY,
      ['d', 'm', 'y'],
      ['2h', '4h', '7d', '3m'],
    );
    return ret;
  };

  const calcRefLines = (days, xAxisParams) => {
    // hours => 1 day refLines
    if (xAxisParams.unit === 'hour') {
      return days.slice(1, days.length).map(d => d.unix());
    }

    // days => 1 week refLines
    if (xAxisParams.unit === 'day') {
      const ret = [];
      for (
        let i = moment(xAxisParams.min * 1000)
          .startOf('week')
          .add(1, 'week');
        i.unix() < xAxisParams.max;
        i.add(1, 'weeks')
      ) {
        ret.push(i.unix());
      }
      return ret;
    }

    // weeks or months => 1 month refLines
    if (xAxisParams.unit === 'month') {
      const ret = [];
      for (
        let i = moment(xAxisParams.min * 1000)
          .startOf('month')
          .add(1, 'month');
        i.unix() < xAxisParams.max;
        i.add(1, 'months')
      ) {
        ret.push(i.unix());
      }
      return ret;
    }

    // no refLines otherwise
    return [];
  };

  const { capTakes, refHours, showWeekLines, minDate, maxDate, onSetMobileTooltipPayload } = props;

  const [mobileTooltipX, setMobileTooltipX] = useState();

  useEffect(() => {
    setMobileTooltipX(undefined);
    onSetMobileTooltipPayload(undefined);
  }, [minDate]);

  const xAxisParams = isMobile
    ? calcXAxisParams_TimeMobile({ start: minDate, end: maxDate, type: props.dateRangeType })
    : calcXAxisParams_PseudoCat(minDate, maxDate, props.graphWidth);

  const refHoursValidated = PrepareRefHours(refHours, minDate, maxDate);
  const refHoursValidatedForMargins = PrepareRefHoursForMargins(refHoursValidated);
  const refHoursJoints = PrepareRefHoursJoints(refHoursValidated);
  const hasSchedule = refHoursValidated !== undefined && refHoursValidated.length > 0;
  const selectedRefHour = refHoursValidated.find(rh => rh.selected);

  const data = PrepareData(capTakes, selectedRefHour, minDate, maxDate, showWeekLines);
  const maxBarCount = Math.max(...data.filter(d => d.bars).map(d => d.bars.length));

  const days = calcDays(minDate, maxDate);
  const refLines = calcRefLines(days, xAxisParams);

  const CustomTooltip = ({ active, payload }) => {
    // console.log('tooltipPayload', payload);
    if (active && payload && payload.length > 0) {
      let payloadP0 = payload.find(p => p.name === 'P0')?.payload;
      const firstX = payload[0].payload.x;
      if (payloadP0.x !== firstX) {
        // console.log(payloadP0.x, firstX, 'BAAAD!!!! Payload[0].x <> PayloadP0.x');
        const foundP0 = data.find(p => p.x === firstX);
        // console.log('payloadP0', payloadP0);
        // console.log('foundP0', foundP0);

        if (foundP0) payloadP0 = { x: firstX, y: 0, all: foundP0 };
      }

      if (payloadP0 === undefined || payloadP0.all == null) return null; // exclude empty data
      if (!isValidMoment(payloadP0.all.moment)) return null; // exclude entries not being date
      const hasValidTakes =
        payloadP0.all.validTakesTimestamps !== null && payloadP0.all.validTakesTimestamps.length > 0;
      const hasUnscheduledTakes =
        payloadP0.all.unscheduledTakesTimestamps !== null && payloadP0.all.unscheduledTakesTimestamps.length > 0;
      const hasMissedTakes =
        payloadP0.all.missedTakesTimestamps !== null && payloadP0.all.missedTakesTimestamps.length > 0;

      // console.log('tooltipPayload', payload);
      // console.log('foundPayloadP0', payloadP0);
      // console.log('hasValid=', hasValidTakes, ', hasUnscheduled=', hasUnscheduledTakes, ', hasMissed=', hasMissedTakes);

      if (!(hasValidTakes || hasUnscheduledTakes || hasMissedTakes)) return null;

      let takesText = [];
      hasValidTakes &&
        payloadP0.all.validTakesTimestamps.forEach((t, i) =>
          takesText.push(
            t.Date.format(TIME_FORMAT_12) +
              (hasSchedule && isValidMoment(t.ScheduledDate)
                ? ` (${Strings.delta}: ${payloadP0.all.validTakesDeltas[i]})`
                : ''),
          ),
        );

      hasUnscheduledTakes &&
        payloadP0.all.unscheduledTakesTimestamps.forEach((t, i) =>
          takesText.push(t.format(TIME_FORMAT_12) + ` (${Strings.unscheduled})`),
        );

      hasMissedTakes &&
        payloadP0.all.missedTakesTimestamps.forEach((t, i) => takesText.push(t.format(TIME_FORMAT_12) + ` (missed)`));

      // sort by hour
      takesText = takesText.sort((a, b) => moment(a.substring(0, 7)) - moment(b.substring(0, 7)));
      return (
        <div className="customTooltip">
          {takesText && (
            <div>
              <p className="customTooltipTitle">
                {Strings.takes}
                <br />
                {moment(payload[0].payload.x * 1000).format('ll')}
              </p>
              <div className="customTooltipDescr">
                {takesText.map((t, i) => (
                  <div key={i}>{t}</div>
                ))}
              </div>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  const TakeBar = props => {
    const { formattedGraphicalItems, takeBarStart, takeBarEnd } = props;

    const firstSeries = formattedGraphicalItems.find(s => s.item.key === takeBarStart);
    const secondSeries = formattedGraphicalItems.find(s => s.item.key === takeBarEnd);

    return firstSeries?.props?.points.map((_, index) => {
      if (!firstSeries?.props?.points[index]?.y || !secondSeries?.props?.points[index]?.y) {
        return <></>;
      }
      const firstSeriesPoint = firstSeries?.props?.points[index];
      const secondSeriesPoint = secondSeries?.props?.points[index];
      const yDiff = firstSeriesPoint.y - secondSeriesPoint.y;

      return (
        <Rectangle
          key={`takebar_${index}`}
          width={graphTakeDotRadius}
          height={-yDiff}
          x={firstSeriesPoint.x - graphTakeDotRadius / 2}
          y={firstSeriesPoint.y}
          fill={graphValidTakeBarColor}
          radius={graphTakeDotRadius}
          fillOpacity={1}
        />
      );
    });
  };

  const chartData = data;
  const yTicksCount = 11;

  const noTakes = chartData && !chartData.some(d => d.validTakes || d.missedTakes || d.unscheduledTakes);

  const useTinyAxes = isMobile;
  const axisYExtraPaddingTB = graphTakeDotRadius / 2 + 1;

  const linesStroke = GRAPH_AXIS_LINE_COLOR;
  const linesStrokeWidth = isMobile ? 0 : GRAPH_AXIS_LINE_WIDTH;

  const calcMobileTooltipPayload = x => {
    if (x === undefined) return undefined;

    // console.log('searching ', x, ' in:');
    // console.log(chartData);

    //     const dayData = chartData.find(d => d.x === x);
    let dayData = undefined;
    if (x < chartData[0].x) {
      dayData = chartData[0];
    } else if (x > chartData[chartData.length - 1].x) {
      dayData = chartData[chartData.length - 1];
    } else {
      let minDiff = Math.abs(chartData[0].x - x);
      dayData = chartData[0];
      chartData.forEach(chd => {
        let diff = Math.abs(chd.x - x);
        if (diff < minDiff) {
          minDiff = diff;
          dayData = chd;
        }
      });
    }

    const ret = { x: dayData.x, takes: [] };
    dayData?.validTakesTimestamps?.forEach(t => {
      ret.takes.push({ name: Strings.takeTypes.scheduled, y: t.Date?.format(TIME_FORMAT_12_LOWERCASE) });
    });
    dayData?.missedTakesTimestamps?.forEach(t => {
      ret.takes.push({ name: Strings.takeTypes.missed, y: t?.format(TIME_FORMAT_12_LOWERCASE) });
    });
    dayData?.unscheduledTakesTimestamps?.forEach(t => {
      ret.takes.push({ name: Strings.takeTypes.unscheduled, y: t?.format(TIME_FORMAT_12_LOWERCASE) });
    });

    // console.log(ret);

    return ret;
  };

  const onChartClick = e => {
    if (!isMobile) return;

    if (e) {
      // console.log('e.activePayload', e.activePayload, e);
      if (e.activePayload && e.activePayload.length > 0 && e.activePayload[0].name && e.activePayload[0].payload) {
        const payloadX = e.activePayload[0].payload.x;

        if (payloadX >= minDate.unix() && payloadX <= maxDate.unix()) {
          const tooltipPayload = calcMobileTooltipPayload(payloadX);
          if (tooltipPayload) {
            const payloadDifferent = !mobileTooltipX || mobileTooltipX !== tooltipPayload.x;

            if (payloadDifferent) {
              // console.log('tooltipPayload', tooltipPayload);
              if (tooltipPayload.takes.length > 0) {
                setMobileTooltipX(tooltipPayload.x);
                onSetMobileTooltipPayload(tooltipPayload);
                return;
              }
            }
          }
        }
      }
    }
    setMobileTooltipX(undefined);
    onSetMobileTooltipPayload(undefined);
  };

  // console.log('chartData', chartData);
  // console.log('refHoursValidated', refHoursValidated);
  // console.log('refHoursValidatedForMargins', refHoursValidatedForMargins);
  // if(refHoursValidatedForMargins[0]) console.log('getSeries_RefLine...', getSeries_RefLine(chartData, refHoursValidatedForMargins[0], minDate, maxDate));

  return (
    <React.Fragment>
      <div
        style={{
          height: `calc(100% - ${useTinyAxes ? X_AXIS_CHART_HEIGHT_TINY : 0}px)`,
          minHeight: MIN_GRAPH_HEIGHT,
          paddingRight: isMobile ? Y_AXIS_WIDTH_TINY : 0,
          position: 'relative',
        }}
        className={useTinyAxes ? 'graph-surface-background' : undefined}
      >
        {useTinyAxes && (
          <React.Fragment>
            <div
              style={{
                position: 'absolute',
                top: '-1px',
                height: 1,
                width: '100%',
                borderTop: 'solid 1px #D9D9D9',
              }}
            />
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                height: 1,
                width: '100%',
                borderTop: 'solid 1px #D9D9D9',
              }}
            />
            <div
              style={{
                position: 'absolute',
                right: Y_AXIS_WIDTH_TINY - 1,
                height: '100%',
                width: 1,
                borderRight: 'solid 1px #D9D9D9',
              }}
            />
            <div
              style={{
                position: 'absolute',
                left: Y_AXIS_WIDTH_TINY - 1,
                height: '100%',
                width: 1,
                borderRight: 'solid 1px #D9D9D9',
              }}
            />
          </React.Fragment>
        )}
        <ResponsiveContainer className="graph-responsive-container">
          <ComposedChart
            data={chartData}
            defaultShowTooltip={false}
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
            onClick={onChartClick}
          >
            {/* reference areas to mimic alternate rows */}
            <ReferenceArea
              y1={0}
              y2={1}
              stroke={graphRowBackground}
              fill={graphRowBackground}
              fillOpacity="1"
              key="refareaStart"
              shape={<CustomReferenceArea dontExtend={useTinyAxes} />}
              yAxisId="yAxis1"
            />
            {Array.from({ length: yTicksCount }, (_, i) => i).map(i => (
              <ReferenceArea
                y1={i * 2 + 1}
                y2={i * 2 + 1 + 2}
                stroke={i % 2 !== 0 ? graphRowBackground : graphAlternateRowBackground}
                fill={i % 2 !== 0 ? graphRowBackground : graphAlternateRowBackground}
                fillOpacity="1"
                key={`refarea${i}`}
                shape={<CustomReferenceArea dontExtend={useTinyAxes} />}
                yAxisId="yAxis1"
              />
            ))}
            <ReferenceArea
              y1={23}
              y2={24}
              stroke={graphRowBackground}
              fill={graphRowBackground}
              fillOpacity="1"
              key="refareaEnd"
              yAxisId="yAxis1"
              shape={<CustomReferenceArea dontExtend={useTinyAxes} />}
            />

            {refLines.map(rl => (
              <ReferenceLine
                x={rl}
                stroke={linesStroke}
                fill={linesStroke}
                key={`refLine_${rl}`}
                yAxisId="yAxis1"
                shape={<CustomReferenceLine xAxisParams={xAxisParams} skipAxis={isMobile} />}
              />
            ))}

            <XAxis
              type="number"
              dataKey="x"
              allowDataOverflow
              height={useTinyAxes ? 0 : 60}
              width={300}
              domain={[xAxisParams.min, xAxisParams.max]}
              ticks={xAxisParams.ticks}
              interval={0}
              name="day"
              orientation={useTinyAxes ? 'bottom' : 'top'}
              tickSize={0}
              tick={
                <CustomizedXAxisProportional
                  xAxisParams={xAxisParams}
                  xAxisLabelsMinMaxOnly={false}
                  leftCornerTexts={undefined}
                  drawFirstAndLastTick
                  oneLine={useTinyAxes}
                />
              }
              axisLine={{ stroke: linesStroke, strokeWidth: linesStrokeWidth }}
            />
            <YAxis
              type="number"
              dataKey="y"
              name="hour"
              unit=":00"
              reversed
              yAxisId="yAxis1"
              tick={
                useTinyAxes ? (
                  false
                ) : (
                  <CustomizedYAxisTick
                    axisLine={{ stroke: linesStroke, strokeWidth: linesStrokeWidth }}
                    dateMin={minDate}
                    dateMax={maxDate.clone().subtract(1, 'd')}
                    onlyHour={useTinyAxes}
                    withoutHeader={useTinyAxes}
                    extraPaddingTB={axisYExtraPaddingTB}
                    isRight={false}
                  />
                )
              }
              domain={[0, 24]}
              tickCount={14}
              tickSize={0}
              width={useTinyAxes ? Y_AXIS_WIDTH_TINY : Y_AXIS_WIDTH}
              axisLine={{ stroke: linesStroke, strokeWidth: linesStrokeWidth }}
              interval={0}
              padding={{
                top: axisYExtraPaddingTB,
                right: 0,
                bottom: axisYExtraPaddingTB,
                left: 0,
              }}
            />
            <YAxis
              type="number"
              dataKey="y"
              name="hour"
              unit=":00"
              reversed
              yAxisId="yAxis2"
              orientation="right"
              domain={[0, 24]}
              tickCount={14}
              ticks={useTinyAxes ? [2, 6, 10, 14, 18, 22] : undefined}
              tick={
                useTinyAxes ? (
                  <CustomizedYAxisTick
                    axisLine={{ stroke: linesStroke, strokeWidth: linesStrokeWidth }}
                    dateMin={minDate}
                    dateMax={maxDate}
                    onlyHour={useTinyAxes}
                    withoutHeader={useTinyAxes}
                    extraPaddingTB={axisYExtraPaddingTB}
                    isRight={true}
                  />
                ) : (
                  false
                )
              }
              tickSize={0}
              width={useTinyAxes ? Y_AXIS_WIDTH_TINY : 0}
              axisLine={{ stroke: linesStroke, strokeWidth: linesStrokeWidth }}
              padding={{
                top: axisYExtraPaddingTB,
                right: 0,
                bottom: axisYExtraPaddingTB,
                left: 0,
              }}
            />

            {isMobile && mobileTooltipX && (
              <ReferenceLine
                x={mobileTooltipX}
                yAxisId="yAxis1"
                shape={<MobileTooltipReferenceLine customColor={getColorsForColorTheme().mainColor} />}
              />
            )}

            {/* weeks vertical lines */}

            {/* schedule hours horizontal lines - for the graph with selecting refHour */}
            {/* {refHoursValidated.map((rh, i) => (
                <Line
                  type="monotone"
                  connectNulls
                  activeDot={false}
                  dot={false}
                  stroke={rh.selected ? graphReferenceLineColorSelected:  graphReferenceLineColor}
                  strokeWidth={graphReferenceLineWidth}
                  data={getSeries_RefLine(chartData, rh)}
                  dataKey="y"
                  key={`rh${i}`}
                  onClick={(e) => this.props.onSelectRefHourIndex(i)}
                />
              ))} */}

            {hasSchedule &&
              Array.from({ length: maxBarCount }, (_, i) => i).map(i =>
                i % 2 === 0 ? (
                  <Area
                    key={`line-for-bars-start-${i}`}
                    yAxisId="yAxis1"
                    tooltipType="none"
                    dataKey={`bars[${i}]`}
                    stackId="a"
                    visibility="hidden"
                  />
                ) : (
                  <React.Fragment>
                    <Area
                      key={`line-for-bars-stop-${i}`}
                      yAxisId="yAxis1"
                      tooltipType="none"
                      dataKey={`bars[${i}]`}
                      stackId="a"
                      visibility="hidden"
                    />
                    <Customized
                      component={TakeBar}
                      takeBarStart={`line-for-bars-start-${i - 1}`}
                      takeBarEnd={`line-for-bars-stop-${i}`}
                    />
                  </React.Fragment>
                ),
              )}

            {refHoursValidatedForMargins.map((rh, i) => (
              <Line
                type="monotone"
                yAxisId="yAxis1"
                connectNulls
                activeDot={false}
                dot={false}
                stroke={graphReferenceLineColor}
                strokeWidth={graphReferenceLineWidth}
                data={getSeries_RefLine(chartData, rh, minDate, maxDate)}
                dataKey="y"
                key={`rh${i}`}
                tooltipType="none"
              />
            ))}
            {refHoursJoints.map((rh, i) => (
              <Line
                type="monotone"
                yAxisId="yAxis1"
                connectNulls
                activeDot={false}
                dot={false}
                stroke={graphReferenceLineColor}
                strokeWidth={graphReferenceLineWidth}
                strokeDasharray="3 3"
                data={getSeries_RefLineJoint(rh)}
                dataKey="y"
                key={`rhj${i}`}
                tooltipType="none"
              />
            ))}

            {/* alternative method of drawing take window: via RefAreas */}
            {/* {refHoursValidated.map((rh, i) => (
                <ReferenceArea
                  x1={get_RefLineArea(chartData, rh).x1}
                  x2={get_RefLineArea(chartData, rh).x2}
                  y1={get_RefLineArea(chartData, rh).y1}
                  y2={get_RefLineArea(chartData, rh).y2}
                  stroke={graphValidTakeDotColor}
                  strokeWidth={1}
                  strokeOpacity={0.5}
                  fill={graphValidTakeDotColor}
                  fillOpacity={0.2}
                  key={`refArea1${i}`}
                />
              ))} */}

            {/* margins via Areas */}
            {refHoursValidatedForMargins.map((rh, i) => (
              <Area
                type="monotone"
                yAxisId="yAxis1"
                connectNulls
                activeDot={false}
                dot={false}
                stroke={graphValidTakeDotColor}
                strokeWidth={1}
                strokeOpacity={0.4}
                fillOpacity={0.1}
                fill={graphValidTakeDotColor}
                data={getSeries_RefLineMargins(chartData, rh, minDate, maxDate)}
                dataKey="y"
                key={`refArea2${i}`}
                tooltipType="none"
              />
            ))}

            {refHoursValidated
              .filter(rh => rh.startDate == null)
              .map((rh, i) => (
                <ReferenceDot
                  yAxisId="yAxis1"
                  x={minDate.unix()}
                  y={rh.refHours[0].hours() + rh.refHours[0].minutes() / 60}
                  r={6}
                  fill={graphReferenceLineColor}
                  stroke="none"
                  key={`startDot${i}`}
                  tooltipType="none"
                />
              ))}
            {refHoursValidated
              .filter(rh => rh.endDate == null && (rh.startDate == null || rh.startDate <= maxDate))
              .map((rh, i) => (
                <ReferenceDot
                  yAxisId="yAxis1"
                  x={maxDate.unix()}
                  y={rh.refHours[rh.refHours.length - 1].hours() + rh.refHours[rh.refHours.length - 1].minutes() / 60}
                  r={6}
                  fill={graphReferenceLineColor}
                  stroke="none"
                  key={`endDot${i}`}
                  tooltipType="none"
                />
              ))}

            {/* unscheduled takes */}
            <Scatter
              tooltipType="none"
              yAxisId="yAxis1"
              name="Invalid Takes"
              fill={graphUnscheduledTakeDotColor}
              shape="circle"
              data={getSeries_InvalidTakes(chartData)}
              dataKey="y"
            />

            {/* valid takes */}
            <Scatter
              tooltipType="none"
              yAxisId="yAxis1"
              name="Valid Takes"
              fill={graphValidTakeDotColor}
              shape="circle"
              data={getSeries_ValidTakes(chartData)}
              dataKey="y"
            />

            {/* missed takes */}
            <Scatter
              tooltipType="none"
              yAxisId="yAxis1"
              name="Missed Takes"
              fill={graphMissedTakeDotColor}
              shape="circle"
              data={getSeries_EmptyDays(chartData)}
              dataKey="y"
            />
            {/* hidden series with all the data needed for tooltips */}
            <Scatter
              name="P0"
              yAxisId="yAxis1"
              fill="white"
              shape="circle"
              fillOpacity={0}
              data={getSeries_All(chartData)}
              dataKey="y"
              visibility="hidden"
            />

            {noTakes && <ReferenceArea yAxisId="yAxis1" y1={0} y2={24} opacity={0.2} label="No takes" />}

            {!isMobile && <Tooltip content={<CustomTooltip />} dataKey="y" isAnimationActive={false} />}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      {useTinyAxes && (
        <CompositeGraphXAxis
          dateRange={{ start: props.minDate, end: props.maxDate }}
          xAxisParams={xAxisParams}
          useTinyAxes
        />
      )}
    </React.Fragment>
  );
}

CapEventsGraph.propTypes = {
  capTakes: PropTypes.array,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  dateRangeType: PropTypes.string,
  refHours: PropTypes.array,
  onSelectRefHourIndex: PropTypes.func,
  showWeekLines: PropTypes.bool,
  onSetMobileTooltipPayload: PropTypes.func,
};

CapEventsGraph.defaultProps = {
  showWeekLines: true,
};
