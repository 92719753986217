import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button as MenuButton, Menu, MenuItem, Wrapper } from 'react-aria-menubutton';

import { openModalAction } from '../../../../actions/modal';
import Strings from '../../../../Strings';
import { actions } from '../../redux/actions';
import TabView, { Tab } from '../../../../components/TabView';
import { orgGroupsUrl } from '../OrganizationGroups';
import GroupOrganizations from './GroupOrganizations';
import GroupMaintainers from './GroupMaintainers';
import GroupUsers from './GroupUsers';
import { EditOrgGroupMode } from '../../../../modals/EditOrgGroupModal';

function OrganizationGroupDetails(props) {
  const { activeTab, getOrganizationGroupDetails, getUsers, editOrganizationGroup, addUserToOrgGroup } = props;
  const groupId = props.match.params.id;
  const [groupDetails, setGroupDetails] = React.useState();
  const [groupUsers, setGroupUsers] = React.useState([]);
  const [isListOpen, setIsListOpen] = React.useState(false);

  const loadGroups = () => {
    getOrganizationGroupDetails({ group_id: groupId}).then(resp => {
      setGroupDetails(resp?.response?.[0]);
    });
  };
  
  const loadUsers = () => {
    getUsers(groupId).then(resp => {
      setGroupUsers(resp?.response || []);
    });
  };

  const loadAll = () => {
    loadGroups();
    loadUsers();
  };

  React.useEffect(() => {
    loadAll();
  }, [groupId]);

  const rootPath = `${orgGroupsUrl}/${groupId}`;
  const tabs = [
    <Tab name={Strings.organizations} path={`${rootPath}/organizations`} key={Strings.organizations}>
      <GroupOrganizations groupDetails={groupDetails} groupUsers={groupUsers} onRefresh={loadGroups} />
    </Tab>,
    <Tab name={Strings.maintainers} path={`${rootPath}/maintainers`} key={Strings.maintainers}>
      <GroupMaintainers groupDetails={groupDetails} onRefresh={loadGroups} />
    </Tab>,
    <Tab name={Strings.users} path={`${rootPath}/users`} key={Strings.users}>
      <GroupUsers groupDetails={groupDetails} groupUsers={groupUsers} onRefresh={loadUsers} />
    </Tab>,
  ];

  const handleSelection = value => {
    switch (value) {
      case 'editGroup':
        editOrganizationGroup({ group: groupDetails, users: groupUsers, onSuccess: loadAll });
        break;
      case 'addOrganizations':
        editOrganizationGroup({ group: groupDetails, users: groupUsers, onSuccess: loadGroups, mode: EditOrgGroupMode.ADD_ORGS });
        break;
      case 'addMainainers':
        editOrganizationGroup({ group: groupDetails, users: groupUsers, onSuccess: loadGroups, mode: EditOrgGroupMode.ADD_MAINTAINERS });
        break;
      case 'addUser':
        editOrganizationGroup({ group: groupDetails, users: groupUsers, onSuccess: loadUsers, mode: EditOrgGroupMode.ADD_USERS });
        break;
      default:
        break;
    }
  };

  const actionsButton = (
    <Wrapper
      className="addMenu"
      onSelection={handleSelection}
      onMenuToggle={({ isOpen }) => {
        setIsListOpen(isOpen);
      }}
    >
      <MenuButton className={`addMenu-button ${isListOpen ? 'expanded' : ''}`}>{Strings.actions}</MenuButton>
      <Menu className="addMenu-menu">
        <MenuItem className="addMenu-menuItem" value="editGroup" id="editGroup">
          {Strings.edit}
        </MenuItem>
        <MenuItem className="addMenu-menuItem" value="addOrganizations" id="addOrganizations">
          {Strings.addRemoveOrganizations}
        </MenuItem>
        <MenuItem className="addMenu-menuItem" value="addMainainers" id="addMainainers" >
          {Strings.addRemoveMaintainers}
        </MenuItem>
        <MenuItem className="addMenu-menuItem" value="addUser" id="addUser" >
          {Strings.addRemoveUsers}
        </MenuItem>
      </Menu>
    </Wrapper>
  );

  return (
    <div>
      <div className="infoHeader">
        <div className="rowDirection">
          <button type="button" className="back" onClick={() => history.back()} tabIndex={0}>
            {Strings.back}
          </button>
          <div className="feildBox">
            {Strings.id}
            :
            <div className="feildValue">{groupDetails?.id}</div>
          </div>
          <div className="pathHr"></div>
          <div className="feildBox">
            {Strings.name}
            :
            <div className="feildValue">{groupDetails?.name}</div>
          </div>
        </div>
        <div className="rowDirection">
          {(groupDetails?.role === 'owner' || groupDetails?.role === 'maintainer') && actionsButton}
        </div>
      </div>
      <TabView activeTab={activeTab} routeMode>
        {tabs}
      </TabView>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  getOrganizationGroupDetails: pageRequest => dispatch(actions.getOrganizationGroups(pageRequest)),
  getUsers: id => dispatch(actions.getGroupUsers(id)),
  editOrganizationGroup: data => dispatch(openModalAction('edit-organization-group', data)),
  addUserToOrgGroup: data => dispatch(openModalAction('add-user-to-organization-group', data)),
});

OrganizationGroupDetails.propTypes = {
  activeTab: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  getOrganizationGroupDetails: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  editOrganizationGroup: PropTypes.func.isRequired,
  addUserToOrgGroup: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(OrganizationGroupDetails);
