import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Modal } from '../containers';
import { closeModal } from '../actions/modal';
import { Input } from '../containers/Form';
import { Switch2 } from '../components/Switch2/Switch2';

export const EditCareProviderApiGeneralSettingsModalName = 'e7bfc4de-7968-4952-a446-20a04644515b';

// function which will make array of string as string comma separated
const arrayToString = (arr) => {
  if (!arr || arr.length === 0) {
    return '';
  }
  return arr.join(',');
}

// function wchich will make coma separated string as array
const stringToArray = (str) => {
  if (!str || str.length === 0) {
    return [];
  }
  return str.split(',').map(item => item.trim());
}

function EditCareProviderApiGeneralSettings({ onClose, onAction, data: { config } }) {

  const [enabled, setEnabled] = React.useState(config.enabled);
  const [host, setHost] = React.useState(config.host);
  const [port, setPort] = React.useState(config.port);
  const [clientId, setClientId] = React.useState(config.oAuthClientCredentials?.clientId);
  const [clientSecret, setClientSecret] = React.useState(config.oAuthClientCredentials?.clientSecret);
  const [tokenUrl, setTokenUrl] = React.useState(config.oAuthClientCredentials?.tokenUrl);
  const [scopes, setScopes] = React.useState(arrayToString(config.oAuthClientCredentials?.scopes));

  const onSave = () => {

    const newConfig = {
      enabled,
      host,
      port: parseInt(port, 10),
    }

    newConfig.authMethod = 'none';
    newConfig.oAuthClientCredentials = null;

    if (clientId && clientSecret) {
      newConfig.oAuthClientCredentials = {
        clientId,
        clientSecret,
        tokenUrl,
        scopes: stringToArray(scopes),
      }

      newConfig.authMethod = 'oAuthClientCredentials'
    }

    onAction(newConfig);
  }

  return (
    <Modal name={EditCareProviderApiGeneralSettingsModalName} additionalClasses={['form-modal', 'rounded-borders']} caption="General settings">
      <div className='care-provider-api-modal'>
        <div className='care-provider-enable-row'>
          <div>Enabled</div>
          <Switch2
            checked={enabled}
            onChange={(sw, state) => setEnabled(state)}
          />
        </div>
        

        <Input
          name="host"
          id="host"
          label="Host"
          type="text"
          value={host}
          onChange={e => setHost(e.target.value)}
          isRequired
        />

        <Input
          name="port"
          id="port"
          label="Port (0 for none)"
          type="text"
          value={port}
          onChange={e => setPort(e.target.value)}
          isRequired
        />

        <Input
          name="client_id"
          id="client_id"
          label="Client ID"
          type="text"
          value={clientId}
          onChange={e => setClientId(e.target.value)}
          isRequired
        />

        <Input
          name="client_secret"
          id="client_secret"
          label="Client secret"
          type="text"
          value={clientSecret}
          onChange={e => setClientSecret(e.target.value)}
          isRequired
        />

        <Input
          name="token_url"
          id="token_url"
          label="Token URL"
          type="text"
          value={tokenUrl}
          onChange={e => setTokenUrl(e.target.value)}
          isRequired
        />

        <Input
          name="scopes"
          id="scopes"
          label="Scopes"
          type="text"
          value={scopes}
          onChange={e => setScopes(e.target.value)}
          isRequired
        />
      </div>
      
      <div className="button-bar reverse extend-button" key="button-bar">
        <button type="button" className='brand-blue' onClick={onSave}>Save</button>
        <button type="button" onClick={onClose}>Close</button>
      </div>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClose: () => dispatch(closeModal(EditCareProviderApiGeneralSettingsModalName)),
  onAction: config => {
    ownProps.data.action(config);
    dispatch(closeModal(EditCareProviderApiGeneralSettingsModalName));
  }
});

EditCareProviderApiGeneralSettings.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  data: PropTypes.shape({
    action: PropTypes.func.isRequired,
    config: PropTypes.object.isRequired,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(EditCareProviderApiGeneralSettings);