import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import moment from 'moment-timezone';

import Strings from '../../../../Strings';
import './Dashboard.scss';
import { actions } from './actions';
import { PLOT_DATA_RESULT } from '../../DeviceManagement/HubDeviceEvents/Graph/actions';
import { SleepLegend, prepareSleepData } from '../../DeviceManagement/HubDeviceEvents/Graph/SleepReport/SleepReport';
import TableWithLocalPagination from '../../../../containers/Table/TableWithLocalPagination';
import { movementColumns } from '../../DeviceManagement/HubDeviceEvents/Graph/MovementReport/MovementReport';
import AdherenceGraph from '../AdherenceGraph';
import CompositeGraph from '../../../../components/Graphs/CompositeGraph/CompositeGraph';
import StepsGraph from '../../../../components/Graphs/StepsGraph';
import SleepGraph from '../../../../components/Graphs/SleepGraph';
import { isBrowser } from 'react-device-detect';
import { ReadingsEnum } from '../../../../utils';
import { graphTypeEnum } from '../../../../components/Graphs/CompositeGraph/GraphTypes';
import { ZEPHY_RX_DASHBOARD } from '../../../../constants';
import SpirometryDashboard from '../../DeviceManagement/HubDeviceEvents/Graph/Spirometry/SpirometryDashboard';

class PatientChart extends PureComponent {
  state = {
    stepsGraphDataDaily: [],
    stepsGraphDataHourly: [],
    sleepGraphData: [],
    activityData: [],
    changeSinceLastReading: { change: '', unit: '-' },
    isLimitsLoading: true,
    showReadingDetails: false,
  };

  startDate = moment()
    .subtract(4, 'months')
    .startOf('month')
    .startOf('day');
  endDate = moment()
    .add(1, 'days')
    .startOf('day');

  componentDidMount() {
    this.refreshChartData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedChart !== this.props.selectedChart) {
      this.refreshChartData();
    }
  }

  calculateChange = (data, propertySelector) => {
    if (data && data.length >= 2) {
      const diffRounded =
        Math.round(
          (propertySelector(data[data.length - 1]) - propertySelector(data[data.length - 2]) + Number.EPSILON) * 100,
        ) / 100;
      return (diffRounded < 0 ? '' : '+') + diffRounded;
    }
    return '';
  };

  refreshChartData = () => {
    this.setState({
      stepsGraphDataDaily: [],
      stepsGraphDataHourly: [],
      sleepGraphData: [],
      activityData: [],
    });

    if (
      this.props.selectedChart !== ReadingsEnum.steps &&
      this.props.selectedChart !== ReadingsEnum.sleep &&
      this.props.selectedChart !== ReadingsEnum.activity
    ) {
      return;
    }

    const request = {
      startDate: this.startDate.format(),
      endDate: this.endDate.format(),
      patientId: this.props.patientId,
      dataType:
        this.props.selectedChart === ReadingsEnum.steps
          ? 'steps_report'
          : this.props.selectedChart === ReadingsEnum.sleep
          ? 'sleep_report'
          : this.props.selectedChart === ReadingsEnum.activity
          ? 'movement_report'
          : '',
    };
    if (this.props.selectedChart === ReadingsEnum.steps) {
      request.resolution = 1 * 60 * 60;
    } else {
      delete request.resolution;
    }

    this.props.getPlotData(request).then(resp => {
      if (resp.type === PLOT_DATA_RESULT && resp.response.length !== 0) {
        switch (this.props.selectedChart) {
          case ReadingsEnum.steps: {
            const stepsGraphDataHourly = resp.response.map(i => {
              return {
                date: moment(i.start_timestamp * 1000),
                steps: i.steps,
              };
            });

            const stepsGraphDataDaily = [];
            stepsGraphDataHourly.forEach(h => {
              const day = h.date.clone().startOf('day');
              const item = stepsGraphDataDaily.find(d => d.date.unix() === day.unix());
              if (item) {
                item.steps += h.steps;
              } else {
                stepsGraphDataDaily.push({
                  date: day,
                  steps: h.steps,
                });
              }
            });

            this.setState({
              stepsGraphDataDaily,
              stepsGraphDataHourly,
              changeSinceLastReading: {
                change: this.calculateChange(stepsGraphDataDaily, p => p.steps),
                unit: '',
              },
            });
            break;
          }

          case ReadingsEnum.sleep: {
            const sleepGraphData = prepareSleepData(resp.response);
            this.setState({
              sleepGraphData,
              changeSinceLastReading: {
                change: this.calculateChange(sleepGraphData, p => p.duration),
                unit: Strings.unitsEnum.minutes,
              },
            });
            break;
          }

          case ReadingsEnum.activity: {
            this.setState({
              activityData: resp.response,
              changeSinceLastReading: {
                change: `${this.calculateChange(resp.response, p => p.burn_calories)} / ${this.calculateChange(
                  resp.response,
                  p => p.exercise_time,
                )}`,
                unit: `kcal/${Strings.unitsEnum.minutes}`,
              },
            });
            break;
          }
          default:
            break;
        }
      }
    });
  };

  onSpirometryReadingClick = readings => {
    const spirometrySeries = readings.find(r => r.metric === 'spirometrySeries');
    if (!spirometrySeries) {
      return;
    }
    this.setState({showReadingDetails: true, spirometerGraphData: spirometrySeries.value});
  };

  render() {
    const { selectedChart } = this.props;
    const { stepsGraphDataDaily, stepsGraphDataHourly, sleepGraphData, activityData, showReadingDetails } = this.state;

    return this.props.selectedMed ? (
      <AdherenceGraph
        patientId={this.props.patientId}
        selectedMed={this.props.selectedMed}
        plotResizeTimestamp={this.props.plotResizeTimestamp}
        includeSchedulesWithNoCap
        patientVersion={this.props.patientVersion}
        onScheduleDataLoaded={this.props.setMedName ? schedule => this.props.setMedName(schedule.med_name) : undefined}
        hideMedSelect
      />
    ) : (
      <div className="dashboard-chart">
        <div className="boxStatsWithGraph">
          {((selectedChart === ReadingsEnum.sleep && isBrowser) ||
            (selectedChart === ReadingsEnum.spirometer && showReadingDetails)) && (
            <React.Fragment>
              <div className="boxStatsWithGraph-title">
                {selectedChart === ReadingsEnum.sleep && isBrowser && (
                  <SleepLegend
                    data={sleepGraphData}
                    dataType="monthly"
                    onMouseOver={() => {}}
                    onMouseLeave={() => {}}
                  />
                )}
                {selectedChart === ReadingsEnum.spirometer && (
                  <span>
                    <a class="ml-20" onClick={() => this.setState({showReadingDetails: false, spirometerGraphData: undefined})}>{Strings.back}</a>
                    <a class="ml-20" href={ZEPHY_RX_DASHBOARD} target="_blank">
                      {' '}
                      {Strings.zephyRxDashboard}
                    </a>
                  </span>
                )}
              </div>
            </React.Fragment>
          )}
          <div
            className={`boxStatsWithGraph-graph noStats ${
              selectedChart === ReadingsEnum.sleep ||
              (selectedChart === ReadingsEnum.spirometer && showReadingDetails) ||
              selectedChart === ReadingsEnum.activity
                ? ''
                : 'no-header'
            }`}
          >
            {selectedChart === ReadingsEnum.bloodPressure && (
              <CompositeGraph
                patientId={this.props.patientId}
                graphType={graphTypeEnum.bp}
                plotResizeTimestamp={this.props.plotResizeTimestamp}
              />
            )}
            {selectedChart === ReadingsEnum.pulseox && (
              <CompositeGraph
                patientId={this.props.patientId}
                graphType={graphTypeEnum.pulseOx}
                plotResizeTimestamp={this.props.plotResizeTimestamp}
              />
            )}
            {selectedChart === ReadingsEnum.weight && (
              <CompositeGraph
                patientId={this.props.patientId}
                graphType={graphTypeEnum.weight}
                plotResizeTimestamp={this.props.plotResizeTimestamp}
              />
            )}
            {selectedChart === ReadingsEnum.glucose && (
              <CompositeGraph
                patientId={this.props.patientId}
                graphType={graphTypeEnum.glucose}
                plotResizeTimestamp={this.props.plotResizeTimestamp}
              />
            )}
            {selectedChart === ReadingsEnum.temperature && (
              <CompositeGraph
                patientId={this.props.patientId}
                graphType={graphTypeEnum.temperature}
                plotResizeTimestamp={this.props.plotResizeTimestamp}
              />
            )}
            {selectedChart === ReadingsEnum.spirometer && showReadingDetails &&
              this.state.spirometerGraphData?.flowVolumeCurve &&
               <SpirometryDashboard data={this.state.spirometerGraphData} />}
            {selectedChart === ReadingsEnum.spirometer && (
              <CompositeGraph
                patientId={this.props.patientId}
                graphType={graphTypeEnum.spirometry}
                plotResizeTimestamp={this.props.plotResizeTimestamp}
                onClick={this.onSpirometryReadingClick}
              />
            )}          
            {selectedChart === ReadingsEnum.steps && (
              <StepsGraph
                graphDataDaily={stepsGraphDataDaily || []}
                graphDataHourly={stepsGraphDataHourly || []}
                minDate={this.startDate}
                maxDate={this.endDate}
                stepsRecommended={5000}
                hasBorder={false}
                plotResizeTimestamp={this.props.plotResizeTimestamp}
              />
            )}
            {selectedChart === ReadingsEnum.sleep && (
              <SleepGraph
                graphData={sleepGraphData || []}
                minDate={this.startDate}
                maxDate={this.endDate}
                hasBorder={false}
                plotResizeTimestamp={this.props.plotResizeTimestamp}
              />
            )}
            {selectedChart === ReadingsEnum.activity && (
              <TableWithLocalPagination
                data={activityData.slice().reverse()}
                columns={movementColumns}
                infiniteScroll
              />
            )}
            {selectedChart === ReadingsEnum.heartRate && (
              <CompositeGraph
                patientId={this.props.patientId}
                graphType={graphTypeEnum.hr}
                plotResizeTimestamp={this.props.plotResizeTimestamp}
              />
            )}
            {selectedChart === ReadingsEnum.pm25 && (
              <CompositeGraph
                patientId={this.props.patientId}
                graphType={graphTypeEnum.airQuality}
                plotResizeTimestamp={this.props.plotResizeTimestamp}
              />
            )}
            {selectedChart === ReadingsEnum.coagulation && (
              <CompositeGraph
                patientId={this.props.patientId}
                graphType={graphTypeEnum.coagulation}
                plotResizeTimestamp={this.props.plotResizeTimestamp}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

PatientChart.propTypes = {
  patientId: PropTypes.any,
  getPlotData: PropTypes.func,
  onClick: PropTypes.func,
  selectedChart: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
  getPlotData: request => dispatch(actions.getPlotData(request)),
  onClick: path => dispatch(push(path)),
});

export default connect(null, mapDispatchToProps)(PatientChart);
