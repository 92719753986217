import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { actions } from './redux/actions';
import '../Widgets.scss';
import Strings from '../../../../Strings';
import { Column } from '../../../../containers/Table/components';
import { PageHeader } from '../../../../components/PageHeader';
import { TextInput } from '../../../../components/PageHeader/TextInput';
import Table from '../../../../containers/Table/TableWithPagination';
import { formatTimeSpanHHMMSS, getVitals, makeValid, toolTipForTable } from '../../../../utils';
import { BILLABLE_TIME_THRESHOLD } from '../../../../constants';
import { isMobile } from 'react-device-detect';
import ListOfTables from '../../../../containers/ListOfTables';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

function PatientsToCall(props) {
  const { orgFilter, refreshTimestamp } = props;
  let searchTime;

  const pageRequest = {
    offset: 0,
    search: '',
    orgId: orgFilter,
  };

  useEffect(() => {
    loadData();
  }, [refreshTimestamp, orgFilter]);

  const loadData = () => {
    props.getData(pageRequest);
  };

  const onSearchQueryChange = query => {
    pageRequest.offset = 0;
    pageRequest.search = query;
    clearTimeout(searchTime);
    searchTime = setTimeout(() => {
      props.getData(pageRequest);
    }, 1000);
  };

  const getHeaderComponents = () => {
    return <TextInput class="" placeholder={Strings.search} onChange={e => onSearchQueryChange(e.target.value)} />;
  };

  const onPatientSelected = (_, patient) => {
    if (patient?.patient_id) {
      const maskedId = encodeURIComponent(patient.patient_id);
      props.onNavigate(`/cap-patients/${maskedId}`);
    }
  };

  const getBillableTime = value => {
    const time = formatTimeSpanHHMMSS(value);
    return <div className={value < BILLABLE_TIME_THRESHOLD ? 'red' : ''}>{time}</div>;
  };

  const getAlerts = (time, vitals) => {
    const timeAlertOn = time && time < BILLABLE_TIME_THRESHOLD;
    const vitalsAlertOn = vitals && vitals.some(v => v.conditions.some(c => c.diff));
    const adherenceAlertOn = vitals && vitals.some(v => v.type === 'schedule_missed_doses');
    const alertOffColor = '#BABABA';

    const timeAlert = (
      <div className="icon-and-text-container">
        <div className={`icon-container no-margin ${timeAlertOn ? '' : 'with-border'}`}>
          <div className={`icon-with-border ${timeAlertOn ? 'dollar-on' : 'dollar-off'}`} />
        </div>
      </div>
    );
    const vitalsAlert = (
      <div className="icon-and-text-container">
        <div className={`icon-container no-margin ${vitalsAlertOn ? '' : 'with-border'}`}>
          <div className={`icon-with-border ${vitalsAlertOn ? 'heart-on' : 'heart-off'}`} />
        </div>
      </div>
    );
    const adherenceAlert = (
      <div className="icon-and-text-container">
        <div className={`icon-container no-margin ${adherenceAlertOn ? '' : 'with-border'}`}>
          <div className={`icon-with-border ${adherenceAlertOn ? 'pill-on' : 'pill-off'}`} />
        </div>
      </div>
    );

    return (
      <div className="alert-container">
        {timeAlert}
        {vitalsAlert}
        {adherenceAlert}
      </div>
    );
  };

  const { isLoading, data } = props;
  const columns = [];

  columns.push(<Column key="pharmacy_patient_id" title={Strings.capPatient.patientId} value={e => e.pharmacy_patient_id} />);
  columns.push(<Column key="patientName" title={Strings.capPatient.patientName} value={d => makeValid(d.name)} />);
  columns.push(<Column key="phone" title={Strings.capPatient.mobileNumber} value={d => d?.phone} />);
  columns.push(<Column key="alerts" title={Strings.alerts} value={d => getAlerts(d?.billiable_time, d?.vitals)} />);
  columns.push(
    <Column
      key="billableTime"
      title={Strings.time}
      value={d => (d?.billiable_time ? getBillableTime(d.billiable_time) : '-')}
    />,
  );
  columns.push(<Column key="vitals" title={Strings.vitals} value={d => getVitals(d?.vitals)} />);

  return (
    <div className="widget-with-table">
      <PageHeader right={() => getHeaderComponents()} left={Strings.patientAlerts} noLeftPadding />
      {isMobile ? (
        <OverlayScrollbarsComponent
          defer
          className="scrollbar-right-margin"
          options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100' } }}
        >
          <ListOfTables
            isLoading={isLoading}
            data={data || []}
            title={row => row.name}
            rows={columns}
            onClick={patient => onPatientSelected(patient.patient_id, patient)}
          />
        </OverlayScrollbarsComponent>
      ) : (
        <Table
          className="patientsToCall-table"
          isLoading={isLoading}
          name="patients"
          uuid="8295a4d8-61fa-4638-b6be-9b36a96c364d"
          data={data || []}
          onRowSelection={onPatientSelected}
          enableColumnFiltering
        >
          {columns}
        </Table>
      )}
    </div>
  );
}

PatientsToCall.widgetId = 'PatientsToCallWidget';

PatientsToCall.propTypes = {
  getData: PropTypes.func,
  data: PropTypes.array,
  isLoading: PropTypes.any,
  pagination: PropTypes.any,
  onNavigate: PropTypes.func,
  refreshTimestamp: PropTypes.any.isRequired,
};

const mapStateToProps = state => {
  const { widgetPatientsToCallReducer } = state.dashboardWidgets;
  const userId = state.auth?.profile?.id;
  return {
    data: widgetPatientsToCallReducer && widgetPatientsToCallReducer?.data,
    isLoading: widgetPatientsToCallReducer && widgetPatientsToCallReducer?.isLoading,
    pagination: widgetPatientsToCallReducer && widgetPatientsToCallReducer?.pagination,
    orgFilter: state.superUser?.patients?.orgFilter?.[userId],
  };
};

const mapDispatchToProps = dispatch => ({
  getData: pageRequest => dispatch(actions.getPatientsToCall(pageRequest)),
  onNavigate: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientsToCall);
