import React from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

function Location(data) {
  const { googleMapsApiKey, center } = data;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey,
  });

  return isLoaded ? (
    <GoogleMap
      center={center}
      zoom={14}
      options={{
        disableDefaultUI: true,
        keyboardShortcuts: false,
      }}
    ></GoogleMap>
  ) : (
    null
  );
}

export default React.memo(Location);
