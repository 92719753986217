import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { actions, PLOT_DATA_RESULT } from '../actions';
import Strings from '../../../../../../Strings';
import { PageHeader } from '../../../../../../components/PageHeader';
import { formatTimeSpanHHMM } from '../../../../../../utils';
import SleepGraphHourly from '../../../../../../components/Graphs/SleepGraphHourly';
import { DATE_FORMAT_YEAR_MONTH_DAY, DATE_MONTH_DAY_YEAR, TIME_FORMAT_12_LOWERCASE } from '../../../../../../constants';
import TableWithLocalPagination, { Column } from '../../../../../../containers/Table/TableWithLocalPagination';
import SleepGraph from '../../../../../../components/Graphs/SleepGraph';

export const prepareSleepData = rawData => {
  const sorted = rawData.sort((a, b) => a.sleep_start - b.sleep_start);
  const ret = sorted.map((sleep, i) => {
    const sleepStart = moment(sleep.sleep_start * 1000);
    const sleepEnd = moment(sleep.sleep_stop * 1000);
    const sleepStartHour =
      sleepStart.hours() >= 0
        ? sleepStart.hours() + sleepStart.minutes() / 60
        : -24 + sleepStart.hours() + sleepStart.minutes() / 60;
    const sleepEndHour = sleepEnd.hours() + sleepEnd.minutes() / 60;
    const sleepParts = sleep.sleep_parts.sort((a, b) => a.sleep_start - b.sleep_start);
    const duration = (sleep.sleep_stop - sleep.sleep_start) / 60;
    let change = 0;
    if (i > 0) {
      const prevDuration = (sorted[i - 1].sleep_stop - sorted[i - 1].sleep_start) / 60;
      change = ((duration - prevDuration) / prevDuration) * 100;
    }
    return {
      sleepStart,
      sleepEnd,
      duration,
      change,
      awakeDuration: sleep.awake_duration,
      remDuration: sleep.rem_duration,
      lightSleepDuration: sleep.light_sleep_duration,
      deepSleepDuration: sleep.deep_sleep_duration,
      sleepStartHour,
      sleepEndHour,
      date: moment(sleep.sleep_stop * 1000).startOf('day'),
      x: moment(sleep.sleep_stop * 1000).format(DATE_FORMAT_YEAR_MONTH_DAY),
      parts: sleepParts.map(part => {
        const partStart = moment(part.sleep_start * 1000);
        const partEnd = moment(part.sleep_stop * 1000);
        return {
          sleepStart: partStart,
          sleepEnd: partEnd,
          depth: part.sleep_depth,
          duration: partEnd.diff(partStart) / 1000 / 60,
          duration2: part.sleep_duration,
        };
      }),
    };
  });

  return ret;
};

export function SleepLegend(props) {
  const { data, graphType, onMouseLeave, onMouseOver } = props;
  let awake, rem, lightSleep, deepSleep;

  if (data && data.length > 0) {
    if (graphType === 'daily') {
      const getAverage = (array, fn = v => v) => {
        var reducer = fn => (total, item) => total + fn(item),
          sum = array.reduce(reducer(fn), 0);
        return sum / array.length;
      };

      awake = `${formatTimeSpanHHMM(getAverage(data, e => e.awakeDuration))}`;
      rem = `${formatTimeSpanHHMM(getAverage(data, e => e.remDuration))}`;
      lightSleep = `${formatTimeSpanHHMM(getAverage(data, e => e.lightSleepDuration))}`;
      deepSleep = `${formatTimeSpanHHMM(getAverage(data, e => e.deepSleepDuration))}`;
    } else {
      const getSum = (array, fn = v => v) => {
        var reducer = fn => (total, item) => total + fn(item),
          sum = array.reduce(reducer(fn), 0);
        return sum;
      };

      awake = formatTimeSpanHHMM(getSum(data, e => e.awakeDuration) / data.length);
      rem = formatTimeSpanHHMM(getSum(data, e => e.remDuration));
      lightSleep = formatTimeSpanHHMM(getSum(data, e => e.lightSleepDuration) / data.length);
      deepSleep = formatTimeSpanHHMM(getSum(data, e => e.deepSleepDuration) / data.length);
    }
  }

  return (
    <div className="legendContainer">
      <div className="dot awake"></div>
      <div className="label" id="legend_awake" onMouseOver={() => onMouseOver('awake')} onMouseLeave={onMouseLeave}>
        {`${Strings.awake}${awake ? ` - ${awake}` : ''}`}
        {graphType === 'daily' && <div className="greyLabel">&ensp;(avg)</div>}
      </div>
      <div className="dot rem"></div>
      <div className="label" id="legend_rem" onMouseOver={() => onMouseOver('rem')} onMouseLeave={onMouseLeave}>
        {`${Strings.rem}${rem ? ` - ${rem}` : ''}`}
        {graphType === 'daily' && <div className="greyLabel">&ensp;(avg)</div>}
      </div>
      <div className="dot lightSleep"></div>
      <div
        className="label"
        id="legend_lightSleep"
        onMouseOver={() => onMouseOver('light')}
        onMouseLeave={onMouseLeave}
      >
        {`${Strings.lightSleep}${lightSleep ? ` - ${lightSleep}` : ''}`}
        {graphType === 'daily' && <div className="greyLabel">&ensp;(avg)</div>}
      </div>
      <div className="dot deepSleep"></div>
      <div className="label" id="legend_deepSleep" onMouseOver={() => onMouseOver('deep')} onMouseLeave={onMouseLeave}>
        {`${Strings.deepSleep}${deepSleep ? ` - ${deepSleep}` : ''}`}
        {graphType === 'daily' && <div className="greyLabel">&ensp;(avg)</div>}
      </div>
    </div>
  );
}

class SleepReport extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      selectedPhase: undefined,
      data: [],
      dataType: 'Monthly',
    };

    this.refreshEnable = true;
  }

  componentDidMount() {
    this.getPlotData();
  }

  componentDidUpdate(prevProps) {
    const { deviceId, startDate, endDate } = this.props;

    if (prevProps.deviceId !== deviceId || prevProps.startDate !== startDate || prevProps.endDate !== endDate)
      this.getPlotData();
  }

  onRefresh = () => {
    this.refreshEnable = false;
    this.turnOffTimeout = setTimeout(() => {
      this.refreshEnable = true;
      this.forceUpdate();
    }, 10000);
    this.getPlotData();
  };

  getPlotData = () => {
    this.setState({ isLoading: true, data: [] });

    const { deviceId, startDate, endDate } = this.props;
    if (deviceId && deviceId !== '' && startDate && startDate !== '' && endDate && endDate !== '') {
      const request = {
        deviceId,
        dataType: 'sleep_report',
        startDate,
        endDate,
        hubId: this.props.hubId,
        kitId: this.props.kitId,
        patientId: this.props.patientId,
      };

      let data = [];
      this.props.getPlotData(request).then(resp => {
        if (resp.type === PLOT_DATA_RESULT) {
          data = prepareSleepData(resp.response);
        }
        this.setState({ isLoading: false, data });
      });
    }
  };

  onMouseOver = e => {
    this.setState({ selectedPhase: e });
  };
  onMouseLeave = e => {
    this.setState({ selectedPhase: undefined });
  };

  formatPercentChange = value => {
    return `${value < 0 ? '' : '+'}${value.toFixed(1)}%`;
  };

  getTable = () => {
    const { isLoading, data, dataType } = this.state;

    if (dataType === 'Monthly') {
      const columns = [
        <Column key="date" title={Strings.date} value={e => e.date?.format(DATE_MONTH_DAY_YEAR)} />,
        <Column key="opportunity" title={Strings.opportunity} value={e => `${formatTimeSpanHHMM(e.duration)}`} />,
        <Column key="change" title={Strings.percentChange} value={e => this.formatPercentChange(e.change)} />,
        <Column key="awake" title={Strings.awake} value={e => `${formatTimeSpanHHMM(e.awakeDuration)}`} />,
        <Column key="rem" title={Strings.rem} value={e => `${formatTimeSpanHHMM(e.remDuration)}`} />,
        <Column key="light" title={Strings.lightSleep} value={e => `${formatTimeSpanHHMM(e.lightSleepDuration)}`} />,
        <Column key="deep" title={Strings.deepSleep} value={e => `${formatTimeSpanHHMM(e.deepSleepDuration)}`} />,
      ];
      return (
        <TableWithLocalPagination
          isLoading={isLoading}
          data={data && data.length > 0 ? data.slice().reverse() : []}
          columns={columns}
        />
      );
    } else if (dataType === 'Daily') {
      const columns = [
        <Column key="time" title={Strings.time} value={e => e.sleepStart.format(TIME_FORMAT_12_LOWERCASE)} />,
        <Column key="ended" title={Strings.ended} value={e => e.sleepEnd.format(TIME_FORMAT_12_LOWERCASE)} />,
        <Column key="length" title={Strings.length} value={e => `${e.duration} ${Strings.unitsEnum.minutes}`} />,
        <Column key="state" title={Strings.state} value={e => this.getStringFromPhase(e.depth)} />,
      ];
      return (
        <TableWithLocalPagination
          isLoading={isLoading}
          data={data && data.length > 0 ? data[0].parts.slice().reverse() : []}
          columns={columns}
        />
      );
    }
    return <React.Fragment />;
  };

  getStringFromPhase(phase) {
    switch (phase) {
      case 'rem':
        return Strings.rem;
      case 'deep_sleep':
        return Strings.deepSleep;
      case 'light_sleep':
        return Strings.lightSleep;
      case 'awake':
        return Strings.awake;
      default:
        return '-';
    }
  }

  getPageHeader = () => {
    const { data, dataType } = this.state;

    return (
      <PageHeader
        left={
          <SleepLegend
            data={data}
            dataType={dataType}
            onMouseOver={this.onMouseOver}
            onMouseLeave={this.onMouseLeave}
          />
        }
      />
    );
  };

  getGraph = () => {
    const { startDate, endDate } = this.props;
    const { selectedPhase, data, dataType } = this.state;
    if (data && data.length > 0)
      return (
        <div className="hub-device-events-margin-bottom">
          {dataType === 'Monthly' ? (
            <SleepGraph
              graphData={data}
              minDate={moment(startDate)}
              maxDate={moment(endDate)}
              selectedPhase={selectedPhase}
              hasBorder
            />
          ) : (
            <SleepGraphHourly graphData={data} date={moment(startDate)} selectedPhase={selectedPhase} />
          )}
        </div>
      );
    return (
      <div className="empty-row-container bottom-margin">
        <div className="empty-row-inner">{Strings.noDataAvailable}</div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.getPageHeader()}
        {this.getGraph()}
        {this.getTable()}
      </React.Fragment>
    );
  }

  static propTypes = {
    deviceId: PropTypes.string.isRequired,
    patientId: PropTypes.string,
    hubId: PropTypes.string,
    kitId: PropTypes.string,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    getPlotData: PropTypes.func.isRequired,
  };
}

const mapDispatchToProps = dispatch => ({
  getPlotData: request => dispatch(actions.getPlotData(request)),
});

export default connect(null, mapDispatchToProps)(SleepReport);
