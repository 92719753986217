import { chartTypeEnum } from '../../../components/Graphs/GenericCategoryGraph';

export const customFormattingConfig = {
  backgroundColor: {
    type: 'color',
    name: 'backgroundColor',
    label: 'Background color',
    defaultValue: '#f7f7f7',
    canBeSetByDashboard: true,
  },
  valueColor: {
    type: 'color',
    name: 'value',
    label: 'Value color',
    defaultValue: '#000000',
    canBeSetByDashboard: true,
  },
  titleColor: {
    type: 'color',
    name: 'titleColor',
    label: 'Title color',
    defaultValue: '#000000',
    canBeSetByDashboard: true,
  },
};
export const stackedChartTypeConfig = {
  stackedChartType: {
    type: 'select',
    name: 'stackedChartType',
    label: 'Stacked Chart Type',
    defaultValue: chartTypeEnum.stackedBar,
    options: [
      { label: 'Stacked Bar chart', value: chartTypeEnum.stackedBar },
      { label: 'Stacked Area chart', value: chartTypeEnum.stackedArea },
      { label: 'Line chart', value: chartTypeEnum.line },
      { label: 'Stacked Horizontal Bar', value: chartTypeEnum.horizontalStackedBar },
    ],
    canBeSetByDashboard: false,
  },
};
export const verticalChartTypeConfig = {
  chartType: {
    type: 'select',
    name: 'chartType',
    label: 'Chart Type',
    defaultValue: chartTypeEnum.bar,
    options: [
      { label: 'Bar chart', value: chartTypeEnum.bar },
      { label: 'Area chart', value: chartTypeEnum.area },
      { label: 'Line chart', value: chartTypeEnum.line },
      { label: 'Pie chart', value: chartTypeEnum.pie },
    ],
    canBeSetByDashboard: false,
  },
};

export const horizontalChartTypeConfig = {
  chartType: {
    type: 'select',
    name: 'chartType',
    label: 'Chart Type',
    defaultValue: chartTypeEnum.bar,
    options: [
      { label: 'Horizontal Bar chart', value: chartTypeEnum.horizontalBar },
      { label: 'Pie chart', value: chartTypeEnum.pie },
    ],
    canBeSetByDashboard: false,
  },
};

