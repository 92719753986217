import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import _, { isNumber } from 'lodash';
import moment from 'moment-timezone';

import { openModalAction } from '../../../actions/modal';
import { BILLABLE_TIME_THRESHOLD, DATE_TIME_SPACE_12_UPPERCASE, PAGE_LIMIT, RPM_PATIENT_STATUS, RPM_PORTAL_ACCESS_STATUS, Timezones } from '../../../constants';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import Strings from '../../../Strings';
import { getSchedulesIcons, getVitalsIcons, lastActivityDisplay, makeValid, showStatus } from '../../../utils';
import './patient.scss';
import { actions } from './redux/actions';
import { actions as cohortActions } from './Cohorts/redux/actions';
import { PATIENT_LIST_FOR_SUGGESTION } from './redux/constants';
import { patientAction } from '../../../actions/patient';
import { getProgramsText } from '../../../utils/cmsPrograms';
import { UPDATE_PATIENT_SCHEDULE } from '../../../actions/action-types';
import { sendRestPasswordMessage } from '../../../actions/auth';
import { PERMISSIONS, hasPermission } from '../../../utils/userPermissions';
import { stages } from '../../../modals/EditRpmPatient/EditRpmPatientModal';
import { OnboardStages } from '../../../modals/OnboardPatientWizard/OnboardPatientWizard';
import { ACTIVATION_METHODS } from '../../../utils/enrollmentFields';

const isEnrollmentEndDateSet = patient => {
  return (
    patient.patientEnrollment?.enrollments &&
    Object.values(patient.patientEnrollment?.enrollments)?.some(e => e.enrollmentEnd)
  );
};

export const isEnrollmentReadyToStart = patient => {
  return (
    patient.patientEnrollment?.enrollments &&
    Object.values(patient.patientEnrollment?.enrollments)?.some(e => e.kitAccepted === true) &&
    (
      Object.values(patient.patientEnrollment?.enrollments)?.some(e => e.enrollmentStart) || 
      Object.values(patient.patientEnrollment?.enrollments)?.some(e => e.activationMethod !== ACTIVATION_METHODS.Manual)
    )
  );
};

export const getEnrollTextForPatient = patient => {
  return patient?.status === RPM_PATIENT_STATUS.activated && isEnrollmentEndDateSet(patient)
    ? Strings.changeEnrollmentEndDate
    : patient?.status === RPM_PATIENT_STATUS.activated
    ? Strings.disenroll
    : patient?.status === RPM_PATIENT_STATUS.enrolled && isEnrollmentReadyToStart(patient)
    ? Strings.activateNow
    : patient?.status === RPM_PATIENT_STATUS.enrolled
    ? Strings.assignKit
    : patient?.status === RPM_PATIENT_STATUS.verified
    ? Strings.enroll
    : patient?.status === RPM_PATIENT_STATUS.ended
    ? Strings.resetPatient
    : Strings.register;
};

function PatientList(props) {

  const onPatientSelected = id => {
    const { patients } = props;
    if (patients && patients[id].id) {
      const data = patients[id];
      const maskedId = encodeURIComponent(data.id);
      props.onNavigate(`/cap-patients/${maskedId}`);
    }
  };

  const editPatient = id => {
    props.onEditPatient(props.patients[id], props.forceRefresh, OnboardStages.editPatient);
  };

  const sendSMS = id => {
    props.onSendMessage(props.patients[id], props.getPatientsForSuggestion, PATIENT_LIST_FOR_SUGGESTION);
  };

  const deletePatient = id => {
    const { patients, pagination } = props;
    const deleteData = { maskedId: patients[id].masked_id, allowBilled: true };
    const data = {
      title: (
        <span>
          {Strings.deletePatientWarning}: <b>{patients[id].patientName}</b>? <br />{' '}
          {Strings.warnigs.deletePatientWarning}
        </span>
      ),

      onConfirmAction: actions.deletetPatient(deleteData, patients[id].id),
      onCancelAction: null,
      confirmPostAction: () => {
        if (pagination.totalRecords - pagination.offset === 1) {
          pageRequest.current.offset -= PAGE_LIMIT;
          // ensure we don't go below 0
          if (pageRequest.current.offset < 0) {
            pageRequest.current.offset = 0;
          }
        }
        props.forceRefresh();
      },
    };
    props.openConfirmModal(data);
  };

  const enrollPatient = id => {
    props.onEnrollPatient(
      props.patients[id],
      props.forceRefresh,
      props.patients[id].status === RPM_PATIENT_STATUS.enrolled ? OnboardStages.activating : OnboardStages.enrolling,
    );
  };

  const registerPatient = id => {
    props.onEditPatient(props.patients[id], props.forceRefresh, OnboardStages.registration);
  };

  const endEnrollment = id => {
    props.onEnrollPatient(props.patients[id], props.forceRefresh, OnboardStages.settingEndDate);
  };

  const startEnrollment = id => {
    const patient = props.patients[id];
    props.startEnrollment(patient, props.forceRefresh);
  };

  const resetPatientStatus = id => {
    const patient = props.patients[id];
    props.openResetPatientStatusModal(patient, props.forceRefresh);
  };

  const cancelEnrollment = id => {
    const patient = props.patients[id];
    if (!patient.patientEnrollment?.enrollments) {
      return;
    }

    const requestBody = { enrollment_id: Object.keys(patient.patientEnrollment.enrollments)[0] };
    const data = {
      title: <span>{Strings.formatString(Strings.cancelEnrollmentWarning, patient.patientName)}</span>,
      onConfirmActions: [actions.endEnrollment(requestBody), actions.editPatient({ needsNewEnrollment: true }, patient.id)],
      onCancelAction: null,
      hideCaution: true,
      caption: Strings.cancelEnrollment,
      confirmPostAction: props.forceRefresh,
    };
    props.openConfirmModal(data);
  };

  const rescheduleTelevisit = id => {
    const patient = props.patients[id];
    props.onEditPatient(patient, props.forceRefresh, stages.rescheduleTelevisit);
  };

  const addMedicine = id => {
    const patientDetails = props.patients[id];
    const defaultEnrollmentId = patientDetails?.patientEnrollment?.enrollments
      ? Object.entries(patientDetails.patientEnrollment.enrollments).find(
          ([key, v]) => v.program === patientDetails.patientEnrollment.defaultProgram,
        )?.[0]
      : null;
    props.onAddMedicine(patientDetails, defaultEnrollmentId, patientDetails.status === 'enrolled');
  };

  const onPatientsUnassignById = id => {
    unassignPatient(props.patients[id]);
  };

  const unassignPatient = patient => {
    const cohort = props;
    const data = {
      title: (
        <span>
          {Strings.unassignPatientWarning} <b>{patient.patientName}</b>?
        </span>
      ),

      onConfirmAction: cohortActions.unassignPatientFromCohort(patient.id, cohort.cohortId),
      confirmPostAction: props.forceRefresh,
      caption: Strings.removePatient,
      hideCaution: true,
    };
    props.openConfirmModal(data);
  };

  const inviteToPortal = patient => {
    const data = {
      title: <span>{Strings.formatString(Strings.invitePatientWarning, patient?.patientName)}</span>,
      caption: Strings.invitePatient,
      onConfirmAction: actions.invitePatientToPortal(patient.id, 'email'),
      onCancelAction: null,
      confirmPostAction: props.forceRefresh,
      onSuccessNotification: Strings.invitationSuccess,
      hideCaution: true,
    };
    props.openConfirmModal(data);
  };

  const resetPassword = patient => {
    const data = {
      title: (
        <span>
          {Strings.warnigs.adminResetPassword} <b>{patient.patientName}</b>?
        </span>
      ),
      hideCaution: true,
      onConfirmAction: sendRestPasswordMessage({
        method: 'email',
        email: patient.email,
      }),
      onCancelAction: null,
      caption: 'Confirm password reset',
    };

    props.openConfirmModal(data);
  };

  const getAlerts = alerts => {
    let alertsCount = 0;
    if (alerts) {
      alertsCount = Object.values(alerts).filter(v => isNumber(v))?.length;
      alertsCount += alerts.schedule_missed_doses_alerts?.length || 0;
    }
    return (
      <div className="icon-and-text-container">
        <div className={`icon-container ${alertsCount > 0 ? 'alert' : 'tick grey'}`} />
        {/* {alertsCount} */}
      </div>
    );
  };

  const getBillableTime = time => {
    const time_m = moment.utc(time * 1000);
    const color = time < BILLABLE_TIME_THRESHOLD ? 'red' : 'grey';
    return (
      <div className="icon-and-text-container">
        <div className={`billable-time-dot ${color}`} />
        {time_m.format('HH:mm:ss')}
      </div>
    );
  };

  const getInbox = hasUnreadConversations => (
    <div className="icon-and-text-container">
      <div className={`icon-container envelope ${hasUnreadConversations ? 'unread' : ''}`} />
    </div>
  );

  const { isLoading, patients, pagination, cohortId } = props;

  const columns = [];
  columns.push(<Column key="pharmacyPatientId" sortKey="pharmacyPatientId" title={Strings.capPatient.patientId} value={e => e.pharmacyPatientId} />);
  columns.push(<Column key="mrn" sortKey="mrn" title={Strings.capPatient.mrn} value={e => e.mrn} />);
  columns.push(
    <Column
      key="patientName"
      sortKey="patientName"
      title={Strings.capPatient.patientName}
      value={d => makeValid(d.patientName)}
    />,
  );
  if (!props.status) {
    columns.push(
      <Column
        key="status"
        title={Strings.status}
        value={d => Strings.capPatient.patientStatus[d.status] || d.status || '-'}
      />,
    );
  }
  if (props.status === RPM_PATIENT_STATUS.onboarded) {
    columns.push(
      <Column
        key="consentForProvider"
        title={Strings.outreachStatus}
        value={d => d.consentForProvider ? Strings.outreachStatusEnum[d.consentForProvider] : '-'}
      />,
    );
  }
  if (props.status === RPM_PATIENT_STATUS.verified) {
    columns.push(
      <Column
        key="televisitDate"
        title={Strings.capPatient.scheduledTelevisits}
        value={d => d.enrollmentSchedules?.length > 0 ? d.enrollmentSchedules.map(s => moment.tz(s.enrollAt, d.timezone).format(DATE_TIME_SPACE_12_UPPERCASE)).join(', ') : '-'}
      />,
    );
  }
  if (hasPermission(PERMISSIONS.GRANT_PATIENT_PORTAL_ACCESS)) {
    columns.push(<Column key="portal_access" title={Strings.portalAccess} value={d => showStatus(d.portalAccess)} />);
    columns.push(<Column key="last_login" title={Strings.lastLogin} value={d => lastActivityDisplay(d.last_login)} />);
  }
  if ([RPM_PATIENT_STATUS.enrolled, RPM_PATIENT_STATUS.activated].includes(props.status)) {
    columns.push(<Column key="program" title={Strings.program} value={d => getProgramsText(d.patientEnrollment)} />);
  }
  columns.push(
    <Column key="readings" title={Strings.latestReadings} value={d => getVitalsIcons(d.lastReadings, d.id)} />,
  );
  columns.push(
    <Column
      key="schedules"
      title={Strings.todaysMedications}
      value={d => getSchedulesIcons(d.todaysMedications, d.lastReadings?.alerts?.schedule_missed_doses_alerts)}
    />,
  );
  columns.push(<Column key="alerts" title={Strings.alerts} value={d => getAlerts(d.lastReadings?.alerts)} />);
  columns.push(<Column key="inbox" title={Strings.messages.inbox} value={d => getInbox(d.unreadConversations)} />);
  columns.push(
    <Column
      key="billing_time"
      title={Strings.timeThisMonth}
      value={d => getBillableTime(d.lastReadings?.billable_time)}
    />,
  );
  columns.push(<Column key="kitId" title={Strings.kitId} value={d => d.kitId} />);
  columns.push(<Column key="cohort" title={Strings.cohort} value={d => d.cohortName} />);
  columns.push(<Column key="facility" title={Strings.providerFacility} value={d => d.facilityName} />);
  columns.push(
    <Column key="textPhoneNo" title={Strings.capPatient.mobileNumber} value={d => makeValid(d.textPhoneNo)} />,
  );
  columns.push(<Column key="email" title={Strings.capPatient.email} value={d => makeValid(d.email)} />);
  columns.push(<Column key="timezone" title={Strings.timezone} value={d => d.timezone ? Timezones[d.timezone] : '-'} />);
  columns.push(<Column key="gender" title={Strings.gender} value={d => d.gender ? Strings.genders[d.gender.toLowerCase()] : '-'} />);
  columns.push(
    <Column key="organType" title={Strings.capPatient.organType} value={d => d.transplantDetails?.[0]?.organType} />,
  );
  columns.push(
    <Column key="insuranceName" title={Strings.capPatient.insurance} value={d => d.insurance?.[0]?.insuranceName} />,
  );
  columns.push(<Column key="policyId" title={Strings.capPatient.policyId} value={d => d.insurance?.[0]?.policyId} />);
  columns.push(
    <Column
      key="clinicContact"
      title={Strings.capPatient.clinicContact}
      value={d => d.centerDetails?.[0]?.clinicContact}
    />,
  );

  const buttons = [];
  if (hasPermission(PERMISSIONS.GRANT_PATIENT_PORTAL_ACCESS) && props.status === RPM_PATIENT_STATUS.activated) {
    buttons.push({
      icon: (id, patient) => {
        return patient?.portalAccess === RPM_PORTAL_ACCESS_STATUS.noAccess
          ? 'invite'
          : patient?.portalAccess === RPM_PORTAL_ACCESS_STATUS.invited
          ? 'invite'
          : patient?.portalAccess === RPM_PORTAL_ACCESS_STATUS.hasAccess
          ? 'refresh'
          : '';
      },
      onClick: (id, patient) => {
        switch (patient?.portalAccess) {
          case RPM_PORTAL_ACCESS_STATUS.noAccess:
          case RPM_PORTAL_ACCESS_STATUS.invited:
            inviteToPortal(patient);
            break;
          case RPM_PORTAL_ACCESS_STATUS.hasAccess:
            resetPassword(patient);
            break;
          default:
            break;
        }
      },
      text: (id, patient) => {
        return patient?.portalAccess === RPM_PORTAL_ACCESS_STATUS.noAccess
          ? Strings.inviteToPortal
          : patient?.portalAccess === RPM_PORTAL_ACCESS_STATUS.invited
          ? Strings.reInviteToPortal
          : patient?.portalAccess === RPM_PORTAL_ACCESS_STATUS.hasAccess
          ? Strings.resetPassword
          : '';
      },
      disabled: (id, patient) => !patient?.email,
      disabledTooltip: Strings.emailNeededForPortalAccess,
    });
  }

  buttons.push({
    icon: id => {
      switch (props.patients[id]?.status) {
        case RPM_PATIENT_STATUS.activated:
          return 'endDate';
        case RPM_PATIENT_STATUS.verified:
          return 'enroll';
        case RPM_PATIENT_STATUS.enrolled:
          return 'activate';
        case RPM_PATIENT_STATUS.onboarded:
          return 'register';
        case RPM_PATIENT_STATUS.ended:
          return 'register';
        default:
          break;
      }
    },
    onClick: id => {
      switch (props.patients[id]?.status) {
        case RPM_PATIENT_STATUS.activated:
          endEnrollment(id);
          break;
        case RPM_PATIENT_STATUS.verified:
          enrollPatient(id);
          break;
        case RPM_PATIENT_STATUS.enrolled:
          {
            if (isEnrollmentReadyToStart(props.patients[id])) {
              startEnrollment(id);
            } else {
              enrollPatient(id);
            }
          }
          break;
        case RPM_PATIENT_STATUS.onboarded:
          registerPatient(id);
          break;
        case RPM_PATIENT_STATUS.ended:
          resetPatientStatus(id);
        default:
          break;
      }
    },
    text: id => getEnrollTextForPatient(props.patients[id]),
    highlighted: (_, patient) => { return [RPM_PATIENT_STATUS.onboarded, RPM_PATIENT_STATUS.verified, RPM_PATIENT_STATUS.enrolled].includes(patient?.status) },
    disabled: (_, patient) => {
      return patient?.status === RPM_PATIENT_STATUS.verified ? !hasPermission(PERMISSIONS.PATIENTS_RPM_ENROLL)
        : patient?.status === RPM_PATIENT_STATUS.enrolled ? !hasPermission(PERMISSIONS.PATIENTS_RPM_ACTIVATE)
        : false;
    },
    disabledTooltip: (_, patient) => {
      return patient?.status === RPM_PATIENT_STATUS.verified ? Strings.capPatient.youDontHaveRightsToEnroll
        : patient?.status === RPM_PATIENT_STATUS.enrolled ? Strings.capPatient.youDontHaveRightsToActivate
        : '';
    },
  });

  if (props.status === RPM_PATIENT_STATUS.verified) {
    buttons.push({
      icon: 'endDate',
      onClick: rescheduleTelevisit,
      text: id => props.patients[id]?.enrollmentSchedules?.length > 0 ? Strings.capPatient.rescheduleTelevisit : Strings.capPatient.scheduleTelevisit,
    });
  }
  if (props.status === RPM_PATIENT_STATUS.enrolled) {
    buttons.push({
      icon: 'cancelEnrollment',
      onClick: cancelEnrollment,
      text: Strings.cancelEnrollment,
      disabled: (_, patient) => patient.kitId,
      disabledTooltip: Strings.cantCancelEnrollmentForPatientWithKit,
    });
  }
  if (props.status === RPM_PATIENT_STATUS.activated) {
    buttons.push({
      icon: 'addMedication',
      onClick: addMedicine,
      text: Strings.addMedicationText,
    });
  }

  if (props.status === RPM_PATIENT_STATUS.activated) {
    buttons.push({
      icon: 'message',
      onClick: sendSMS,
      text: Strings.sendSMS,
    });
  }

  if (props.status !== RPM_PATIENT_STATUS.ended) {
    buttons.push({
      icon: 'edit',
      onClick: editPatient,
      text: Strings.edit,
    });
  }
  if (props.status !== RPM_PATIENT_STATUS.activated && props.status !== RPM_PATIENT_STATUS.enrolled) {
    buttons.push({
      icon: 'delete',
      onClick: cohortId ? onPatientsUnassignById : deletePatient,
      text: cohortId ? Strings.capPatient.unassignFromCohort : Strings.delete,
    });
  }

  return (
    <Table
      className="schedules-table"
      isLoading={isLoading}
      name="organizations"
      uuid="703c850e-af80-4550-9e86-a0f35bad91c1"
      data={patients || []}
      onRowSelection={onPatientSelected}
      onSortClick={props.onSortClick}
      onOffsetChange={props.onOffsetChange}
      pagination={
        pagination || {
          offset: 0,
          total: 0,
        }
      }
      buttons={buttons}
      enableColumnFiltering
    >
      {columns}
    </Table>
  );
}

PatientList.propTypes = {
  getPatients: PropTypes.func,
  isLoading: PropTypes.any,
  onEditPatient: PropTypes.func,
  onNavigate: PropTypes.func,
  openConfirmModal: PropTypes.func,
  pagination: PropTypes.shape({
    offset: PropTypes.number,
    total: PropTypes.number,
  }),
  patients: PropTypes.array,
  cohortId: PropTypes.string,
  refreshTimestamp: PropTypes.any,
  onEnrollPatient: PropTypes.func,
  onSendMessage: PropTypes.func,
  getPatientsForSuggestion: PropTypes.func,
  onAddMedicine: PropTypes.func,
  replacePath: PropTypes.func,
  startEnrollment: PropTypes.func,
  forceRefresh: PropTypes.func,
  status: PropTypes.string,
  openResetPatientStatusModal: PropTypes.func,
  onSortClick: PropTypes.func,
  onOffsetChange: PropTypes.func,
};

const mapStateToProps = state => {
  const { patients } = state.superUser;
  return {
    patients: patients && patients?.data?.data,
    isLoading: patients && patients?.isLoading,
    pagination: patients && patients?.data?.pagination,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  getPatients: pageRequest => dispatch(actions.getPatients(pageRequest)),
  getPatientsForSuggestion: pageRequest => dispatch(actions.getPatientsForSuggestion(pageRequest)),
  onEditPatient: (patient, nextAction, stage) =>
    dispatch(
      openModalAction('onboard-patient', {
        onSuccess: nextAction,
        patient,
        stage,
      }),
    ),
  onEnrollPatient: (patient, nextAction, stage) =>
    dispatch(
      openModalAction('onboard-patient', {
        patient,
        onSuccess: nextAction,
        stage,
      }),
    ),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  onSendMessage: (patient, loadOptionsAction, loadOptionsActionType) =>
    dispatch(
      openModalAction(
        'send-message',
        {
          patient,
          loadRecipients: loadOptionsAction,
          loadRecipientsActionType: loadOptionsActionType,
        },
        patient.id,
      ),
    ),
  onAddMedicine: (patient, enrollmentId, logTask) =>
    dispatch(
      openModalAction('edit-rpm-schedule', {
        patient,
        action: patientAction.actionUpdateSchedule,
        actionType: UPDATE_PATIENT_SCHEDULE,
        logTask,
        enrollmentId,
      }),
    ),
  startEnrollment: (patient, onSuccess) =>
    dispatch(
      openModalAction('start-enrollmend-and-schedules', {
        patient,
        onSuccess,
      }),
    ),
  editPatient: (data, id) => dispatch(actions.editPatient(data, id)),
  endEnrollment: requestBody => dispatch(actions.endEnrollment(requestBody)),
  replacePath: path => dispatch(replace(path)),
  openResetPatientStatusModal: (patient, onSuccess) =>
    dispatch(openModalAction('reset-enrollment-and-unattach-devices', { patient, onSuccess })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientList);
