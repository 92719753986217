import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './AppHeader.module.scss';

function AppHeader({
  onLeftButtonClick,
  onLogoClick,
  leftMenuVisible,
  isMainPage,
  leftInteractionEnabled = true,
}) {
  return (
    <header className={styles.main}>
      <div
        onClick={leftInteractionEnabled ? onLogoClick : () => {}}
        className={cn(styles.headerLeft, {
          [styles.active]: isMainPage,
          [styles.cursorArrow]: leftInteractionEnabled,
          [styles.collapsed]: !leftMenuVisible,
        })}
      >
        {leftMenuVisible ? (
          <div
            className={cn(styles.headerLogo, {
              [styles.cursorArrow]: leftInteractionEnabled,
            })}
            onClick={onLeftButtonClick}
          />
        ) : null}
        <button
          type="button"
          onClick={onLeftButtonClick}
          onKeyDown={() => {
            // const charCode = e.which ? e.which : e.keyCode;
            // if (charCode === 13) {
            onLeftButtonClick;
            // }
          }}
          className={`${styles.sideMenuToggle} ${leftMenuVisible ? styles.isActive : ''}`}
          tabIndex={0}
          aria-label={leftMenuVisible ? 'collapse sidebar' : 'expand sidebar'}
        ></button>
      </div>
    </header>
  );
}

AppHeader.propTypes = {
  onLeftButtonClick: PropTypes.func,
  onLogoClick: PropTypes.func,
  isMainPage: PropTypes.bool,
  leftInteractionEnabled: PropTypes.bool,
  leftMenuVisible: PropTypes.bool,
};

export default AppHeader;