import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone/moment-timezone';

import { actions, PLOT_DATA_RESULT } from '../actions';
import { DATE_MONTH_DAY_YEAR, TIME_FORMAT_12_UPPERCASE } from '../../../../../../constants';
import Strings from '../../../../../../Strings';
import TableWithLocalPagination, { Column } from '../../../../../../containers/Table/TableWithLocalPagination';
import { STEPS_REPORT } from '../../../constant';
import StepsGraphHourly from '../../../../../../components/Graphs/StepsGraphHourly';
import StepsGraph from '../../../../../../components/Graphs/StepsGraph';

const RECOMMENDED_STEPS = 10000;

class StepsReport extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };

    this.dataToShow = [];
    this.refreshEnable = true;
    this.minDateFromEvents = moment();
  }

  componentDidMount() {
    this.getPlotData();
  }

  onRefresh = () => {
    this.refreshEnable = false;
    this.turnOffTimeout = setTimeout(() => {
      this.refreshEnable = true;
      this.forceUpdate();
    }, 10000);
    this.getPlotData();
  };

  getPlotData = () => {
    this.dataToShow = [];
    this.setState({ isLoading: true });

    const { deviceId, startDate, endDate, dateRangeType, hubId, kitId, patientId } = this.props;

    const request = {
      deviceId,
      hubId,
      kitId,
      patientId,
      dataType: STEPS_REPORT,
      startDate,
      endDate,
      resolution: dateRangeType === 'Monthly' ? 24 * 60 * 60 : 60 * 60,
    };

    this.props.getPlotData(request).then(resp => {
      if (resp.type === PLOT_DATA_RESULT) {
        this.dataToShow = this.prepareData(resp.response);
        this.minDateFromEvents = moment(Math.min(...resp.response.map(d => d.timestamp)) * 1000);
      }
      this.setState({ isLoading: false });
    });
  };

  componentDidUpdate(prevProps) {
    const { deviceId, startDate, endDate } = this.props;

    if (prevProps.deviceId !== deviceId || prevProps.startDate !== startDate || prevProps.endDate !== endDate)
      this.getPlotData();
  }

  prepareData = events => {
    if (events && !events.find(p => p.steps !== 0)) return [];
    return events?.map(d => {
      return {
        date: moment(d.start_timestamp * 1000),
        steps: d.steps,
      };
    });
  };

  getGraph = () => {
    const minDate =
      moment(this.props.startDate).year() === 1970 ? this.minDateFromEvents : moment(this.props.startDate);
    const maxDate = moment(this.props.endDate);

    if (this.dataToShow.length > 0 && this.props.dateRangeType === 'Monthly') {
      return (
        <div className="hub-device-events-margin-bottom">
          <StepsGraph
            graphDataDaily={this.dataToShow || []}
            graphDataHourly={[]}
            minDate={minDate}
            maxDate={maxDate}
            stepsRecommended={RECOMMENDED_STEPS}
            hasBorder
          />
        </div>
      );
    }

    if (this.dataToShow.length > 0 && this.props.dateRangeType === 'Daily') {
      const sum = this.dataToShow.reduce((accumulator, object) => {
        return accumulator + object.steps;
      }, 0);

      return (
        <div className="hub-device-events-margin-bottom">
          <StepsGraphHourly graphData={this.dataToShow} date={minDate} goodDay={sum >= RECOMMENDED_STEPS} />
        </div>
      );
    }

    return (
      <div className="empty-row-container bottom-margin">
        <div className="empty-row-inner">{Strings.noDataAvailable}</div>
      </div>
    );
  };

  getTable = () => {
    const { isLoading } = this.state;
    const columns = [
      <Column key="date" title={Strings.date} value={e => e.date.format(DATE_MONTH_DAY_YEAR)} />,
      <Column key="time" title={Strings.time} value={e => e.date.format(TIME_FORMAT_12_UPPERCASE)} />,
      <Column key="steps" title={Strings.steps} value={e => e.steps} />,
    ];

    const dataForTable = this.dataToShow
      .filter(d => d.date.unix() <= moment().unix())
      .slice()
      .reverse();

    // data in table should be in reverse order than graph, last events on top
    return <TableWithLocalPagination isLoading={isLoading} data={dataForTable} columns={columns} />;
  };

  render() {
    return (
      <React.Fragment>
        {this.getGraph()}
        {this.getTable()}
      </React.Fragment>
    );
  }

  static propTypes = {
    deviceId: PropTypes.string.isRequired,
    patientId: PropTypes.string,
    hubId: PropTypes.string,
    kitId: PropTypes.string,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    getPlotData: PropTypes.func.isRequired,
    dateRangeType: PropTypes.string,
  };
  static defaultProps = {
    dateRangeType: 'Monthly',
  };
}

const mapDispatchToProps = dispatch => ({
  getPlotData: pageRequest => dispatch(actions.getPlotData(pageRequest)),
});

export default connect(null, mapDispatchToProps)(StepsReport);
