import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import React from 'react';
import CapEventsGraph, { takesFormat } from '../../components/Graphs/CapEventsGraph';
import { connect } from 'react-redux';
import _ from 'lodash';
import { RangePicker } from '../../components/Graphs/CompositeGraph/RangePicker';
import {
  Y_AXIS_LEFT_WIDTH,
  Y_AXIS_RIGHT_WIDTH,
  Y_AXIS_WIDTH_TINY,
} from '../../components/Graphs/CompositeGraph/Constants';
import {
  MINIMUM_PIXELS_PER_DAY,
  MIN_GRAPH_HEIGHT,
  OPTIMUM_PIXELS_PER_DAY,
  RANGE_PICKER_HEIGHT,
  RANGE_PICKER_HEIGHT_MOBILE,
} from '../../components/Graphs/GraphConstants';
import { isBrowser, isDesktop, isMobile } from 'react-device-detect';
import { useGesture } from '@use-gesture/react';
import {
  calcDateRangeAround,
  defaultOnDrag,
  defaultOnDragEnd,
  defaultOnDragEnd_Mobile,
  defaultOnDrag_Mobile,
} from '../../components/Graphs/CompositeGraph/ZoomHelpers';
import { MobileRangePicker } from '../../components/Graphs/CompositeGraph/MobileRangePicker';
import { DEFAULT_MOBILE_DATE_RANGE_UNIT, mobileDataRangeTypes } from '../../components/Graphs/CompositeGraph/Metrics';
import { TooltipWidgets } from '../../components/Graphs/TooltipWidgets';

function CapTakesPlot(props) {
  const MAX_DATE = 2147483647; // 9223372036854776000

  const [graphWidth, setGraphWidth] = React.useState();
  const [dateRange, setDateRange] = React.useState(
    isMobile
      ? calcDateRangeAround(props.graphMaxDate, DEFAULT_MOBILE_DATE_RANGE_UNIT)
      : {
          start: props.graphMaxDate
            .clone()
            .subtract(30, 'days')
            .startOf('day'),
          end: props.graphMaxDate.clone().startOf('day'),
        },
  );
  const maxRange = {
    start:
      props.graphMinDate < props.graphMaxDate.clone().subtract(30, 'days')
        ? props.graphMinDate.clone().startOf('day')
        : props.graphMaxDate
            .clone()
            .subtract(30, 'days')
            .startOf('day'),
    end: props.graphMaxDate
      .clone()
      .add(1, 'day')
      .startOf('day'),
  };

  const [mobileTooltipPayload, setMobileTooltipPayload] = React.useState(undefined);

  const graphContainerRef = React.useCallback(
    node => {
      if (node !== null) {
        const width = node.getBoundingClientRect().width;
        setGraphWidth(width);
        if (!isMobile) {
          const newStart = props.graphMaxDate
            .clone()
            .subtract(width / OPTIMUM_PIXELS_PER_DAY - 1, 'days')
            .startOf('day');
          const newEnd = props.graphMaxDate.clone().startOf('day');

          setDateRange({
            start: newStart.unix() >= maxRange.start.unix() ? newStart : maxRange.start.clone(),
            end: newEnd,
          });
        }
      }
    },
    [props.plotResizeTimestamp],
  );

  function calculateGraphTakes(plotData, graphTimezone, hasSchedule) {
    const graphCapTakesRaw =
      plotData &&
      plotData.events &&
      plotData.events
        .filter(e => !(e.type === 'missed_take' && e.scheduled >= moment().unix()))
        .map(e => {
          return {
            timestamp: e.timestamp,
            scheduledTimestamp: e.scheduled,
            type: hasSchedule ? e.type : 'scheduled_take',
          };
        });

    const graphCapTakes = [];
    graphCapTakesRaw &&
      graphCapTakesRaw.forEach(ct =>
        graphCapTakes.push({
          timestamp: ct.timestamp != null ? moment(ct.timestamp * 1000).tz(graphTimezone) : null,
          scheduledTimestamp:
            ct.scheduledTimestamp != null ? moment(ct.scheduledTimestamp * 1000).tz(graphTimezone) : null,
          type: ct.type,
        }),
      );
    return graphCapTakes;
  }

  function _unused_calculateGraphRefHours_SelectingRefHours(plotScheduleData, graphTimezone, minDate, maxDate) {
    const graphRefHoursRaw =
      plotScheduleData &&
      plotScheduleData.schedules &&
      plotScheduleData.schedules.map(s => {
        return {
          refHours: s.hours,
          start_date: s.start_date,
          end_date: s.end_date,
        };
      });

    // conversion to schedule timezone
    const graphRefHours = [];
    graphRefHoursRaw &&
      graphRefHoursRaw.forEach(rh =>
        graphRefHours.push({
          refHours: rh.refHours ? rh.refHours.map(h => moment(h * 1000).tz(graphTimezone)) : [],
          start_date: rh.start_date != null ? moment(rh.start_date * 1000).tz(graphTimezone) : null,
          end_date: rh.end_date != null ? moment(rh.end_date * 1000).tz(graphTimezone) : null,
        }),
      );

    const ret = [];

    graphRefHours.forEach(rh => {
      const isInSelectedDateRange =
        (rh.start_date == null && rh.end_date == null) ||
        (rh.start_date == null && rh.end_date >= minDate) ||
        (rh.end_date == null && rh.start_date <= maxDate) ||
        !(rh.start_date > maxDate || rh.end_date < minDate);

      if (isInSelectedDateRange)
        rh.refHours.forEach(rh2 =>
          ret.push({
            startDate: rh.start_date,
            endDate: rh.end_date,
            refHour: rh2,
            selected: false,
          }),
        );
    });

    const prevSelectedElement =
      state.graphRefHours && state.graphRefHours.length > 0 ? state.graphRefHours.find(r => r.selected) : undefined;

    if (ret.length > 0 && prevSelectedElement === undefined) ret[0].selected = true;
    if (ret.length > 0 && prevSelectedElement !== undefined) {
      let newSelected = ret.find(
        r =>
          r.startDate.unix() === prevSelectedElement.startDate.unix() &&
          r.endDate.unix() === prevSelectedElement.endDate.unix() &&
          r.refHour.unix() === prevSelectedElement.refHour.unix(),
      );
      if (newSelected !== undefined) newSelected.selected = true;
      else ret[0].selected = true;
    }

    return ret;
  }

  const calculateGraphRefHours = (plotScheduleData, applicationTimezone, graphMinDate, graphMaxDate) => {
    if (!plotScheduleData || !plotScheduleData.schedules) return [];
    const graphRefHoursRaw = plotScheduleData.schedules
      .sort((a, b) => (!a.start_date ? -1 : !b.start_date ? 1 : a.start_date - b.start_date))
      .map(s => {
        return {
          refHours: s.hours?.sort((a, b) => a - b),
          start_date: s.start_date,
          end_date: s.end_date > MAX_DATE || s.end_date === null ? MAX_DATE : s.end_date,
          timezone: s.timezone,
          marginPre: s.take_window?.[0] ? s.take_window[0] / 60 / 60 : null,
          marginPost: s.take_window?.[1] ? s.take_window[1] / 60 / 60 : null,
        };
      });

    const areSchedulesEqual = (timezone, refHours1, refHours2) => {
      if (!refHours1 && !refHours2) return true;
      if (refHours1 && !refHours2) return false;
      if (!refHours1 && refHours2) return false;
      if (refHours1.length !== refHours2.length) return false;

      let equal = true;
      refHours1.forEach((rh, index) => {
        const rh1 = rh;
        const rh2 = refHours2[index];
        const dt1 = moment(rh1 * 1000).tz(timezone);
        const dt2 = moment(rh2 * 1000).tz(timezone);
        if (dt1.hours() !== dt2.hours() || dt1.minutes() !== dt2.minutes()) equal = false;
      });

      return equal;
    };

    const areSchedulesWindowsEqual = (schedule1, schedule2) => {
      if (
        (schedule1.marginPre && !schedule2.marginPre) ||
        (!schedule1.marginPre && schedule2.marginPre) ||
        (schedule1.marginPre !== schedule2.marginPre && schedule1.marginPost !== schedule2.marginPost)
      )
        return false;
      return true;
    };

    const graphRefHoursRawCompacted = [];
    if (graphRefHoursRaw && graphRefHoursRaw.length > 0) {
      graphRefHoursRawCompacted.push({ ...graphRefHoursRaw[0], scheduleId: 0 });
      for (let i = 1; i < graphRefHoursRaw.length; i++) {
        const lastItem = graphRefHoursRawCompacted[graphRefHoursRawCompacted.length - 1];
        const thisItem = { ...graphRefHoursRaw[i], scheduleId: i };
        const schedulesEqual = areSchedulesEqual(lastItem.timezone, lastItem.refHours, thisItem.refHours);
        const marginsEqual = schedulesEqual ? areSchedulesWindowsEqual(lastItem, thisItem) : false;
        const areEqual = lastItem.end_date === thisItem.start_date && schedulesEqual && marginsEqual;
        if (areEqual) {
          lastItem.end_date = thisItem.end_date;
        } else {
          graphRefHoursRawCompacted.push({
            ...thisItem,
            continuationOf: schedulesEqual && !marginsEqual ? lastItem.scheduleId : undefined,
          });
        }
      }
    }

    // flatten and convert to schedule timezone
    const graphRefHours_Flat_InScheduleTz = [];
    graphRefHoursRawCompacted &&
      graphRefHoursRawCompacted.forEach((rh, i) =>
        rh.refHours?.forEach(rh2 =>
          graphRefHours_Flat_InScheduleTz.push({
            scheduleId: rh.scheduleId,
            refHour: moment(rh2 * 1000).tz(rh.timezone),
            start_date: rh.start_date != null ? moment(rh.start_date * 1000).tz(rh.timezone) : null,
            end_date: rh.end_date != null ? moment(rh.end_date * 1000).tz(rh.timezone) : null,
            timezone: rh.timezone,
            marginPre: rh.marginPre,
            marginPost: rh.marginPost,
            continuationOf: rh.continuationOf,
          }),
        ),
      );

    const takesWithinTimePeriod = (start_date, end_date, refHour, rhTimezone, graphMinDate, graphMaxDate) => {
      if (start_date != null && start_date > graphMaxDate) return [];
      if (end_date != null && end_date < graphMinDate) return [];
      if (start_date != null && end_date != null && end_date < start_date) return [];
      const date1 = moment(!start_date ? graphMinDate : start_date < graphMinDate ? graphMinDate : start_date).tz(
        rhTimezone,
      );
      const date2 = moment(!end_date ? graphMaxDate : end_date > graphMaxDate ? graphMaxDate : end_date).tz(rhTimezone);
      const deltaH = refHour.hours();
      const deltaM = refHour.minutes();
      const takes = [];
      for (let index = date1.clone().startOf('day'); index < date2; index = moment(index).add(1, 'days')) {
        let dateX = index.clone();
        dateX.add(1, 'days');
        dateX.subtract(23 - deltaH, 'hours');
        dateX.subtract(60 - deltaM, 'minutes');
        if (
          (!start_date || dateX >= start_date) &&
          (!end_date || dateX <= end_date) &&
          dateX >= graphMinDate &&
          dateX <= graphMaxDate
        ) {
          takes.push(dateX.unix());
        }
      }
      return takes;
    };
    // generate scheduled takes times (still in schedule timezone)
    const graphRefHours_Flat_WithTakes_InScheduleTz = [];
    graphRefHours_Flat_InScheduleTz.forEach(rh => {
      const takes = takesWithinTimePeriod(
        rh.start_date,
        rh.end_date,
        rh.refHour,
        rh.timezone,
        graphMinDate,
        graphMaxDate,
      );
      graphRefHours_Flat_WithTakes_InScheduleTz.push({
        scheduleId: rh.scheduleId,
        refHours: takes.map(t => moment(t * 1000).tz(rh.timezone)),
        start_date: rh.start_date,
        end_date: rh.end_date,
        marginPre: rh.marginPre,
        marginPost: rh.marginPost,
        continuationOf: rh.continuationOf,
      });
    });
    // convert to application timezone
    const ret = graphRefHours_Flat_WithTakes_InScheduleTz.map(rh => {
      return {
        scheduleId: rh.scheduleId,
        refHours: rh.refHours.map(take => take.clone().tz(applicationTimezone)),
        start_date: rh.start_date.clone().tz(applicationTimezone),
        end_date: rh.end_date.clone().tz(applicationTimezone),
        marginPre: rh.marginPre,
        marginPost: rh.marginPost,
        continuationOf: rh.continuationOf,
      };
    });
    return ret.filter(rh => rh.refHours.length > 0);
  };

  const onBrushChange = (startTimestamp, endTimestamp) => {
    const start = moment(startTimestamp * 1000);
    const end = moment(endTimestamp * 1000);
    if (start.isValid() && end.isValid()) {
      const range = {
        start: start,
        end: end.startOf('day'),
      };
      setDateRange(range);
    }
  };

  const { plotData, plotScheduleData, timezone } = props;

  const graphRefHours = calculateGraphRefHours(plotScheduleData, timezone, dateRange.start, dateRange.end);

  // unused: for plot with selecting refHour
  // const graphRefHoursForSelect = graphRefHours.map((rh, i) => ({
  //   text: `${rh.refHours[0].format(TIME_FORMAT_12)} (${rh.startDate ? rh.startDate.format('ll') : ''} - ${
  //     rh.endDate ? rh.endDate.format('ll') : ''
  //   })`,
  //   value: i,
  // }));
  // const selectedRefHoursIndex = graphRefHours.findIndex(r => r.selected);

  const hasSchedule = graphRefHours !== undefined && graphRefHours.length > 0;
  const graphCapTakes = calculateGraphTakes(plotData, timezone, hasSchedule);

  const calcMaxSelectionDays = () => {
    return Math.floor(graphWidth / MINIMUM_PIXELS_PER_DAY);
  };
  const calcMinSelectionDays = () => {
    return 7;
  };
  const bindGestures = isDesktop
    ? () => {}
    : useGesture(
        {
          onDrag: state =>
            !isMobile
              ? defaultOnDrag(state)
              : defaultOnDrag_Mobile(
                  state,
                  graphWidth,
                  { dateRange, maxRange },
                  newDateRange => setDateRange(newDateRange),
                  false,
                ),
          onDragEnd: state =>
            !isMobile
              ? defaultOnDragEnd(
                  state,
                  1.8,
                  dateRange.start,
                  dateRange.end,
                  maxRange.start,
                  maxRange.end,
                  calcMinSelectionDays() - 1,
                  calcMaxSelectionDays(),
                  newDateRange => setDateRange(newDateRange),
                )
              : defaultOnDragEnd_Mobile(state, dateRange, maxRange, newDateRange => setDateRange(newDateRange)),
        },
        {
          drag: { threshold: 5 },
        },
      );

  return (
    plotData &&
    graphRefHours && (
      <div className={`graphDiv ${isBrowser ? 'withBorder' : ''}`} ref={graphContainerRef}>
        {isMobile && (
          <MobileRangePicker
            dateRange={dateRange}
            maxRange={maxRange}
            allowedRangeTypes={[mobileDataRangeTypes.week, mobileDataRangeTypes.twoWeeks, mobileDataRangeTypes.month]}
            onChange={setDateRange}
            tooltipTimestamp={mobileTooltipPayload?.x}
            tooltipDateSkipHour
          />
        )}
        <div
          style={{
            height: `calc(100% - ${isMobile ? RANGE_PICKER_HEIGHT_MOBILE : RANGE_PICKER_HEIGHT}px)`,
            minHeight: MIN_GRAPH_HEIGHT,
            userSelect: 'none',
            touchAction: 'none',
            WebkitUserSelect: 'none',
            position: 'relative',
          }}
          {...bindGestures()}
        >
          <CapEventsGraph
            capTakes={graphCapTakes}
            minDate={dateRange.start}
            maxDate={dateRange.end.clone().add(1, 'd')}
            dateRangeType={dateRange.type}
            refHours={graphRefHours}
            // onSelectRefHourIndex={onRefHourChange.bind(this)}
            showWeekLines={false}
            graphWidth={graphWidth}
            onSetMobileTooltipPayload={setMobileTooltipPayload}
          />
        </div>
        {isMobile && mobileTooltipPayload && (
          <TooltipWidgets tooltipPayload={mobileTooltipPayload} metricsOverride={takesFormat} />
        )}
        {!isMobile && <div className="horizontal-rule thick" />}
        {!isMobile && (
          <RangePicker
            startTimestamp={dateRange.start.unix()}
            endTimestamp={dateRange.end.unix()}
            dataStartTimestamp={maxRange.start.unix()}
            resolution="days"
            resolutionMulti={1}
            ticks={Math.round((maxRange.end.unix() - maxRange.start.unix()) / (24 * 60 * 60))}
            width="100%"
            leftPadding={isMobile ? Y_AXIS_WIDTH_TINY : Y_AXIS_LEFT_WIDTH + 1}
            rightPadding={isMobile ? Y_AXIS_WIDTH_TINY : Y_AXIS_RIGHT_WIDTH}
            onChange={onBrushChange}
            dateRange={maxRange}
            maxSelectionTicks={isMobile ? undefined : calcMaxSelectionDays()}
            minSelectionTicks={isMobile ? undefined : calcMinSelectionDays()}
            renderMiniVersion={isMobile}
          />
        )}
      </div>
    )
  );
}

CapTakesPlot.propTypes = {
  plotData: PropTypes.shape({
    schedules: PropTypes.array,
  }),
  plotScheduleData: PropTypes.shape({
    events: PropTypes.array,
  }),
  graphMinDate: PropTypes.any,
  graphMaxDate: PropTypes.any,
  timezone: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    timezone: state.auth?.profile?.preferences?.timezone,
  };
};

export default connect(mapStateToProps, null)(CapTakesPlot);
