import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard } from 'swiper/modules';

import { drawDotShape } from './Components';
import { manipulateColor } from './ColorTools';
import { NOTIFICATIONS_COLORS } from './Constants';
import Strings from '../../../Strings';
import { scatterDotShapeEnum, symbolDotSizeEnum } from './Formatting';

export const renderMetric = metric => (
  <React.Fragment key={metric.id}>
    {drawDotShape(metric.format.dotShape, {
      cx: 0,
      cy: 0,
      fill: manipulateColor(0.4, metric.format.stroke),
      stroke: metric.format.stroke,
      format: metric.format,
    })}
    <div className="label">{metric.label}</div>
  </React.Fragment>
);

const renderRemindersLegend = () => (
  <React.Fragment>
    <div className="hr-vertical" />
    <div className="reminders-legend">
      {Object.values(NOTIFICATIONS_COLORS).map(color =>
        drawDotShape(scatterDotShapeEnum.cross, {
          cx: 0,
          cy: 0,
          fill: color,
          format: {
            dotSize: symbolDotSizeEnum.small,
          },
        }),
      )}
    </div>
    <div className="label">{Strings.reminders}</div>
  </React.Fragment>
);

export function GraphLegend(props) {
  return (
    <div className="legendContainer">
      {props.metricsLeft?.metricsExt?.length > 0 && props.metricsLeft.metricsExt.map(metric => renderMetric(metric))}
      {props.metricsRight?.metricsExt?.length > 0 && (
        <React.Fragment>
          <div className="hr-vertical" />
          {props.metricsRight.metricsExt.map(metric => renderMetric(metric))}
        </React.Fragment>
      )}
      {props.showReminders && renderRemindersLegend()}
    </div>
  );
}

export function GraphLegendSimple(props) {
  const metrics = graphTypes.find(t => t.id === props.graphType)?.allowedMetrics;
  return <div className="legendContainer">{metrics?.length > 0 && metrics.map(metric => renderMetric(metric))}</div>;
}

export function GraphLegendWithToggle(props) {
  return props.metrics?.length > 1 ? (
    <Swiper
      keyboard={{
        enabled: true,
      }}
      modules={[Keyboard]}
      spaceBetween={20}
      slidesPerView={'auto'}
      rewind
      watchSlidesProgress
      className="legendContainer"
    >
      {props.metrics.map(metric => (
        <SwiperSlide key={metric.id}>
          <div
            className={`legendItem ${metric.isSelected ? 'selected' : ''} ${metric.isDisabled ? 'disabled' : ''}`}
            onClick={props.toggleMetric ? () => props.toggleMetric(metric.id) : undefined}
          >
            {renderMetric(metric)}
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  ) : (
    <></>
  );
}
