import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment-timezone';

import TableWithLocalPagination, { Column } from '../../../containers/Table/TableWithLocalPagination';
import { isIsoDate, toolTipForTable } from '../../../utils';
import Strings from '../../../Strings';
import { DATE_FORMAT_FOR_SLIDER } from '../../../constants';

export default function PatientImportData({importData}) {

  if (!importData || !importData.length) {
    return <div>{Strings.noData}</div>;
  }

  const columns = importData[0].columns;
  const dataForTranspondedTable = columns.map(column => ({[Strings.property]: column}));
  importData.slice(1).forEach((row, index) => (
    columns.forEach((property, i) => {
      let dateValue;
      if (typeof row[property] === 'string' && isIsoDate(row[property])) {
        dateValue = moment(row[property]).format(DATE_FORMAT_FOR_SLIDER);
      }
      dataForTranspondedTable[i][`${Strings.record} ${index + 1}`] = dateValue || row[property];
    })
  ));
  
  return (
    <div className="raw-data-table">
      <TableWithLocalPagination
        name="import-data"
        data={dataForTranspondedTable}
        columns={Object.keys(dataForTranspondedTable[0]).map(c => <Column key={c} title={c} value={e => e[c] && typeof e[c] === 'string' ? toolTipForTable(e[c], e[c]) : e[c]} />)}
        infiniteScroll
      />
    </div>
  );
}

PatientImportData.propTypes = {
  importData: PropTypes.arrayOf(PropTypes.object).isRequired,
};