import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Strings from '../../../Strings';
import { actions } from '../../SuperUser/Patients/redux/actions';
import { RPM_PATIENT_STATUS } from '../../../constants';
import { graphContainer } from './graphContainers';
import { ColorPalette } from '../DashboardColorPalettes';
import GenericCategoryGraph, { chartTypeEnum } from '../../../components/Graphs/GenericCategoryGraph';
import { roundToDecimal } from '../../../utils/unitConverters';

export const PatientStatusMetadata = {
  id: 'PatientStatusWidget',
  name: Strings.widgets.patientStatus,
  permission: '',
  defProps: { i: 'PatientStatusWidget', w: 4, h: 2, minW: 2, minH: 2 },
  configuration: {
    chartType: {
      type: 'select',
      name: 'chartType',
      label: 'Chart Type',
      defaultValue: 'numbers',
      options: [
        { label: 'Numbers', value: 'numbers' },
        { label: 'Pie chart', value: chartTypeEnum.pie },
        { label: 'Bar chart', value: chartTypeEnum.bar },
        { label: 'Area chart', value: chartTypeEnum.area },
        { label: 'Line chart', value: chartTypeEnum.line },
        { label: 'Horizontal bar chart', value: chartTypeEnum.horizontalBar },
      ],
      canBeSetByDashboard: false,
    },
  },
};

function PatientStatus(props) {
  const [data, setData] = React.useState(
    Object.values(RPM_PATIENT_STATUS).map(status => ({ status: Strings.capPatient.patientStatus[status], key: status, count: '-' })),
  );
  const { colorPalette, refreshTimestamp, graphType, orgFilter, dragging, getPatients, onClick } = props;

  React.useEffect(() => {
    const pageRequest = {
      offset: 0,
      search: '',
      limit: 1,
      orgId: orgFilter,
    };
    Object.values(RPM_PATIENT_STATUS).forEach(status => {
      const patients = [...data];
      pageRequest.status = status;
      getPatients(pageRequest).then(response => {
        patients.find(p => p.key === status).count =  response.response?.pagination?.totalRecords;
        if (!patients.some(p => !p)) {
          setData(patients);
        }
    });
  });
  }, [refreshTimestamp, orgFilter]);

  const dataSeries = [
    {
      name: 'Status',
      color: colorPalette?.interpolate(0.73),
      selector: item => item.count,
    },
  ];

  const getColorForStatus = stat => {
    switch (stat) {
      case 'Onboarded':
      case 'Registered':
        return 'blue';
      case 'Enrolled':
      case 'Activated':
        return 'green';
      case 'Disenrolled':
        return 'red';
      default:
        return 'black';
    }
  };

  const total = data.map(d => d.count).reduce((a, b) => typeof a === 'number' && typeof a === 'number' ? a + b : b, 0);
  const numericWidget = (
    <div className="box2colsWithVl v3cols">
      {data.concat({ status: 'Total', key: 'total', count: total })
        .map(v => (
          <div className="box2colsWithVl-box" key={`stats-${v.status}`}>
            <div
              className={`box2colsWithVl-valueBox ${getColorForStatus(v.status)}`}
              data-tooltip-content={`${
                total === 0 ? 0 : roundToDecimal((v.count / total) * 100, 0)
              }% off all patients`}
              data-tooltip-id={
                dragging || (total && v.count === 'total_number') ? undefined : 'tooltip'
              }
            >
              {v.count}
            </div>
            <div
              onClick={v.status !== 'Total' ? () => onClick("/cap-patients/list", null, `?status=${v.key}`) : undefined}
              className={`box2colsWithVl-labelBox ${
                v.status !== 'Total' ? 'clickable' : ''
              }`}
              data-tooltip-content={v.status === 'Total'
                ? Strings.numberOfPatients
                : Strings.formatString(Strings.capPatient.numberOfPatientsInState, Strings.capPatient.patientStatus[v.key])}
              data-tooltip-id={
                dragging ? undefined : 'tooltip'
              }
            >
              {v.status}
            </div>
          </div>
        ))}
    </div>
  )

  return graphType === 'numbers' ? numericWidget : graphContainer(
    <GenericCategoryGraph
      graphData={data}
      hasBorder
      forceCondensed
      xAxisTopPosition={false}
      yAxisWidth={graphType === chartTypeEnum.horizontalBar ? 100 : 40}
      yAxisUnit=""
      tooltipTitle={Strings.status}
      dataSeries={dataSeries}
      categorySelector={item => item.status}
      hasReferenceAreas
      graphType={graphType}
      colorPalette={colorPalette}
      onCategoryClicked={state => onClick("/cap-patients/list", null, `?status=${data.find(item => item.status === state).key}`)}
    />,
    'Patient Status',
  );
}

PatientStatus.propTypes = {
  getPatients: PropTypes.func,
  onClick: PropTypes.func,
  colorPalette: PropTypes.any,
  refreshTimestamp: PropTypes.any,
  graphType: PropTypes.string,
  orgFilter: PropTypes.string,
  dragging: PropTypes.bool,
};

const mapStateToProps = state => {
  const userId = state.auth?.profile?.id;
  return {
    colorPalette:
      ColorPalette[state.dashboardState.configuration?.allDashboards?.colorPalette] || Object.values(ColorPalette)[0],
      orgFilter: state.superUser?.patients?.orgFilter?.[userId],
  };
};


const mapDispatchToProps = dispatch => ({
  getPatients: pageRequest => dispatch(actions.getPatients(pageRequest)),
  onClick: (path, params, search) => {
    dispatch(
      push({
        pathname: path,
        state: { params },
        search,
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientStatus);
