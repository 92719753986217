import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { openModalAction } from '../../actions/modal';
import { PageHeader } from '../../components/PageHeader';
import { Button } from '../../components/PageHeader/Button';
import { TextInput } from '../../components/PageHeader/TextInput';
import Table, { Column } from '../../containers/Table/TableWithPagination';
import Strings from '../../Strings';
import { lastActivityDisplay, showStatus } from '../../utils';
import { EDIT_USER, INVITE_USER, userActions } from '../../actions/user';
import { CARE_PROVIDER, DOCTOR } from '../../utils/userRoles';
import { HEADER_BUTTON_BLUE } from '../../constants';
import { hasPermission, PERMISSIONS } from '../../utils/userPermissions';

function UserList(props) {
  const orgId = props.organizationId ? decodeURIComponent(props.organizationId) : props.orgId;
  const pageRequest = React.useRef({
    offset: 0,
    search: '',
    orgId,
    care_providers: props.type === CARE_PROVIDER ? true : undefined,
  });

  const [isLoading, setIsLoading] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [pagination, setPagination] = React.useState({});
  const { role, refreshTimestamp } = props;

  const loadData = () => {
    setIsLoading(true);
    props.getUsers(pageRequest.current).then(response => {
      if (response && response.response.data) {
        setUsers(response.response.data);
        setPagination(response.response.pagination);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    pageRequest.current.archetype = role;
    loadData();
  }, [role, refreshTimestamp]);

  const onSearchQueryChange = query => {
    return onSearchQueryChangeDebounced(query);
  };

  const onSearchQueryChangeDebounced = _.debounce(query => {
    pageRequest.current.offset = 0;
    pageRequest.current.search = query;
    loadData();
  }, 1000);

  const onOffsetChange = (offset, limit) => {
    pageRequest.current.offset = offset;
    if (limit) {
      pageRequest.current.limit = limit;
    }
    loadData();
  };

  const addUser = () => {
    props.addUser(loadData);
  };

  const editUser = (id, user) => {
    props.editUser(user, loadData);
  };

  const deleteUser = (id, user) => {
    const idToDelete = user.masked_id ? user.masked_id : user.id;
    const data = {
      title: (
        <span>
          {Strings.deleteOrgAdminWarning} <b>{`${user.first_name} ${user.last_name}`}</b>?
        </span>
      ),

      onConfirmAction: userActions.delete(idToDelete),
      confirmPostAction: () => loadData(),
      onCancelAction: null,
    };
    props.openConfirmModal(data);
  };

  const resetPassword = user => {
    const data = {
      title: (
        <span>
          {Strings.warnigs.adminResetPassword} <b>{user.username}</b>?
        </span>
      ),
      hideCaution: true,
      onConfirmAction: userActions.resetPassword({
        method: 'email',
        email: user.email,
      }),
      onCancelAction: null,
      caption: Strings.confirmPasswordReset,
    };

    props.openConfirmModal(data);
  };

  const resendInvite = user => {
    const data = {
      title: (
        <span>
          {Strings.warnigs.resendInvite} <b>{user.username}</b>?
        </span>
      ),
      hideCaution: true,
      onConfirmAction: userActions.resendInvite({
        id: user.masked_id ? user.masked_id : user.id,
      }),
      onCancelAction: null,
      caption: Strings.confirmReinvite,
    };

    props.openConfirmModal(data);
  };

  const onTextInputChange = e => {
    onSearchQueryChange(e.target.value);
  };

  const getHeaderComponents = () => {
    return (
      <React.Fragment>
        <TextInput class="search" placeholder={Strings.search} onChange={onTextInputChange} />
        {props.type !== CARE_PROVIDER && (
          <Button onClick={addUser} class={HEADER_BUTTON_BLUE} title={Strings.add} />
        )}
      </React.Fragment>
    );
  };

  const headerTitle =
    !isLoading && users && pagination
      ? Strings.formatString(
          Strings.showingXOf,
          users.length,
          pagination.totalRecords ? pagination.totalRecords : 0,
          props.type === CARE_PROVIDER ? Strings.capPatient.careProviders : Strings.rolesPluralEnum[role],
        )
      : Strings.showingWait;

  const columns = [
    <Column key="name" title={Strings.name} value={d => `${d.first_name} ${d.last_name}`} />,
    <Column key="username" title={Strings.userName} value={d => d.username} />,
    <Column key="email" title={Strings.emailAddress} value={d => d.email} />,
    <Column key="phone" title={Strings.phoneNumber} value={d => d.phone} />,
    <Column key="last_login" title={Strings.lastLogin} value={d => lastActivityDisplay(d.last_login)} />,
  ];

  props.type === CARE_PROVIDER &&
    columns.push(
      <Column key="organization" title={Strings.organization} value={d => d.organization} />,
    );
  role === DOCTOR &&
    columns.push(
      <Column
        key="privileges"
        title={Strings.adminWritePrevileges}
        value={d => showStatus(d.read_only ? 'disabled' : 'enabled')}
      />,
    );
  role === DOCTOR &&
    columns.push(
      <Column
        key="timer"
        title={Strings.adminTimerRights}
        value={d => showStatus(d.timer_off ? 'disabled' : 'enabled')}
      />,
    );
  columns.push(<Column key="status" title={Strings.status} value={d => showStatus(d.status)} />);
  columns.push(<Column key="anonymize" title={Strings.maskPHIData} value={d => showStatus(d.anonymize ? 'enabled' : 'disabled')} />);

  let buttons = [];
  const adminButtons = [
    {
      icon: 'edit',
      onClick: editUser,
      text: Strings.edit,
    },
    {
      icon: 'delete',
      onClick: deleteUser,
      text: Strings.delete,
    },
    {
      icon: 'refresh',
      onClick: (_, user) => user.status === 'pending' ? resendInvite(user) : resetPassword(user),
      text: (_, user) => user.status === 'pending' ? Strings.reInviteToPortal : Strings.resetPassword,
    },
  ];
  if (props.type === CARE_PROVIDER && hasPermission(PERMISSIONS.CARE_PROVIDER_REASSIGN)) {
    buttons.push({
      icon: 'refresh',
      onClick: (_, user) => props.reassignCareProvider({user, organizationId: orgId}),
      text: Strings.reassignCareProvider,
    });
  } 
  if (props.type !== CARE_PROVIDER) {
    buttons = adminButtons;
  }

  return (
    <>
      <PageHeader left={headerTitle} right={getHeaderComponents()} />
      <Table
        isLoading={isLoading}
        name="organizations"
        data={users}
        onOffsetChange={onOffsetChange}
        pagination={pagination}
        buttons={buttons}
        infiniteScroll
      >
        {columns}
      </Table>
    </>
  );
}

const mapStateToProps = state => {
  return {
    orgId: state.auth.organization?.masked_id,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onNavigate: path => dispatch(push(path)),
  getUsers: pageRequest => dispatch(userActions.list(pageRequest)),
  addUser: onSuccess =>
    dispatch(
      openModalAction('edit-user', {
        action: userActions.invite,
        actionType: INVITE_USER,
        organization_id: props.organizationId,
        userType: props.role,
        onSuccess,
      }),
    ),
  editUser: (user, onSuccess) =>
    dispatch(
      openModalAction('edit-user', {
        action: userActions.edit,
        actionType: EDIT_USER,
        organization_id: props.organizationId,
        user,
        userType: props.role,
        onSuccess,
      }),
    ),
  reassignCareProvider: data =>
    dispatch(
      openModalAction('reassign-care-provider', data),
    ),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
