import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Wizard, { wizardTypes } from '../../containers/Modal/Wizard';
import { closeModal } from '../../actions/modal';
import PatientImportData from '../../pages/SuperUser/Patients/PatientImportData';
import Strings from '../../Strings';
import './PatientRawDataModal.scss';

function PatientRawDataModal(props) {
  const { data, modalId, isTopModal } = props;
  const { patient } = data;

  const pages = [
    {
      id: 'patient-raw-data',
      title: `${Strings.importData} for ${patient?.patientName}`,
      content: (<PatientImportData importData={patient?.rawPatientImportData} />),
    },
  ];

  return (
    <Wizard
      name="patient-raw-data"
      id={modalId}
      pages={pages}
      showPagesFilter={false}
      isTopModal={isTopModal}
      type={wizardTypes.floatingModal}
    />
  );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  closeModal: () => dispatch(closeModal('patient-raw-data', {}, ownProps.modalId)),
});

PatientRawDataModal.propTypes = {
  data: PropTypes.shape({
    patient: PropTypes.shape({
      patientName: PropTypes.string,
      rawPatientImportData: PropTypes.object,
    }),
  }),
  modalId: PropTypes.string.isRequired,
  isTopModal: PropTypes.bool.isRequired,
};

export default connect(null, mapDispatchToProps)(PatientRawDataModal);
