import moment from 'moment-timezone';

import Strings from '../../Strings';
import { enrollmentFieldsEnum } from '../enrollmentFields';
import { removeNonErrors } from './rpmPatient';


export const validateEnrollment = (enrollment, stage, timezone) => {
  const errors = validateCondition(enrollment.conditionId)
    .concat(validateProgram(enrollment.subprogram))
    .concat(validateConsents(enrollment))
    .concat(validateDates(enrollment, timezone, stage))
    .concat(validateKit(enrollment.kit_id, stage));

  return errors;
};

export function validateCondition(conditionId) {
  const ret = [];
  if (!conditionId) {
    ret.push({ property: 'conditionId', propertyName: Strings.enrollmentCondition, errors: [], missing: enrollmentFieldsEnum.conditionId.required() });
  }
  return removeNonErrors(ret);
};

export function validateProgram(subprogram) {
  const ret = [];
  if (!subprogram) {
    ret.push({ property: 'subprogram', propertyName: Strings.enrollmentProgram, errors: [], missing: enrollmentFieldsEnum.subprogram.required() });
  }
  return removeNonErrors(ret);
};

export function validateConsents(enrollment) {
  const { verbalConsent, writtenConsent } = enrollment;
  const ret = [];
  if (!verbalConsent && !writtenConsent) {
    ret.push({ property: 'enrollmentConsents', propertyName: Strings.enrollmentConsent, errors: [], missing: enrollmentFieldsEnum.enrollmentConsents.required() });
  }
  return removeNonErrors(ret);
};

export function validateDates(enrollment, timezone, stage) {
  const { enrollmentStart, enrollmentEnd } = enrollment;
  const errors = [];

  if (!enrollmentStart) {
    errors.push({ property: 'enrollmentStart', propertyName: Strings.enrollmentStart, errors: [], missing: enrollmentFieldsEnum.enrollmentStart.required() });
  }
  if (!enrollmentEnd) {
    errors.push({ property: 'enrollmentEnd', propertyName: Strings.enrollmentEnd, errors: [], missing: enrollmentFieldsEnum.enrollmentEnd.required(enrollment, stage) });
  }
  if (enrollmentEnd && moment(enrollmentEnd).unix() < moment().tz(timezone).endOf('day').unix()) {
    errors.push({ property: 'enrollmentEnd', propertyName: Strings.enrollmentEnd, errors: [Strings.errors.dateInThePast], missing: false });
  }
  if (enrollmentStart && moment(enrollmentStart).unix() < moment().tz(timezone).endOf('day').unix()) {
    errors.push({ property: 'enrollmentStart', propertyName: Strings.enrollmentStart, errors: [Strings.errors.dateInThePast], missing: false });
  }
  if (enrollmentStart && enrollmentEnd && moment(enrollmentStart).isAfter(enrollmentEnd)) {
    errors.push({ property: 'enrollmentEnd', propertyName: Strings.enrollmentEnd, errors: [Strings.errors.enrollmentEndMustBeAfterenrollmentStart] });
  }
  return removeNonErrors(errors);
};

export function validateKit(kitId, stage) {
  const errors = [];
  if (!kitId) {
    errors.push({ property: 'kit_id', propertyName: Strings.enrollmentKit, errors: [], missing: enrollmentFieldsEnum.kit_id.required(undefined, stage) });
  }
  return removeNonErrors(errors);
};
