import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import Wizard, { wizardTypes } from '../../containers/Modal/Wizard';
import Strings from '../../Strings';
import { notificationActions } from '../../components/Notification/redux/actions';
import { actions as cohortActions } from '../../pages/SuperUser/Patients/Cohorts/redux/actions';
import { actions } from '../../pages/SuperUser/Patients/redux/actions';
import { actions as kitActions } from '../../pages/Kits/redux/actions';
import { actions as orgActions } from '../../pages/Organizations/redux/actions';
import { patientAction } from '../../actions/patient';
import userActions from '../../actions/user';
import { closeModal, openModalAction } from '../../actions/modal';
import { ASSIGN_PATIENT_TO_COHORT_ERROR, ASSIGN_PATIENT_TO_FACILITY_ERROR, CREATE_CONDITION_ERROR } from '../../pages/SuperUser/Patients/Cohorts/redux/constants';
import { ATTACH_PATIENT_TO_KIT_ERROR, DELETE_KIT_ERROR, DELETE_KIT_RESULT, GET_PATIENT_KIT_INFO_RESULT } from '../../pages/Kits/redux/constants';
import { ADD_NOTE_ERROR, ADD_PATIENT_ERROR, EDIT_PATIENT_ERROR, ENROLL_PATIENT_ERROR, GET_REMINDERS_DEFAULT_MESSAGES_RESULT, SEND_WELCOME_MESSAGE_ERROR } from '../../pages/SuperUser/Patients/redux/constants';
import { UPDATE_PATIENT_SCHEDULE } from '../../actions/action-types';
import { getFieldsForPage, getRequiredFieldsForPage, getWrongFieldsCountForPage, OnboardPatientModalPages } from '../_ModalsMetadata/ModalsMetadata';
import { validateRpmPatient } from '../../utils/validators/rpmPatient';
import { validateEnrollment } from '../../utils/validators/enrollment';
import { validateWelcomeMessage } from '../../utils/validators/welcomeMessage';
import { checkIfPatientWasModified, fixNewConditions, patientToDTO } from '../../utils/rpmPatientFields';
import { ACTIVATION_METHODS, enrollmentToDTO, fixNewEnrollmentCondition } from '../../utils/enrollmentFields';
import { welcomeMessageToDTO } from '../../utils/welcomeMessageFields';
import { flatProgramsSubprogramsList, programs, RTMBehavioralHealth, RTMMusculoskeletal, RTMRespiratory, subprograms } from '../../utils/cmsPrograms';
import { RPM_PATIENT_STATUS } from '../../constants';
import PatientDetailsPage from './PatientDetailsPage';
import ContactInformationPage from './ContactInformationPage';
import MedicalHistoryPage from './MedicalHistoryPage';
import CenterDetailsPage from './CenterDetailsPage';
import InsurancePage from './InsurancePage';
import TransplantDetailsPage from './TransplantDetailsPage';
import RegistrationPage from './RegistrationPage';
import ProgramPage from './ProgramPage';
import KitPage from './KitPage';
import EnrollmentDatesPage from './EnrollmentDatesPage';
import WelcomeMessagePage from './WelcomeMessagePage';
import './OnboardPatientWizard.scss';
import { GET_LINKED_ORGANIZATIONS_RESULT } from '../../pages/Organizations/redux/constants';

export const OnboardStages = {
  onboarding: 'onboarding',
  registration: 'registration',
  rescheduleTelevisit: 'rescheduleTelevisit',
  enrolling: 'enrolling',
  activating: 'activating',
  settingEndDate: 'settingEndDate',
  editPatient: 'editPatient',
};

const calcNextStageForPatient = (patient, modalStage) => {
  switch (modalStage) {
    case OnboardStages.onboarding:
      return RPM_PATIENT_STATUS.onboarded;
    case OnboardStages.registration:
      return RPM_PATIENT_STATUS.verified;
    case OnboardStages.enrolling:
      return RPM_PATIENT_STATUS.enrolled;
    case OnboardStages.activating:
      return RPM_PATIENT_STATUS.activated;
    case OnboardStages.settingEndDate:
      return RPM_PATIENT_STATUS.ended;
    case OnboardStages.editPatient:
      return patient.status;
    default:
      return patient.status;
  }
};

function OnboardPatientWizard(props) {
  const getPatientFromProps = patient => {
    const futureTelevisit = patient.enrollmentSchedules?.find(s => moment(s.enrollAt).isAfter(moment()));
    return {
      ...patient,
      dateOfBirth: patient.dateOfBirth ? new Date(moment(patient.dateOfBirth, 'YYYY-MM-DD').format()) : '',
      conditions: Object.keys(patient.conditions),
      medicalHistory: null,
      transplantDetails: patient.transplantDetails?.length > 0 ? patient.transplantDetails.map(td => ({
        ...td,
        transplantDate: td.transplantDate ? new Date(moment(td.transplantDate, 'YYYY-MM-DD').format()) : '',
      })) : [{}],
      centerDetails: patient.centerDetails?.length > 0 ? patient.centerDetails : [{}],
      insurance: patient.insurance?.length > 0 ? patient.insurance : [{}],
      medications: patient.medications?.filter(m => !m.ndc && _.isEmpty(m.prescriber)).map(m => m.name),
      medicationsWithExtraData: patient.medications?.filter(m => m.ndc || !_.isEmpty(m.prescriber)),
      televisitChecked: futureTelevisit ? true : false,
      initiateEnrollmentByProviderAt: futureTelevisit?.enrollAt ? new Date(moment.tz(futureTelevisit.enrollAt, patient.timezone).format()) : null,
    };
  };

  const getEnrollmentFromProps = (patient, stage, orgTimezone) => {
    if (_.isEmpty(patient?.patientEnrollment?.enrollments)) {
      return {
        conditionId: patient?.conditions && Object.keys(patient.conditions).length === 1 ? Object.keys(patient.conditions)[0] : null,
        enrollmentEnd: '',
      };
    }
    const enrollments = Object.values(patient.patientEnrollment.enrollments).map(v => {
        return {
          ...v,
          name: programs[v.program],
          enrollmentStart: v.enrollmentStart
          ? new Date(v.enrollmentStart)
          : stage === OnboardStages.activating
          ? new Date(
            moment
              .tz(orgTimezone)
              .add(14, 'days')
              .startOf('day')
              .format())
          : '',
          enrollmentEnd: v.enrollmentEnd ? new Date(v.enrollmentEnd) : '',
          subprogram: subprograms[v.subprogram],
          isDefault: v.program === patient.patientEnrollment.defaultProgram,
          id: flatProgramsSubprogramsList.find(
            l => l.name === programs[v.program] && l.subprogram === subprograms[v.subprogram],
          )?.id,
          care_provider: v.careProvider?.uuid || null,
          care_provider_org_uuid: v.careProviderOrg?.uuid || null,
          medication: v.medications?.length > 0 ? v.medications[0] : null,
          activation_method: v.activationMethod,
        };
      });
    return enrollments[0];
  };

  const [patient, setPatient] = useState(
    props.data.patient
      ? getPatientFromProps(props.data.patient)
      : {
          centerDetails: [{}],
          insurance: [{}],
          transplantDetails: [{}],
          language: Object.keys(Strings.languagesEnum)[0],
          timezone: props.orgTimezone,
        },
  );
  const [patientNotes, setPatientNotes] = useState();
  const [registrationNote, setRegistrationNote] = useState();
  const [enrollment, setEnrollment] = useState(getEnrollmentFromProps(props.data.patient, props.data.stage, props.orgTimezone));
  const [enrollmentNote, setEnrollmentNote] = useState();
  const [kits, setKits] = useState([]);
  const [welcomeMessage, setWelcomeMessage] = useState({
    set: false,
    text: undefined,
    dateTime: new Date(
      moment
        .tz(props.data.patient?.timezone)
        .add(14, 'days')
        .startOf('day')
        .add(9, 'hours')
        .format()),
  });
  const [defaultWelcomeMessage, setDefaultWelcomeMessage] = useState();
  const [linkedOrgs, setLinkedOrgs] = useState([]);
  const [careProviders, setCareProviders] = React.useState([]);
  const [orgConfig, setOrgConfig] = React.useState();

  const [highlightInvalidFields, setHighlightMissingFields] = React.useState(false);
  const [validationErrors, setValidationErrors] = React.useState([]);
  const [saveBtnClicked, setSaveBtnClicked] = React.useState(false);

  const stage = props.data?.stage;
  const patientNextStage = calcNextStageForPatient(patient, stage);
    
  useEffect(() => {
    props.getConditions();
    props.getFacilities();
    if (props.data?.patient?.id) {
      props.getMedications(props.data.patient.id);
      props.getNotes(patient.id).then(resp => {
        if (resp.response?.data?.length > 0) {
          setPatientNotes(resp.response.data.map(n => n.content).join('\n'));
        }
      });
    }

    if (props.data?.patient?.id && stage === OnboardStages.activating) {
      // get patient kit
      props.getPatientKitInfo(patient.id).then(resp => {
        if (
          resp.type === GET_PATIENT_KIT_INFO_RESULT &&
          resp.response.kit?.id &&
          props.data.stage === OnboardStages.activating
        ) {
          setEnrollment(e => ({ ...e, kit_id: resp.response.kit.id }));
          setKits(k => ([...k, { label: resp.response.kit.id, value: resp.response.kit.id }]));
        }
      });
      props.getKits({ havingPatients: false }).then(resp =>
        setKits(k => (
          k.concat(resp.response.kits.map(d => {
              return { label: `${d.id}${d.name ? ` (${d.name})` : ''}`, value: d.id, name: d.name };
            }),
          )
        )),
      );
      props.getRemindersDefaultMessages({ language: patient.language }).then(resp => {
        if (resp.type === GET_REMINDERS_DEFAULT_MESSAGES_RESULT) {
          const defaultMessage = resp.response?.data?.find(m => m.type === 'welcome_msg_sms')?.message;
          setDefaultWelcomeMessage(defaultMessage);
          setWelcomeMessage(m => ({...m, text: defaultMessage}));
        }
      });
    }
    props.getOrgConfig({organizationId: props.organizationId}).then(resp => {
      setOrgConfig({
        ...resp.response,
        enrollment_activation_modes: !resp.response?.enrollment_activation_modes?.some(m => m === ACTIVATION_METHODS.Manual)
          ? [ACTIVATION_METHODS.Manual].concat(resp.response?.enrollment_activation_modes || [])
          : resp.response?.enrollment_activation_modes
      });
    });
  }, []);

  useEffect(() => {
    if (!props.conditions || props.conditions?.length === 0) {
      return;
    }
    setPatient(p => ({
      ...p,
      conditionsObjects: p.conditions?.map(c => {
        const condition = props.conditions.find(con => con.id === c);
        return {
          ...condition,
          value: condition?.id,
          label: condition?.title,
        };
      }),
    }));

    const conditionToSet = props.conditions.find(c => c.id === enrollment?.conditionId);
    if (conditionToSet) {
      setEnrollment(e => ({
        ...e,
        conditionObject: {
            ...conditionToSet,
            value: conditionToSet?.id,
            label: conditionToSet?.title,
          },
      }));
    }
  }, [props.conditions]);

  useEffect(() => {
    let errors = [];
    if (props.facilities && props.conditions) {
      errors = validateRpmPatient(patient, props.conditions, props.facilities, patientNextStage);
    }
    const enrollmentErrors = validateEnrollment(enrollment, patientNextStage, props.orgTimezone);
    if (stage === OnboardStages.settingEndDate) {
      setValidationErrors(enrollmentErrors?.filter(e => e.property === 'enrollmentEnd') || []);
      return;  
    }

    const welcomeMessageErrors = stage === OnboardStages.activating ? validateWelcomeMessage(welcomeMessage, patient.timezone) : [];
    const patientNotChanged = stage === OnboardStages.editPatient && 
      !checkIfPatientWasModified(fixNewConditions(patient, patient.conditionsObjects?.map(c => ({...c, id: c.code}))), getPatientFromProps(props.data.patient));
    errors = errors.concat(patientNotChanged ? [{ propertyName: Strings.capPatient.patientDetails, errors: ['No changes to save'] }] : []);

    setValidationErrors(stage !== OnboardStages.activating && stage !== OnboardStages.enrolling ? errors : errors.concat(enrollmentErrors).concat(welcomeMessageErrors));
  }, [patient, enrollment, welcomeMessage, props.conditions, props.facilities]);

  useEffect(() => {
    if (enrollment.enrollmentStart && !welcomeMessage.dateSet) {
      setWelcomeMessage(m => ({...m, date: new Date(enrollment.enrollmentStart)}));
    }
  }, [enrollment.enrollmentStart]);

  useEffect(() => {
    if (!props.organizationId) {
      return;
    }

    props.getLinkedOrgs(props.organizationId).then(resp => {
      if (resp?.type === GET_LINKED_ORGANIZATIONS_RESULT) {
        setLinkedOrgs(resp.response.map(o => ({ ...o, value: o.uuid, label: o.name })));
        if (!enrollment.care_provider_org_uuid && resp.response?.length === 1) {
            setEnrollment(e => ({ ...e, care_provider_org_uuid: resp.response[0].uuid }));
          }
        }
    });
    const careProvidersRequest = {
      orgId: props.organizationId,
      care_providers: true,
    }
    props.getCareProviders(careProvidersRequest).then(resp => {
      if (resp.response.data?.length > 0) {
        setCareProviders(
          resp.response.data.map(cp => ({
            ...cp,
            value: cp.masked_id,
            label: `${cp.first_name} ${cp.last_name}`,
            organization: cp.organization,
          }))
        );
      } else {
        const selectedProvider = enrollment?.careProvider;
        if (selectedProvider) {
          setCareProviders([{ ...selectedProvider, value: selectedProvider.uuid, label: selectedProvider.name, organization: selectedProvider.organization }]);
        }
      }
    });
  }, [props.organizationId]);
  
  const saveNote = async note => {
    if (note) {
      await props.addNote(patient.id, { content: note }).then(resp => {
        if (resp.type === ADD_NOTE_ERROR) {
          const error = resp.response?.data?.error?.message;
          props.showNotification(error, 5000, true);
        }
      });
    }
  };

  const savePatient = async newConditions => {
    const newPatient = fixNewConditions(patient, newConditions);
    if(props.data.patient?.id && !checkIfPatientWasModified(newPatient, getPatientFromProps(props.data.patient))) {
      return newPatient;
    }
    const data = patientToDTO(newPatient);

    const response = await props.savePatient(data);
    if (response) {
      if (response.type === ADD_PATIENT_ERROR || response.type === EDIT_PATIENT_ERROR) {
        const error = response.response?.data?.error?.message;
        props.showNotification(error, 5000, true);
        return false;
      }
      props.showNotification(Strings.formatString(Strings.capPatient.patientSaved, response.response.patientName));
      if (patient.facilityId && patient.facilityId !== props.data.patient?.facilityId) {
        const facilityResp = await props.assignPatientToFacility(parseInt(response.response?.id, 10), patient.facilityId);
        if (facilityResp?.type === ASSIGN_PATIENT_TO_FACILITY_ERROR) {
          const assignError = resp.response.data?.error?.message;
          props.showNotification(assignError, 5000, true);
          return false;
        } else {
          return { ...response.response, facilityId: patient.facilityId };
        }
      }
      return response.response;
    }
  };

  const addConditions = async () => {
    let fail = false;
    const newConditions = [];

    const addCondition = async condition => {
      const request = { title: condition.title, icd10cm: condition.code };
      const response = await props.createCondition(request);
      if (response && response.type === CREATE_CONDITION_ERROR) {
        props.showNotification(response.response?.data?.error?.message, 5000, true);
        setSaveBtnClicked(false);
        fail = true;
      } else {
        newConditions.push({...condition, id: response.response?.id});
      }
    }
  
    if (patient.conditionsObjects?.filter(c => c.code).length > 0) {
      for (const [_, condition] of patient.conditionsObjects.filter(c => c.code).entries()) {
        await addCondition(condition);
      }
    }

    if (enrollment.conditionObject?.code && !newConditions.some(c => c.code === enrollment.conditionObject.code)) {
      await addCondition(enrollment.conditionObject);
    }

    return fail ? null : newConditions;
  };

  const addEnrollmentConditionToPatient = async newConditions => {
    let ret = false;
    let conditionId = enrollment.conditionObject?.id;
    if (patient.conditionsObjects.some(c => c.id === enrollment.conditionObject?.id)) {
      return conditionId;
    }
    if(!enrollment.conditionObject?.id && enrollment.conditionObject?.code) {
      conditionId = newConditions.find(c => c.code === enrollment.conditionObject.code)?.id;
    }
    const editPatientResp = await props
      .savePatient(
        { conditions: patient.conditions.concat([conditionId]) },
        parseInt(patient.id, 10),
      );
      if (editPatientResp?.type === EDIT_PATIENT_ERROR) {
        const error = editPatientResp.response?.data?.error?.message;
        props.showNotification(error, 5000, true);
      }
      ret = conditionId;
    return ret;
  };

  const checkForLanguageChangeAndShowModal = () => {
    const patientHasReminders = props.patientMedications?.some(m => m.sms_reminder);
    if (props.data?.patient?.id && props.data?.patient?.language !== patient.language && patientHasReminders) {
      const data = {
        title: <span>{Strings.remindersChangeRequired}</span>,
        caption: Strings.remindersChangeRequiredCaption,
        hideCaution: true,
        onConfirmAction: () => {
          props.onNavigate(`/cap-patients/${props.data.patient.id}/medications`),
            props.closeModal('confirmation-modal');
        },
        onCancelAction: null,
      };
      props.openConfirmModal(data);
    }
  };

  const assignToCohort = async () => {
    let ret = false;
    if (patient.cohortId && patient.cohortId !== props.data.patient?.cohortId) {
    const response = await props.assignPatientToCohort(parseInt(patient.id, 10), patient.cohortId);
    if (response?.type === ASSIGN_PATIENT_TO_COHORT_ERROR) {
        const error = response.response?.data?.error?.message;
        props.showNotification(error, 5000, true);
        ret = false;
      }
      ret = true;
    }
    return ret;
  };

  const onAssignKit = async () => {
    if (!enrollment.kit_id) {
      return true;
    }
    // if patient kit remains unchanged
    if (props.kit && props.kit.id === enrollment.kit_id) {
      return true;
    }

    // otherwise if patient kit is reassigned, delete the one he has
    let deleteKitSuccessful = true;
    if (props.kit) {
      const delResp = await props.deleteKit(props.kit.id);
      if (delResp.type === DELETE_KIT_RESULT) {
        deleteKitSuccessful = true;
      } else if (resp.type === DELETE_KIT_ERROR) {
        const error = delResp.response?.data?.error?.message;
        props.showNotification(error, 5000, true);
        deleteKitSuccessful = false;
      }
    }
    if (!deleteKitSuccessful) {
      return false;
    }

    // assign new kit
    let ret = false;
    const resp = await props.attachKitToPatient(enrollment.kit_id, patient.id);
    if (resp.type === ATTACH_PATIENT_TO_KIT_ERROR) {
      const error = resp.response?.data?.error?.message;
      props.showNotification(error, 5000, true);
      ret = false;
    } else {
      props.showNotification(Strings.formatString(Strings.kitAssignedInfo, enrollment.kit_id, patient.patientName));
      ret = true;
    }
    return ret;
  };

  const enrollPatient = async newConditions => {
    let ret = false;
    const newEnrollment = fixNewEnrollmentCondition(enrollment, newConditions);
    const data = { patient_id: patient.id };
    data.data = [enrollmentToDTO(newEnrollment, props.orgTimezone)];

    const resp = await props.enrollPatient(data);
    if (resp.type === ENROLL_PATIENT_ERROR) {
      const error = resp.response?.data?.error?.message;
      props.showNotification(error, 5000, true);
      ret = false;
    } else {
      props.showNotification(Strings.formatString(Strings.patientEnrolledInfo, patient.patientName));
      ret = true;
    }
    return ret;
  };

  const sendWelcomeSms = async () => {
    let ret = false;
    if (welcomeMessage.set) {
      const welcomeMessageDTO = welcomeMessageToDTO(welcomeMessage, defaultWelcomeMessage, patient, enrollment.kit_id);
      const request = { user_id: patient.id, kit_id: enrollment.kit_id, ...welcomeMessageDTO };
      
      const resp = await props.sendWelcomeMessage(request);
      if (resp.type === SEND_WELCOME_MESSAGE_ERROR) {
        const error = resp.response?.data?.error?.message;
        props.showNotification(error, 5000, true);
        ret = false;
      } else {
        props.showNotification(Strings.formatString(Strings.welcomeSmsSent, patient.patientName));
        ret = true;
      }
    }
    return ret;
  };

  const onSave = async () => {
    if (saveBtnClicked) {
      return;
    }
    setSaveBtnClicked(true);

    const newConditions = await addConditions();
    const savedPatient = await savePatient(newConditions);
    if (!savedPatient) {
      setSaveBtnClicked(false);
      return;
    }

    if (stage === OnboardStages.editPatient) {
      checkForLanguageChangeAndShowModal();
    }
    if (stage === OnboardStages.editPatient || stage === OnboardStages.onboarding) {
      props.closeModalWithNextAction(savedPatient);
      return;
    }

    if (registrationNote && patient?.id) {
      await saveNote(registrationNote);
    }
    if (enrollmentNote && patient?.id) {
      await saveNote(enrollmentNote);
    }

    if (stage === OnboardStages.enrolling || stage === OnboardStages.activating) {
      const assignSuccessful = await assignToCohort();
    }
    if (stage === OnboardStages.activating) {
      const kitSuccessful = await onAssignKit();
    }
    if (stage === OnboardStages.enrolling || stage === OnboardStages.activating || stage === OnboardStages.settingEndDate) {
      const enrollmentConditionId = await addEnrollmentConditionToPatient(newConditions);
      const enrollSuccessful = await enrollPatient(newConditions);
    }
    if (stage === OnboardStages.activating) {
      const welcomeSmsSuccessful = await sendWelcomeSms();
    }

    const shouldStartAddingMed =
      props.data?.stage === OnboardStages.activating && [RTMMusculoskeletal, RTMRespiratory, RTMBehavioralHealth].includes(enrollment.subprogram);

    props.closeModalWithNextAction(savedPatient, shouldStartAddingMed);

    setSaveBtnClicked(false);
  };

  const stages = [
    {
      id: OnboardStages.onboarding,
      stageName: Strings.capPatient.enrollFlowStages.onboarding,
      stageCompleted: props.data.patient?.status,
      pages: [
        OnboardPatientModalPages.patientDetails,
        OnboardPatientModalPages.contactInformation,
        OnboardPatientModalPages.medicalHistory,
        OnboardPatientModalPages.centerDetails,
        OnboardPatientModalPages.insurance,
      ].concat(props.showTransplantDetails ? [OnboardPatientModalPages.transplantDetails] : []),
    },
    {
      id: OnboardStages.registration,
      stageName: Strings.capPatient.enrollFlowStages.registration,
      stageCompleted: props.data.patient?.status && [RPM_PATIENT_STATUS.verified, RPM_PATIENT_STATUS.enrolled, RPM_PATIENT_STATUS.activated].includes(props.data.patient?.status),
      pages: [OnboardPatientModalPages.agreementToSpeakToProvider],
    },
    {
      id: OnboardStages.enrolling,
      stageName: Strings.capPatient.enrollFlowStages.enrolling,
      stageCompleted: props.data.patient?.status && [RPM_PATIENT_STATUS.enrolled, RPM_PATIENT_STATUS.activated].includes(props.data.patient?.status),
      pages: [OnboardPatientModalPages.enrollmentProgram],
    },
    {
      id: OnboardStages.activating,
      stageName: Strings.capPatient.enrollFlowStages.activating,
      stageCompleted: props.data.patient?.status === RPM_PATIENT_STATUS.activated,
      pages: [OnboardPatientModalPages.enrollmentKit, OnboardPatientModalPages.enrollmentDates, OnboardPatientModalPages.welcomeMessage],
    },
  ];

  const getPageContent = (page, fieldsDef) => {
    const fields = fieldsDef.reduce((acc, f) => {
      acc[f.fieldId] = f;
      return acc;
    }, {});

    const pageProps = {
      patient,
      setPatient,
      enrollment,
      setEnrollment,
      kits,
      setKits,
      welcomeMessage,
      setWelcomeMessage,
      registrationNote,
      setRegistrationNote,
      enrollmentNote,
      setEnrollmentNote,
      conditions: props.conditions,
      previousNotes: patientNotes,
      defaultWelcomeMessage,
      linkedOrgs,
      careProviders,
      orgConfig,
      highlightInvalidFields,
      validationErrors,
      fields,
      stage,
    };

    switch (page.id) {
      case OnboardPatientModalPages.patientDetails.id:
        return <PatientDetailsPage {...pageProps} />;
      case OnboardPatientModalPages.contactInformation.id:
        return <ContactInformationPage {...pageProps} />;
      case OnboardPatientModalPages.medicalHistory.id:
        return <MedicalHistoryPage {...pageProps} />;
      case OnboardPatientModalPages.centerDetails.id:
        return <CenterDetailsPage {...pageProps} />;
      case OnboardPatientModalPages.insurance.id:
        return <InsurancePage {...pageProps} />;
      case OnboardPatientModalPages.transplantDetails.id:
        return <TransplantDetailsPage {...pageProps} />;
      case OnboardPatientModalPages.agreementToSpeakToProvider.id:
        return <RegistrationPage {...pageProps} />;
      case OnboardPatientModalPages.enrollmentProgram.id:
        return <ProgramPage {...pageProps} />;
      case OnboardPatientModalPages.enrollmentKit.id:
        return <KitPage {...pageProps} />;
      case OnboardPatientModalPages.enrollmentDates.id:
        return <EnrollmentDatesPage {...pageProps} />;
      case OnboardPatientModalPages.welcomeMessage.id:
        return <WelcomeMessagePage {...pageProps} />;
      default:
        return null;
    }
  };

  const pages = Object.values(OnboardPatientModalPages).map(page => {
    const wrongFields = getWrongFieldsCountForPage(page.fields, validationErrors);
    const requiredFields = getRequiredFieldsForPage(page.id, patient, patientNextStage);
    const fields = getFieldsForPage(page.id, patient, patientNextStage);
    return {
      ...page,
      content: getPageContent(page, fields),
      wrongFieldsCount: wrongFields || 0,
      requiredFieldsCount: requiredFields?.length,
      stage: stages.find(s => s.pages.includes(page)),
    };
  });

  const getPagesToShow = () => {
    if (props.mergedModal) {
      switch (stage) {
        case OnboardStages.onboarding:
          return pages.filter(p => p.stage?.id === OnboardStages.onboarding);
        case OnboardStages.registration:
        case OnboardStages.rescheduleTelevisit:
            return pages.filter(p => [OnboardStages.onboarding, OnboardStages.registration].includes(p.stage?.id));
        case OnboardStages.enrolling:
          return pages.filter(p => [OnboardStages.onboarding, OnboardStages.registration, OnboardStages.enrolling].includes(p.stage?.id));
        case OnboardStages.activating:
          return pages.filter(p => [OnboardStages.onboarding, OnboardStages.registration, OnboardStages.enrolling, OnboardStages.activating].includes(p.stage?.id));
        case OnboardStages.settingEndDate:
          return pages.filter(p => p.id === OnboardPatientModalPages.enrollmentDates.id);
        case OnboardStages.editPatient:
          return pages.filter(p => p.stage?.id === OnboardStages.onboarding && (!props.data.pages || props.data.pages.includes(p.id)));
        default:
          return pages;
      }
    } else {
      switch (stage) {
        case OnboardStages.onboarding:
          return pages.filter(p => p.stage?.id === OnboardStages.onboarding);
        case OnboardStages.registration:
        case OnboardStages.rescheduleTelevisit:
          return pages.filter(p => p.stage?.id === OnboardStages.registration).concat(pages.filter(p => p.stage?.id === OnboardStages.onboarding));
        case OnboardStages.enrolling:
          return pages.filter(p => p.stage?.id === OnboardStages.enrolling);
        case OnboardStages.activating:
          return pages.filter(p => p.stage?.id === OnboardStages.enrolling || p.stage?.id === OnboardStages.activating);
        case OnboardStages.settingEndDate:
          return pages.filter(p => p.id === OnboardPatientModalPages.enrollmentDates.id);
        case OnboardStages.editPatient:
          return pages.filter(p => p.stage?.id === OnboardStages.onboarding && (!props.data.pages || props.data.pages.includes(p.id)));
        default:
          return pages;
      }
    }
  };

  const getPageToScrollTo = () => {
    switch (stage) {
      case OnboardStages.registration:
      case OnboardStages.rescheduleTelevisit:
        return OnboardPatientModalPages.agreementToSpeakToProvider.id;
      case OnboardStages.enrolling:
        return OnboardPatientModalPages.enrollmentProgram.id;
      case OnboardStages.activating:
        return OnboardPatientModalPages.enrollmentKit.id;
      default:
        return undefined;
    }
  };

  const tooltip = validationErrors && validationErrors?.length > 0 ? validationErrors?.map(v => v.errors.map(e => `${v.propertyName}: ${e}`)).flat()?.concat(validationErrors?.filter(v => v.missing)?.map(v => `${v.propertyName} is missing`)).map(s => <div>{s}</div>) : undefined;

  return (
    <Wizard
      name="onboard-patient"
      pages={getPagesToShow()}
      onNextButtonHover={e => setHighlightMissingFields(e)}
      title={props.data?.patient?.id 
        ? (<div className="horizontal-flex unequal-children">
          <div>{props.data.patient.patientName}</div>
          {patient.rawPatientImportData ? <a className="" href="" onClick={e => {e.preventDefault(); props.showPatientImportData(patient)}}>{Strings.showExtendedData}</a> : ''}
          </div>)
        : Strings.capPatient.newPatient}
      onSubmit={onSave}
      onSubmitEnabled={validationErrors.length === 0}
      showPagesFilter={false}
      type={wizardTypes.horizontal}
      scrollToPage={getPageToScrollTo()}
      nextButtonTooltip={tooltip}
      hideStages={true}
      hideStagesProgress={true}
    />
  );
}

const mapStateToProps = state => {
  return {
    conditions: state.superUser.cohorts?.conditions,
    facilities: state.superUser.cohorts?.facilities,
    patientMedications: state.superUser?.patients?.patientMedications?.medications,
    orgTimezone: state.auth?.ui_config?.timezone,
    kit: state.entities.kits?.patientKit,
    organizationId: state.auth?.organization.masked_id,
    mergedModal: state.auth?.ui_config?.merged_onboard_modals,
    showTransplantDetails: state.auth?.ui_config?.transplant_details_enabled,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal(ownProps.name)),
  closeModal: modal => dispatch(closeModal(modal)),
  showNotification: (message, timeout, error) => dispatch(notificationActions.show(message, timeout, error)),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  closeModalWithNextAction: (patient, startAddingMed) => {
    dispatch(closeModal('onboard-patient'));
    if (ownProps.data.onSuccess) ownProps.data.onSuccess(patient);
    if (startAddingMed) {
      dispatch(
        openModalAction('edit-rpm-schedule', {
          patient: ownProps.data?.patient,
          action: patientAction.actionUpdateSchedule,
          actionType: UPDATE_PATIENT_SCHEDULE,
        }),
      );
    }
  },
  getConditions: () => dispatch(cohortActions.getConditions()),
  getFacilities: () => dispatch(cohortActions.getFacilities()),
  getMedications: patientId => dispatch(actions.getMedications(patientId)),
  getNotes: patientId => dispatch(actions.getNotes(patientId)),
  getKits: pageRequest => dispatch(kitActions.getKits(pageRequest)),
  getPatientKitInfo: patientId => dispatch(kitActions.getPatientKitInfo(patientId)),
  getRemindersDefaultMessages: request => dispatch(actions.getRemindersDefaultMessages(request)),
  getCareProviders: pageRequest => dispatch(userActions.list(pageRequest)),
  getLinkedOrgs: organizationId => dispatch(orgActions.getLinkedOrganizations(organizationId)),
  getOrgConfig: pageRequest => dispatch(orgActions.getOrgConfig(pageRequest)),
  savePatient: data => dispatch(ownProps.data.patient?.id ? actions.editPatient(data, ownProps.data.patient?.id) : actions.addPatient(data)),
  assignPatientToFacility: (patientId, facilityId) =>
    dispatch(cohortActions.assignPatientToFacility(patientId, facilityId)),
  attachKitToPatient: (kitId, patientId) => dispatch(kitActions.attachKitToPatient(kitId, patientId)),
  addNote: (patientId, data) => dispatch(actions.addNote(patientId, data)),
  createCondition: data => dispatch(cohortActions.createCondition(data)),
  assignPatientToCohort: (patientId, cohortId) => dispatch(cohortActions.assignPatientToCohort(patientId, cohortId)),
  enrollPatient: data => dispatch(actions.enrollPatient(data)),
  sendWelcomeMessage: data => dispatch(actions.sendWelcomeMessage(data)),
  onNavigate: path => dispatch(push(path)),
  showPatientImportData: patient =>
    dispatch(
      openModalAction(
        'patient-raw-data',
        { patient },
        patient.id,
      ),
    ),
  showPatientImportData: patient =>
    dispatch(
      openModalAction(
        'patient-raw-data',
        { patient },
        patient.id,
      ),
    ),
});

OnboardPatientWizard.defaultProps = { mergedModal: true };
export default connect(mapStateToProps, mapDispatchToProps)(OnboardPatientWizard);
