import * as React from 'react'
import { connect } from 'react-redux';
import { useOverlayScrollbars } from 'overlayscrollbars-react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import _ from 'lodash';

import { DropdownTableFilter } from '../../components/DropdownTableFilter';
import { PAGE_LIMIT } from '../../constants';
import { tableActions } from './reducer';

const columnHelper = createColumnHelper();
const TABLE_PAGINATION_HEIGHT = 51;
const ROW_HEIGHT = 50;
const HEADER_HEIGHT = 78;

function Table(props) {
  const {
    name,
    data,
    columns,
    enableColumnFiltering,
    pinFirstColumn,
    pagination,
    noScroll,
    isLoading,
    onOffsetChange,
    onRowClick,
    buttons,
    tableState,
    setColumnsVisibility,
    setColumnsSizing,
  } = props;


  // Add buttons column if buttons are provided
  const tableColumns = React.useMemo(() => {
    const dataColumns = columns;
    return dataColumns.concat(buttons?.length ? [
      columnHelper.display({
        id: 'actions',
        cell: p => <TableButtons row={p.row} buttons={buttons} />,
        size: 0,
      })] : []);
  }, [columns, buttons]);

  const table = useReactTable({
    data: data || [],
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      columnPinning: { left: pinFirstColumn ? [columns[0]?.accessorKey] : 0},
      pagination: pagination ? { pageSize: pagination.limit || PAGE_LIMIT } : undefined,
      ...tableState,
    },
    getPaginationRowModel: pagination ? undefined : getPaginationRowModel(),
    manualPagination: !pagination,
    rowCount: pagination?.totalRecords || data?.length || undefined,
    columnResizeMode: 'onChange',
    enableColumnResizing: true,
  });

  const { rows } = table.getRowModel();
  const scrollRef = React.useRef();
  const viewportRef = React.useRef();
  const tableContainerRef = React.useRef();
  const resizeObserver = React.useRef();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => viewportRef.current,
    estimateSize: () => ROW_HEIGHT,
    overscan: 5,
  });

  const scrollEvent = _.debounce(() => {
    const viewport = osInstance().elements().viewport;
    const scrollAtPage = calculatePageForScrollPosition(
      viewport?.scrollHeight,
      viewport?.clientHeight,
      viewport?.scrollTop,
    );
    table.setPageIndex(scrollAtPage - 1);
  }, 100);

  const [initialize, osInstance] = useOverlayScrollbars({
    defer: true,
    options: { scrollbars: { autoHide: 'leave', autoHideDelay: '100' } },
    events: { scroll: noScroll ? undefined : scrollEvent },
  });

  React.useEffect(() => {
    if (scrollRef.current && viewportRef.current) {
      initialize({ target: scrollRef.current, elements: { viewport: viewportRef.current } });
    }

    return () => osInstance()?.destroy();
  }, [initialize, osInstance]);

  React.useEffect(() => {
    const onResize = entries => {
      if (!noScroll) {
        const height = entries[0].target?.clientHeight;
        const headerHeight = HEADER_HEIGHT;
        const footerNeeded = table.rowCount
          ? table.rowCount > (height - headerHeight) / ROW_HEIGHT
          : false;
        const footerHeight = footerNeeded ? TABLE_PAGINATION_HEIGHT : 0;
        const pageLimit = Math.floor((height - headerHeight - footerHeight) / ROW_HEIGHT);
        table.setPageSize(pageLimit);
      };
    }
    resizeObserver.current = new ResizeObserver(onResize);
    resizeObserver.current.observe(tableContainerRef.current);
    onResize([{ target: tableContainerRef.current }]);

  return () => {
    if (resizeObserver.current) {
      resizeObserver.current.disconnect();
    }
  }
  }, []);

  const onPaginationManuallyChanged = value => {
    if (!noScroll && scrollRef.current) {
        const scroll = value * (table.getState().pagination.pageSize * ROW_HEIGHT);
        const viewport = osInstance().elements().viewport;
        viewport.scrollTo({ top: scroll, behavior: 'smooth' });
      }
  };

  const calculatePageForScrollPosition = (scrollHeight, clientHeight, scrollTop) => {
    const pageLimit = table.getState().pagination.pageSize;

    const isBottomReached = scrollHeight - Math.round(scrollTop) === clientHeight;
    const currentPage = Math.round(scrollTop / (pageLimit * ROW_HEIGHT)) + 1;
    const lastPartialPageNeeded =
      rows.length % pageLimit && rows.length > currentPage * pageLimit;
    return isBottomReached && lastPartialPageNeeded ? currentPage + 1 : currentPage;
  };
  
  React.useEffect(() => {
    if (!onOffsetChange || withLocalPagination) {
      return;
    }
    const p = table.getState().pagination;
    onOffsetChange(p.pageIndex * p.pageSize);
  }, [table.getState().pagination]);

  React.useEffect(() => {
    setColumnsVisibility(name, table.getState().columnVisibility);
  }, [table.getState().columnVisibility]);

  React.useEffect(() => {
    setColumnsSizing(name, table.getState().columnSizing);
  }, [table.getState().columnSizing]);

  const filters = React.useMemo(() => {
    return table.getAllLeafColumns()
    .slice(0, buttons?.length ? table.getAllLeafColumns().length - 1 : table.getAllLeafColumns().length)
    .map(column => {
      return {
        label: column.columnDef.header,
        id: column.id,
        checked: column.getIsVisible(),
        disabled: column.columnDef.enableHiding === false,
      };
    });
  }, [table.getState().columnVisibility]);

  const onSetColumns = (id, checked) => {
    table.getAllFlatColumns().find(c => c.id === id).toggleVisibility(checked);
  };

  const resetColumnsFilters = () => {
    table.getAllLeafColumns().forEach(column => {
      column.toggleVisibility(true);
    });
  };

  const columnSizeVars = React.useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i];
      colSizes[`--header-${header.id}-size`] = header.getSize();
      colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
    }
    return colSizes;
  }, [table.getState().columnSizingInfo, table.getState().columnSizing])

  return (
    <div className="table-container" style={{width: table.getTotalSize() - 18, maxWidth: '100%'}} ref={tableContainerRef}>
      {enableColumnFiltering && (
        <DropdownTableFilter
          filters={filters}
          handleChange={onSetColumns}
          resetToDefaults={resetColumnsFilters}
          dontDisableFirstEntry
        />
      )}
      <div
        className="simplebar-100percent-width"
        ref={scrollRef}
        style={{
          ...columnSizeVars,
          width: table.getTotalSize() - 18,
          height: `calc(100% - ${TABLE_PAGINATION_HEIGHT}px)`,
        }}
      >
        <div ref={viewportRef}>
          <div
            style={{
              height: `${rowVirtualizer.getTotalSize() + 78}px`,
              width: table.getTotalSize() - 20,
              position: 'relative',
            }}
          >
            <table className={`table fixed-layout ${name}`} style={{width: table.getTotalSize() - 20}}>
              <thead className="table-header">
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id} className="table-row">
                    {headerGroup.headers.map(header => {
                      const { column } = header;
                      if (column.id === 'actions') {
                        return;
                      }
                    return (
                      <th key={header.id} className={getColumnClassName(column)} style={{...getCommonPinningStyles(column)}}>
                        <div>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                          )}
                          {/* {!header.isPlaceholder && header.column.getCanPin() && (
                            <>
                              {header.column.getIsPinned() !== 'left' ? (
                                <button
                                  style={{ minWidth: 'unset'}}
                                  onClick={() => {
                                    header.column.pin('left')
                                  }}
                                >
                                  📌
                                </button>
                              ) : null}
                              {header.column.getIsPinned() ? (
                                <button
                                style={{ minWidth: 'unset', filter: 'grayscale(100%)'}}
                                  onClick={() => {
                                    header.column.pin(false)
                                  }}
                                >
                                  📌
                                </button>
                              ) : null}
                            </>
                          )} */}
                        </div>
                        <div
                          {...{
                            onDoubleClick: () => header.column.resetSize(),
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler(),
                            className: `resizer ${
                              header.column.getIsResizing() ? 'isResizing' : ''
                            }`,
                          }}
                        />
                      </th>
                    )}
                  )}
                  </tr>
                ))}
              </thead>
              {table.getState().columnSizingInfo.isResizingColumn ? (
                <MemoizedTableBody table={table} rowVirtualizer={rowVirtualizer} onRowClick={onRowClick} buttons={buttons} />
              ) : (
                <TableBody table={table} rowVirtualizer={rowVirtualizer} onRowClick={onRowClick} buttons={buttons} />
              )}
              
              {/* <tfoot>
                {table.getFooterGroups().map(footerGroup => (
                  <tr key={footerGroup.id}>
                    {footerGroup.headers.map(header => (
                      <th key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.footer,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </tfoot> */}
            </table>
          </div>
        </div>
      </div>
      <Pagination
        table={table}
        isLoading={isLoading}
        onPaginationManuallyChanged={noScroll ? undefined : onPaginationManuallyChanged}
      />
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const { name } = props;
  return {
    tableState: state.tableReducer[name],
  };
};

const mapDispatchToProps = dispatch => ({
  setColumnsVisibility: (name, columns) => dispatch(tableActions.setColumnsVisibility(name, columns)),
  setColumnsSizing: (name, columns) => dispatch(tableActions.setColumnsSizing(name, columns)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);

const getCommonPinningStyles = (column) => {
  const isPinned = column.getIsPinned();
  const resizable = column.getCanResize();
  return {
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    width: `calc(var(--header-${column.id}-size) * 1px)`,
    paddingRight: resizable ? '0px' : undefined,
  };
}

const getColumnClassName = column => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right');
  const isActionColumn = column.id === 'actions';
  return [
    isPinned ? "pinned" : null,
    isLastLeftPinnedColumn ? 'last-left-pinned' : null,
    isFirstRightPinnedColumn ? 'first-right-pinned' : null,
    isActionColumn ? 'sticky-right' : null,
  ]
    .filter(Boolean)
    .join(' ')
};

function Pagination({ table, isLoading, onPaginationManuallyChanged }) {
  const paginationInputRef = React.useRef();
  const currentPage = table.getState().pagination.pageIndex;

  const onPageIndexChange = _.debounce((pageIndex) => {
    paginationInputRef?.current?.blur();
    paginationInputRef.current.value = null;
    if (onPaginationManuallyChanged) {
      onPaginationManuallyChanged(pageIndex);
    }
  }, 250);

  const onPageIndexChangeNoDebounce = pageIndex => {
    if (onPaginationManuallyChanged) {
      onPaginationManuallyChanged(pageIndex);
    }
  };


  return (
    <div className="table-pagination">
      <div className={`footerButtonContainer ${!table.getCanPreviousPage() ? 'disabled' : ''}`}>
        <button
          className={`add-arrow left prev ${!table.getCanPreviousPage() ? 'disabled' : ''}`}
          onClick={() => onPageIndexChangeNoDebounce(currentPage - 1)}
          data-testid="table-prev-page"
        />
      </div>
      {isLoading? (
        <div className="pagination-spinner-container">
          <div className="spinner" />
        </div>
      ) : (
        <input
        ref={paginationInputRef}
        type="text"
          placeholder={`${currentPage + 1} / ${table.getPageCount().toLocaleString()}`}
          data-testid="table-custom-page"
          onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0
            onPageIndexChange(page)
          }}
        />
      )}
      <div className={`footerButtonContainer ${!table.getCanNextPage() ? 'disabled' : ''}`}>
        <button
          className={`add-arrow right next ${!table.getCanNextPage() ? 'disabled' : ''}`}
          onClick={() => onPageIndexChangeNoDebounce(currentPage + 1)}
          data-testid="table-next-page"
        />
      </div>
    </div>
  );
};

function TableButtons ({buttons, row}) {
  return buttons && buttons.length > 0 ? (
    <div className="buttons-wrapper">
      <div className="buttons-background-gradient" />
      <div className="buttons-background">
        <div className="buttons">
          {buttons.map(b => {
            const icon = typeof b.icon === 'function' ? b.icon(row.id, row.original) : b.icon;
            const disabled = (typeof b.disabled === 'function' && b.disabled(row.id, row.original)) || (typeof b.disabled == 'boolean' && b.disabled);
            const highlighted = (typeof b.highlighted === 'function' && b.highlighted(row.id, row.original) === true) || (typeof b.highlighted == 'boolean' && b.highlighted);
            const text  = typeof b.text === 'function' ? b.text(row.id, row.original) : b.text;
            const disabledTooltip = typeof b.disabledTooltip === 'function' ? b.disabledTooltip(row.id, row.original) : b.disabledTooltip;
            return (
            <div
              className="icon-container"
              data-tooltip-content={disabled && disabledTooltip ? disabledTooltip : text}
              data-tooltip-id="tooltip"
            >
              <div
                className={`icon ${icon} ${disabled ? 'disabled' : ''} ${highlighted ? 'highlighted': ''} `}
                onClick={e => {
                  e.stopPropagation();
                  b.onClick(row.id, row.original);
                }}
              />
            </div>
          )})}
        </div>
      </div>
    </div>
) : <></>};

function TableBody({ table, rowVirtualizer, onRowClick, buttons }) {
  const { getVirtualItems, getTotalSize } = rowVirtualizer;
  const { rows } = table.getRowModel();

  const paddingTop = getVirtualItems().length > 0 ? (getVirtualItems()?.at(0)?.start || 0) : 0;
  const paddingBottom = getVirtualItems().length > 0 ? getTotalSize() - (getVirtualItems()?.at(-1)?.end ||0) : 0;
  const isVirtualizerReady = getVirtualItems().length < getTotalSize() / (rows.length + 10 + 20);
  return (
    <tbody>
      {paddingTop > 0 && (
        <tr>
          <td style={{ height: `${paddingTop}px` }} />
        </tr>
      )}
      {isVirtualizerReady && getVirtualItems().map(virtualRow => {
          const row = rows[virtualRow.index]
          return (
            <tr
              key={row.id}
              style={{
                height: `${virtualRow.size}px`,
              }}
              onClick={onRowClick ? () => onRowClick(row.original) : undefined}
              className={`${buttons?.length ? 'with-buttons' : ''}`}
            >
              {row.getVisibleCells().map((cell) => {
                const { column } = cell;
                return (
                  <td key={cell.id} className={getColumnClassName(column)} style={{...getCommonPinningStyles(column)}}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                )
              })}
            </tr>
          )
        })}
      {paddingBottom > 0 && (
        <tr>
          <td style={{ height: `${paddingBottom}px` }} />
        </tr>
      )}
      {/* {table.getRowModel().rows.map(row => (
        <tr key={row.id} onClick={() => onRowClick(row.original)} className={`${buttons?.length ? 'with-buttons' : ''}`}>
          {row.getVisibleCells().map(cell => {
            const { column } = cell;
            return (
            <td key={cell.id} className={getColumnClassName(column)} style={{...getCommonPinningStyles(column)}}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          )}
        )}
        </tr>
      ))} */}
    </tbody>
  );
};

export const MemoizedTableBody = React.memo(
  TableBody,
  (prev, next) => prev.table.options.data === next.table.options.data
);