import PropTypes from 'prop-types';
import { Background } from 'components/Login/Background/Background';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Input } from '../../containers/Form';
import React, { useEffect, useState } from 'react';
import Strings from '../../Strings';
import { ButtonRow } from '../../components/Login/ButtonRow/ButtonRow';
import { Button, BUTTON_TYPE_BLUE, BUTTON_TYPE_WHITE } from '../../components/Buttons/Button';
import { PASSWORD_PATTERN_EXP } from '../../constants';
import { resetPassword } from '../../actions/user-settings';
import { RESET_PASSWORD_RESULT } from '../../actions/action-types';

function NewPassword(props) {
  const codeFromUrl = new URLSearchParams(window.location.search).get('code');

  const [securityCode, setSecurityCode] = useState(codeFromUrl || '');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [buttonHover, setButtonHover] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const passwordPattern = new RegExp(PASSWORD_PATTERN_EXP);

  useEffect(() => {
    const errors = [];

    if (securityCode === '') {
      errors.push({ property: 'securityCode', errors: [Strings.securityCodeIsRequired] });
    }

    if (newPassword === '' || !passwordPattern.test(newPassword)) {
      errors.push({ property: 'newPassword', errors: [Strings.errors.invalidPassword] });
    }

    if (newPassword != confirmPassword) {
      errors.push({ property: 'confirmPassword', errors: [Strings.errors.passwordDoesNotMatched] });
    }

    setValidationErrors(errors);
  }, [securityCode, newPassword, confirmPassword]);

  const onSubmit = () => {
    if (validationErrors.length > 0) {
      return;
    }

    setResponseMessage('');
    props.onResetPassword(securityCode, newPassword).then(resp => {
      if (resp.type == RESET_PASSWORD_RESULT) {
        props.toLoginPage();
      } else {
        setResponseMessage(Strings.passwordChangeFailedCheckYourSecurityCode);
        setSecurityCode('');
      }
    });
  };

  return (
    <Background title="Set New Password" noLines>
      <div className="recover-password wizard">
        <div className="content-block">
          {!codeFromUrl && (
            <Input
              name="securityCode"
              id="securityCode"
              label={Strings.securityCode}
              type={showPassword ? 'text' : 'password'}
              value={securityCode || ''}
              onChange={e => setSecurityCode(e.target.value)}
              isRequired
              highlightInvalid={buttonHover}
              errorsForTooltip={validationErrors.find(v => v.property === 'securityCode')?.errors || []}
            />
          )}
          <Input
            name="newPassword"
            id="newPassword"
            label={Strings.newPassword}
            type={showPassword ? 'text' : 'password'}
            value={newPassword || ''}
            onChange={e => setNewPassword(e.target.value)}
            isRequired
            highlightInvalid={buttonHover}
            errorsForTooltip={validationErrors.find(v => v.property === 'newPassword')?.errors || []}
          />
          <Input
            name="confirmPassword"
            id="confirmPassword"
            label={Strings.confirmPassword}
            type={showPassword ? 'text' : 'password'}
            value={confirmPassword || ''}
            onChange={e => setConfirmPassword(e.target.value)}
            isRequired
            highlightInvalid={buttonHover}
            errorsForTooltip={validationErrors.find(v => v.property === 'confirmPassword')?.errors || []}
          />
          {responseMessage && <p>{responseMessage}</p>}
          <ButtonRow
            left={
              <Button
                buttonType={BUTTON_TYPE_WHITE}
                type="button"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? Strings.hidePassword : Strings.showPassword}
              </Button>
            }
            right={
              <Button
                buttonType={validationErrors.length > 0 ? BUTTON_TYPE_WHITE : BUTTON_TYPE_BLUE}
                type="button"
                disabled={validationErrors.length > 0}
                onClick={() => {
                  onSubmit();
                }}
                onMouseEnter={() => setButtonHover(true)}
                onMouseLeave={() => setButtonHover(false)}
              >
                {Strings.submit}
              </Button>
            }
          />
        </div>
      </div>
    </Background>
  );
}

NewPassword.propTypes = {
  onResetPassword: PropTypes.func.isRequired,
  toLoginPage: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onResetPassword: (token, password) => dispatch(resetPassword(token, password)),
  toLoginPage: () => dispatch(push('/login')),
});

export default connect(null, mapDispatchToProps)(NewPassword);
