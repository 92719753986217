import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { createFilter } from 'react-select';

import './style.scss';

function Select(props) {
  const {
    data,
    label,
    id,
    defaultValue,
    value,
    className,
    searchByLabelOnly,
    menuPositionFixed,
    clearValueAfterSelection,
    ...otherProps
  } = props;

  const defaultFilter = {
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    stringify: option => `${option.data.label} ${option.data.value}`,
    trim: true,
  };

  const getLabel = option => (
    <React.Fragment key={`option_${option.value}`}>
      {option.label}
    </React.Fragment>
  );
  
  return (
    <>
      <label htmlFor={id} className="visuallyhidden">
        {label}
      </label>
      <ReactSelect
        id={id}
        key={id}
        className={`${className || ''} react-select-container`}
        classNamePrefix="react-select"
        defaultValue={data.find(o => o.value === defaultValue)}
        value={
          value?.value || !isNaN(value?.value)
            ? value
            : value || !isNaN(value)
            ? data.find(o => o.value === value)
            : clearValueAfterSelection
            ? ''
            : undefined
        }
        onChange={props.onChange}
        options={data}
        unstyled
        menuPosition={menuPositionFixed ? 'fixed' : 'absolute'}
        isDisabled={props.disabled}
        filterOption={searchByLabelOnly ? createFilter({ stringify: option => option.data.label }) : createFilter(defaultFilter)}
        getOptionLabel={getLabel}
        {...otherProps}
      />
    </>
  );
}

export default Select;

Select.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ]),
  data: PropTypes.array.isRequired,
  isSearchable: PropTypes.bool,
  menuPositionFixed: PropTypes.bool,
  clearValueAfterSelection: PropTypes.bool,
};

Select.defaultProps = { isSearchable: false, menuPositionFixed: true, clearValueAfterSelection: false };
