export const APP_ID = 'j6a6podk';
export const IOS_LINK = 'https://apps.apple.com/us/app/lid-sync/id1544410811';
export const ANDROID_LINK = 'https://play.google.com/store/apps/details?id=com.lidsync';
export const DEFAULT_TIMEZONE = 'America/New_York';
export const NDC = 'ndc';
export const LANGUAGE_EN = 'en';
export const RXNORM = 'rxnorm';
export const LS_SELECTED_COLOR_THEME = 'selected-color-theme';

const getEndPointForDevelopment = () => {
  if (typeof process.env.BACKEND === 'undefined') {
    console.log('[INFO] BACKEND is undefined. Using default development endpoint.');
    return 'https://api.rxcap.dev/';
  }

  switch (process.env.BACKEND) {
    case 'local':
      console.log('[INFO] Using local endpoint as backend.');
      return 'http://localhost:8080';
    case 'dev':
      console.log('[INFO] Using default development endpoint as backend.');
      return 'https://api.rxcap.dev/';
    default:
      console.log('[INFO] BACKEND is unknown. Using default development as default.');
      return 'https://api.rxcap.dev/';
  }
};

const getBaseUrl = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return getEndPointForDevelopment();
    case 'int':
      return 'https://int-api.rxcap.dev/';
    case 'stage':
      return 'https://staging-api.rxcap.dev/';
    case 'production':
      return 'https://pc.rxcap.net/';
    default:
      return 'https://pc.rxcap.net/';
  }
};

const getPhoneNumber = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return '314-916-3322';
    case 'stage':
      return '314-916-3322';
    case 'int':
      return '903 400 5043';
    case 'production':
      return '888-618-0463';
    default:
      return '888-618-0463';
  }
};

const getBaseName = () => {
  return process.env.REACT_APP_ENV === 'lidsyncdist' || process.env.REACT_APP_ENV === 'lidsyncdev' ? '/portal' : ''; // for lidsync Portal env.
};

export const BASE_NAME = getBaseName();

export const BATTERY_LEVEL_POOR = 1;
export const BATTERY_LEVEL_MEDIUM = 3;
export const BATTERY_LEVEL_GOOD = 4;

export const ADHERENCE_LEVEL_POOR = 'poor';
export const ADHERENCE_LEVEL_GOOD = 'good';
export const ADHERENCE_LEVEL_AVERAGE = 'average';
export const ADHERENCE_LEVEL_POOR_C = 'Poor';
export const ADHERENCE_LEVEL_GOOD_C = 'Good';
export const ADHERENCE_LEVEL_AVERAGE_C = 'Average';

export const UTC_TIMEZONE = 'utc';
export const LOCAL_TIMEZONE = 'local';

// To convert svg to #14568d. generated by https://codepen.io/sosuke/pen/Pjoqqp
export const MED_ICON_COLOR_FILTER =
  'brightness(0) saturate(100%) invert(23%) sepia(97%) saturate(904%) hue-rotate(180deg) brightness(95%) contrast(92%)';

export const MED_ICON_COLOR_FILTER_INACTIVE =
  'brightness(0) saturate(100%) invert(53%) sepia(3%) saturate(0%) hue-rotate(153deg) brightness(94%) contrast(80%)';

export const MED_ICON_COLOR_FILTER_PINK =
  'invert(35%) sepia(77%) saturate(741%) hue-rotate(285deg) brightness(90%) contrast(91%)';

export const MED_ICON_COLOR_FILTER_ORANGE =
  'invert(50%) sepia(99%) saturate(1294%) hue-rotate(5deg) brightness(105%) contrast(97%)';

export const MED_ICON_COLOR_FILTER_GREEN =
  'invert(58%) sepia(7%) saturate(3386%) hue-rotate(48deg) brightness(105%) contrast(95%)';

export const BASE_URL = getBaseUrl();

export const ESI_PHONE_NUMBER_TO_VERIFY_CAP_ID = getPhoneNumber();

export const GmapApiKey = 'AIzaSyBO7_1CTUB83j3IQWOLYXYBCli5vQTAjw0';
export const LocationUSCenter = {
  lat: 39.828175,
  lng: -98.5795,
};
export const IosAppLink = 'https://itunes.apple.com/us/app/dosesmart/id981107346?mt=8';
export const PASSWORD_PATTERN = new RegExp(
  /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[~!**^!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,32}$/,
);
export const PASSWORD_PATTERN_EXP = '^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[~!**^!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,32}$';

export const PASSWORD_SPECIAL_CHARS_PATTERN = /[!@#$%^&*\s*]+/;
export const PASSWORD_NOT_ALLOWED_CHARACTERS_PATTERN = /[a-zA-Z0-9!@#$%^&*\s*]+/;

export const EMAIL_PATTERN = new RegExp(
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);
export const IDLE_TIME = 13; // 14 mins
export const IDLE_TIME_ON_CONFIRM_MSG = 30000; // 30 sec
export const IS_EXPIRTED_SESSION_INTEVAL = 40000; // 20 sec interval
export const NUMBER_PATTERN = /^\+?[0-9]+$/;
export const RESIZE_CAROUSEL = ['History'];
export const MAX_DATE = 31;
export const MAX_MONTH = 12;
export const STUDY_NAME_LENGTH = 40;
export const TOAST_TIME_PERIOD = 3300;
export const PAGE_LIMIT = 10;
export const SUPER_ADMIN = 'supercapadmin';
export const CAP_MANAGER = 'capmanager';
export const WEEKS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const ACTIVE_STATUS = 'active';
export const MONTHS_RANGE = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const HEADER_BUTTON_BLUE = 'blue';
export const HEADER_BUTTON_WHITE = 'white';

export const SHORT_MONTHS_NAME_RANGE = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const CHAR_LIMIT = 3500;

export const DATE_FORMAT_LLLL = 'dddd, Do MMMM YYYY, h:mm:ss A';
export const DATE_FORMAT = 'mm/dd/YYYY';
export const DATE_FORMAT_SPACES = 'MM / DD / YYYY';
export const DATE_FORMAT_FOR_SLIDER = 'MM/DD/YYYY';
export const DATE_FORMAT_SLASHES = 'DD/MM/YYYY';
export const DATE_FORMAT_MONTH_NAME = 'MMM DD, YYYY';
export const DAY_DATE_FORMAT_MONTH_NAME = 'ddd MMM DD, YYYY';
export const DATE_FORMAT_MONTH_NAME_2 = 'DD MMM YYYY';
export const DATE_FORMAT_MONTH_SPACE_YEAR = 'MMM, YYYY';
export const TIME_FORMAT_12 = 'hh:mm a';
export const TIME_FORMAT_12_UPPERCASE = 'hh:mm A';
export const TIME_FORMAT_12_LOWERCASE = 'hh:mm a';
export const TIME_FORMAT_0_24 = 'H:mm';
export const DATE_FORMAT_MONTH_YEAR = 'MM/YYYY';
export const DATE_FORMAT_YEAR_MONTH_DAY = 'YYYY-MM-DD';
export const TIME_HOUR_MINUTE_SECONDS_FORMAT_12 = 'hh:mm:ss a';
export const TIME_HOUR_MINUTE_SECONDS_FORMAT_12_UPPERCASE = 'hh:mm:ss A';
export const DATE_MONTH_DAY_YEAR_WITH_DASH = 'MMM-DD-YYYY, hh:mm A';
export const DATE_MONTH_DAY_YEAR = 'MMM-DD-YYYY';
export const DATE_TIME_SPACE_12_UPPERCASE = 'MM / DD / YYYY hh:mm A';
export const DATE_TIME_WITH_MILISECONDS = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const DATE_TIME_HOUR_DAY_MONTH = 'hh:mm a, MMM DD';
export const DATE_DAY_MONTH = 'MMM DD';
export const DATE_TIME_HOUR_DAY_MONTH_YEAR = 'hh:mm a, MMM DD YYYY';
export const CAP_LTE_TYPE = 'lte';
export const CAP_BLE_TYPE = 'ble';
export const UPDATE_STOCK_REMINDER = 39600;
export const REMINDER_DAYS = 3;
export const ZEPHY_RX_DASHBOARD = 'https://dashboard.zephyrx.com/login';
export const COUNTRIES_TEL_CODE = [
  {
    name: 'Afghanistan',
    value: '+93',
  },
  {
    name: 'Albania',
    value: '+355',
  },
  {
    name: 'Algeria',
    value: '+213',
  },
  {
    name: 'American Samoa',
    value: '+1-684',
  },
  {
    name: 'Andorra',
    value: '+376',
  },
  {
    name: 'Angola',
    value: '+244',
  },
  {
    name: 'Anguilla',
    value: '+1-264',
  },
  {
    name: 'Antarctica',
    value: '+672',
  },
  {
    name: 'Antigua and Barbuda',
    value: '+1-268',
  },
  {
    name: 'Argentina',
    value: '+54',
  },
  {
    name: 'Armenia',
    value: '+374',
  },
  {
    name: 'Aruba',
    value: '+297',
  },
  {
    name: 'Australia',
    value: '+61',
  },
  {
    name: 'Austria',
    value: '+43',
  },
  {
    name: 'Azerbaijan',
    value: '+994',
  },
  {
    name: 'Bahamas',
    value: '+1-242',
  },
  {
    name: 'Bahrain',
    value: '+973',
  },
  {
    name: 'Bangladesh',
    value: '+880',
  },
  {
    name: 'Barbados',
    value: '+1-246',
  },
  {
    name: 'Belarus',
    value: '+375',
  },
  {
    name: 'Belgium',
    value: '+32',
  },
  {
    name: 'Belize',
    value: '+501',
  },
  {
    name: 'Benin',
    value: '+229',
  },
  {
    name: 'Bermuda',
    value: '+1-441',
  },
  {
    name: 'Bhutan',
    value: '+975',
  },
  {
    name: 'Bolivia',
    value: '+591',
  },
  {
    name: 'Bosnia and Herzegovina',
    value: '+387',
  },
  {
    name: 'Botswana',
    value: '+267',
  },
  {
    name: 'Brazil',
    value: '+55',
  },
  {
    name: 'British Indian Ocean Territory',
    value: '+246',
  },
  {
    name: 'British Virgin Islands',
    value: '+1-284',
  },
  {
    name: 'Brunei',
    value: '+673',
  },
  {
    name: 'Bulgaria',
    value: '+359',
  },
  {
    name: 'Burkina Faso',
    value: '+226',
  },
  {
    name: 'Burundi',
    value: '+257',
  },
  {
    name: 'Cambodia',
    value: '+855',
  },
  {
    name: 'Cameroon',
    value: '+237',
  },
  {
    name: 'Canada',
    value: '+1',
  },
  {
    name: 'Cape Verde',
    value: '+238',
  },
  {
    name: 'Cayman Islands',
    value: '+1-345',
  },
  {
    name: 'Central African Republic',
    value: '+236',
  },
  {
    name: 'Chad',
    value: '+235',
  },
  {
    name: 'Chile',
    value: '+56',
  },
  {
    name: 'China',
    value: '+86',
  },
  {
    name: 'Christmas Island',
    value: '+61',
  },
  {
    name: 'Cocos Islands',
    value: '+61',
  },
  {
    name: 'Colombia',
    value: '+57',
  },
  {
    name: 'Comoros',
    value: '+269',
  },
  {
    name: 'Cook Islands',
    value: '+682',
  },
  {
    name: 'Costa Rica',
    value: '+506',
  },
  {
    name: 'Croatia',
    value: '+385',
  },
  {
    name: 'Cuba',
    value: '+53',
  },
  {
    name: 'Curacao',
    value: '+599',
  },
  {
    name: 'Cyprus',
    value: '+357',
  },
  {
    name: 'Czech Republic',
    value: '+420',
  },
  {
    name: 'Democratic Republic of the Congo',
    value: '+243',
  },
  {
    name: 'Denmark',
    value: '+45',
  },
  {
    name: 'Djibouti',
    value: '+253',
  },
  {
    name: 'Dominica',
    value: '+1-767',
  },
  {
    name: 'Dominican Republic',
    value: '+1-809, 1-829, 1-849',
  },
  {
    name: 'East Timor',
    value: '+670',
  },
  {
    name: 'Ecuador',
    value: '+593',
  },
  {
    name: 'Egypt',
    value: '+20',
  },
  {
    name: 'El Salvador',
    value: '+503',
  },
  {
    name: 'Equatorial Guinea',
    value: '+240',
  },
  {
    name: 'Eritrea',
    value: '+291',
  },
  {
    name: 'Estonia',
    value: '+372',
  },
  {
    name: 'Ethiopia',
    value: '+251',
  },
  {
    name: 'Falkland Islands',
    value: '+500',
  },
  {
    name: 'Faroe Islands',
    value: '+298',
  },
  {
    name: 'Fiji',
    value: '+679',
  },
  {
    name: 'Finland',
    value: '+358',
  },
  {
    name: 'France',
    value: '+33',
  },
  {
    name: 'French Polynesia',
    value: '+689',
  },
  {
    name: 'Gabon',
    value: '+241',
  },
  {
    name: 'Gambia',
    value: '+220',
  },
  {
    name: 'Georgia',
    value: '+995',
  },
  {
    name: 'Germany',
    value: '+49',
  },
  {
    name: 'Ghana',
    value: '+233',
  },
  {
    name: 'Gibraltar',
    value: '+350',
  },
  {
    name: 'Greece',
    value: '+30',
  },
  {
    name: 'Greenland',
    value: '+299',
  },
  {
    name: 'Grenada',
    value: '+1-473',
  },
  {
    name: 'Guam',
    value: '+1-671',
  },
  {
    name: 'Guatemala',
    value: '+502',
  },
  {
    name: 'Guernsey',
    value: '+44-1481',
  },
  {
    name: 'Guinea',
    value: '+224',
  },
  {
    name: 'Guinea-Bissau',
    value: '+245',
  },
  {
    name: 'Guyana',
    value: '+592',
  },
  {
    name: 'Haiti',
    value: '+509',
  },
  {
    name: 'Honduras',
    value: '+504',
  },
  {
    name: 'Hong Kong',
    value: '+852',
  },
  {
    name: 'Hungary',
    value: '+36',
  },
  {
    name: 'Iceland',
    value: '+354',
  },
  {
    name: 'India',
    value: '+91',
  },
  {
    name: 'Indonesia',
    value: '+62',
  },
  {
    name: 'Iran',
    value: '+98',
  },
  {
    name: 'Iraq',
    value: '+964',
  },
  {
    name: 'Ireland',
    value: '+353',
  },
  {
    name: 'Isle of Man',
    value: '+44-1624',
  },
  {
    name: 'Israel',
    value: '+972',
  },
  {
    name: 'Italy',
    value: '+39',
  },
  {
    name: 'Ivory Coast',
    value: '+225',
  },
  {
    name: 'Jamaica',
    value: '+1-876',
  },
  {
    name: 'Japan',
    value: '+81',
  },
  {
    name: 'Jersey',
    value: '+44-1534',
  },
  {
    name: 'Jordan',
    value: '+962',
  },
  {
    name: 'Kazakhstan',
    value: '+7',
  },
  {
    name: 'Kenya',
    value: '+254',
  },
  {
    name: 'Kiribati',
    value: '+686',
  },
  {
    name: 'Kosovo',
    value: '+383',
  },
  {
    name: 'Kuwait',
    value: '+965',
  },
  {
    name: 'Kyrgyzstan',
    value: '+996',
  },
  {
    name: 'Laos',
    value: '+856',
  },
  {
    name: 'Latvia',
    value: '+371',
  },
  {
    name: 'Lebanon',
    value: '+961',
  },
  {
    name: 'Lesotho',
    value: '+266',
  },
  {
    name: 'Liberia',
    value: '+231',
  },
  {
    name: 'Libya',
    value: '+218',
  },
  {
    name: 'Liechtenstein',
    value: '+423',
  },
  {
    name: 'Lithuania',
    value: '+370',
  },
  {
    name: 'Luxembourg',
    value: '+352',
  },
  {
    name: 'Macau',
    value: '+853',
  },
  {
    name: 'Macedonia',
    value: '+389',
  },
  {
    name: 'Madagascar',
    value: '+261',
  },
  {
    name: 'Malawi',
    value: '+265',
  },
  {
    name: 'Malaysia',
    value: '+60',
  },
  {
    name: 'Maldives',
    value: '+960',
  },
  {
    name: 'Mali',
    value: '+223',
  },
  {
    name: 'Malta',
    value: '+356',
  },
  {
    name: 'Marshall Islands',
    value: '+692',
  },
  {
    name: 'Mauritania',
    value: '+222',
  },
  {
    name: 'Mauritius',
    value: '+230',
  },
  {
    name: 'Mayotte',
    value: '+262',
  },
  {
    name: 'Mexico',
    value: '+52',
  },
  {
    name: 'Micronesia',
    value: '+691',
  },
  {
    name: 'Moldova',
    value: '+373',
  },
  {
    name: 'Monaco',
    value: '+377',
  },
  {
    name: 'Mongolia',
    value: '+976',
  },
  {
    name: 'Montenegro',
    value: '+382',
  },
  {
    name: 'Montserrat',
    value: '+1-664',
  },
  {
    name: 'Morocco',
    value: '+212',
  },
  {
    name: 'Mozambique',
    value: '+258',
  },
  {
    name: 'Myanmar',
    value: '+95',
  },
  {
    name: 'Namibia',
    value: '+264',
  },
  {
    name: 'Nauru',
    value: '+674',
  },
  {
    name: 'Nepal',
    value: '+977',
  },
  {
    name: 'Netherlands',
    value: '+31',
  },
  {
    name: 'Netherlands Antilles',
    value: '+599',
  },
  {
    name: 'New Caledonia',
    value: '+687',
  },
  {
    name: 'New Zealand',
    value: '+64',
  },
  {
    name: 'Nicaragua',
    value: '+505',
  },
  {
    name: 'Niger',
    value: '+227',
  },
  {
    name: 'Nigeria',
    value: '+234',
  },
  {
    name: 'Niue',
    value: '+683',
  },
  {
    name: 'North Korea',
    value: '+850',
  },
  {
    name: 'Northern Mariana Islands',
    value: '+1-670',
  },
  {
    name: 'Norway',
    value: '+47',
  },
  {
    name: 'Oman',
    value: '+968',
  },
  {
    name: 'Pakistan',
    value: '+92',
  },
  {
    name: 'Palau',
    value: '+680',
  },
  {
    name: 'Palestine',
    value: '+970',
  },
  {
    name: 'Panama',
    value: '+507',
  },
  {
    name: 'Papua New Guinea',
    value: '+675',
  },
  {
    name: 'Paraguay',
    value: '+595',
  },
  {
    name: 'Peru',
    value: '+51',
  },
  {
    name: 'Philippines',
    value: '+63',
  },
  {
    name: 'Pitcairn',
    value: '+64',
  },
  {
    name: 'Poland',
    value: '+48',
  },
  {
    name: 'Portugal',
    value: '+351',
  },
  {
    name: 'Puerto Rico',
    value: '+1-787, 1-939',
  },
  {
    name: 'Qatar',
    value: '+974',
  },
  {
    name: 'Republic of the Congo',
    value: '+242',
  },
  {
    name: 'Reunion',
    value: '+262',
  },
  {
    name: 'Romania',
    value: '+40',
  },
  {
    name: 'Russia',
    value: '+7',
  },
  {
    name: 'Rwanda',
    value: '+250',
  },
  {
    name: 'Saint Barthelemy',
    value: '+590',
  },
  {
    name: 'Saint Helena',
    value: '+290',
  },
  {
    name: 'Saint Kitts and Nevis',
    value: '+1-869',
  },
  {
    name: 'Saint Lucia',
    value: '+1-758',
  },
  {
    name: 'Saint Martin',
    value: '+590',
  },
  {
    name: 'Saint Pierre and Miquelon',
    value: '+508',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    value: '+1-784',
  },
  {
    name: 'Samoa',
    value: '+685',
  },
  {
    name: 'San Marino',
    value: '+378',
  },
  {
    name: 'Sao Tome and Principe',
    value: '+239',
  },
  {
    name: 'Saudi Arabia',
    value: '+966',
  },
  {
    name: 'Senegal',
    value: '+221',
  },
  {
    name: 'Serbia',
    value: '+381',
  },
  {
    name: 'Seychelles',
    value: '+248',
  },
  {
    name: 'Sierra Leone',
    value: '+232',
  },
  {
    name: 'Singapore',
    value: '+65',
  },
  {
    name: 'Sint Maarten',
    value: '+1-721',
  },
  {
    name: 'Slovakia',
    value: '+421',
  },
  {
    name: 'Slovenia',
    value: '+386',
  },
  {
    name: 'Solomon Islands',
    value: '+677',
  },
  {
    name: 'Somalia',
    value: '+252',
  },
  {
    name: 'South Africa',
    value: '+27',
  },
  {
    name: 'South Korea',
    value: '+82',
  },
  {
    name: 'South Sudan',
    value: '+211',
  },
  {
    name: 'Spain',
    value: '+34',
  },
  {
    name: 'Sri Lanka',
    value: '+94',
  },
  {
    name: 'Sudan',
    value: '+249',
  },
  {
    name: 'Suriname',
    value: '+597',
  },
  {
    name: 'Svalbard and Jan Mayen',
    value: '+47',
  },
  {
    name: 'Swaziland',
    value: '+268',
  },
  {
    name: 'Sweden',
    value: '+46',
  },
  {
    name: 'Switzerland',
    value: '+41',
  },
  {
    name: 'Syria',
    value: '+963',
  },
  {
    name: 'Taiwan',
    value: '+886',
  },
  {
    name: 'Tajikistan',
    value: '+992',
  },
  {
    name: 'Tanzania',
    value: '+255',
  },
  {
    name: 'Thailand',
    value: '+66',
  },
  {
    name: 'Togo',
    value: '+228',
  },
  {
    name: 'Tokelau',
    value: '+690',
  },
  {
    name: 'Tonga',
    value: '+676',
  },
  {
    name: 'Trinidad and Tobago',
    value: '+1-868',
  },
  {
    name: 'Tunisia',
    value: '+216',
  },
  {
    name: 'Turkey',
    value: '+90',
  },
  {
    name: 'Turkmenistan',
    value: '+993',
  },
  {
    name: 'Turks and Caicos Islands',
    value: '+1-649',
  },
  {
    name: 'Tuvalu',
    value: '+688',
  },
  {
    name: 'U.S. Virgin Islands',
    value: '+1-340',
  },
  {
    name: 'Uganda',
    value: '+256',
  },
  {
    name: 'Ukraine',
    value: '+380',
  },
  {
    name: 'United Arab Emirates',
    value: '+971',
  },
  {
    name: 'United Kingdom',
    value: '+44',
  },
  {
    name: 'United States',
    value: '+1',
  },
  {
    name: 'Uruguay',
    value: '+598',
  },
  {
    name: 'Uzbekistan',
    value: '+998',
  },
  {
    name: 'Vanuatu',
    value: '+678',
  },
  {
    name: 'Vatican',
    value: '+379',
  },
  {
    name: 'Venezuela',
    value: '+58',
  },
  {
    name: 'Vietnam',
    value: '+84',
  },
  {
    name: 'Wallis and Futuna',
    value: '+681',
  },
  {
    name: 'Western Sahara',
    value: '+212',
  },
  {
    name: 'Yemen',
    value: '+967',
  },
  {
    name: 'Zambia',
    value: '+260',
  },
  {
    name: 'Zimbabwe',
    value: '+263',
  },
];

export const states = [
  {
    name: 'ALABAMA',
    abbreviation: 'AL',
  },
  {
    name: 'ALASKA',
    abbreviation: 'AK',
  },
  {
    name: 'AMERICAN SAMOA',
    abbreviation: 'AS',
  },
  {
    name: 'ARIZONA',
    abbreviation: 'AZ',
  },
  {
    name: 'ARKANSAS',
    abbreviation: 'AR',
  },
  {
    name: 'CALIFORNIA',
    abbreviation: 'CA',
  },
  {
    name: 'COLORADO',
    abbreviation: 'CO',
  },
  {
    name: 'CONNECTICUT',
    abbreviation: 'CT',
  },
  {
    name: 'DELAWARE',
    abbreviation: 'DE',
  },
  {
    name: 'DISTRICT OF COLUMBIA',
    abbreviation: 'DC',
  },
  {
    name: 'FEDERATED STATES OF MICRONESIA',
    abbreviation: 'FM',
  },
  {
    name: 'FLORIDA',
    abbreviation: 'FL',
  },
  {
    name: 'GEORGIA',
    abbreviation: 'GA',
  },
  {
    name: 'GUAM',
    abbreviation: 'GU',
  },
  {
    name: 'HAWAII',
    abbreviation: 'HI',
  },
  {
    name: 'IDAHO',
    abbreviation: 'ID',
  },
  {
    name: 'ILLINOIS',
    abbreviation: 'IL',
  },
  {
    name: 'INDIANA',
    abbreviation: 'IN',
  },
  {
    name: 'IOWA',
    abbreviation: 'IA',
  },
  {
    name: 'KANSAS',
    abbreviation: 'KS',
  },
  {
    name: 'KENTUCKY',
    abbreviation: 'KY',
  },
  {
    name: 'LOUISIANA',
    abbreviation: 'LA',
  },
  {
    name: 'MAINE',
    abbreviation: 'ME',
  },
  {
    name: 'MARSHALL ISLANDS',
    abbreviation: 'MH',
  },
  {
    name: 'MARYLAND',
    abbreviation: 'MD',
  },
  {
    name: 'MASSACHUSETTS',
    abbreviation: 'MA',
  },
  {
    name: 'MICHIGAN',
    abbreviation: 'MI',
  },
  {
    name: 'MINNESOTA',
    abbreviation: 'MN',
  },
  {
    name: 'MISSISSIPPI',
    abbreviation: 'MS',
  },
  {
    name: 'MISSOURI',
    abbreviation: 'MO',
  },
  {
    name: 'MONTANA',
    abbreviation: 'MT',
  },
  {
    name: 'NEBRASKA',
    abbreviation: 'NE',
  },
  {
    name: 'NEVADA',
    abbreviation: 'NV',
  },
  {
    name: 'NEW HAMPSHIRE',
    abbreviation: 'NH',
  },
  {
    name: 'NEW JERSEY',
    abbreviation: 'NJ',
  },
  {
    name: 'NEW MEXICO',
    abbreviation: 'NM',
  },
  {
    name: 'NEW YORK',
    abbreviation: 'NY',
  },
  {
    name: 'NORTH CAROLINA',
    abbreviation: 'NC',
  },
  {
    name: 'NORTH DAKOTA',
    abbreviation: 'ND',
  },
  {
    name: 'NORTHERN MARIANA ISLANDS',
    abbreviation: 'MP',
  },
  {
    name: 'OHIO',
    abbreviation: 'OH',
  },
  {
    name: 'OKLAHOMA',
    abbreviation: 'OK',
  },
  {
    name: 'OREGON',
    abbreviation: 'OR',
  },
  {
    name: 'PALAU',
    abbreviation: 'PW',
  },
  {
    name: 'PENNSYLVANIA',
    abbreviation: 'PA',
  },
  {
    name: 'PUERTO RICO',
    abbreviation: 'PR',
  },
  {
    name: 'RHODE ISLAND',
    abbreviation: 'RI',
  },
  {
    name: 'SOUTH CAROLINA',
    abbreviation: 'SC',
  },
  {
    name: 'SOUTH DAKOTA',
    abbreviation: 'SD',
  },
  {
    name: 'TENNESSEE',
    abbreviation: 'TN',
  },
  {
    name: 'TEXAS',
    abbreviation: 'TX',
  },
  {
    name: 'UTAH',
    abbreviation: 'UT',
  },
  {
    name: 'VERMONT',
    abbreviation: 'VT',
  },
  {
    name: 'VIRGIN ISLANDS',
    abbreviation: 'VI',
  },
  {
    name: 'VIRGINIA',
    abbreviation: 'VA',
  },
  {
    name: 'WASHINGTON',
    abbreviation: 'WA',
  },
  {
    name: 'WEST VIRGINIA',
    abbreviation: 'WV',
  },
  {
    name: 'WISCONSIN',
    abbreviation: 'WI',
  },
  {
    name: 'WYOMING',
    abbreviation: 'WY',
  },
];

export const MODE_ONE_COLUMN = 0;
export const MODE_TWO_COLUMNS = 1;

export const TIMEZONES_ABBERAVIATIONS = Object.freeze({
  AlaskanTime: {
    text: 'Alaskan time (Anchorage)',
    timezone: 'America/Anchorage',
    shortText: 'AKST',
  },
  CentralTime: {
    text: 'Central time (Chicago)',
    timezone: 'America/Chicago',
    shortText: 'CST',
  },
  PacificTime: {
    text: 'Pacific time (Los Angeles)',
    timezone: 'America/Los_Angeles',
    shortText: 'PST',
  },
  EasternTime: {
    text: 'Eastern time (New York)',
    timezone: 'America/New_York',
    shortText: 'EST',
  },
  MountainTime: {
    text: 'Mountain time (Denver)',
    timezone: 'America/Denver',
    shortText: 'MST',
  },
  HawaiianTime: {
    text: 'Hawaiian time (Honolulu)',
    timezone: 'Pacific/Honolulu',
    shortText: 'HST',
  },
  GreenwhichMeanTime: {
    text: 'Greenwhich mean time',
    timezone: 'GMT',
    shortText: 'GMT',
  },
});

export const GetAbbervationByTimezone = timezone => {
  const timezoneAbberavation = Object.keys(TIMEZONES_ABBERAVIATIONS).find(
    key => TIMEZONES_ABBERAVIATIONS[key].timezone === timezone,
  );
  return TIMEZONES_ABBERAVIATIONS[timezoneAbberavation];
};

export const GetTimezoneTextMapping = () => {
  const timezoneTextMapping = {};
  Object.keys(TIMEZONES_ABBERAVIATIONS).forEach(key => {
    timezoneTextMapping[TIMEZONES_ABBERAVIATIONS[key].timezone] = TIMEZONES_ABBERAVIATIONS[key].text;
  });
  return timezoneTextMapping;
};

export const Timezones = Object.keys(TIMEZONES_ABBERAVIATIONS).reduce((acc, key) => {
  acc[TIMEZONES_ABBERAVIATIONS[key].timezone] = TIMEZONES_ABBERAVIATIONS[key].text;
  return acc;
}
, {});


export const RPM_PATIENT_STATUS = Object.freeze({
  onboarded: 'onboarded',
  verified: 'verified',
  enrolled: 'enrolled',
  activated: 'activated',
  ended: 'ended',
});

export const RPM_PORTAL_ACCESS_STATUS = Object.freeze({
  noAccess: 'forbidden',
  invited: 'invited',
  hasAccess: 'configured',
});

export const BILLABLE_TIME_THRESHOLD = 1200;
export const MAX_MESSAGE_LENGTH = 500;
