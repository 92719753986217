import React from 'react';
import PropTypes from 'prop-types';

import './graph.scss';
import GenericCategoryGraph, { chartTypeEnum } from './GenericCategoryGraph';
import { getProgramDisplayName } from '../../utils/cmsPrograms';

export const GetPatientsPerProgramDataSeries = colorPalette => [
  {
    name: getProgramDisplayName('RTM', 'RTMRespiratory'),
    color: colorPalette?.interpolate(0.9),
    selector: item => item['rtmResp'],
  },
  {
    name: getProgramDisplayName('RTM', 'RTMMusculoskeletal'),
    color: colorPalette?.interpolate(0.73),
    selector: item => item['rtmMs'],
  },
  {
    name: getProgramDisplayName('RTM', 'RTMBehavioralHealth'),
    color: colorPalette?.interpolate(0.36),
    selector: item => item['rtmBeh'],
  },
  {
    name: getProgramDisplayName('RPM'),
    color: colorPalette?.interpolate(0.1),
    selector: item => item['rpm'],
  },
];
export default function PatientsPerProgramGraph(props) {
  const { graphData } = props;
  return (
    <GenericCategoryGraph
      graphData={graphData}
      hasBorder
      forceCondensed
      xAxisTopPosition={false}
      yAxisWidth={props.graphType === chartTypeEnum.horizontalBarStacked ? 100 : 50}
      yAxisAlign="start"
      yAxisUnit=""
      tooltipTitle={'Patients per Program'}
      dataSeries={GetPatientsPerProgramDataSeries(props.colorPalette)}
      categorySelector={item => item.month}
      hasReferenceAreas
      graphType={props.graphType}
    />
  );
}

PatientsPerProgramGraph.propTypes = { graphData: PropTypes.array };
