import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { forEach } from 'lodash';

import { actions, GET_CARE_PROVIDER_API_CONFIGURATION_RESULT, HEALTH_CHECK_CARE_PROVIDER_API_CONFIGURATION_RESULT, SET_CARE_PROVIDER_API_CONFIGURATION_RESULT } from './actions';
import { PageHeader } from '../../../components/PageHeader';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import { notificationActions } from '../../../components/Notification/redux/actions';
import { Switch2 } from '../../../components/Switch2/Switch2';
import { openModalAction } from '../../../actions/modal';
import { EditCareProviderApiRouteModalName } from '../../../modals/EditCareProviderApiRoute';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../../components/PageHeader/Button';
import { HEADER_BUTTON_BLUE } from '../../../constants';
import { EditCareProviderApiGeneralSettingsModalName } from '../../../modals/EditCareProviderApiGeneralSettings';

function CareProviderApi(props) {
  const { getConfig, showNotification, onEditRoute, onEditGeneralSettings, healthCheck, saveConfig } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [routing, setRouting] = useState([]);
  const [generalSettings, setGeneralSettings] = useState([]);
  const [config, setConfig] = useState({});

  const makeRequest = () => {
    const request = {};

    generalSettings.forEach(e => {
      request[e.parameter] = e.value;
    });

    request.routing = {}

    routing.forEach(e => {
      request.routing[e.route] = {
        url: e.url,
        enabled: e.enabled,
      }
    });

    return request;
  }

  const decodeGeneralSettings = (e) => {
    const newGeneralSettings = [];

    Object.entries(e).forEach(([key, value]) => {
      if (key !== 'routing') {
        newGeneralSettings.push({
          parameter: key,
          value,
        });
      }
    });

    setGeneralSettings(newGeneralSettings);
  }

  const decodeResp = (e) => {
    decodeGeneralSettings(e);

    const newRouting = [];
    Object.entries(e.routing).forEach(([key, value]) => {
      newRouting.push({
        route: key,
        url: value.url,
        enabled: value.enabled,
        healthCheck: null,
      })
    });

    setRouting(newRouting);
  }

  React.useEffect(() => {
    getConfig().then(resp => {
      if (resp.type === GET_CARE_PROVIDER_API_CONFIGURATION_RESULT) {
        decodeResp(resp.response);
        setConfig(resp.response);
        setIsLoading(false);
      } else {
        showNotification('Error while loading configuration', 5000);
      }
      setIsLoading(false);
    });
  }, []);

  const handleUrlEdit = (route, enabled, url) => {
    routing.forEach(e=> {
      if (e.route === route) {
        e.url = url;
        e.enabled = enabled;
        e.healthCheck = null;
      }
    })
  }

  const handleGeneralSettingsEdit = (e) => {
    decodeGeneralSettings(e);
    setConfig(e);
  }

  const renderSwitch = (e) => (
    <Switch2 checked={e.enabled} name={`${e.route}`} disabled />
  );

  const renderHealthCheck = (e) => {
    if (e.healthCheck === null) {
      return 'N/A';
    }

    if (e.healthCheck === true) {
      return 'OK';
    }

    return 'Failed';
  }

  const save = () => {
    saveConfig(makeRequest()).then(resp => {
      if (resp.type === SET_CARE_PROVIDER_API_CONFIGURATION_RESULT) {
        showNotification('Configuration saved', 5000);
      } else {
        showNotification('Error while saving configuration', 5000);
      }
    });
  }

  const onHealthCheck = () => {
    healthCheck(makeRequest()).then(resp => {
      if (resp.type === HEALTH_CHECK_CARE_PROVIDER_API_CONFIGURATION_RESULT) {
        Object.entries(resp.response).forEach(([key, value]) => {
          forEach(routing, e => {
            if (e.route === key) {
              e.healthCheck = value;
            }
          });
        });
        showNotification('Health check completed', 5000);
      } else {
        showNotification('Error while performing health check', 5000);
      }
    });
  }

  const settingsRender = (e) => {
    if (e.parameter === 'oAuthClientCredentials' && e.value !== null) {
      return '***';
    }
    
    if (e.parameter === 'enabled') {
      return <Switch2 checked={e.value} disabled />;
    }

    return e.value;
  }

  return (
    <>
      <PageHeader left="Care Provider API Auth and Settings" right={<Button class={HEADER_BUTTON_BLUE} title="Edit General Settings" onClick={() => onEditGeneralSettings(config, handleGeneralSettingsEdit)} />} />
      <div className="external-api-configuration-table-container">
        <Table
          name="care-provider-api-general-settings"
          data={generalSettings || {}}
          isLoading={isLoading}
        >
          <Column title="Item" key="route" value={e => e.parameter} />
          <Column title="Value" key="url" value={settingsRender} />
        </Table>
        <PageHeader left="Care Provider API Routing" />
        <Table
          name="care-provider-api-routing"
          data={routing || {}}
          isLoading={isLoading}
          buttons={[
            {
              icon: 'edit',
              onClick: (_, item) => onEditRoute(item.route, handleUrlEdit, item.enabled, item.url),
              text: 'Edit URL',
            }
          ]}
        >
          <Column title="Route" key="route" value={e => e.route} />
          <Column title="URL" key="url" value={e => e.url} />
          <Column title="Enabled" key="enabled" value={renderSwitch} />
          <Column title="Health Check" key="healthCheck" value={renderHealthCheck} />
        </Table>
        <div className="buttons">
          <Button class={HEADER_BUTTON_DARK_BLUE} title="Save" onClick={save} />
          <Button class={HEADER_BUTTON_DARK_BLUE} title="Health Check" onClick={onHealthCheck} />
        </div>

      </div>
    </>
  );
}

CareProviderApi.propTypes = {
  organizationId: PropTypes.string,
  getConfig: PropTypes.func,
  showNotification: PropTypes.func,
  onEditRoute: PropTypes.func,
  onEditGeneralSettings: PropTypes.func,
  healthCheck: PropTypes.func,
  saveConfig: PropTypes.func,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getConfig: () =>
    dispatch(actions.getCareProviderApiConfiguration(ownProps.organizationId)),
  healthCheck: config =>
    dispatch(actions.careProviderApiConfigurationHealthCheck(config)),
  saveConfig: config =>
    dispatch(actions.setCareProviderApiConfiguration(ownProps.organizationId, config)),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
  onEditGeneralSettings: (config, action ) =>
    dispatch(
      openModalAction(EditCareProviderApiGeneralSettingsModalName, { action, config })
    ),
  onEditRoute: (route, action, enabled, url) => 
    dispatch(
      openModalAction(EditCareProviderApiRouteModalName, { action, route, enabled, url })
    )
});

export default connect(null, mapDispatchToProps)(CareProviderApi);