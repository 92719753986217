import { store } from './store';

export const PERMISSIONS = Object.freeze({
  ADMIN: 'admin',
  ALERT_MANAGEMENT: 'alert_management',
  BILLING_TASKS: 'billing/tasks',
  BILLING_TASK_DETAILS: 'billing/task_details',
  BILLING_DEVICE_EVENTS: 'billing/events',
  CALENDAR: 'calendar',
  CAP_MANAGER: 'cap-manager',
  DASHBOARD_PATIENT: 'dashboard_patient',
  DASHBOARD_ADHERENCE_CAP_EVOLUTION: 'dashboard_adherence/cap_evolution',
  DASHBOARD_ADHERENCE_ADHERENCE_CHART: 'dashboard_adherence/adherence_chart',
  DASHBOARD_ADHERENCE_ADHERENCE_STATS: 'dashboard_adherence/adherence_stats',
  DASHBOARD_GENERAL_DEVICE: 'dashboard_general/device',
  DASHBOARD_RPM_DEVICE: 'dashboard_rpm/device',
  DEVICES_BLE: 'devices/ble',
  DEVICES_LTE: 'devices/lte',
  DEVICES_HUB: 'devices/hub',
  HISTORY: 'history',
  MEDICATIONS: 'medications',
  MESSAGES_ARCHIVED: 'messages/archived',
  MESSAGES_INBOX: 'messages/inbox',
  MESSAGES_PREFABS: 'messages/prefabs',
  MESSAGES_SEND_MESSAGES: 'messages/send_messages',
  PATIENTS_RPM_PATIENT_INFO: 'patient_rpm/patient_info',
  PATIENTS_RPM_PATIENT_INFO_EDIT: 'patient_rpm/patient_info_edit',
  PATIENTS_RPM_PATIENT_LIST: 'patient_rpm/patient_list',
  PATIENTS_RPM_PATIENT_EVENTS: 'patient_rpm/patient_events',
  PATIENTS_RPM_PATIENT_DEVICES: 'patient_rpm/patient_devices',
  PATIENTS_RPM_PATIENT_DEVICES_EDIT: 'patient_rpm/patient_devices_edit',
  PATIENTS_RPM_PATIENT_MEDICATIONS: 'patient_rpm/patient_medications',
  PATIENTS_RPM_PATIENT_MEDICATIONS_EDIT: 'patient_rpm/medications_edit',
  PATIENTS_RPM_PATIENT_TASK_HISTORY: 'patient_rpm/patient_task_history',
  PATIENTS_RPM_PATIENT_DEVICE_DETAILS: 'patient_rpm/patient_device_details',
  PATIENTS_RPM_PATIENT_OVERLAY_PLOTS: 'patient_rpm/patient_overlay_plots',
  PATIENTS_RPM_PATIENT_LOG: 'patient_rpm/patient_log',
  PATIENTS_RPM_TASK_TIMER: 'patient_rpm/task_timer',
  PATIENTS_RPM_UPLOAD_PATIENT_LIST: 'patient_rpm/upload-patient-list',
  PATIENTS_RPM_DOWNLOAD_PATIENTS_VITALS_CSV: 'patient_rpm/download_patients_vitals_csv',
  PATIENTS_RPM_ENROLL: 'patient_rpm/enroll',
  PATIENTS_RPM_ACTIVATE: 'patient_rpm/activate',
  GRANT_PATIENT_PORTAL_ACCESS: 'patient_portal_access',
  RPM_PATIENT_PAGE_HEALTH_CENTER: 'rpm_patient_page/health_center',
  RPM_PATIENT_PAGE_MEDICATIONS: 'rpm_patient_page/medications',
  RPM_PATIENT_PAGE_DEVICES: 'rpm_patient_page/devices',
  DASHBOARD_RPM_PATIENT_BILLABLE_TIME: 'dashboard_rpm_patient/billable_time',
  DASHBOARD_RPM_PATIENT_ALERT_TRESHOLDS: 'dashboard_rpm_patient/alert_tresholds',
  DASHBOARD_RPM_PATIENT_PATIENT_INFO: 'dashboard_rpm_patient/patient_info',
  DASHBOARD_RPM_PATIENT_DEVICES_MEDS_COUNT: 'dashboard_rpm_patient/patient_devices_meds_count',
  DASHBOARD_RPM_PATIENT_DOCUMENTS: 'dashboard_rpm_patient/documents',
  DASHBOARD_RPM_PATIENT_MEDICATIONS: 'dashboard_rpm_patient/medications',
  DASHBOARD_RPM_PATIENT_READINGS: 'dashboard_rpm_patient/readings',
  PATIENTS_ADHERENCE_ACTIVE: 'patient_adherence/active',
  PATIENTS_ADHERENCE_PENDING: 'patient_adherence/pending',
  PATIENTS_ADHERENCE_TERMED: 'patient_adherence/termed',
  SCHEDULE_MANAGEMENT: '',
  SETTINGS_DETAILS: 'settings/details',
  SUPPORT_CHAT: 'support/chat',
  SUPPORT_EMAIL: 'support/email',
  SUPPORT_HELP_CENTER: 'support/help_center',
  SUPPORT_SMART_CAP: 'support/smart_cap',
  SUPPORT_DOCUMENTS: 'support/documents',
  SUPPORT_RPM_VIDEO: 'support/rpm_video',
  SUPPORT_EXTERNAL_SPEC: 'support/external_spec',
  DEVICE_MANAGEMENT_BLE: 'device_management/ble',
  DEVICE_MANAGEMENT_HUB: 'device_management/hub',
  DEVICE_MANAGEMENT_LTE: 'device_management/lte',
  DEVICE_MANAGEMENT_LSC: 'device_management/lsc',
  DEVICE_MANAGEMENT_INJECTABLE_TRACKER: 'device_management/injectable_tracker',
  DEVICE_MANAGEMENT_MDP: 'device_management/mdp',
  DEVICE_MANAGEMENT_LTE_MEDICATION_ORGANIZER: 'device_management/lte_medication_organizer',
  DEVICE_MANAGEMENT_BPM: 'device_management/bpm',
  DEVICE_MANAGEMENT_WEIGHTING_MACHINE: 'device_management/wm',
  DEVICE_MANAGEMENT_WB: 'device_management/wb',
  DEVICE_MANAGEMENT_GLUCO: 'device_management/glucometer',
  DEVICE_MANAGEMENT_THERMOMETER: 'device_management/thermometer',
  DEVICE_MANAGEMENT_INHALER: 'device_management/inhaler',
  DEVICE_MANAGEMENT_SPIROMETER: 'device_management/spirometer',
  DEVICE_MANAGEMENT_PULSE_OXIMETER: 'device_management/pulseox',
  DEVICE_MANAGEMENT_AIR_QUALITY_MONITOR: 'device_management/atmocube',
  DEVICE_MANAGEMENT_HAILIE_INHALER: 'device_management/hailie_inhaler',
  DEVICE_MANAGEMENT_DEXCOM_CGM: 'device_management/dexcom_cgm',
  DEVICE_MANAGEMENT_PTINR_MONITOR: 'device_management/ptinr_monitor',
  DEVICE_MANAGEMENT_SHOW_DELETED: 'device_management/show_deleted',
  ORGANIZATIONS_ADMIN: 'organizations/admin',
  ORGANIZATIONS_CAPMANAGER: 'organizations/capmanager',
  ORGANIZATIONS_DEVICES: 'organizations/devices',
  ORGANIZATIONS_ORGANIZATION_MANAGER: 'organizations/organization_manager',
  ORGANIZATIONS_OUTBOUND_API: 'organizations/outbound_api',
  ORGANIZATIONS_GROUPING: 'organizations/grouping',
  ORGANIZATIONS_ANALYTICS: 'organizations/analytics',
  PROVISIONING_HUB: 'provisioning/hub',
  PROVISIONING_LTE: 'provisioning/lte',
  PROVISIONING_LSC: 'provisioning/lsc',
  REPORT: 'report',
  KITS_MANAGEMENT: 'kits/management',
  KITS_SHOW_PATIENT: 'kits/show_patient',
  ALERTS: 'alerts',
  WORKFLOW_ENROLLMENT: 'workflow_enrollment',
  DOCUMENTS: 'documents',
  DOCUMENTS_S3: 'documents_s3',
  SYSTEM_CONFIGURATION: 'system_configuration',
  CARE_PROVIDER_LIST: 'care_provider/list',
  CARE_PROVIDER_REASSIGN: 'care_provider/reassign',
  CARE_PROVIDER_ROLE: 'care_provider/role',
});

export const PERMISSION_GROUPS = Object.freeze({
  BILLING: 'billing/',
  DASHBOARD: 'dashboard/',
  DASHBOARD_ADHERENCE: 'dashboard_adherence/',
  DASHBOARD_GENERAL: 'dashboard_general/',
  DASHBOARD_RPM: 'dashboard_rpm/',
  DEVICES: 'devices/',
  MESSAGES: 'messages/',
  PATIENTS_RPM: 'patient_rpm/',
  PATIENTS_ADHERENCE: 'patient_adherence',
  SETTINGS: 'settings/',
  SUPPORT: 'support/',
  DEVICE_MANAGEMENT: 'device_management/',
  ORGANIZATIONS: 'organizations/',
  PROVISIONING: 'provisioning/',
  STUDY: 'study/',
  KITS: 'kits/',
  DASHBOARD_RPM_PATIENT: 'dashboard_rpm_patient',
});

export function getPermissions() {
  const ret = store.getState().auth.permissions;
  return ret;
}

export function hasPermission(permission) {
  return getPermissions()?.find(p => p === permission) !== undefined;
}

export function hasAnyPermissionInGroup(permissionGroup) {
  return getPermissions()?.find(p => p.startsWith(permissionGroup)) !== undefined;
}
