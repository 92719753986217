import React from 'react';
import PropTypes from 'prop-types';
import { ReferenceArea } from 'recharts';
import moment from 'moment-timezone';

import { GRAPH_AXIS_FONT_SIZE, GRAPH_AXIS_LINE_WIDTH, GRAPH_X_AXIS_FONT_COLOR } from './GraphConstants';
import { isMobile } from 'react-device-detect';

const graphAlternateRowBackground = '#F4F8FC';
const graphRowBackground = '#fff';
const graphAxisFontSize = GRAPH_AXIS_FONT_SIZE;
const graphAxisFontColor = GRAPH_X_AXIS_FONT_COLOR;
const graphYAxisFontColor = '#4F4F4F';
const graphAxisFontColorWeekend = '#bbb';

export const calcDays = (minDate, maxDate) => {
  const days = [];
  for (let i = minDate.clone(); i <= maxDate; i.add(1, 'days')) {
    days.push(i.clone().startOf('day'));
  }

  return days;
};

export const calcHours = (minDate, maxDate) => {
  const days = [];

  for (let i = minDate.clone(); i <= maxDate; i.add(1, 'days')) {
    for (let h = 0; h < 24; h++) {
      days.push(i.clone().add(h, 'hours'));
    }
  }

  return days;
};

export function CustomReferenceLine(props) {
  //console.log(props)

  // fill: "#f5f5f5"
  // fillOpacity: 1
  // stroke: "#f5f5f5"
  // strokeWidth: 1
  // x: 1694390400
  // x1: 1194
  // x2: 1194
  // y1: 293
  // y2: 0

  const { xAxisParams, x, x1, y1, y2, fill, fillOpacity, skipAxis } = props;

  // if (isNaN(x) || isNaN(width)) {
  //   return <React.Fragment />;
  // }

  // if (isUpper) {
  //   const magicNumberExtraHeight = 2; // axis line width???
  //   return (
  //     <g>
  //       <rect
  //         x={0}
  //         y={y - height / 2 - magicNumberExtraHeight}
  //         width={2}
  //         height={height / 2 + magicNumberExtraHeight}
  //         fillOpacity={fillOpacity}
  //         fill={fill}
  //       />
  //     </g>
  //   );
  // }

  if (x <= xAxisParams.min) return <React.Fragment />;
  if (x >= xAxisParams.max) return <React.Fragment />;

  const yStart = skipAxis ? Math.min(y1, y2) : 0;
  const yLength = skipAxis ? Math.abs(y2 - y1) : Math.max(y1, y2);

  // console.log(skipAxis, y1, y2);

  return (
    <g>
      <line
        x1={x1 - 1}
        x2={x1 - 1}
        y1={yStart}
        y2={yStart + yLength}
        stroke={fill}
        strokeDasharray={isMobile ? '1, 5' : ''}
      />
      {/* <rect
        x={x1 - 1}
        y={yStart}
        width={1}
        //height={2000} //{y2-y1}
        height={yLength} //{y2-y1}
        fillOpacity={fillOpacity}
        fill={fill}
      /> */}
    </g>
  );
}
CustomReferenceLine.propTypes = {
  x1: PropTypes.number,
  x2: PropTypes.number,
  y1: PropTypes.number,
  y2: PropTypes.number,
  x: PropTypes.number,
  fill: PropTypes.any,
  fillOpacity: PropTypes.any,
  viewBox: PropTypes.any,
  //isUpper: PropTypes.bool,
  xAxisParams: PropTypes.object,
};

export function MobileTooltipReferenceLine(props) {
  const { x1, x2, y1, y2, customColor } = props;

  const r = 3;
  // const yMin = Math.min(y1, y2) + r + 2;
  // const yMax = Math.max(y1, y2) - r - 1;
  const yMin = Math.min(y1, y2);
  const yMax = Math.max(y1, y2);

  return (
    <g>
      <circle cx={x1} cy={yMin} r={r} fill={customColor} />
      <circle cx={x2} cy={yMax} r={r} fill={customColor} />

      <line x1={x1} x2={x2} y1={yMin} y2={yMax} stroke={customColor} opacity={0.3} style={{ strokeWidth: 2 * r }} />
    </g>
  );
}
MobileTooltipReferenceLine.propTypes = {
  x1: PropTypes.number,
  x2: PropTypes.number,
  y1: PropTypes.number,
  y2: PropTypes.number,
  customColor: PropTypes.any,
};

export function CustomReferenceArea(props) {
  const { x, y, width, height, fill, fillOpacity, isUpper, leftAxisWidth, padding, dontExtend } = props;

  //const magicNumberExtraWidth = (leftAxisWidth ? leftAxisWidth : 100) - (narrower ? narrower : 0); // draw ref area to the right border of the chart

  if (isNaN(x) || isNaN(width)) {
    return <React.Fragment />;
  }

  //console.log(props);

  const useX = dontExtend ? x - (padding ? padding : 0) : 0;
  const useWidth = width + (padding ? padding * 2 : 0) + (dontExtend ? 0 : x * 2);

  if (isUpper) {
    const magicNumberExtraHeight = 2; // axis line width???
    return (
      <g>
        <rect x={useX} y={y + height / 2} width={useWidth} height={height / 2} fillOpacity={fillOpacity} fill={fill} />
      </g>
    );
  }

  return (
    <g>
      <rect x={useX} y={y} width={useWidth} height={height} fillOpacity={fillOpacity} fill={fill} />
    </g>
  );
}
CustomReferenceArea.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.any,
  fillOpacity: PropTypes.any,
  viewBox: PropTypes.any,
  isUpper: PropTypes.bool,
  paddding: PropTypes.number,
  dontExtend: PropTypes.bool,
};

export const referenceAreas = (yAxisParams, dontExtend, padding) => {
  return (
    <React.Fragment>
      <ReferenceArea
        y1={yAxisParams.axisMin}
        y2={yAxisParams.axisMin + yAxisParams.axisTick / 2}
        stroke={graphAlternateRowBackground}
        fill={graphAlternateRowBackground}
        fillOpacity="1"
        key={`refareaStart}`}
        shape={<CustomReferenceArea padding={padding} dontExtend={dontExtend} />}
        yAxisId="yAxis1"
      />
      {Array.from({ length: yAxisParams.axisTicks }, (_, i) => i).map(i => (
        <ReferenceArea
          y1={yAxisParams.axisMin + yAxisParams.axisTick / 2 + i * yAxisParams.axisTick}
          y2={yAxisParams.axisMin + yAxisParams.axisTick / 2 + i * yAxisParams.axisTick + yAxisParams.axisTick}
          stroke={i % 2 === 0 ? graphRowBackground : graphAlternateRowBackground}
          fill={i % 2 === 0 ? graphRowBackground : graphAlternateRowBackground}
          fillOpacity="1"
          key={`refarea${i}`}
          shape={<CustomReferenceArea padding={padding} dontExtend={dontExtend} />}
          yAxisId="yAxis1"
        />
      ))}
      <ReferenceArea
        y1={yAxisParams.axisMax - yAxisParams.axisTick / 2}
        y2={yAxisParams.axisMax}
        stroke={yAxisParams.axisTicks % 2 === 0 ? graphRowBackground : graphAlternateRowBackground}
        fill={yAxisParams.axisTicks % 2 === 0 ? graphRowBackground : graphAlternateRowBackground}
        fillOpacity="1"
        key={`refareaEnd}`}
        shape={<CustomReferenceArea padding={padding} dontExtend={dontExtend} />}
        yAxisId="yAxis1"
      />
    </React.Fragment>
  );
};

export const CustomizedXAxisTick = props => {
  const { x, y, payload, axisLine, axisMin } = props; // stroke
  const day = moment(payload.value);
  const dayOfWeek = day instanceof moment && day.isValid() ? day.day() : -1;
  const dayOfMonth = day instanceof moment && day.isValid() ? day.date() : -1;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        className="span"
        fontSize={graphAxisFontSize}
        dy={-30}
        textAnchor="middle"
        fill={dayOfWeek === 0 || dayOfWeek === 6 ? graphAxisFontColorWeekend : graphAxisFontColor}
      >
        {dayOfMonth > 0 ? dayOfMonth : ''}
      </text>

      {dayOfMonth === 1 && (
        <text
          className="span"
          fontSize={graphAxisFontSize}
          dy={-10}
          textAnchor="middle"
          fill={graphAxisFontColorWeekend}
        >
          {day.format('MMM').toUpperCase()}
        </text>
      )}
    </g>
  );
};
CustomizedXAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  payload: PropTypes.any,
  axisLine: PropTypes.any,
  axisMin: PropTypes.string,
};

export const CustomizedYAxisTick = props => {
  const {
    x,
    y,
    width,
    payload,
    axisMax,
    axisLine,
    dateMin,
    dateMax,
    day,
    withoutHeader,
    useK,
    isRight = false,
  } = props; // stroke
  const textDx = isRight ? (width === undefined ? 35 : width / 2 - 2) : width === undefined ? -35 : -width / 2;

  if (
    payload.value === axisMax &&
    ((dateMin instanceof moment && dateMax instanceof moment) || day instanceof moment)
  ) {
    return (
      <g transform={`translate(${x},${y})`}>
        {!withoutHeader && (
          <rect
            x={-x}
            y={0 - payload.offset - axisLine.strokeWidth / 2}
            width={x + 1}
            height={axisLine.strokeWidth}
            fill={axisLine.stroke}
          />
        )}
        {!withoutHeader && payload.value === axisMax && (
          <rect
            y={-y}
            x={0 - payload.offset - axisLine.strokeWidth / 2 + GRAPH_AXIS_LINE_WIDTH}
            height={y + 1}
            width={axisLine.strokeWidth}
            fill={axisLine.stroke}
          />
        )}
        {!withoutHeader && dateMin && dateMax && (
          <g>
            <text
              className="span"
              fontSize={graphAxisFontSize}
              dy={-35}
              dx={-width / 2}
              textAnchor="middle"
              fill={graphAxisFontColorWeekend}
            >
              {dateMin.format('MMM D -').toUpperCase()}
            </text>
            <text
              className="span"
              fontSize={graphAxisFontSize}
              dy={-20}
              dx={-width / 2}
              textAnchor="middle"
              fill={graphAxisFontColorWeekend}
            >
              {dateMax.format('MMM D').toUpperCase()}
            </text>
          </g>
        )}
        {day && (
          <g>
            <text
              className="span"
              fontSize={graphAxisFontSize}
              dy={-35}
              dx={-width / 2}
              textAnchor="middle"
              fill={graphAxisFontColorWeekend}
            >
              {day.format('ddd,').toUpperCase()}
            </text>
            <text
              className="span"
              fontSize={graphAxisFontSize}
              dy={-20}
              dx={-width / 2}
              textAnchor="middle"
              fill={graphAxisFontColorWeekend}
            >
              {day.format('MMM DD').toUpperCase()}
            </text>
          </g>
        )}
      </g>
    );
  }

  const val = !useK ? payload.value : `${payload.value / 1000}k`;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        className="span"
        fontSize={graphAxisFontSize}
        dy={5}
        dx={textDx}
        textAnchor="middle"
        fill={graphYAxisFontColor}
      >
        {val}
      </text>
    </g>
  );
};
CustomizedYAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  stroke: PropTypes.any,
  payload: PropTypes.any,
  axisMax: PropTypes.number,
  dateMin: PropTypes.any,
  dateMax: PropTypes.any,
  axisLine: PropTypes.any,
  withoutHeader: PropTypes.bool,
  useK: PropTypes.bool,
};

export const CustomizedYAxisTick_Category = props => {
  const {
    x,
    y,
    width,
    payload,
    align,
  } = props;

  const tickHeight = 20;
  return (
    <g transform={`translate(${x - width},${y - (tickHeight / 2) - 1})`}>
      <foreignObject x={0} y={0} width={width} height={tickHeight}>
        <div style={{maxWidth: width, textAlign: align}} className="y-axis-tick" title={payload.value}>{payload.value}</div>
      </foreignObject>
    </g>
  );
};
CustomizedYAxisTick_Category.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  stroke: PropTypes.any,
  payload: PropTypes.any,
  axisLine: PropTypes.any,
  withoutHeader: PropTypes.bool,
  align: PropTypes.string,
};


export const CustomizedXAxisTickHourly = props => {
  const { x, y, payload } = props; // stroke
  const day = moment(payload.value);
  const hour = day.hours();

  return (
    <g transform={`translate(${x},${y})`}>
      <text className="span" fontSize={graphAxisFontSize} dy={-30} textAnchor="middle" fill={graphAxisFontColor}>
        {day.format('h')}
      </text>

      {hour % 12 === 0 && (
        <text
          className="span"
          fontSize={graphAxisFontSize}
          dy={-10}
          textAnchor="middle"
          fill={graphAxisFontColorWeekend}
        >
          {hour === 0 ? 'AM' : hour === 12 ? 'PM' : ''}
        </text>
      )}
    </g>
  );
};
CustomizedXAxisTickHourly.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.any,
  axisLine: PropTypes.any,
  date: PropTypes.object,
};
