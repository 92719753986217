import {
  UPDATE_ADVANCED_FILTER_STATE,
  CLEAR_FILTERS_ADVANCED_FILTER_STATE,
  INITALIZE_ADVANCED_FILTER_STATE,
  CLEAR_ALL_ADVANCED_FILTER_STATE,
} from './actions';
import UpdateFiltersState from './internals/stateHelper';

export const AdvancedFiltersReducer = (state = { filters: { isInitalized: false, items: [] } }, action) => {
  switch (action.type) {
    case UPDATE_ADVANCED_FILTER_STATE:
      return {
        ...state,
        filters: {
          isInitalized: state.filters.isInitalized,
          items: UpdateFiltersState(action.data, action.filterType, state.filters.items),
        },
      };
    case CLEAR_FILTERS_ADVANCED_FILTER_STATE:
      return {
        ...state,
        filters: {
          isInitalized: state.filters.isInitalized,
          items: [],
        },
      };
    case INITALIZE_ADVANCED_FILTER_STATE:
      return {
        ...state,
        filters: {
          isInitalized: true,
          items: state.filters.items,
        },
      };
    case CLEAR_ALL_ADVANCED_FILTER_STATE:
      return {
        ...state,
        filters: {
          isInitalized: false,
          items: [],
        },
      };
    default:
      return state;
  }
};
