import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { ComposedChart, ResponsiveContainer, XAxis, Brush, YAxis, LineChart, ReferenceLine } from 'recharts';
import moment from 'moment-timezone';

import {
  GRAPH_AXIS_LINE_COLOR,
  GRAPH_AXIS_LINE_WIDTH,
  MINI_RANGE_PICKER_HEIGHT,
  RANGE_PICKER_HEIGHT,
} from '../GraphConstants';
import { DATE_FORMAT_YEAR_MONTH_DAY } from '../../../constants';
import { getColorsForColorTheme } from '../../../utils/colorTheme';
import { drawLineSeries } from './DrawMetrics';

const TRAVELLER_WIDTH = 8;

export const RangePicker = React.forwardRef((props, ref) => {
  const calcTicks = (startTimestamp, ticks, resolution, resolutionMulti) => {
    // console.log(startTimestamp);
    const ret = Array.from({ length: ticks }, (_, i) => ({
      timestamp: moment(startTimestamp * 1000)
        .add(i * (resolutionMulti ? resolutionMulti : 1), resolution ? resolution : 'day')
        .unix(),
      y: i,
    }));
    //console.table(ret);
    //console.table(ret.map(i => moment(i.timestamp*1000).toString()));
    // console.log('pierwszy ts: ', moment(ret[0].timestamp * 1000).toString());
    // console.log('pierwszy ts: ', moment(ret[0].timestamp * 1000).format());
    return ret;
  };

  const [dateEntries, setDateEntries] = React.useState([]);
  const [startTimestamp, setStartTimestamp] = React.useState(props.startTimestamp);
  const [endTimestamp, setEndTimestamp] = React.useState(props.endTimestamp);
  const [keyForChart, setKeyForChart] = React.useState(`${props.startTimestamp}-${props.endTimestamp}`);

  const onChange = e => {
    if (dateEntries[e.startIndex].timestamp !== startTimestamp || dateEntries[e.endIndex].timestamp !== endTimestamp) {
      const start = dateEntries[e.startIndex].timestamp;
      const end = dateEntries[e.endIndex].timestamp;
      setStartTimestamp(start);
      setEndTimestamp(end);
      // console.log(start, end);
      // console.log(e.startIndex, e.endIndex);
      // console.log(e);
      props.onChange(start, end);
    }
  };

  useEffect(() => {
    setDateEntries(calcTicks(props.dataStartTimestamp, props.ticks, props.resolution, props.resolutionMulti));
  }, []);

  useEffect(() => {
    setDateEntries(calcTicks(props.dataStartTimestamp, props.ticks, props.resolution, props.resolutionMulti));
  }, [props.dataStartTimestamp, props.ticks, props.resolution, props.resolutionMulti]);

  useEffect(() => {
    if (props.startTimestamp != startTimestamp || props.endTimestamp !== endTimestamp) {
      // console.log('ustawiam klucz: ', startTimestamp, props.startTimestamp, endTimestamp, props.endTimestamp);
      setKeyForChart(`${props.startTimestamp}-${props.endTimestamp}`);
    }
  }, [props.startTimestamp, props.endTimestamp]);

  const findFirstIndexOf = timestamp => {
    const exactIndex = dateEntries.findIndex(e => e.timestamp === timestamp);
    if (exactIndex >= 0) return exactIndex;
    // console.log('FF');
    return dateEntries.findIndex(e => e.timestamp >= timestamp);
  };
  const findLastIndexOf = timestamp => {
    const exactIndex = dateEntries.findIndex(e => e.timestamp === timestamp);
    if (exactIndex >= 0) return exactIndex;
    // console.log('FL');
    // console.log('searching ', timestamp, moment(timestamp * 1000).toString());
    // console.log(
    //   'found pre ',
    //   dateEntries.findLastIndex(e => e.timestamp <= timestamp) >= 0
    //     ? dateEntries[dateEntries.findLastIndex(e => e.timestamp <= timestamp)].timestamp
    //     : -1,
    // );
    // console.log(
    //   'found post ',
    //   dateEntries.findIndex(e => e.timestamp >= timestamp) >= 0
    //     ? dateEntries[dateEntries.findIndex(e => e.timestamp >= timestamp)].timestamp
    //     : -1,
    // );

    return dateEntries.findLastIndex(e => e.timestamp <= timestamp);
  };

  const bottomMargin = props.renderMiniVersion ? 0 : 5;
  const height = props.renderMiniVersion ? MINI_RANGE_PICKER_HEIGHT : RANGE_PICKER_HEIGHT;
  const disableTraveller = props.disableTraveller || props.renderMiniVersion;

  return (
    <ResponsiveContainer width={props.width} height={height} className="brush" ref={ref}>
      <ComposedChart
        margin={{
          top: 0,
          right: 0,
          bottom: bottomMargin,
          left: 0,
        }}
        data={dateEntries}
        key={keyForChart}
      >
        <YAxis yAxisId="yAxis1" name="yAxis1" orientation="left" tick={null} width={props.leftPadding} />
        <YAxis yAxisId="yAxis2" name="yAxis2" orientation="right" tick={null} width={props.rightPadding} />
        <XAxis
          height={0}
          type="category"
          dataKey="timestamp"
          allowDuplicatedCategory={false}
          interval={0}
          name="day"
          tick={null}
          axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: GRAPH_AXIS_LINE_WIDTH }}
        />
        <MyBrush
          dataKey="timestamp"
          onChange={onChange}
          height={height - bottomMargin - 1}
          tickFormatter={t => (props.renderMiniVersion ? '' : moment(t * 1000).format(DATE_FORMAT_YEAR_MONTH_DAY))}
          startIndex={props.startTimestamp ? findFirstIndexOf(props.startTimestamp) : undefined}
          endIndex={props.endTimestamp ? findLastIndexOf(props.endTimestamp) : undefined}
          stroke={getColorsForColorTheme().mainColor}
          radius={(height - TRAVELLER_WIDTH * 2 - bottomMargin - 1) / 2}
          leaveTimeOut={2000}
          travellerWidth={TRAVELLER_WIDTH}
          traveller={
            disableTraveller ? (
              <></>
            ) : (
              <svg viewBox={`0 0 ${TRAVELLER_WIDTH} ${height}`}>
                <rect
                  width={TRAVELLER_WIDTH}
                  height={height}
                  fill={getColorsForColorTheme().mainColor}
                  stroke={getColorsForColorTheme().mainColor}
                  rx={TRAVELLER_WIDTH / 2}
                  ry={TRAVELLER_WIDTH / 2}
                />
                <circle
                  cx={TRAVELLER_WIDTH / 2}
                  cy={height / 2}
                  r={(TRAVELLER_WIDTH - 2) / 2}
                  fill="#fff"
                  fillOpacity="0.6"
                />
              </svg>
            )
          }
          maxSelectionTicks={props.maxSelectionTicks}
          minSelectionTicks={props.minSelectionTicks ? props.minSelectionTicks - 1 : 0}
          disableTraveller={disableTraveller}
          renderMiniVersion={props.renderMiniVersion}
        >
          {props.renderMiniVersion ? (
            <></>
          ) : (
            <LineChart>
              <XAxis
                dataKey="x"
                type="number"
                domain={[props.dateRange.start.unix(), props.dateRange.end.unix()]}
                hide={true}
              />
              <YAxis
                domain={['auto', 'auto']}
                hide={true}
                yAxisId="yAxis1"
                padding={{ top: TRAVELLER_WIDTH, bottom: TRAVELLER_WIDTH }}
              />
              {props.metric && drawLineSeries([props.metric], props.yAxisGroups, props.data, props.dateRange, false, true)}
              {props.tooltipX && (
                <ReferenceLine
                  x={props.tooltipX}
                  yAxisId="yAxis1"
                  stroke={getColorsForColorTheme().mainColor}
                  strokeWidth={2}
                />
              )}
            </LineChart>
          )}
        </MyBrush>
      </ComposedChart>
    </ResponsiveContainer>
  );
});

RangePicker.propTypes = {
  startTimestamp: PropTypes.number,
  endTimestamp: PropTypes.number,
  onChange: PropTypes.func,
  dataStartTimestamp: PropTypes.number,
  ticks: PropTypes.number,
  resolution: PropTypes.string,
  resolutionMulti: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  leftPadding: PropTypes.number,
  dateRange: PropTypes.shape({
    start: PropTypes.any,
    end: PropTypes.any,
  }),
  maxSelectionTicks: PropTypes.number,
  minSelectionTicks: PropTypes.number,
  disableTraveller: PropTypes.bool,
};

class MyBrush extends Brush {
  constructor(props) {
    super(props);
    this.handleSlideDrag = this.handleSlideDrag.bind(this);
    this.handleTravellerMove = this.handleTravellerMove.bind(this);
    this.renderBackground = this.renderBackground.bind(this);
    this.renderSlide = this.renderSlide.bind(this);
  }

  handleTravellerMove(e) {
    //console.log(this.props);
    //console.log(this.state);
    //console.log(e);

    const { brushMoveStartX, movingTravellerId, endX, startX } = this.state;
    const prevValue = this.state[movingTravellerId];

    const { x, width, travellerWidth, onChange, gap, data } = this.props;

    const params = { startX: this.state.startX, endX: this.state.endX };

    let delta = e.pageX - brushMoveStartX;
    if (delta > 0) {
      delta = Math.min(delta, x + width - travellerWidth - prevValue);
    } else if (delta < 0) {
      delta = Math.max(delta, x - prevValue);
    }

    const isStartMoving = movingTravellerId === 'startX';

    params[movingTravellerId] = prevValue + delta;
    const startEndSwitched = params.startX > params.endX;
    //console.log('SWITCH', startEndSwitched);

    const newIndex = this.getIndex(params);

    if (startEndSwitched) {
      //console.log('FIXING NEG. istartMoving: ', isStartMoving, this.props.minSelectionTicks);

      const tmp = newIndex.startIndex;
      newIndex.startIndex = newIndex.endIndex;
      newIndex.endIndex = tmp;

      let newX = brushMoveStartX;
      if (isStartMoving) {
        newIndex.startIndex = newIndex.endIndex - (this.props.minSelectionTicks ? this.props.minSelectionTicks : 0);
        newX = this.state.scale(newIndex.startIndex);
      }
      if (!isStartMoving) {
        newIndex.endIndex = newIndex.startIndex + (this.props.minSelectionTicks ? this.props.minSelectionTicks : 0);
        newX = this.state.scale(newIndex.endIndex);
      }

      this.setState(
        {
          //[movingTravellerId]: prevValue + delta,
          startX: isStartMoving ? newX : this.state.startX,
          endX: isStartMoving ? this.state.endX : newX,
        },
        () => {
          if (onChange) onChange(newIndex);
        },
      );

      return;
    }

    if (this.props.maxSelectionTicks) {
      const diff = newIndex.endIndex - newIndex.startIndex;
      if (diff > this.props.maxSelectionTicks || startEndSwitched) {
        let newX = brushMoveStartX;
        if (isStartMoving) {
          newIndex.startIndex = newIndex.endIndex - this.props.maxSelectionTicks;
          newX = this.state.scale(newIndex.startIndex);
        }
        if (!isStartMoving) {
          newIndex.endIndex = newIndex.startIndex + this.props.maxSelectionTicks;
          newX = this.state.scale(newIndex.endIndex);
        }

        this.setState(
          {
            [movingTravellerId]: prevValue + delta,
            startX: isStartMoving ? newX : this.state.startX,
            endX: isStartMoving ? this.state.endX : newX,
          },
          () => {
            if (onChange) onChange(newIndex);
          },
        );

        return;
      }
    }

    if (this.props.minSelectionTicks) {
      const diff = newIndex.endIndex - newIndex.startIndex;
      if (diff < this.props.minSelectionTicks || startEndSwitched) {
        //console.log('FIXING');

        let newX = brushMoveStartX;
        if (isStartMoving) {
          newIndex.startIndex = newIndex.endIndex - this.props.minSelectionTicks;
          newX = this.state.scale(newIndex.startIndex);
        }
        if (!isStartMoving) {
          newIndex.endIndex = newIndex.startIndex + this.props.minSelectionTicks;
          newX = this.state.scale(newIndex.endIndex);
        }

        this.setState(
          {
            [movingTravellerId]: prevValue + delta,
            startX: isStartMoving ? newX : this.state.startX,
            endX: isStartMoving ? this.state.endX : newX,
          },
          () => {
            if (onChange) onChange(newIndex);
          },
        );

        return;
      }
    }

    const { startIndex, endIndex } = newIndex;

    const isFullGap = () => {
      const lastIndex = data.length - 1;
      if (
        (movingTravellerId === 'startX' && (endX > startX ? startIndex % gap === 0 : endIndex % gap === 0)) ||
        (endX < startX && endIndex === lastIndex) ||
        (movingTravellerId === 'endX' && (endX > startX ? endIndex % gap === 0 : startIndex % gap === 0)) ||
        (endX > startX && endIndex === lastIndex)
      ) {
        return true;
      }
      return false;
    };

    this.setState(
      {
        [movingTravellerId]: prevValue + delta,
        brushMoveStartX: e.pageX,
      },
      () => {
        if (onChange) {
          if (isFullGap()) {
            onChange(newIndex);
          }
        }
      },
    );

    return;
  }

  handleSlideDrag(e) {
    const { slideMoveStartX, startX, endX } = this.state;
    const { x, width, travellerWidth, startIndex, endIndex, onChange } = this.props;

    // console.log('handleSlideDrag');
    // console.log(this.props);
    // console.log(this.state);
    let delta = e.pageX - slideMoveStartX;

    if (delta > 0) {
      delta = Math.min(delta, x + width - travellerWidth - endX, x + width - travellerWidth - startX);
    } else if (delta < 0) {
      delta = Math.max(delta, x - startX, x - endX);
    }

    const prevIndex = {
      startIndex: this.props.startIndex,
      endIndex: this.props.endIndex,
    };

    const newIndex = this.getIndex({
      startX: startX + delta,
      endX: endX + delta,
    });

    const prevLength = prevIndex.endIndex - prevIndex.startIndex;
    const newLength = newIndex.endIndex - newIndex.startIndex;

    if (prevLength !== newLength) {
      // console.log('DIFFERENT SLIDER LENGTH!!!!', prevLength, ' => ', newLength);
      // console.log(prevIndex, ' => ', newIndex);
      // console.log('FIXING...');
      const isGoingUp = newIndex.startIndex > prevIndex.startIndex || newIndex.endIndex > prevIndex.endIndex;
      if (isGoingUp) {
        newIndex.startIndex = newIndex.endIndex - prevLength;
      } else {
        newIndex.endIndex = newIndex.startIndex + prevLength;
      }
    }

    if ((newIndex.startIndex !== startIndex || newIndex.endIndex !== endIndex) && onChange) {
      onChange(newIndex);
    }

    this.setState({
      startX: startX + delta,
      endX: endX + delta,
      slideMoveStartX: e.pageX,
    });
  }

  renderBackground() {
    var _this$props5 = this.props,
      x = _this$props5.x,
      y = _this$props5.y,
      width = _this$props5.width,
      height = _this$props5.height,
      fill = _this$props5.fill,
      stroke = _this$props5.stroke;
    const miniVersion = this.props.renderMiniVersion;
    return (
      <g>
        <filter id="inset-shadow">
          <feComponentTransfer in="SourceAlpha">
            <feFuncA type="table" tableValues="1 0" />
          </feComponentTransfer>
          <feGaussianBlur stdDeviation="4" />
          <feOffset dx="0" dy="2" result="offsetblur" />
          <feFlood floodColor="rgb(0, 0, 0)" result="color" />
          <feComposite in2="offsetblur" operator="in" />
          <feComposite in2="SourceAlpha" operator="in" />
          <feMerge>
            <feMergeNode in="SourceGraphic" />
            <feMergeNode />
          </feMerge>
        </filter>
        {React.createElement('rect', {
          stroke: miniVersion ? 'none' : '#BABABA',
          fill: miniVersion ? 'none' : fill,
          x: x,
          y: miniVersion ? y : y + TRAVELLER_WIDTH,
          width: width,
          height: miniVersion ? height : height - TRAVELLER_WIDTH * 2,
          rx: this.props.radius,
          ry: this.props.radius,
          filter: miniVersion ? 'none' : 'url(#inset-shadow)',
        })}
      </g>
    );
  }

  renderSlide(startX, endX) {
    var _this$props8 = this.props,
      y = _this$props8.y,
      height = _this$props8.height,
      stroke = _this$props8.stroke,
      travellerWidth = _this$props8.travellerWidth;
    const miniVersion = this.props.renderMiniVersion;
    const sliderHeight = miniVersion ? height : height - TRAVELLER_WIDTH * 2;
    var x = miniVersion ? Math.min(startX, endX) : Math.min(startX, endX) + travellerWidth;
    var width = miniVersion ? Math.abs(endX - startX) : Math.max(Math.abs(endX - startX) - travellerWidth, 0);
    return React.createElement('rect', {
      className: 'recharts-brush-slide',
      onMouseEnter: this.handleEnterSlideOrTraveller,
      onMouseLeave: this.handleLeaveSlideOrTraveller,
      onMouseDown: this.handleSlideDragStart,
      onTouchStart: this.handleSlideDragStart,
      style: {
        cursor: 'move',
      },
      stroke: 'none',
      fill: stroke,
      fillOpacity: 0.2,
      x: x,
      y: miniVersion ? y : y + TRAVELLER_WIDTH,
      width: width,
      height: sliderHeight,
      rx: miniVersion ? MINI_RANGE_PICKER_HEIGHT / 2 : 0,
      ry: miniVersion ? MINI_RANGE_PICKER_HEIGHT / 2 : 0,
    });
  }
}
