import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import moment from 'moment-timezone';

import { actions } from '../../SuperUser/Report/redux/actions';
import { graphContainer } from './graphContainers';
import { ColorPalette } from '../DashboardColorPalettes';
import { getProgramDisplayName } from '../../../utils/cmsPrograms';
import GenericCategoryGraph, { chartTypeEnum } from '../../../components/Graphs/GenericCategoryGraph';
import Strings from '../../../Strings';

function ProgramDistribution(props) {
  const [data, setData] = React.useState();

  React.useEffect(() => {
    let request = {};
    request.start_time = moment()
      .subtract(30, 'days')
      .startOf('day')
      .format();
    request.end_time = moment()
      .subtract(1, 'days')
      .endOf('day')
      .format();
    props.getProgramStatistics(request).then(resp => {
      setData([
        {program: getProgramDisplayName('RTM', 'RTMRespiratory'), count: resp.response.RTM?.total_patients_per_subprogram?.RTMRespiratory || 0},
        {program: getProgramDisplayName('RTM', 'RTMMusculoskeletal'), count: resp.response.RTM?.total_patients_per_subprogram?.RTMMusculoskeletal || 0},
        {program: getProgramDisplayName('RTM', 'RTMBehavioralHealth'), count: resp.response.RTM?.total_patients_per_subprogram?.RTMBehavioralHealth || 0},
        {program: getProgramDisplayName('RPM'), count: resp.response.RPM?.total_patients_per_subprogram?.RPM || 0},
      ]);
    });
  }, [props.refreshTimestamp]);

  const dataSeries = [
    {
      name: 'Program',
      color: props.colorPalette?.interpolate(0.73),
      selector: item => item.count,
    },
  ];

  return graphContainer(
    <GenericCategoryGraph
      graphData={data}
      hasBorder={true}
      forceCondensed
      xAxisTopPosition={false}
      yAxisUnit=""
      tooltipTitle={Strings.program}
      dataSeries={dataSeries}
      categorySelector={item => item.program}
      hasReferenceAreas={true}
      graphType={props.graphType}
      colorPalette={props.colorPalette}
      yAxisWidth={props.graphType === chartTypeEnum.horizontalBar ? 150 : 40}
    />,
    'Enrollments',
  );
}

ProgramDistribution.widgetId = 'ProgramDistributionWidget';

ProgramDistribution.propTypes = {
  getProgramStatistics: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    colorPalette:
      ColorPalette[state.dashboardState.configuration?.allDashboards?.colorPalette] || Object.values(ColorPalette)[0],
  };
};


const mapDispatchToProps = dispatch => ({
  getProgramStatistics: request => dispatch(actions.getProgramStatistics(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramDistribution);
