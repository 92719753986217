import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import { Buffer } from "buffer";

import { closeModal, openModal, openModalAction } from '../../../actions/modal';
import { CardWrapper } from '../../../components/CardWrapper/CardWrapper';
import { PageHeader } from '../../../components/PageHeader';
import EmptyModal from '../../../modals/EmptyModal/EmptyModal';
import Strings from '../../../Strings';
import blueonboarding from '../../../video/blue-onboarding.mp4';
import RxCapSmartCapInstructionsFinal from '../../../video/RxCapSmartCapInstructionsFinal.mp4';
import styles from './Support.module.scss';
import TabView, { Tab } from '../../../components/TabView';
import { actions } from './redux/actions';
import Location from './Location';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import { downloadFromURL, downloadJson } from '../../../utils';
import { PERMISSIONS, hasPermission } from '../../../utils/userPermissions';
import { roundToDecimal } from '../../../utils/unitConverters';
import { FileMimeType } from '../../../utils/mimeTypes';
import { GET_GMAPS_API_KEY_RESULT, GET_SPEC_RESULT, servicesActions } from '../../../actions/services';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../../components/PageHeader/Button';

const mapStateToProps = state => ({
  support: state.support,
  organization: state.auth.organization,
  eula: state.auth.eula,
});

const mapDispatchToProps = dispatch => ({
  openHelpModal: () => dispatch(openModal('technical-support-modal')),
  openEmptyModal: data => dispatch(openModalAction(EmptyModal.MODAL_NAME, data)),
  closeEmptyModal: () => dispatch(closeModal(EmptyModal.MODAL_NAME)),
  getConfig: id => dispatch(actions.getConfig(id)),
  getGoogleMapsApiKey: () => dispatch(servicesActions.getGoogleMapsApiKey()),
  getSpec: () => dispatch(servicesActions.getExternalSpec()),
});

const bluetutorial =
  'https://storage.googleapis.com/static.rxcap.com/videos/RPM/RxCap%20-%20Web%20Portal%20Tutorial%20-%20Final%20-%20parts%201%20to%204.mp4';
const blueenrollment =
  'https://storage.googleapis.com/static.rxcap.com/videos/RPM/RxCap%20Web%20Portal%20-%20Part%202%20-%20Enrollment.mp4';
const bluebilling =
  'https://storage.googleapis.com/static.rxcap.com/videos/RPM/RxCap%20Web%20Portal%20-%20Add%20Medications.mp4';
const bluemonitoring =
  'https://storage.googleapis.com/static.rxcap.com/videos/RPM/RxCap%20Web%20Portal%20-%20Medication%20Monitoring.mp4';

function Support(props) {
  const [helpFiles, setHelpFiles] = React.useState([]);
  const supportLinkRef = React.createRef();
  const [googleMapsApiKey, setGoogleMapsApiKey] = React.useState(null);
  const [spec, setSpec] = React.useState(null);

  window.Buffer = window.Buffer || Buffer

  React.useEffect(() => {
    supportLinkRef && supportLinkRef?.current?.focus();
    props.getConfig(props.organization?.masked_id);
    axios
      .get('https://storage.googleapis.com/storage/v1/b/static.rxcap.com/o?prefix=docs%2FSupport%2FRTM%2FWhiteLabel')
      .then(response => {
        setHelpFiles(response?.data?.items.filter(f => f.size > 0));
      });

    props.getGoogleMapsApiKey().then(r => {
      if (r.type === GET_GMAPS_API_KEY_RESULT) {
        setGoogleMapsApiKey(r.response.apiKey)
      }
    })

    props.getSpec().then(r => {
      if (r.type === GET_SPEC_RESULT) {
        setSpec(JSON.parse(r.response.schema))
      }
    }
    )
  }, []);

  const onDocSelected = id => {
    window.open(`https://storage.googleapis.com/static.rxcap.com/${helpFiles[id].name}`);
  };

  const { openHelpModal, support } = props;
  const flags = support?.data?.support?.flags;
  const sections = support?.data?.support?.sections;

  const { activeTab } = props;
  const tabArray = [];
  const location = {
    lat: +sections?.location?.coordinates?.split(', ')[0],
    lng: +sections?.location?.coordinates?.split(', ')[1],
  };

  const contactUs = (
    <Tab name={Strings.contactUs} key={Strings.contactUs} path="/support/contact-us">
      <div className={styles.wrapper}>
        {flags && flags?.email && (
          <CardWrapper>
            <React.Fragment>
              <div className={styles.title}>{Strings.emailUs}</div>
              <div className={styles.text}>
                {Strings.emailText}
                <br></br>
                <br></br>
                <a href={`mailto:${sections.email?.emailAddress}?subject=${sections.email?.emailSubject}`}>
                  &nbsp;{sections.email?.emailAddress}
                </a>
              </div>
            </React.Fragment>
          </CardWrapper>
        )}

        {flags && flags?.chat && (
          <CardWrapper>
            <React.Fragment>
              <div className={styles.title}>{Strings.callUs}</div>
              <div className={styles.text}>
                {Strings.chatText}

                <br></br>
                <br></br>
                <a href={'tel:' + sections.chat?.phone}>
                  <span className={styles.phone}>{sections.chat?.phone}</span>
                </a>
              </div>
            </React.Fragment>
          </CardWrapper>
        )}

        {flags && flags?.location && (
          <CardWrapper>
            <React.Fragment>
              <div className={styles.title}>{sections.location?.title}</div>

              <div className={styles.mapParent}>
                <div className={styles.text}>
                  {sections.location?.description}
                  <br></br>
                  <br></br>
                  <a target="_blank" href={sections.location?.map}>
                    <span className={styles.phone}>{sections.location?.location}</span>
                  </a>
                </div>
                {sections?.location?.coordinates && googleMapsApiKey && <Location center={location} googleMapsApiKey={googleMapsApiKey}></Location>}
              </div>
            </React.Fragment>
          </CardWrapper>
        )}

        {flags && flags?.help_center && (
          <CardWrapper>
            <React.Fragment>
              <div className={styles.title}>{Strings.helpCenter}</div>
              <div className={styles.text}>{Strings.helpCenterText}</div>
              <a className={styles.button} onClick={openHelpModal} tabIndex={0}>
                {Strings.helpCenter}
              </a>
            </React.Fragment>
          </CardWrapper>
        )}
      </div>
    </Tab>
  );

  const actionBtn = (url, subject) => (
    <div className={styles.actionBox}>
      <button
        className={styles.btn}
        onClick={() => {
          navigator.clipboard.writeText(url);
        }}
      >
        <i className={styles.urlCopy}></i>
      </button>
      <button
        className={styles.btn}
        onClick={e => {
          window.location.href = `mailTo:?subject=${subject}&body=${encodeURIComponent(url)}`;
          e.preventDefault();
        }}
      >
        <i className={styles.urlMessage}></i>
      </button>
      <button
        className={styles.btn}
        onClick={() => {
          downloadFromURL(url);
        }}
      >
        <i className={styles.urlDownload}></i>
      </button>
    </div>
  );

  const videos = (
    <Tab name={Strings.videos} key={Strings.videos} path="/support/videos">
      {flags && flags?.rpm_video && (
        <React.Fragment>
          <div className={styles.wrapper}>
            <div className={styles.vidContainer}>
              <React.Fragment>
                <div className={styles.title}>
                  <div className={styles.header}>{Strings.gettingStart}</div>
                  {actionBtn(bluetutorial, Strings.gettingStart)}
                </div>
                <video className={styles.videoModalTutorial} src={bluetutorial} controls />
                <div className={styles.description}>{Strings.gettingStartDesc}</div>
              </React.Fragment>
            </div>
            <div className={styles.vidContainer}>
              <React.Fragment>
                <div className={styles.title}>
                  <div className={styles.header}>{Strings.onboarding}</div>
                  {actionBtn(blueonboarding, Strings.onboarding)}
                </div>

                <video className={styles.videoModalTutorial} src={blueonboarding} controls />
                <div className={styles.description}>{Strings.onboardingDesc}</div>
              </React.Fragment>
            </div>
            <div className={styles.vidContainer}>
              <React.Fragment>
                <div className={styles.title}>
                  <div className={styles.header}>{Strings.enrollment}</div>
                  {actionBtn(blueenrollment, Strings.onboardingDesc)}
                </div>
                <video className={styles.videoModalTutorial} src={blueenrollment} controls />
                <div className={styles.description}>{Strings.enrollementDesc}</div>
              </React.Fragment>
            </div>
            <div className={styles.vidContainer}>
              <React.Fragment>
                <div className={styles.title}>
                  <div className={styles.header}>{Strings.monitoring}</div>
                  {actionBtn(bluemonitoring, Strings.monitoring)}
                </div>
                <video className={styles.videoModalTutorial} src={bluemonitoring} controls />
                <div className={styles.description}>{Strings.enrollementDesc}</div>
              </React.Fragment>
            </div>
            <div className={styles.vidContainer}>
              <React.Fragment>
                <div className={styles.title}>
                  <div className={styles.header}>{Strings.billing.billing}</div>
                  {actionBtn(bluebilling, Strings.billing.billing)}
                </div>
                <video className={styles.videoModalTutorial} src={bluebilling} controls />
                <div className={styles.description}>{Strings.enrollementDesc}</div>
              </React.Fragment>
            </div>
            <div className={styles.vidContainer}>
              <React.Fragment>
                <div className={styles.title}>
                  <div className={styles.header}>{Strings.SmartCapRTM}</div>
                  {actionBtn(RxCapSmartCapInstructionsFinal, Strings.SmartCapRTM)}
                </div>

                <div>
                  <center>
                    <video className={styles.potraitVideo} src={RxCapSmartCapInstructionsFinal} controls />
                  </center>
                  <div className={styles.description}>{Strings.enrollementDesc}</div>
                </div>
              </React.Fragment>
            </div>
          </div>
        </React.Fragment>
      )}
    </Tab>
  );

  const columns = [
    <Column key="descriptions" title={Strings.name} value={e => e.metadata?.Description || '-'} />,
    <Column key="type" title={Strings.type} value={e => FileMimeType[e.contentType] || '-'} />,
    <Column key="size" title={Strings.size} value={e => (e.size ? `${roundToDecimal(e.size / 1024, 0)}KB` : '-')} />,
  ];

  const documents = (
    <Tab name={Strings.documents} key={Strings.documents} path="/support/documents">
      {flags && flags?.rpm_video && (
        <div className={styles.mt30}>
          <Table name="documents" isLoading={false} data={helpFiles} onRowSelection={onDocSelected}>
            {columns}
          </Table>
        </div>
      )}
    </Tab>
  );

  if (
    flags &&
    (flags?.email || flags?.chat || flags?.help_center) &&
    (hasPermission(PERMISSIONS.SUPPORT_EMAIL) ||
      hasPermission(PERMISSIONS.SUPPORT_CHAT) ||
      hasPermission(PERMISSIONS.SUPPORT_HELP_CENTER))
  ) {
    tabArray.push(contactUs);
  }

  if (flags && flags?.rpm_video && hasPermission(PERMISSIONS.SUPPORT_RPM_VIDEO)) {
    tabArray.push(videos);
  }

  if (hasPermission(PERMISSIONS.SUPPORT_DOCUMENTS)) {
    tabArray.push(documents);
  }

  if (props.eula?.eula_content) {
    tabArray.push(
      <Tab name={Strings.endUserLicenseAgreement} key={Strings.endUserLicenseAgreement} path="/support/eula">
        <div className={styles.eulaAndTC}>{props.eula.eula_content}</div>
      </Tab>,
    );
  }
  if (props.eula?.tc_content) {
    tabArray.push(
      <Tab name={Strings.termsAndConditions} key={Strings.termsAndConditions} path="/support/terms">
        <div className={styles.eulaAndTC}>{props.eula.tc_content}</div>
      </Tab>,
    );
  }

  if(hasPermission(PERMISSIONS.SUPPORT_EXTERNAL_SPEC)) {
    tabArray.push(
      <Tab name={Strings.spec} key="spec" path="/support/spec">
        {spec && (
          <>
            <PageHeader right={<Button class={HEADER_BUTTON_DARK_BLUE} title={Strings.download} onClick={() => downloadJson(spec, "rxcap_external_swagger")} />} />
            <SwaggerUI spec={spec} defaultModelsExpandDepth="-1" />
          </>
        )}
      </Tab>,
    );
  }

  const tab = activeTab || (tabArray.length > 0 ? tabArray[0].props.name : null);

  return (
    <>
      <PageHeader noLeftPadding left={Strings.supportHeader} />

      <TabView key="tabs" activeTab={tab} routeMode className="" noSwiping>
        {tabArray}
      </TabView>
    </>
  );
}

Support.propTypes = {
  closeEmptyModal: PropTypes.func,
  getConfig: PropTypes.func,
  openEmptyModal: PropTypes.func,
  openHelpModal: PropTypes.func,
  support: PropTypes.shape({
    flags: PropTypes.shape({
      chat: PropTypes.any,
      email: PropTypes.any,
      help_center: PropTypes.any,
      smart_cap: PropTypes.any,
    }),
    sections: PropTypes.shape({
      chat: PropTypes.shape({
        description: PropTypes.any,
        phone: PropTypes.any,
        title: PropTypes.any,
      }),
      email: PropTypes.shape({
        description: PropTypes.any,
        emailAddress: PropTypes.any,
        emailSubject: PropTypes.any,
        title: PropTypes.any,
      }),
      help_center: PropTypes.shape({
        description: PropTypes.any,
        title: PropTypes.any,
      }),
      smart_cap: PropTypes.shape({ title: PropTypes.any }),
    }),
  }),
  eula: PropTypes.any,
  getGoogleMapsApiKey: PropTypes.func.isRequired,
  getSpec: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Support);
