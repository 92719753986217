import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment-timezone';

import Strings from '../../../Strings';
import { PageHeader } from '../../../components/PageHeader';
import Select from '../../../components/Select';
import TableWidget from './component/TableWidget';
import { DATE_MONTH_DAY_YEAR_WITH_DASH } from '../../../constants';

export default function ProviderVisitSummary({enrollments}) {
  const [selectedEnrollment, setSelectedEnrollment] = React.useState({...enrollments?.[0], value: enrollments?.[0]?.enrollment_id});

  if (!enrollments || !enrollments.length) {
    return <div>{Strings.noData}</div>;
  }

  return (
    <>
      {enrollments?.length > 1 ? (
        <PageHeader
          right={(
            <Select
              data={enrollments.map((e, i) => ({
                value: e.unguessable_id,
                label: `(${i+1}) ${moment(e.enrollment_status.timestamp).format(DATE_MONTH_DAY_YEAR_WITH_DASH)}`,
                 ...e
              }))}
              value={selectedEnrollment.value}
              onChange={setSelectedEnrollment}
            />
          )}
        />
      ) : <div style={{height: 20, minHeight: 20}} />}
      {selectedEnrollment && (
        <div className="list-table">
          <div className="list-table-border">
            <TableWidget
              title={Strings.providerVisitSummary}
              data={[
                [Strings.status, selectedEnrollment.enrollment_status.status],
                [Strings.enrollmentCondition, selectedEnrollment.enrollment_status.enrollment_condition],
                [Strings.medication, selectedEnrollment.enrollment_status.enrollment_medication],
                [Strings.program, selectedEnrollment.enrollment_status.enrollment_program],
                [Strings.date, moment(selectedEnrollment.enrollment_status.timestamp).format(DATE_MONTH_DAY_YEAR_WITH_DASH)],
              ]}
            />
          </div>
          <div className="list-table-border with-wrapping">
            <TableWidget
              title={Strings.answers}
              data={selectedEnrollment.enrollment_status.visit.answers.map(answer => {
                return [answer.question, answer.answer];
              })}
            />
          </div>
        </div>
      )}
    </>
  );
}

ProviderVisitSummary.propTypes = {
  enrollments: PropTypes.arrayOf(PropTypes.object).isRequired,
};