import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { openModalAction } from '../../../actions/modal';
import { PageHeader } from '../../../components/PageHeader';
import { Button } from '../../../components/PageHeader/Button';
import { TextInput } from '../../../components/PageHeader/TextInput';
import { HEADER_BUTTON_BLUE } from '../../../constants';
import { Column } from '../../../containers/Table/TableWithPagination';
import Strings from '../../../Strings';
import { actions } from '../redux/actions';
import TableWithLocalPagination from '../../../containers/Table/TableWithLocalPagination';
import { lastActivityDisplay, toolTipForTable } from '../../../utils';

export const orgGroupsUrl = '/org-groups';

function OrganizationGroups(props) {
  const { getOrganizationGroups, editOrganizationGroup, onNavigate, openConfirmModal } = props;
  const pageRequest = React.useRef({});
  const [groups, setGroups] = React.useState([]);

  const loadGroups = () => {
    getOrganizationGroups(pageRequest.current).then(resp => {
      setGroups(resp?.response || []);
    });
  };

  React.useEffect(() => {
    loadGroups();
  }, []);

  const onSearchQueryChange = query => {
    pageRequest.current.search = query;
    setTimeout(() => {
      loadGroups();
    }, 1000);
  };

  const getHeaderComponents = () => {
    const { addOrganizationGroup } = props;
    return (
      <>
        <TextInput class="search" placeholder={Strings.search} onChange={e => onSearchQueryChange(e.target.value)} />
        <Button class={HEADER_BUTTON_BLUE} onClick={() => addOrganizationGroup(loadGroups)} title={Strings.addOrganizationGroup} />
      </>
    );
  };

  const onGroupSelected = (_, group) => {
    if (group?.id) {
      onNavigate(`${orgGroupsUrl}/${group.id}/organizations`);
    }
  };

  const onOrgGrpEdit = (_, group) => {
    editOrganizationGroup({ group, onSuccess: loadGroups });
  };

  const onOrgGrpDelete = (_, group) => {
    const data = {
      title: (
        <span>
          {Strings.deleteOrgGrpWarning} 
          <b>{group.name}</b>
          ?
        </span>
      ),

      onConfirmAction: actions.deleteOrganizationGroup(group.id),
      onCancelAction: null,
      confirmPostAction: loadGroups,
    };
    openConfirmModal(data);
  };

  const buttons = [
    {
      icon: 'edit',
      onClick: onOrgGrpEdit,
      disabled: (_, group) => {
        return group.role !== 'owner' && group.role !== 'maintainer';
      },
      text: Strings.edit,
      disabledTooltip: Strings.youDontHaveRightsToEditGroup,
    },
    {
      icon: 'delete',
      onClick: onOrgGrpDelete,
      disabled: (_, group) => {
        return group.role !== 'owner' && group.role !== 'maintainer';
      },
      text: Strings.delete,
      disabledTooltip: Strings.youDontHaveRightsToDeleteGroup,
    },
  ];

  return (
    <div>
      <PageHeader right={getHeaderComponents()} left={Strings.organizationGroups} />
      <TableWithLocalPagination
        name="organization-groups"
        data={groups || []}
        onRowSelection={onGroupSelected}
        buttons={buttons}
        infiniteScroll
        columns={[
          <Column key="id" title={Strings.id} value={d => d.id} />,
          <Column key="name" title={Strings.name} value={d => d.name} />,
          <Column key="created_at" title={Strings.createdAt} value={d => lastActivityDisplay(d.created_at)} />,
          <Column key="created_by" title={Strings.createdBy} value={d => d.created_by} />,
          <Column key="role" title={Strings.role} value={d => d.role} />,
          <Column key="organizations" title={Strings.organizations} value={d => (d.organizations ? toolTipForTable(d.organizations.length, d.organizations.map(o => o.name)?.join(", ")) : null)} />,
          <Column key="maintainers" title={Strings.maintainers} value={d => (d.maintainers ? toolTipForTable(d.maintainers.length, d.maintainers.map(o => o.name)?.join(", ")) : null)} />,
        ]}
      />
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  getOrganizationGroups: pageRequest => dispatch(actions.getOrganizationGroups(pageRequest)),
  addOrganizationGroup: onSuccess => dispatch(openModalAction('edit-organization-group', { onSuccess })),
  editOrganizationGroup: data => dispatch(openModalAction('edit-organization-group', data)),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
});

OrganizationGroups.propTypes = {
  getOrganizationGroups: PropTypes.func.isRequired,
  addOrganizationGroup: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  editOrganizationGroup: PropTypes.func.isRequired,
  openConfirmModal: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(OrganizationGroups);
