import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { SelectField, AsyncSelectField, TextArea } from '../../containers/Form';
import Strings from '../../Strings';
import { actions } from '../../pages/SuperUser/Patients/Cohorts/redux/actions';
import { flatProgramsSubprogramsList } from '../../utils/cmsPrograms';
import { getLabelTextForCondition } from './MedicalHistoryPage';

function ProgramPage(props) {
  const {
    enrollment,
    setEnrollment,
    patient,
    setPatient,
    validationErrors,
    highlightInvalidFields,
    fields,
    previousNotes,
    enrollmentNote,
    setEnrollmentNote,
    linkedOrgs,
    careProviders,
  } = props;

  React.useEffect(() => {
    const cohortsRequest = { limit: 1000 };
    if (patient.facilityId) {
      cohortsRequest.facilityId = patient.facilityId;
    } else {
      cohortsRequest.facilityId = '';
    }
    if (patient.conditionsObjects?.length > 0) {
      cohortsRequest.conditionId = patient.conditionsObjects.map(c => c.id).join(',');
    }
    props.getCohorts(cohortsRequest);
  }, [patient?.facilityId, patient.conditionsObjects]);

  const onConsentChange = option => {
    setEnrollment(e => ({
      ...e,
      verbalConsent: option.value === 'verbal',
      writtenConsent: option.value === 'written',
    }));
  };

  const onOrgSelected = option => {
    setEnrollment(e => ({...e, care_provider_org_uuid: option.value}));
    setEnrollment(e => ({...e, care_provider: null}));
  };

  const onMedSelected = option => {
    setEnrollment(e => ({...e, medication: { name: option.name, ndc: option.ndc }}));
  };

  const loadOptionsConditions = _.debounce((value, callback) => {
    if (value?.length < 3) {
      return;
    }
    setTimeout(() => {
      props.getIdcConditions({ search: value }).then(resp => {
        callback(() => {
          if (!resp.response.data || resp.response.data.length === 0) {
            return [];
          }
          const idcConditions = resp.response.data.map(e => {
            return {
              ...e,
              value: e.code,
              label: e.title,
            };
          });
          const orgConditions = props.conditions.filter(c => c.title.includes(value) || c.condition_icd10cm?.includes(value)).map(c => ({ ...c, value: c.id, label: c.title })) || [];
          return orgConditions.concat(idcConditions.filter(d => !props.conditions.some(p => p.condition_icd10cm === d.code)));
        });
      });
    }, 1000);
  }, 1000);

  const careProviderOrgs = _.uniq(careProviders.map(c => c.organization));
  const programPage = (
    <>
      {props.conditions && props.conditions.length > 0 && (
        <AsyncSelectField
          name="conditionId"
          id="conditionId"
          placeholder={Strings.select}
          label={Strings.condition}
          value={enrollment.conditionObject || ''}
          onChange={option => setEnrollment(e => ({...e, conditionId: option.value, conditionObject: option}))}
          loadOptions={loadOptionsConditions}
          defaultOptions={props.conditions.map(c => ({ ...c, value: c.id, label: c.title })).concat(enrollment?.conditionObject?.code ? [enrollment?.conditionObject] : [])}
          getLabel={getLabelTextForCondition}
          isRequired={fields.conditionId.required}
          disabled={fields.conditionId.readOnly}
          errorsForTooltip={validationErrors.find(v => v.property === 'conditionId')?.errors || []}
          highlightInvalid={highlightInvalidFields}
        />
      )}
      <SelectField
        name="subprogram"
        id="subprogram"
        value={enrollment.subprogram}
        data={flatProgramsSubprogramsList.map(l => ({ ...l, value: l.subprogram, label: l.displayText }))}
        onChange={option => setEnrollment(e => ({...e, subprogram: option.value, default_program: true}))}
        placeholder={Strings.select}
        label={Strings.selectProgram}
        errorsForTooltip={validationErrors.find(v => v.property === 'subprogram')?.errors || []}
        highlightInvalid={highlightInvalidFields}
        isRequired={fields.subprogram.required}
        disabled={fields.subprogram.readOnly}
      />
      {careProviders?.length > 0 && (
        <>
          <SelectField
            name="organization"
            id="organization"
            label={`${Strings.capPatient.careProvider} ${Strings.organization}`}
            value={enrollment.care_provider_org_uuid}
            onChange={onOrgSelected}
            data={linkedOrgs}
            isRequired={fields.care_provider.required}
            disabled={fields.care_provider.readOnly}
            highlightInvalid={highlightInvalidFields}
          />
          <SelectField
            name="care_provider"
            id="care_provider"
            label={Strings.capPatient.careProvider}
            value={enrollment.care_provider}
            onChange={option => setEnrollment(e => ({...e, care_provider: option.value}))}
            placeholder={Strings.select}
            data={careProviders.filter(c => enrollment.care_provider_org_uuid ? c.org_id === enrollment.care_provider_org_uuid : c.value === 'none')}
            isRequired={fields.care_provider.required}
            disabled={fields.care_provider.readOnly}
            errorsForTooltip={validationErrors.find(v => v.property === 'care_provider')?.errors || []}
            highlightInvalid={highlightInvalidFields}
          />
        </>
      )}
      {patient.medicationsWithExtraData?.length > 0 && (
        <SelectField
          name="medication"
          id="medication"
          label={Strings.medication}
          value={enrollment.medication?.name}
          onChange={onMedSelected}
          placeholder={Strings.select}
          data={patient.medicationsWithExtraData.map(m => ({ ...m, value: m.name, label: m.name }))}
          isRequired={fields.medication.required}
          disabled={fields.medication.readOnly}
          errorsForTooltip={validationErrors.find(v => v.property === 'medication')?.errors || []}
          highlightInvalid={highlightInvalidFields}
        />
      )}
      <SelectField
        name="enrollmentConsents"
        id="enrollmentConsents"
        label={Strings.enrollmentConsent}
        value={enrollment.verbalConsent ? 'verbal' : enrollment.writtenConsent ? 'written' : enrollment.verbalConsent === false && enrollment.writtenConsent === false ? false : undefined}
        onChange={onConsentChange}
        placeholder={Strings.noOptionSelected}
        data={[
          { value: 'verbal', label: Strings.verbalConsentGiven },
          { value: 'written', label: Strings.writtenConsentGiven },
          { value: false, label: Strings.capPatient.patientNotConsented },
        ]}
        errorsForTooltip={validationErrors.find(v => ['enrollmentConsents'].includes(v.property))?.errors || []}
        highlightInvalid={highlightInvalidFields}
        isRequired={fields.enrollmentConsents.required}
        disabled={fields.enrollmentConsents.readOnly}
        rowClassName="double-width-left"
      />
      {enrollment?.subprogram && props.cohorts?.length > 0 && (
        <SelectField
          name="cohort"
          id="cohort"
          label={Strings.capPatient.assignCohort}
          value={patient.cohortId}
          onChange={option => setPatient(p => ({...p, cohortId: option.value}))}
          placeholder={Strings.select}
          data={props.cohorts.map(c => ({ value: c.id, label: c.title }))}
        />
      )}
      <TextArea
        name="previousNotes"
        id="previousNotes"
        rows={4}
        value={previousNotes}
        label={Strings.placeholder.notesArea}
        disabled
        rowClassName="double-width-left"
      />
      <TextArea
        name="enrollmentNote"
        id="enrollmentNote"
        rows={4}
        value={enrollmentNote}
        label={Strings.addNote}
        onChange={e => setEnrollmentNote(e.target.value)}
        rowClassName="double-width-right"
      />
    </>
  );

  return programPage;
};

const mapStateToProps = state => {
  return {
    cohorts: state.superUser?.cohorts?.cohorts,
  };
};

const mapDispatchToProps = dispatch => ({
  getIdcConditions: pageRequest => dispatch(actions.getIdcConditions(pageRequest)),
  getCohorts: pageRequest => dispatch(actions.getCohorts(pageRequest)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramPage);
