import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { now } from 'moment';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Wrapper, Button, Menu, MenuItem } from 'react-aria-menubutton';
import moment from 'moment-timezone';
import { isBrowser } from 'react-device-detect';

import TabView, { Tab } from '../../../components/TabView';
import Strings from '../../../Strings';
import CloudDocuments from '../../CloudDocuments/CloudDocuments';
import BoxDocuments from '../../CloudDocuments/BoxDocuments';
import PatientDevices from './PatientDevices';
import PatientEvent from './PatientEvent';
import PatientInfo, { DASHBOARD_ID as INFO_DASHBOAD_ID } from './PatientInfo/PatientInfo';
import PatientMedications, { PATIENT_MEDICATION_DASHBOARD_ID_PREFIX } from './PatientMedications/PatientMedications';
import { actions } from './redux/actions';
import { patientAction } from '../../../actions/patient';
import { actions as kitActions } from '../../Kits/redux/actions';
import TaskHistory from './TaskHistory';
import { closeModal, openModalAction } from '../../../actions/modal';
import {
  DOWNLOAD_KIT_DATA_RESULT,
  DOWNLOAD_NOTES_AND_ACTIONS_RESULT,
  PATIENT_DASHBOARD_INFO_RESULT,
  PATIENT_LIST_FOR_SUGGESTION,
} from './redux/constants';
import {
  DATE_FORMAT_YEAR_MONTH_DAY,
  DATE_MONTH_DAY_YEAR,
  RPM_PATIENT_STATUS,
  RPM_PORTAL_ACCESS_STATUS,
} from '../../../constants';
import DashboardFilter from '../../Dashboards/DashboardFilter';
import { Button as RefreshButton } from '../../../components/PageHeader/Button';
import { downloadFileOnly } from '../../../utils';
import MessageDetails from '../Messages/MessageDetails';
import { UPDATE_PATIENT_SCHEDULE } from '../../../actions/action-types';
import { getProgramsFromPatient } from '../../../utils/cmsPrograms';
import HubDeviceEvents from '../DeviceManagement/HubDeviceEvents';
import ShipperCapsDetails from '../../ShipperCapsDetails';
import PatientDashboard, { PATIENT_DASHBOARD_ID_PREFIX, getReadingsWidgetMetadata } from './Dashboard/PatientDashboard';
import { PERMISSIONS, PERMISSION_GROUPS, hasAnyPermissionInGroup, hasPermission } from '../../../utils/userPermissions';
import { sendRestPasswordMessage } from '../../../actions/auth';
import DashboardEditModeButton from '../../Dashboards/DashboardEditModeButton';
import PatientDevicesHistory from './PatientDevicesHistory';
import { dexcomActions } from './redux/dexcomActions';
import { getEnrollTextForPatient, isEnrollmentReadyToStart } from './PatientList';
import { DeviceClass, DeviceTypes } from '../../../utils/deviceTypes';
import { OnboardStages } from '../../../modals/OnboardPatientWizard/OnboardPatientWizard';
import PatientImportData from './PatientImportData';
import ProviderVisitSummary from './ProviderVisitSummary';

function PatientDetails(props) {
  const patientId = props.match.params.patientId || props.userId;
  const [patientsRefreshTimestamp, setPatientsRefreshTimestamp] = useState(now());
  const [refreshEnable, setRefreshEnable] = useState(true);
  const [patientDevices, setPatientDevices] = useState([]);
  const [providerVisitSummary, setProviderVisitSummary] = useState();

  useEffect(() => {
    getPatientData();
    return () => props.clearPatientInfo();
  }, [patientId]);

  const getPatientData = () => {
    props.getTaskList();
    props.getPatientInfo(patientId);
    props.getPatientKitInfo(patientId);
    props.getPatientDashboardInfo(patientId).then(resp => {
      if (resp.type === PATIENT_DASHBOARD_INFO_RESULT) {
        const deviceCount = resp.response.devices;
        const pageRequest = {
          offset: 0,
          search: '',
          limit: deviceCount,
        };
        props.getDevices(pageRequest, patientId).then(response => {
          setPatientDevices(response.response?.data?.map(d => d.deviceType));
        });
      }
    });
    props.getProviderVisitSummary(patientId).then(resp => {
      setProviderVisitSummary(resp.response.enrollments);
    });
  };

  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);

  const { taskList, patientDetails, isPatientMedicationsLoading, patientMedications } = props;

  useEffect(() => {
    if (
      getProgramsFromPatient(patientDetails).length > 0 &&
      taskList?.length > 0 &&
      !props.timer_off &&
      hasPermission(PERMISSIONS.PATIENTS_RPM_TASK_TIMER)
    ) {
      props.openTimer({
        taskList,
        patientId,
        programs: getProgramsFromPatient(patientDetails),
      });
      return () => props.closeTimer();
    }
  }, [patientDetails, taskList]);

  //#region tabs
  const pathRoot = props.role === 'patient' ? '/rpm-patient' : `/cap-patients/${patientId}`;
  const deviceClass = DeviceTypes[decodeURIComponent(props.match?.params?.deviceType)]?.deviceClass;
  const tabArray = [];
  if (hasAnyPermissionInGroup(PERMISSION_GROUPS.DASHBOARD_RPM_PATIENT)) {
    tabArray.push(
      <Tab name="Dashboard" path={`${pathRoot}/dashboard`} className="patient-tab-page" key="dashboard">
        <PatientDashboard
          patientId={patientId}
          refreshTimestamp={patientsRefreshTimestamp}
          patientDevices={patientDevices}
          patientDetails={patientDetails}
          refresh={() => onRefresh()}
        />
      </Tab>,
    );
  }
  if (hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_INFO)) {
    tabArray.push(
      <Tab name="Info" path={`${pathRoot}/info`} key="info">
        <PatientInfo
          patientId={patientId}
          patientDetails={patientDetails}
          refreshTimestamp={patientsRefreshTimestamp}
        />
      </Tab>,
    );
  }
  if (patientDetails?.rawPatientImportData?.length > 0) {
    tabArray.push(
      <Tab name={Strings.importData} path={`${pathRoot}/import-data`} key="import-data">
        <PatientImportData importData={patientDetails.rawPatientImportData} />
      </Tab>,
    );
  }
  if (providerVisitSummary?.length > 0) {
    tabArray.push(
      <Tab name={Strings.providerVisitSummary} path={`${pathRoot}/provider-visit-summary`} key="provider-visit-summary">
        <ProviderVisitSummary enrollments={providerVisitSummary} />
      </Tab>,
    );
  }
  if (hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_MEDICATIONS)) {
    tabArray.push(
      <Tab name="Medications" path={`${pathRoot}/medications`} key="medications">
        <PatientMedications
          patientId={patientId}
          patientDetails={patientDetails}
          refreshTimestamp={patientsRefreshTimestamp}
        />
      </Tab>,
    );
  }
  if (hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_EVENTS)) {
    tabArray.push(
      <Tab
        name={Strings.readings}
        patientId={patientId}
        patientDetails={patientDetails}
        path={`${pathRoot}/readings`}
        key="readings"
      >
        <PatientEvent patientId={patientId} patientDetails={patientDetails} />
      </Tab>,
    );
  }
  if (hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_TASK_HISTORY)) {
    tabArray.push(
      <Tab name="Task History" path={`${pathRoot}/history`} key="task-history">
        <TaskHistory patientId={patientId} patientDetails={patientDetails} taskList={taskList} />
      </Tab>,
    );
  }
  if (hasPermission(PERMISSIONS.MESSAGES_INBOX) && patientDetails?.textPhoneNo) {
    tabArray.push(
      <Tab name="Messages" path={`${pathRoot}/messages`} key="messages">
        <MessageDetails patientId={patientId} showPatientDetails={false} showHeader={false} />
      </Tab>,
    );
  }
  if (hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_DEVICES)) {
    tabArray.push(
      <Tab name="Devices" path={`${pathRoot}/devices`} key="devices">
        {deviceClass === DeviceClass.thirdParty ? (
          <HubDeviceEvents {...props} showBackButton={false} />
        ) : [DeviceClass.hub, DeviceClass.cap, DeviceClass.ble].includes(deviceClass) ? (
          <ShipperCapsDetails {...props} showBackButton={false} />
        ) : (
          <PatientDevices patientId={patientId} patientDetails={patientDetails} kit={props.kit} />
        )}
      </Tab>,
    );
  }
  if (
    hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_DEVICES) &&
    hasPermission(PERMISSIONS.DEVICE_MANAGEMENT_SHOW_DELETED)
  ) {
    tabArray.push(
      <Tab name="Devices History" path={`${pathRoot}/devices-history`} key="devices-history">
        {deviceClass === DeviceClass.thirdParty ? (
          <HubDeviceEvents {...props} showBackButton={false} />
        ) : [DeviceClass.hub, DeviceClass.cap, DeviceClass.ble].includes(deviceClass) ? (
          <ShipperCapsDetails {...props} showBackButton={false} />
        ) : (
          <PatientDevicesHistory patientId={patientId} patientDetails={patientDetails} kit={props.kit} deleted />
        )}
      </Tab>,
    );
  }

  if (hasPermission(PERMISSIONS.DOCUMENTS)) {
    tabArray.push(
      <Tab name={Strings.documents} path={`${pathRoot}/documents`} key="documents">
        <BoxDocuments patientId={patientId} />
      </Tab>,
    );
  }

  if (hasPermission(PERMISSIONS.DOCUMENTS_S3)) {
    tabArray.push(
      <Tab name={Strings.documents} path={`${pathRoot}/documents`} key="s3-documents">
        <CloudDocuments patientId={patientId} />
      </Tab>,
    );
  }

  const activeTab = props.activeTab || tabArray[0]?.props?.name;
  //#endregion

  const onDownloadBtnClickSuccessHandler = (req, filename) => {
    const fileName =
      req?.response && req?.response?.name ? req?.response?.name : filename ? filename : moment().toString();
    downloadFileOnly(req, fileName);
  };

  const downloadNotesAndActions = () => {
    const onDateSubmited = dates => {
      const request = {
        patientId,
        includeSignature: true,
        startTimestamp: moment(dates.startDate * 1000).format(),
        endTimestamp: moment(dates.endDate * 1000).format(),
      };
      props.downloadNotesAndActions(request).then(resp => {
        if (resp.type === DOWNLOAD_NOTES_AND_ACTIONS_RESULT) {
          onDownloadBtnClickSuccessHandler(
            resp,
            `Note__${patientDetails?.patientName?.replace(' ', '_')}__${moment(request.startTimestamp).format(
              DATE_FORMAT_YEAR_MONTH_DAY,
            )}_${moment(request.endTimestamp).format(DATE_FORMAT_YEAR_MONTH_DAY)}`,
          );
        }
      });
    };
    props.openDateRangeSelectionModal({
      actionOnDone: onDateSubmited,
      title: Strings.downloadNotesAndActions,
      submitButtonText: Strings.download,
    });
  };

  const downloadKitData = () => {
    const onDateSubmited = dates => {
      const request = {
        startTimestamp: moment(dates.startDate * 1000).format(),
        endTimestamp: moment(dates.endDate * 1000).format(),
      };
      props.downloadKitData(patientId, request).then(resp => {
        if (resp.type === DOWNLOAD_KIT_DATA_RESULT) {
          onDownloadBtnClickSuccessHandler(
            resp,
            `Readings__${patientDetails?.patientName?.replace(' ', '_')}__${moment(request.startTimestamp).format(
              DATE_FORMAT_YEAR_MONTH_DAY,
            )}_${moment(request.endTimestamp).format(DATE_FORMAT_YEAR_MONTH_DAY)}`,
          );
        }
      });
    };
    props.openDateRangeSelectionModal({
      actionOnDone: onDateSubmited,
      title: Strings.downloadEvents,
      submitButtonText: Strings.download,
    });
  };

  const resetPatientStatus = () => {
    props.openResetPatientStatusModal(patientDetails, onRefresh);
  };

  const inviteToPortal = patient => {
    const data = {
      title: <span>{Strings.formatString(Strings.invitePatientWarning, patient?.patientName)}</span>,
      caption: Strings.invitePatient,
      onConfirmAction: actions.invitePatientToPortal(patient.id, 'email'),
      onCancelAction: null,
      onSuccessNotification: Strings.invitationSuccess,
      confirmPostAction: onRefresh,
      hideCaution: true,
    };
    props.openConfirmModal(data);
  };

  const resetPassword = patient => {
    const data = {
      title: (
        <span>
          {Strings.warnigs.adminResetPassword} <b>{patient.patientName}</b>?
        </span>
      ),
      hideCaution: true,
      onConfirmAction: sendRestPasswordMessage({
        method: 'email',
        email: patient.email,
      }),
      onCancelAction: null,
      caption: 'Confirm password reset',
    };

    props.openConfirmModal(data);
  };

  const flagPatientForDexcom = patient => {
    const data = {
      title: <span>{Strings.formatString(Strings.enableDexcomIntegrationConfirm, patient?.patientName)}</span>,
      hideCaution: true,
      onConfirmAction: dexcomActions.flagPatientForDexcom(patient.id, 'new'),
      onSuccessNotification: Strings.enableDexcomIntegrationSuccess,
      onCancelAction: null,
      confirmPostAction: onRefresh,
      caption: Strings.enableDexcomIntegration,
    };

    props.openConfirmModal(data);
  };

  const flagPatientForDexcomDisable = patient => {
    const data = {
      title: <span>{Strings.formatString(Strings.disableDexcomIntegrationConfirm, patient?.patientName)}</span>,
      hideCaution: true,
      onConfirmAction: dexcomActions.flagPatientForDexcom(patient.id, 'delete'),
      onSuccessNotification: Strings.disableDexcomIntegrationSuccess,
      onCancelAction: null,
      confirmPostAction: onRefresh,
      caption: Strings.disableDexcomIntegration,
    };

    props.openConfirmModal(data);
  };

  const handleSelection = (value, event) => {
    switch (value) {
      case 'note':
        props.onAddNote();
        break;
      case 'action':
        props.onAddAction();
        break;
      case 'email':
        window.location = `mailto:${patientDetails?.email}`;
        break;
      case 'downloadDevicesData':
        downloadKitData();
        break;
      case 'downloadNotesAndActions':
        downloadNotesAndActions();
        break;
      case 'enroll':
        props.onEnroll(
          patientDetails,
          () => props.getPatientInfo(patientId),
          patientDetails?.status === RPM_PATIENT_STATUS.enrolled ? OnboardStages.activating : OnboardStages.enrolling,
        );
        break;
      case 'endEnrollment':
        props.onEnroll(patientDetails, () => props.getPatientInfo(patientId), OnboardStages.settingEndDate);
        break;
      case 'startEnrollment':
        props.startEnrollment(patientDetails, onRefresh);
        break;
      case 'register':
        props.editPatient(patientDetails, () => props.getPatientInfo(patientId), OnboardStages.registration);
        break;
      case 'cancelEnrollment':
        resetPatientStatus();
        break;
      case 'resetPatient':
        resetPatientStatus();
        break;
      case 'sms':
        props.onSendMessage(patientDetails, props.getPatientsForSuggestion, PATIENT_LIST_FOR_SUGGESTION);
        break;
      case 'addMedicine':
        props.onAddMedicine(patientDetails, onRefresh);
        break;
      case 'addCaregiver':
        props.addCaregiver(activeTab === 'Info' ? onRefresh : () => {});
        break;
      case 'inviteToPortal':
        inviteToPortal(patientDetails);
        break;
      case 'resetPassword':
        resetPassword(patientDetails);
        break;
      case 'flagForDexcom':
        flagPatientForDexcom(patientDetails);
        break;
      case 'flagForDexcomDisable':
        flagPatientForDexcomDisable(patientDetails);
        break;
      default:
        break;
    }
  };

  const onRefresh = () => {
    setPatientsRefreshTimestamp(now());
    getPatientData();
    setRefreshEnable(false);
    setTimeout(() => {
      setRefreshEnable(true);
    }, 10000);
  };

  const enrollOption = () => {
    const text = getEnrollTextForPatient(patientDetails);
    let action;
    switch (patientDetails?.status) {
      case RPM_PATIENT_STATUS.activated:
        action = 'endEnrollment';
        break;
      case RPM_PATIENT_STATUS.verified:
        if (!hasPermission(PERMISSIONS.PATIENTS_RPM_ENROLL)) {
          break;
        }
        action = 'enroll';
        break;
      case RPM_PATIENT_STATUS.enrolled:
        {
          if (!hasPermission(PERMISSIONS.PATIENTS_RPM_ACTIVATE)) {
            break;
          }
          if (isEnrollmentReadyToStart(patientDetails)) {
            action = 'startEnrollment';
          } else {
            action = 'enroll';
          }
        }
        break;
      case RPM_PATIENT_STATUS.onboarded:
        action = 'register';
        break;
      case RPM_PATIENT_STATUS.ended:
        action = 'resetPatient';
        break;
      default:
        break;
    }
    if (!action) {
      return <></>;
    }
    return (
      <MenuItem className="addMenu-menuItem" value={action} id={action}>
        {text}
      </MenuItem>
    );
  };

  const actionsButton = (
    <Wrapper
      className="addMenu"
      onSelection={handleSelection}
      onMenuToggle={({ isOpen }) => {
        setIsActionMenuOpen(isOpen);
      }}
    >
      <Button className={`addMenu-button ${isActionMenuOpen ? 'expanded' : ''}`}>{Strings.actions}</Button>
      <Menu className="addMenu-menu">
        <MenuItem className="addMenu-menuItem" value="note" id="note">
          {Strings.addNote}
        </MenuItem>
        <MenuItem className="addMenu-menuItem" value="action" id="action">
          {Strings.addOpenAction}
        </MenuItem>
        {patientDetails?.email && (
          <MenuItem className="addMenu-menuItem" value="email" id="email">
            {Strings.emailPatient}
          </MenuItem>
        )}
        {patientDetails?.textPhoneNo && (
          <MenuItem className="addMenu-menuItem" value="sms" id="sms">
            {Strings.message}
          </MenuItem>
        )}
        {activeTab === Strings.readings && props.kit?.id && (
          <MenuItem className="addMenu-menuItem" value="downloadDevicesData" id="downloadDevicesData">
            {Strings.downloadData}
          </MenuItem>
        )}
        {activeTab === 'Dashboard' && (
          <MenuItem className="addMenu-menuItem" value="downloadNotesAndActions" id="downloadNotesAndActions">
            {Strings.downloadData}
          </MenuItem>
        )}
        {enrollOption()}
        {patientDetails?.status === RPM_PATIENT_STATUS.enrolled && (
          <MenuItem className="addMenu-menuItem" value="cancelEnrollment" id="cancelEnrollment">
            {Strings.cancelEnrollment}
          </MenuItem>
        )}
        {patientDetails?.status === RPM_PATIENT_STATUS.activated && (
          <MenuItem className="addMenu-menuItem" value="addMedicine" id="addMedicine">
            {Strings.addMedicine}
          </MenuItem>
        )}
        {patientDetails?.status !== RPM_PATIENT_STATUS.ended && (
          <MenuItem className="addMenu-menuItem" value="addCaregiver" id="addCaregiver">
            {Strings.addCaregiver}
          </MenuItem>
        )}
        {hasPermission(PERMISSIONS.GRANT_PATIENT_PORTAL_ACCESS) &&
          patientDetails?.portalAccess === RPM_PORTAL_ACCESS_STATUS.noAccess &&
          patientDetails?.status === RPM_PATIENT_STATUS.activated && (
            <MenuItem className="addMenu-menuItem" value="inviteToPortal" id="inviteToPortal">
              {Strings.inviteToPortal}
            </MenuItem>
          )}
        {hasPermission(PERMISSIONS.GRANT_PATIENT_PORTAL_ACCESS) &&
          patientDetails?.portalAccess === RPM_PORTAL_ACCESS_STATUS.invited &&
          patientDetails?.status !== RPM_PATIENT_STATUS.ended && (
            <MenuItem className="addMenu-menuItem" value="inviteToPortal" id="inviteToPortal">
              {Strings.reInviteToPortal}
            </MenuItem>
          )}
        {hasPermission(PERMISSIONS.GRANT_PATIENT_PORTAL_ACCESS) &&
          patientDetails?.portalAccess === RPM_PORTAL_ACCESS_STATUS.hasAccess &&
          patientDetails?.status !== RPM_PATIENT_STATUS.ended && (
            <MenuItem className="addMenu-menuItem" value="resetPassword" id="resetPassword">
              {Strings.resetPassword}
            </MenuItem>
          )}
        {hasPermission(PERMISSIONS.DEVICE_MANAGEMENT_DEXCOM_CGM) &&
          patientDetails?.kitId &&
          patientDetails?.status === RPM_PATIENT_STATUS.activated && (
            <MenuItem className="addMenu-menuItem" value="flagForDexcom" id="flagForDexcom">
              {!patientDetails?.dexcomStatus ? Strings.enableDexcomIntegration : Strings.resetDexcomIntegration}
            </MenuItem>
          )}
        {hasPermission(PERMISSIONS.DEVICE_MANAGEMENT_DEXCOM_CGM) &&
          patientDetails?.dexcomStatus &&
          patientDetails.dexcomStatus !== 'deleted' &&
          patientDetails?.kitId &&
          patientDetails?.status === RPM_PATIENT_STATUS.activated && (
            <MenuItem className="addMenu-menuItem" value="flagForDexcomDisable" id="flagForDexcomDisable">
              {Strings.disableDexcomIntegration}
            </MenuItem>
          )}
      </Menu>
    </Wrapper>
  );

  return (
    <div className="patient-page">
      <div className="patient-page-innerdiv">
        <div className="infoHeader spaceBeteween white">
          {isBrowser ? (
            <div className="rowDirection">
              <button className="back" onClick={() => history.back()} tabIndex={0}>
                {Strings.back}
              </button>
              <div className="feildBox">
                {Strings.capPatient.patientId}: <div className="feildValue">{patientDetails?.pharmacyPatientId}</div>
              </div>
              <div className="pathHr"></div>
              <div className="feildBox">
                {Strings.name}: <div className="feildValue">{patientDetails?.patientName}</div>
              </div>
              <div className="pathHr"></div>
              <div className="feildBox">
                {Strings.capPatient.dob}:{' '}
                <div className="feildValue">{moment(patientDetails?.dateOfBirth).format(DATE_MONTH_DAY_YEAR)}</div>
              </div>
              <div className="pathHr"></div>
              <div className="feildBox">
                {Strings.kitId}: <div className="feildValue">{props.kit?.id || 'none'}</div>
              </div>
            </div>
          ) : (
            <div className="rowDirection">
              <button className="back" onClick={() => history.back()} tabIndex={0}>
                {Strings.back}
              </button>
              <div className="feildValue">{patientDetails?.mrn}</div>
              <div className="pathHr"></div>
              <div className="feildValue">{patientDetails?.patientName}</div>
            </div>
          )}
          <div className="rowDirection">
            {actionsButton}
            {activeTab === 'Info' && (
              <React.Fragment>
                <DashboardEditModeButton dashboardId={INFO_DASHBOAD_ID} />
                <DashboardFilter dashboardId={INFO_DASHBOAD_ID} />
              </React.Fragment>
            )}
            {activeTab === 'Medications' &&
              isPatientMedicationsLoading === false &&
              patientMedications &&
              patientMedications.length > 0 && (
                <React.Fragment>
                  <DashboardEditModeButton dashboardId={`${PATIENT_MEDICATION_DASHBOARD_ID_PREFIX}${patientId}`} />
                  <DashboardFilter
                    dashboardId={`${PATIENT_MEDICATION_DASHBOARD_ID_PREFIX}${patientId}`}
                    additionalData={patientMedications}
                  />
                </React.Fragment>
              )}
            {activeTab === 'Dashboard' && (
              <div className="page-header">
                <div className="right">
                  <DashboardEditModeButton dashboardId={`${PATIENT_DASHBOARD_ID_PREFIX}${patientId}`} />
                  <DashboardFilter
                    dashboardId={`${PATIENT_DASHBOARD_ID_PREFIX}${patientId}`}
                    additionalData={{
                      meds: patientMedications,
                      readingsWidgetMetadata: getReadingsWidgetMetadata(patientDevices),
                      patientDetails,
                    }}
                  />
                  <RefreshButton class="refresh" disabled={!refreshEnable} onClick={onRefresh} />
                </div>
              </div>
            )}
            {activeTab === 'Devices' && props.match?.params?.type && <div className="refresh-button-placeholder"></div>}
          </div>
        </div>
      </div>
      <TabView key="tabs" isBack={false} activeTab={activeTab} routeMode className="patient-tab-view">
        {tabArray}
      </TabView>
    </div>
  );
}

PatientDetails.propTypes = {
  activeTab: PropTypes.any,
  getPatientInfo: PropTypes.func,
  getPatientKitInfo: PropTypes.func,
  getTaskList: PropTypes.func,
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.any }) }),
  patientDetails: PropTypes.any,
  patientMedications: PropTypes.array,
  taskList: PropTypes.any,
  onAddNote: PropTypes.func,
  isPatientMedicationsLoading: PropTypes.any,
  kit: PropTypes.any,
  onEnroll: PropTypes.func,
  onAddAction: PropTypes.func,
  clearPatientInfo: PropTypes.func,
  downloadNotesAndActions: PropTypes.func,
  onSendMessage: PropTypes.func,
  getPatientsForSuggestion: PropTypes.func,
  onAddMedicine: PropTypes.func,
  openDateRangeSelectionModal: PropTypes.func,
  downloadKitData: PropTypes.func,
  openConfirmModal: PropTypes.func,
  editPatient: PropTypes.func,
  startEnrollment: PropTypes.func,
  closeTimer: PropTypes.func,
  openTimer: PropTypes.func,
  openResetPatientStatusModal: PropTypes.func,
  role: PropTypes.string,
  userId: PropTypes.any,
  timer_off: PropTypes.any,
  getDevices: PropTypes.func,
  getProviderVisitSummary: PropTypes.func,
  onNavigate: PropTypes.func,
  addCaregiver: PropTypes.func,
};

const mapStateToProps = state => {
  const { patients } = state.superUser;
  return {
    role: state.auth.role,
    taskList: patients && patients?.taskList?.data,
    patientDetails: patients?.patientDetails,
    patientMedications: patients?.patientMedications?.medications,
    isPatientMedicationsLoading: patients?.isPatientMedicationsLoading,
    kit: state.entities.kits?.patientKit,
    userId: state.auth.profile?.id,
    timer_off: state.auth.profile?.timer_off,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onNavigate: path => dispatch(push(path)),
  getTaskList: () => dispatch(actions.getTasks()),
  getPatientInfo: patientId => dispatch(actions.getPatientInfo(patientId)),
  getPatientDashboardInfo: patientId => dispatch(actions.getPatientDahsboardInfo(patientId)),
  getDevices: (request, patientId) => dispatch(actions.getPatientDevices(request, patientId)),
  clearPatientInfo: () => dispatch(actions.clearPatientInfo()),
  getPatientKitInfo: patientId => dispatch(kitActions.getPatientKitInfo(patientId)),
  getProviderVisitSummary: patientId => dispatch(actions.getProviderVisitSummary(patientId)),
  onAddNote: () =>
    dispatch(
      openModalAction('add-note', {
        patientId: props.match.params.patientId,
      }),
    ),
  onAddAction: () =>
    dispatch(
      openModalAction('add-note', {
        patientId: props.match.params.patientId,
        actionMode: true,
      }),
    ),
  onEnroll: (patient, nextAction, stage) =>
    dispatch(
      openModalAction('enroll-patient', {
        patient,
        onSuccess: nextAction,
        stage,
      }),
    ),
  downloadNotesAndActions: request => dispatch(actions.downloadNotesAndActions(request)),
  downloadKitData: (id, request) => dispatch(actions.downloadKitData(id, request)),
  onSendMessage: (patient, loadOptionsAction, loadOptionsActionType) =>
    dispatch(
      openModalAction(
        'send-message',
        {
          patient,
          loadRecipients: loadOptionsAction,
          loadRecipientsActionType: loadOptionsActionType,
        },
        patient.id,
      ),
    ),
  getPatientsForSuggestion: pageRequest => dispatch(actions.getPatientsForSuggestion(pageRequest)),
  onAddMedicine: (patient, onRefresh) =>
    dispatch(
      openModalAction('edit-rpm-schedule', {
        patient,
        action: patientAction.actionUpdateSchedule,
        actionType: UPDATE_PATIENT_SCHEDULE,
        onSuccess: onRefresh,
      }),
    ),
  addCaregiver: onSuccess =>
    dispatch(
      openModalAction('edit-caregivers', {
        patientId: props.match.params.patientId,
        onSuccess,
      }),
    ),
  openTimer: data => dispatch(openModalAction('timer', data)),
  closeTimer: () => dispatch(closeModal('timer')),
  openDateRangeSelectionModal: data => dispatch(openModalAction('data-range-selection-modalion', data)),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  editPatient: (patient, onSuccess, stage) =>
    dispatch(
      openModalAction('onboard-patient', {
        patientAction: actions.editPatient,
        onSuccess,
        patient,
        stage,
      }),
    ),
  startEnrollment: (patient, onSuccess) =>
    dispatch(
      openModalAction('start-enrollmend-and-schedules', {
        patient,
        onSuccess,
      }),
    ),
  openResetPatientStatusModal: (patient, onSuccess) =>
    dispatch(openModalAction('reset-enrollment-and-unattach-devices', { patient, onSuccess })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails);
