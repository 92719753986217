import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Strings from '../../../Strings';
import { actions } from '../redux/actions';
import { GET_LINKED_ORGANIZATIONS_RESULT, LINK_ORGANIZATION_ERROR, ORGANIZATION_LIST_RESULT, UNLINK_ORGANIZATION_ERROR } from '../redux/constants';
import { notificationActions } from '../../../components/Notification/redux/actions';
import Multiselect, { SelectedOptions } from '../../../components/Select/Multiselect';
import UserList from '../../Users/UserList';
import { CARE_PROVIDER, DOCTOR } from '../../../utils/userRoles';

function OrgCareProviders(props) {
  const [orgs, setOrgs] = React.useState([]);
  const [linkedOrgs, setLinkedOrgs] = React.useState([]);
  const [selectedOrgs, setSelectedOrgs] = React.useState([]);
  const [refreshTimestamp, setRefreshTimestamp] = React.useState(Date.now());

  const getLinkedOrgsAndProviders = () => {
    props.getLinkedOrgs().then(resp => {
      if (resp?.type === GET_LINKED_ORGANIZATIONS_RESULT) {
        setLinkedOrgs(resp.response.map(o => o.uuid));
        setSelectedOrgs(resp.response.map(o => o.uuid));
      }
    });
    setRefreshTimestamp(Date.now());
  };

  React.useEffect(() => {
    props.getOrgs().then(resp => {
      if (resp?.type === ORGANIZATION_LIST_RESULT) {
        setOrgs(
          resp.response?.data?.map(o => {
            return { ...o, value: o.masked_id, label: o.name };
          }),
        );
      }
    });
    getLinkedOrgsAndProviders(); 
  }, []);

  const onSave = async () => {
    const toDelete = linkedOrgs.filter(o => !selectedOrgs.includes(o));
    for (const [_, o] of toDelete.entries()) {
      const response = await props.unlinkOrganization(o);
      if (response && response.type === UNLINK_ORGANIZATION_ERROR) {
        props.showNotification(response.response?.data?.error?.message, 5000, true);
      }
    }

    const toAdd = selectedOrgs.filter(o => !linkedOrgs.includes(o));
    for (const [_, o] of toAdd.entries()) {
      const response = await props.linkOrganization({provider_org_uuid: o});
      if (response && response.type === LINK_ORGANIZATION_ERROR) {
        props.showNotification(response.response?.data?.error?.message, 5000, true);
      }
    }

    getLinkedOrgsAndProviders();
  };

  return (
    <div className="config-sections">
      <h3 className="config-sections-title">Linked organizations</h3>
      {orgs?.length > 0 && (
        <React.Fragment>
          <Multiselect
            data={orgs}
            value={selectedOrgs}
            onChange={o => setSelectedOrgs(o.map(op => op.value))}
            isSearchable
          />
          <SelectedOptions
          items={selectedOrgs.map(c => orgs.find(o => o.value === c)) || []}
          onRemove={e => setSelectedOrgs(selectedOrgs.filter(c => c !== e.value))}
          itemsPerRow={2}
        />
        </React.Fragment>
      )}
      <button className="brand-blue" key="submit" onClick={onSave}>
        {Strings.submit}
      </button>
      <h3 className="config-sections-title">Care providers</h3>
      <UserList
        organizationId={props.organizationId}
        role={DOCTOR}
        type={CARE_PROVIDER}
        refreshTimestamp={refreshTimestamp} 
      />
    </div>
  );
}

OrgCareProviders.propTypes = {
  organizationId: PropTypes.any,
  showNotification: PropTypes.func,
};

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getLinkedOrgs: () => dispatch(actions.getLinkedOrganizations(ownProps.organizationId)),
  linkOrganization: request => dispatch(actions.linkOrganization(ownProps.organizationId, request)),
  unlinkOrganization: linkedOrgId => dispatch(actions.unlinkOrganization(ownProps.organizationId, linkedOrgId)),
  getOrgs: () => dispatch(actions.getOrgs()),
  showNotification: (message, timeout, error) => dispatch(notificationActions.show(message, timeout, error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgCareProviders);
