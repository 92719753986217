import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment, { now } from 'moment-timezone';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import DashboardLayout from './DashboardLayout';
import { PageHeader } from '../../components/PageHeader';
import { Button } from '../../components/PageHeader/Button';
import Strings from '../../Strings';
import PendingCallsMessages from './Widgets/PendingCallsMessages';
import PatientStatus, { PatientStatusMetadata } from './Widgets/PatientStatus';
import TotalKits from './Widgets/TotalKits';
import { actions as patientActions } from '../SuperUser/Patients/redux/actions';
import { actionsDashboard as actions } from './redux/actions';
import { actions as actionsPCM } from './Widgets/PendingCallsMessages/redux/actions';
import { actions as actionsTK } from './Widgets/TotalKits/redux/actions';
import { actions as actionsPwnR } from './Widgets/PatientsWithNoReadings/redux/actions';
import { actions as actionsPN } from './Widgets/PatientsWithNewNotes/redux/actions';
import DashboardFilter from './DashboardFilter';
import PatientsToCall from './Widgets/PatientsToCall';
import DashboardEditModeButton from './DashboardEditModeButton';
import PatientsWithNoReadings from './Widgets/PatientsWithNoReadings';
import PatientsWithNewNotes from './Widgets/PatientsWithNewNotes';
import Select from '../../components/Select';

export const DASHBOARD_ID = 'DashboardRPM';

const PendingCallsMessagesMetadata = {
  id: PendingCallsMessages.widgetId,
  name: Strings.widgets.unreadMessages,
  permission: '',
  defProps: { i: PendingCallsMessages.widgetId, w: 4, h: 2, minW: 2, minH: 2 },
};

const TotalKitsMetadata = {
  id: TotalKits.widgetId,
  name: Strings.widgets.totalKits,
  permission: '',
  defProps: { i: TotalKits.widgetId, w: 4, h: 2, minW: 2, minH: 2 },
};

const PatientsWithNoReadingsMetadata = {
  id: PatientsWithNoReadings.widgetId,
  name: Strings.widgets.patientsWithNoReadings,
  permission: '',
  defProps: { i: PatientsWithNoReadings.widgetId, w: 4, h: 2, minW: 2, minH: 2 },
  configuration: {
    days: {
      type: 'number',
      name: 'days',
      label: Strings.numberOfDays,
      min: 1,
      max: 30,
      defaultValue: 7,
      isRequired: true,
    },
    // We're not allowing to change widget title (yet). It would cause problems with dashboard filters.
    // title: {
    //   type: 'string',
    //   name: 'title',
    //   label: Strings.title,
    //   defaultValue: Strings.widgets.patientsWithNoReadings,
    //   isRequired: true,
    // },
  },
};

const PatientsWithNewNotesMetadata = {
  id: PatientsWithNewNotes.widgetId,
  name: Strings.widgets.patientsWithNewNotes,
  permission: '',
  defProps: { i: PatientsWithNewNotes.widgetId, w: 4, h: 2, minW: 2, minH: 2 },
  configuration: {
    days: {
      type: 'number',
      name: 'days',
      label: Strings.numberOfDays,
      min: 1,
      max: 30,
      defaultValue: 1,
      isRequired: true,
    },
  },
};

const PatientsToCallMetadata = {
  id: PatientsToCall.widgetId,
  name: Strings.patientAlerts,
  permission: '',
  defProps: { i: PatientsToCall.widgetId, w: 12, h: 6, minW: 4, minH: 3 },
};

export const WidgetMetadata = [
  PendingCallsMessagesMetadata,
  PatientStatusMetadata,
  TotalKitsMetadata,
  PatientsWithNoReadingsMetadata,
  PatientsWithNewNotesMetadata,
  PatientsToCallMetadata,
];

const l = [
  { ...PendingCallsMessagesMetadata.defProps, x: 6, y: 0, w: 3, h: 2 },
  { ...PatientStatusMetadata.defProps, x: 0, y: 0, w: 6, h: 4 },
  { ...TotalKitsMetadata.defProps, x: 6, y: 0, w: 3, h: 2 },
  { ...PatientsWithNoReadingsMetadata.defProps, x: 9, y: 0, w: 3, h: 2 },
  { ...PatientsWithNewNotesMetadata.defProps, x: 9, y: 2, w: 3, h: 2 },
  { ...PatientsToCallMetadata.defProps, x: 0, y: 6, w: 12, h: 10 },
];

const m = [
  { ...PendingCallsMessagesMetadata.defProps, x: 3, y: 0, w: 2, h: 2 },
  { ...PatientStatusMetadata.defProps, x: 0, y: 0, w: 3, h: 4 },
  { ...TotalKitsMetadata.defProps, x: 3, y: 2, w: 2, h: 2 },
  { ...PatientsWithNoReadingsMetadata.defProps, x: 5, y: 0, w: 3, h: 2 },
  { ...PatientsWithNewNotesMetadata.defProps, x: 5, y: 2, w: 3, h: 2 },
  { ...PatientsToCallMetadata.defProps, x: 0, y: 4, w: 8, h: 6 },
];

const s = [
  { ...PendingCallsMessagesMetadata.defProps, x: 0, y: 0, w: 4, h: 2 },
  { ...PatientStatusMetadata.defProps, x: 0, y: 2, w: 4, h: 2 },
  { ...TotalKitsMetadata.defProps, x: 0, y: 4, w: 4, h: 2 },
  { ...PatientsWithNoReadingsMetadata.defProps, x: 0, y: 6, w: 4, h: 2 },
  { ...PatientsWithNewNotesMetadata.defProps, x: 0, y: 10, w: 4, h: 2 },
  { ...PatientsToCallMetadata.defProps, x: 0, y: 6, w: 4, h: 4 },
];

export const DefaultLayouts = { l, m, s };

function DashboardRPM(props) {
  const { orgFilter, getCareProvidedOrganizations } = props;
  const [refreshEnable, setRefreshEnable] = useState(true);
  const [widgets, setWidgets] = useState([]);
  const [refreshTimestamp, setRefreshTimestamp] = useState(now());
  const [orgs, setOrgs] = useState([]);

  const initializeDashboard = () => {
    const widgetPCM = {
      ...PendingCallsMessagesMetadata,
      render: <PendingCallsMessages />,
      noPadding: true,
    };

    const widgetPE = {
      ...PatientStatusMetadata,
      render: <PatientStatus
        graphType={
          props.configuration?.[PatientStatusMetadata.id]?.chartType?.value ||
          PatientStatusMetadata.configuration.chartType.defaultValue
        }
        refreshTimestamp={refreshTimestamp}
      />,
      noPadding: true,
    };

    const widgetTK = {
      ...TotalKitsMetadata,
      render: <TotalKits />,
    };

    const widgetPwnR = {
      ...PatientsWithNoReadingsMetadata,
      render: <PatientsWithNoReadings />,
    };

    const widgetPN = {
      ...PatientsWithNewNotesMetadata,
      render: <PatientsWithNewNotes />,
    };

    const widgetPC = {
      ...PatientsToCallMetadata,
      render: <PatientsToCall refreshTimestamp={refreshTimestamp} />,
    };

    setWidgets([widgetPCM, widgetPE, widgetTK, widgetPwnR, widgetPN, widgetPC]);
  };

  useEffect(() => {
    initializeDashboard();
  }, [refreshTimestamp, props.configuration, orgFilter]);

  useEffect(() => {
    getCareProvidedOrganizations().then(resp => {
      setOrgs(
        resp.response?.map(o => {
          return { ...o, value: o.uuid, label: o.name };
        }),
      );
    });
  }, [refreshTimestamp]);

  const onRefresh = () => {
    setRefreshEnable(false);
    setTimeout(() => {
      setRefreshEnable(true);
    }, 10000);
    setRefreshTimestamp(now());
    if (!props.filters || props.filters.some(f => f.id === PatientStatus.widgetId && f.checked)) props.refreshPE();
    if (!props.filters || props.filters.some(f => f.id === PendingCallsMessages.widgetId && f.checked))
      props.refreshPCM();
    if (!props.filters || props.filters.some(f => f.id === TotalKits.widgetId && f.checked)) props.refreshTK();
    if (!props.filters || props.filters.some(f => f.id === PatientsWithNoReadings.widgetId && f.checked)) {
      const days =
        props.configuration?.[PatientsWithNoReadings.widgetId]?.days?.value ||
        PatientsWithNoReadingsMetadata.configuration.days.defaultValue;
      props.refreshPwnR(
        moment()
          .subtract(days, 'day')
          .startOf('day')
          .format(),
      );
    }
    if (!props.filters || props.filters.some(f => f.id === PatientsWithNewNotes.widgetId && f.checked)) {
      const days =
        props.configuration?.[PatientsWithNewNotes.widgetId]?.days?.value ||
        PatientsWithNewNotesMetadata.configuration.days.defaultValue;
      props.refreshPN(
        moment()
          .subtract(days, 'day')
          .startOf('day')
          .format(),
      );
    }
  };

  return (
    <React.Fragment>
      <PageHeader
        isBlack
        left={`${Strings.welcome} ${props.userName}`}
        right={
          <React.Fragment>
            {orgs?.length > 0 && (
              <Select
                data={orgs}
                value={orgFilter}
                onChange={o => props.setOrgFilter(props.userId, o?.value)}
                placeholder={Strings.selectOrganization}
              />
            )}
            <DashboardEditModeButton dashboardId={DASHBOARD_ID} />
            <DashboardFilter dashboardId={DASHBOARD_ID} />
            <Button class="refresh" disabled={!refreshEnable} onClick={onRefresh} />
          </React.Fragment>
        }
      />
      {widgets.length > 0 && !_.isEmpty(DefaultLayouts) && (
        <OverlayScrollbarsComponent
          defer
          className="scrollbar-right-margin"
          options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100' } }}
        >
          <DashboardLayout
            dashboardId={DASHBOARD_ID}
            widgets={widgets}
            defaultLayouts={DefaultLayouts}
            rowHeight={45}
          />
        </OverlayScrollbarsComponent>
      )}
    </React.Fragment>
  );
}

DashboardRPM.propTypes = {
  userName: PropTypes.string,
  filters: PropTypes.array,
  configuration: PropTypes.any,
  initializeDashboard: PropTypes.func,
};

const mapStateToProps = state => {
  const { profile } = state.auth;
  const { dashboardFilters } = state.dashboardState;
  const filters = dashboardFilters[DASHBOARD_ID];
  const configuration = state.dashboardState.configuration[DASHBOARD_ID];
  const userId = state.auth?.profile?.id;
  return {
    userName: profile ? (profile.first_name ? `${profile.first_name} ${profile.last_name}` : profile.username) : '',
    filters,
    configuration,
    userId,
    orgFilter: state.superUser?.patients?.orgFilter?.[userId],
  };
};

const mapDispatchToProps = dispatch => ({
  initializeDashboard: (dashboardId, defaultLayouts, widgets, force) =>
    dispatch(actions.initializeDashboard(dashboardId, defaultLayouts, widgets, force)),
  refreshPCM: () => dispatch(actionsPCM.getPendingCallsMessages()),
  refreshTK: () => dispatch(actionsTK.getTotalKits()),
  refreshPwnR: date => dispatch(actionsPwnR.getPatientsWithNoReadings(date)),
  refreshPN: date => dispatch(actionsPN.getPatientsWithNewNotes(date)),
  getCareProvidedOrganizations: () => dispatch(patientActions.getCareProvidedOrganizations()),
  setOrgFilter: (userId, orgId) => dispatch(patientActions.setOrgFilter(userId, orgId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardRPM);
