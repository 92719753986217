const { VALUE_FOR_NO_FILTER } = require("../FiltersDefinition");

function isFilterPresent(filter, filters) {
  return filters.some(e => e.group === filter.group && e.value === filter.value);
}

function setSelectorAllInGroup(group, state, filters) {
  return filters.map(e => {
    if (e.group === group && e.value == 'all') return { group: e.group, value: e.value, state };
    return e;
  });
}

function setStateForGroup(group, state, filters) {
  return filters.map(e => {
    if (e.group === group) return { group: e.group, value: e.value, state };
    return e;
  });
}

function isSelectorAllShouldBeEnabledInGroup(group, filters) {
  return !filters.some(e => e.group == group && e.value !== 'all' && e.state == false);
}

function verifySelectorAllInGroup(group, filters) {
  if (isSelectorAllShouldBeEnabledInGroup(group, filters)) {
    return setSelectorAllInGroup(group, true, filters);
  }
  return filters;
}

function updateGroup(event, filters) {
  return filters.map(e => {
    if (e.group === event.group) return { group: e.group, value: e.value, state: event.state };
    return e;
  });
}

function selectOneInGroup(event, filters) {
  return filters.map(e => {
    if (e.group === event.group) return { group: e.group, value: e.value, state: e.value === event.value ? event.state : false };
    return e;
  });
}

function updateRegular(event, filters) {
  const newFiltersState = filters.map(e => {
    if (e.group === event.group && e.value === event.value)
      return { group: e.group, value: e.value, state: event.state };
    return e;
  }, event);

  if (event.state == false) {
    return setSelectorAllInGroup(event.group, false, newFiltersState);
  }
  return verifySelectorAllInGroup(event.group, newFiltersState);
}

function UpdateFiltersState(filterState, filterType, filters) {
  if (!isFilterPresent(filterState, filters)) {
    return filters.concat([filterState]);
  } 
  if (filterType === 'radio') {
    if (!filterState.state) {
      return filters.filter(e => e.group !== filterState.group);
    }
    if (filterState.value === VALUE_FOR_NO_FILTER) {
      return setStateForGroup(filterState.group, false, filters);
    }
    return selectOneInGroup(filterState, filters);
  }

  if (filterState.value == 'all') {
    return updateGroup(filterState, filters);
  }
  return updateRegular(filterState, filters);
}

export default UpdateFiltersState;
