import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment, { now } from 'moment-timezone';
import _ from 'lodash';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import DashboardLayout from './DashboardLayout';
import Devices, { DevicesMetadata } from './Widgets/Devices';
import HubStats, { deviceStatsWidgetTypes } from './Widgets/HubStats';
import LocationOfYourKits from './Widgets/LocationOfYourKits';
import HubsWithoutReadings from './Widgets/HubsWithoutReadings';
import { actionsDashboard as actions } from './redux/actions';
import { actions as actionsWidgets } from './redux/actionsWidgets';
import { PageHeader } from '../../components/PageHeader';
import { Button } from '../../components/PageHeader/Button';
import Strings from '../../Strings';
import DashboardFilter from './DashboardFilter';
import HubMetrics from './Widgets/HubMetrics';
import DashboardEditModeButton from './DashboardEditModeButton';
import { hasPermission, PERMISSIONS } from '../../utils/userPermissions';
import { openModalAction } from '../../actions/modal';
import { DeviceClass, DeviceTypes } from '../../utils/deviceTypes';

const deviceStatsWidgetConfig = {
  widgetType: {
    type: 'select',
    name: 'widgetType',
    label: 'Widget Type',
    defaultValue: deviceStatsWidgetTypes.barChart,
    options: [
      { label: 'Numbers', value: deviceStatsWidgetTypes.numbers },
      { label: 'Bar chart', value: deviceStatsWidgetTypes.barChart },
      { label: 'Pie chart', value: deviceStatsWidgetTypes.pieChart },
      { label: 'Line chart', value: deviceStatsWidgetTypes.lineChart },
      { label: 'Area chart', value: deviceStatsWidgetTypes.areaChart },
    ],
    canBeSetByDashboard: true,
  },
};

export const DASHBOARD_ID = 'DashboardDevices';
const kitsLocationMetadata = {
  id: LocationOfYourKits.widgetId,
  name: Strings.widgets.locationsOfYourKits,
  permission: '',
  defProps: { i: LocationOfYourKits.widgetId, w: 4, h: 4, minW: 2, minH: 2 },
};
const hubsWithNoReadingsMetadata = {
  id: HubsWithoutReadings.widgetId,
  name: Strings.widgets.hubsWithoutReadings,
  permission: PERMISSIONS.DEVICE_MANAGEMENT_HUB,
  defProps: { i: HubsWithoutReadings.widgetId, w: 4, h: 2, minW: 2, minH: 2 },
};
const monthlyOperatingRateMetadata = {
  id: 'MonthlyOperatingRateWidget',
  name: Strings.widgets.monthlyOperatingRate,
  permission: PERMISSIONS.DEVICE_MANAGEMENT_HUB,
  defProps: { i: 'MonthlyOperatingRateWidget', w: 4, h: 2, minW: 2, minH: 2 },
};

export const getWidgetsMetadata = () => {
  const ret = [DevicesMetadata, hubsWithNoReadingsMetadata, kitsLocationMetadata, monthlyOperatingRateMetadata];
  Object.values(DeviceTypes)
    .filter(d => d.deviceClass === DeviceClass.hub || d.deviceClass === DeviceClass.cap)
    .filter(d => hasPermission(d.permission))
    .forEach(d => {
      ['real_time', 'daily', 'monthly'].forEach(period => {
        ret.push(
          {
            id: `${d.id}-${period}`,
            name: Strings.formatString(Strings.widgets.numberOf, d.displayNamePlural, Strings.widgets[period]),
            permission: d.permission,
            defProps: { i: `${d.id}-${period}`, w: 4, h: 4, minW: 2, minH: 2 },
            configuration: deviceStatsWidgetConfig,
          }
        );
      });
    });
  return ret;
}

const l = [
  { ...DevicesMetadata.defProps, x: 0, y: 0, w: 9, h: 6 },
  { ...hubsWithNoReadingsMetadata.defProps, x: 9, y: 3, w: 3, h: 3 },
  { ...kitsLocationMetadata.defProps, x: 0, y: 100, w: 12, h: 6 },
  { ...monthlyOperatingRateMetadata.defProps, x: 9, y: 0, w: 3, h: 3 },
];

const m = [
  { ...DevicesMetadata.defProps, x: 0, y: 0, w: 6, h: 6 },
  { ...hubsWithNoReadingsMetadata.defProps, x: 6, y: 3, w: 3, h: 3 },
  { ...kitsLocationMetadata.defProps, x: 0, y: 150, w: 9, h: 5 },
  { ...monthlyOperatingRateMetadata.defProps, x: 6, y: 0, w: 3, h: 3 },
];

const s = [
  { ...DevicesMetadata.defProps, x: 4, y: 12, w: 4, h: 2 },
  { ...hubsWithNoReadingsMetadata.defProps, x: 2, y: 12, w: 2, h: 2 },
  { ...kitsLocationMetadata.defProps, x: 0, y: 15, w: 8, h: 5 },
  { ...monthlyOperatingRateMetadata.defProps, x: 0, y: 12, w: 2, h: 2 },
];

export const DefaultLayouts = { l, m, s };

export const getDefaultLayouts = () => {
  const ret = { l, m, s };
  const widgets = getWidgetsMetadata();
  widgets
  .filter(w => ![DevicesMetadata.id, hubsWithNoReadingsMetadata.id, kitsLocationMetadata.id, monthlyOperatingRateMetadata.id].includes(w.id))
  .forEach((w, i) => {
    ret.l.push({ x: (i * 4) % 12, y: i + 6, ...w.defProps });
    ret.m.push({ x: (i * 3) % 9, y: i + 6, ...w.defProps, w: 3 });
    ret.s.push({ x: (i + 1) * 4, y: i + 6, ...w.defProps });
  });
  return ret;
};

const startDate = moment()
  .tz('UTC')
  .subtract(1, 'month')
  .format();

function DevicesDashboard(props) {
  const { configuration } = props;
  const [refreshEnable, setRefreshEnable] = useState(true);
  const [widgets, setWidgets] = useState([]);
  const [refreshTimestamp, setRefreshTimestamp] = useState(now());
  const [statsLastMonth, setStatsLastMonth] = useState();
  const [widgetDragging, setWidgetDragging] = useState(false);
  const scrollbarRef = React.useRef();

  useEffect(() => {
    props
      .getActivityStatistics({
        start_time: startDate,
        device_type: DeviceTypes.Datahub.id,
        type: 'monthly',
      })
      .then(resp => {
        setStatsLastMonth(resp.response);
      });
  }, [refreshTimestamp]);

  const onRefresh = () => {
    setRefreshEnable(false);
    setTimeout(() => {
      setRefreshEnable(true);
    }, 10000);
    setRefreshTimestamp(now());
  };

  const calcColorForMubMetric = (value, total, percent = null) => {
    if (!total && !percent) {
      return 'black';
    }
    const p = percent || value / total;
    if (p < 0.25) {
      return 'red';
    }
    if (p > 0.75) {
      return 'green';
    }
    return 'blue';
  };

  const initializeDashboard = () => {
    const widgetDE = {
      ...DevicesMetadata,
      render: (
        <Devices
          refreshTimestamp={refreshTimestamp}
          graphType={
            configuration?.[DevicesMetadata.id]?.chartType?.value ||
            DevicesMetadata.configuration.chartType.defaultValue
          }
        />),
      noPadding: true,
    };
    const widgetLO = {
      ...kitsLocationMetadata,
      render: <LocationOfYourKits refreshTimestamp={refreshTimestamp} />,
      noPadding: true,
    };
    const widgetHW = {
      ...hubsWithNoReadingsMetadata,
      render: <HubsWithoutReadings refreshTimestamp={refreshTimestamp} />,
    };

    const widgetACR = {
      ...monthlyOperatingRateMetadata,
      render: (
        <HubMetrics
          refreshTimestamp={refreshTimestamp}
          value={isNaN(statsLastMonth?.operating_rate) ? '-' : `${statsLastMonth?.operating_rate}%`}
          label={Strings.widgets.monthlyOperatingRate}
          color={calcColorForMubMetric(null, null, statsLastMonth?.operating_rate / 100)}
          tooltip={Strings.widgetsTooltips.monthlyOperatingRate}
          dragging={widgetDragging}
        />
      ),
    };
    
    const capWidgets = getWidgetsMetadata()
    .filter(w => ![DevicesMetadata.id, hubsWithNoReadingsMetadata.id, kitsLocationMetadata.id, monthlyOperatingRateMetadata.id].includes(w.id))
    .map(w => {
      return {
        ...w,
        render: (
          <HubStats
            refreshTimestamp={refreshTimestamp}
            statsPeriod={w.id.split('-').pop()}
            deviceType={DeviceTypes[w.id.split('-').shift()]}
            widgetType={
              configuration?.[w.id]?.widgetType?.value ||
              deviceStatsWidgetTypes.numbers
            }
          />
        ),
        noPadding: true,
      };
    });

    const w = [
      widgetDE,
      widgetHW,
      widgetLO,
      widgetACR,
      ...capWidgets,
    ];
    setWidgets(w);
  };

  useEffect(() => {
    initializeDashboard();
  }, [refreshTimestamp, statsLastMonth, widgetDragging, configuration]);

  return (
    <>
      <PageHeader
        isBlack
        left={`${Strings.welcome} ${props.userName}`}
        right={
          <>
            <DashboardEditModeButton dashboardId={DASHBOARD_ID} />
            {props.dashboardSettingsFeature && (
              <div className="settings-button">
                <Button class="settings-button-icon" onClick={props.configDashboard}></Button>
              </div>
            )}
            <DashboardFilter dashboardId={DASHBOARD_ID} />
            {/* <Button class="download" onClick={this.onDownload} /> */}
            <Button class="refresh" disabled={!refreshEnable} onClick={onRefresh} />
          </>
        }
      />
      {widgets.length > 0 && !_.isEmpty(DefaultLayouts) && (
        <OverlayScrollbarsComponent
          defer
          className="scrollbar-right-margin"
          options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100' } }}
          ref={scrollbarRef}
        >
          <DashboardLayout
            dashboardId={DASHBOARD_ID}
            widgets={widgets}
            defaultLayouts={DefaultLayouts}
            rowHeight={65}
            cols={{ l: 12, m: 9, s: 4 }}
            onDragStart={() => setWidgetDragging(true)}
            onDragStop={() => setWidgetDragging(false)}
            scrollbarRef={scrollbarRef}
          />
        </OverlayScrollbarsComponent>
      )}
    </>
  );
}

DevicesDashboard.propTypes = {
  userName: PropTypes.string,
  timezone: PropTypes.string,
  filters: PropTypes.array,
  initializeDashboard: PropTypes.func,
  getActivityStatistics: PropTypes.func,
};

const mapStateToProps = state => {
  const { profile } = state.auth;
  const { dashboardFilters } = state.dashboardState;
  const filters = dashboardFilters[DASHBOARD_ID];
  const labFeatures = state.labState.features;
  return {
    userName: profile ? (profile.first_name ? `${profile.first_name} ${profile.last_name}` : profile.username) : '',
    timezone: state.auth?.profile?.preferences?.timezone,
    filters,
    dashboardSettingsFeature: labFeatures.find(f => f.name === 'Dashboard configuration')?.selected,
    configuration: state.dashboardState.configuration[DASHBOARD_ID],
  };
};

const mapDispatchToProps = dispatch => ({
  initializeDashboard: (dashboardId, defaultLayouts, widgets, force) =>
    dispatch(actions.initializeDashboard(dashboardId, defaultLayouts, widgets, force)),
  getActivityStatistics: request => dispatch(actionsWidgets.getActivityStatistics(request)),
  configDashboard: () =>
    dispatch(openModalAction('dashboard-configuration', { dashboardId: DASHBOARD_ID, additionalData: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(DevicesDashboard);
