import React from 'react';
import moment from 'moment-timezone';

import Strings from '../Strings';
import { DATE_MONTH_DAY_YEAR, RPM_PATIENT_STATUS } from '../constants';
import { ACTIVATION_METHODS } from './enrollmentFields';

export const RTM = 'rtm';
export const RPM = 'rpm';
export const CCM = 'ccm';
export const PCM = 'pcm';
export const RTMMusculoskeletal = 'musculoskeletal';
export const RTMRespiratory = 'respiratory';
export const RTMBehavioralHealth = 'behavioralHealth';
export const CCMBasic = 'basic';
export const CCMComplex = 'complex';
export const programs = { RTM, RPM, CCM, PCM };
export const subprograms = { RTMMusculoskeletal, RTMRespiratory, RTMBehavioralHealth, RPM, CCMBasic, CCMComplex, PCM };

const getProgramAndSubprogramString = program => {
  const pr = programs[program.program];
  const subprogram = program.subprogram != program.program ? subprograms[program.subprogram] : null;

  return `${Strings.programs[pr]}${subprogram ? ` - ${Strings.subprograms[pr][subprogram]}` : ''}`;
};

export const datesText = program => {
  if (!program.enrollmentStart && !program.enrollmentEnd) {
    return '';
  }
  return `${program.enrollmentStart ? moment(program.enrollmentStart).format(DATE_MONTH_DAY_YEAR) : '?'} - ${
    program.enrollmentEnd ? moment(program.enrollmentEnd).format(DATE_MONTH_DAY_YEAR) : '?'
  }`;
};

const careProviderText = program => {
  if (!program.careProvider) {
    return '';
  }
  return `${program.careProvider.name} (${program.careProvider.organization})`;
};

export const getProgramsText = patientEnrollment => {
  if (!patientEnrollment || !patientEnrollment.enrollments) {
    return '-';
  }
  const enrollmentSorted = [];
  const defaultProgram = Object.values(patientEnrollment.enrollments).find(
    e => e.program === patientEnrollment.defaultProgram,
  );

  let text, tooltip;
  if (defaultProgram) {
    text = getProgramAndSubprogramString(defaultProgram);
    const dates = datesText(defaultProgram);
    const activateAt = defaultProgram.activationMethod !== ACTIVATION_METHODS.Manual
      ? `${Strings.capPatient.enrollmentWillActivate}: ${Strings.capPatient.enrollmentActivationModesEnum[defaultProgram.activationMethod]}`
      : '';
    const provider = careProviderText(defaultProgram);
    const providerText = provider ? `${Strings.capPatient.careProvider}: ${provider}` : '';
    const array = [dates, activateAt, providerText];
    tooltip = array.filter(v => v).join(', ');
    enrollmentSorted.push(
      <div
        className="icon-and-text-container"
        data-tooltip-content={tooltip}
        data-tooltip-id={tooltip ? 'tooltip' : undefined}
      >
        {defaultProgram.enrollmentStart && defaultProgram.status === 'verified' && (
          <div className="icon-container tick" />
        )}
        {text}
      </div>,
    );
  }
  Object.values(patientEnrollment.enrollments).forEach(v => {
    if (v.subprogram !== defaultProgram.subprogram) {
      text = getProgramAndSubprogramString(v);
      const dates = datesText(v);
      const activateAt = defaultProgram.activationMethod !== ACTIVATION_METHODS.Manual
      ? `${Strings.capPatient.enrollmentWillActivate}: ${Strings.capPatient.enrollmentActivationModesEnum[defaultProgram.activationMethod]}`
      : '';
      const provider = careProviderText(v);
      const providerText = provider ? `${Strings.capPatient.careProvider}: ${provider}` : '';
      const array = [dates, activateAt, providerText];
      tooltip = array.filter(t => t).join(', ');
      enrollmentSorted.push(
        <div
          className="icon-and-text-container"
          data-tooltip-content={tooltip}
          data-tooltip-id={tooltip ? 'tooltip' : undefined}
        >
          {v.enrollmentStart && v.status === 'verified' && <div className="icon-container tick" />}
          {text}
        </div>,
      );
    }
  });
  return <React.Fragment>{enrollmentSorted}</React.Fragment>;
};

export const flatProgramsSubprogramsList = [
  // id - for selects
  {
    name: RTM,
    subprogram: RTMMusculoskeletal,
    displayText: `${Strings.programs.rtm} - ${Strings.subprograms.rtm.musculoskeletal}`,
    id: 0,
  },
  {
    name: RTM,
    subprogram: RTMRespiratory,
    displayText: `${Strings.programs.rtm} - ${Strings.subprograms.rtm.respiratory}`,
    id: 1,
  },
  {
    name: RTM,
    subprogram: RTMBehavioralHealth,
    displayText: `${Strings.programs.rtm} - ${Strings.subprograms.rtm.behavioralHealth}`,
    id: 2,
  },
  { name: RPM, subprogram: RPM, displayText: Strings.programs.rpm, id: 3 },
  {
    name: CCM,
    subprogram: CCMBasic,
    displayText: `${Strings.programs.ccm} - ${Strings.subprograms.ccm.basic}`,
    id: 4,
  },
  {
    name: CCM,
    subprogram: CCMComplex,
    displayText: `${Strings.programs.ccm} - ${Strings.subprograms.ccm.complex}`,
    id: 5,
  },
  { name: PCM, subprogram: PCM, displayText: Strings.programs.pcm, id: 6 },
];

export const getProgramForApi = name => {
  return Object.keys(programs).find(key => programs[key] === name);
};

export const getSubprogramForApi = name => {
  return Object.keys(subprograms).find(key => subprograms[key] === name);
};

export const getProgramDisplayName = (program, subprogram = null) => {
  return `${Strings.programs[programs[program]]}${
    subprogram && subprogram !== program ? ` - ${Strings.subprograms[programs[program]][subprograms[subprogram]]}` : ''
  }`;
};

export const getProgramsFromPatient = (patientDetails, allowVerified = false) => {
  if (
    (patientDetails?.status !== RPM_PATIENT_STATUS.activated && !allowVerified) ||
    !patientDetails?.patientEnrollment ||
    !Object.keys(patientDetails?.patientEnrollment.enrollments).length === 0
  ) {
    return [];
  }
  const ret = [];
  Object.entries(patientDetails.patientEnrollment.enrollments).forEach(([key, v]) => {
    if ((v.kitAccepted === true || allowVerified) && (v.verbalConsent === true || v.writtenConsent === true)) {
      ret.push({
        ...v,
        enrollmentId: key,
        name: v.program,
        isDefault: v.program === patientDetails.patientEnrollment.defaultProgram,
      });
    }
  });
  return ret;
};
