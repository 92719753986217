import React from 'react';
import { connect } from 'react-redux';

import { closeModal } from '../../actions/modal';
import { Input, SelectField } from '../../containers/Form';
import { actions as kitActions } from '../../pages/Kits/redux/actions';
import { ASSIGN_DEVICE_TO_KIT_ERROR, KITS_LIST_RESULT } from '../../pages/Kits/redux/constants';
import { actions } from '../../pages/Organizations/redux/actions';
import Strings from '../../Strings';
import DeviceList from './DeviceList';
import Wizard from '../../containers/Modal/Wizard';
import { notificationActions } from '../../components/Notification/redux/actions';

const MAX_KIT_NAME_LENGTH = 50;
const MAX_DEVICES_IN_SELECT = 500;

function CreateKitAndAddDevices(props) {
  const kitId = props.data?.kitId;
  const [kitName, setKitName] = React.useState(props.data?.kitName);
  const [selectedOrgId, setselectedOrgId] = React.useState();
  const [devices, setDevices] = React.useState([]);
  const [unassignedDevices, setUnassignedDevices] = React.useState([]);
  const [asyncSelectNeeded, setAsyncSelectNeeded] = React.useState(false);
  const [highlightInvalidFields, setHighlightMissingFields] = React.useState(false);

  const editMode = props.data?.kitId;

  React.useEffect(() => {
    if (!props.organizations) {
      props.getOrgs();
    }

    if (editMode) {
      props.getKits({ search: kitId }).then(resp => {
        if (resp.type === KITS_LIST_RESULT) {
          setselectedOrgId(resp.response.kits[0].org_id);
        }
      });
    }
  }, []);

  React.useEffect(() => {
    if (selectedOrgId) {
      loadCaps(selectedOrgId);
    }
  }, [selectedOrgId]);

  const attachDevices = (kit_id, devices = []) => {
    const results = [];

    devices.forEach(device => {
      props.assignDeviceToKit(kit_id, { device_id: device.device_id }).then(r => {
        if (r.type === ASSIGN_DEVICE_TO_KIT_ERROR) {
          props.showNotification(r.response?.data?.error?.message, 5000, true);
        } else {
          results.push(r);

          if (results.length === devices.length) {
            props.parentRefresh();
            props.onCancel();
          }
        }
      });
    });
  };

  const onSubmit = async () => {
    const realDevices = devices.filter(e => e.device_id !== '');
    const areThereAny = realDevices.length > 0;

    const request = { name: kitName };
    if (editMode) {
      request.id = kitId;
    } else {
      request.org_id = selectedOrgId;
    }

    let id = editMode ? kitId : null;
    if (!editMode || kitName !== props.data?.kitName) {
      const resp = await props.onSubmit(request);

      if (resp.type === `${props.data.actionType}/error`) {
        props.showNotification(resp.response?.data?.error?.message, 5000, true);
      } else {
        id = resp.response.kit.id;
      }
    }

    if (areThereAny) {
      attachDevices(id, devices);
    } else {
      props.parentRefresh();
      props.onCancel();
    }
  };

  const clickOnDelete = record => {
    setDevices(d => d.filter(r => r !== record));
  };

  const loadCaps = orgId => {
    setDevices([]);
    props.loadCaps({ orgId, limit: MAX_DEVICES_IN_SELECT }).then(resp => {
      const data = resp.response.devices.map(e => {
        return {
          value: e.device_id,
          device_id: e.device_id,
          label: `${e.device_id}${e.device_name ? ` (${e.device_name})` : ''}`,
          device_name: e.device_name,
        };
      });
      setUnassignedDevices(data.filter(d => !devices.some(dv => dv.device_id === d.device_id)));
      setAsyncSelectNeeded(resp.response.pagination.totalRecords > MAX_DEVICES_IN_SELECT);
    });
  };

  const loadOptions = (value, callback) => {
    if (!value || value.length < 3) {
      return;
    }
    setTimeout(() => {
      props
        .loadCaps({
          search: value,
          orgId: selectedOrgId,
        })
        .then(resp =>
          callback(() => {
            const data = resp.response.devices.map(e => {
              return {
                value: e.device_id,
                device_id: e.device_id,
                label: `${e.device_id}${e.device_name ? ` (${e.device_name})` : ''}`,
                device_name: e.device_name,
              };
            });
            return data.filter(d => !devices.some(dv => dv.device_id === d.device_id));
          }),
        );
    }, 1000);
  };

  const listOfDevices = () => {
    const realDevices = devices.filter(e => e.device_id !== '');
    const areThereDevices = realDevices.length > 0;
    return (
      <React.Fragment>
        {areThereDevices && <label className="header-label">{Strings.devices}:</label>}
        {areThereDevices &&
          realDevices.map(device => (
            <li className="mb-20" key={device.device_id}>
              {device.label}
            </li>
          ))}
      </React.Fragment>
    );
  };

  const dataPage = (
    <React.Fragment>
      {!editMode && (
        <SelectField
          name="organization"
          label={Strings.organization}
          key={`org-list-${props.organizations.length}`}
          onChange={option => setselectedOrgId(option.masked_id)}
          data={props.organizations.map(org => ({
            value: org.masked_id,
            label: org.name,
            masked_id: org.masked_id,
          }))}
          isSearchable
          isRequired
          searchByLabelOnly
          highlightInvalid={highlightInvalidFields}
          value={selectedOrgId}
          placeholder={Strings.select}
        />
      )}
      <Input
        label={Strings.kitName}
        type="text"
        name="kitName"
        id="kitName"
        onChange={e => setKitName(e.target.value)}
        value={kitName}
        maxLength={MAX_KIT_NAME_LENGTH}
      />
      <DeviceList
        asyncNeeded={asyncSelectNeeded}
        delete={clickOnDelete}
        devices={devices}
        data={unassignedDevices}
        handleChange={option => setDevices(d => d.concat(option))}
        loadOptions={loadOptions}
      />
    </React.Fragment>
  );
  const reviewPage = (
    <React.Fragment>
      <label className="header-label">
        {Strings.kitName} : {kitName || 'N/A'}
      </label>
      {editMode ? null : (
        <label className="header-label">
          {Strings.organization} : {props.organizations.find(o => o.masked_id === selectedOrgId)?.name}
        </label>
      )}
      {listOfDevices()}
    </React.Fragment>
  );

  const pages = [
    {
      id: 'edit-kit',
      title: editMode ? Strings.editKit : Strings.createKit,
      content: dataPage,
      emptyFieldsCount: selectedOrgId ? 0 : 1,
      canGoNext: !!selectedOrgId,
      nextButton: { text: Strings.next },
    },
    {
      id: 'review',
      title: Strings.review,
      content: reviewPage,
      canGoNext: true,
      nextButton: { text: editMode ? Strings.save : Strings.create },
    },
  ];

  return (
    <Wizard
      name="create-kit-and-add-devices"
      pages={pages}
      onNextButtonHover={e => setHighlightMissingFields(e)}
      onSubmit={onSubmit}
      onCancelAction={props.onCancel}
      showPagesFilter={false}
    />
  );
}

const mapStateToProps = state => ({
  AttachCaptoAdminModalLoading: state.entities.caps.loading,
  organizations: state.superUser?.organizations?.organizationAll,
  devices: state.entities.kits?.devices,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('create-kit-and-add-devices')),
  getOrgs: request => dispatch(actions.getOrgs(request)),
  getKits: pageRequest => dispatch(kitActions.getKits(pageRequest)),
  onSubmit: request => dispatch(ownProps.data.action(request)),
  updateKit: (id, request) => dispatch(kitActions.updateKit(id, request)),
  createKit: data => dispatch(kitActions.createKit(data)),
  assignDeviceToKit: (kitId, data) => dispatch(kitActions.assignDeviceToKit(kitId, data)),
  loadCaps: pageRequest => dispatch(kitActions.getDevicesUnassignedToKit(pageRequest)),
  parentRefresh: () => ownProps.data.onRefresh(),
  showNotification: (message, timeout, isError) => dispatch(notificationActions.show(message, timeout, isError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateKitAndAddDevices);
