import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ReferenceLine, ResponsiveContainer, Bar, Cell, Tooltip, XAxis, YAxis, ComposedChart } from 'recharts';

import {
  GRAPH_AXIS_LINE_COLOR,
  GRAPH_AXIS_LINE_WIDTH,
  GRAPH_REF_LINE_DASH,
  MINIMUM_PIXELS_PER_DAY_STEPS,
  MIN_GRAPH_HEIGHT,
  OPTIMUM_PIXELS_PER_DAY_STEPS,
  RANGE_PICKER_HEIGHT,
  RANGE_PICKER_HEIGHT_MOBILE,
} from './GraphConstants';
import './graph.scss';
import {
  CustomReferenceLine,
  CustomizedYAxisTick,
  MobileTooltipReferenceLine,
  calcDays,
  calcHours,
  referenceAreas,
} from './GraphComponents';
import { RangePicker } from './CompositeGraph/RangePicker';
import { DEFAULT_DATE_RANGE_DAYS, mobileDataRangeTypes } from './CompositeGraph/Metrics';
import {
  X_AXIS_CHART_HEIGHT_TINY,
  X_AXIS_PADDING,
  Y_AXIS_LEFT_WIDTH,
  Y_AXIS_RIGHT_WIDTH,
  Y_AXIS_WIDTH_TINY,
} from './CompositeGraph/Constants';
import { calcXAxisParams_Time, calcXAxisParams_TimeMobile, drawXAxis } from './CompositeGraph/Axes';
import {
  calcDateRangeAround,
  defaultOnDrag,
  defaultOnDragEnd,
  defaultOnDragEnd_Mobile,
  defaultOnDrag_Mobile,
} from './CompositeGraph/ZoomHelpers';
import { useGesture } from '@use-gesture/react';
import { isDesktop, isMobile } from 'react-device-detect';
import { MobileRangePicker } from './CompositeGraph/MobileRangePicker';
import CompositeGraphXAxis from './CompositeGraph/CompositeGraphXAxis';
import { getColorsForColorTheme } from '../../utils/colorTheme';
import { TooltipWidgets } from './TooltipWidgets';
import { scatterDotShapeEnum, symbolDotSizeEnum } from './CompositeGraph/Formatting';
import Strings from '../../Strings';

const graphMaxBarSize = 10;
const graphBarFill = '#74B236';
const graphBarFillBad = '#DA547D';

const graphReferenceVerticalLineColor = '#eeeeee';
const graphReferenceHorizontalLineColor = '#979797';

export default function StepsGraph(props) {
  const calcInitialDateRange = range => {
    const lastEventWithSteps = props.graphDataDaily.findLast(d => d.steps > 0);
    if (!isMobile) {
      const newStart = lastEventWithSteps
        ? lastEventWithSteps.date
            .clone()
            .subtract(range, 'days')
            .startOf('day')
        : moment()
            .subtract(range, 'days')
            .startOf('day');
      const newEnd = lastEventWithSteps
        ? lastEventWithSteps.date
            .clone()
            .add(1, 'day')
            .startOf('day')
        : moment()
            // .add(1, 'day')
            .startOf('day');

      const maxRangeStart = props.minDate.clone().startOf('day');
      const maxRangeEnd = props.maxDate.clone().startOf('day');

      return {
        start: newStart.unix() >= maxRangeStart.unix() ? newStart : maxRangeStart,
        end: newEnd,
      };
    } else {
      const dr = calcDateRangeAround(
        lastEventWithSteps ? lastEventWithSteps.date : moment(),
        mobileDataRangeTypes.twoWeeks,
      );
      return dr;
    }
  };

  const [graphWidth, setGraphWidth] = useState();
  const [dateRange, setDateRange] = useState(calcInitialDateRange(DEFAULT_DATE_RANGE_DAYS));
  const maxRange = {
    start: props.minDate.clone().startOf('day'),
    end: props.maxDate.clone().startOf('day'),
  };

  const graphContainerRef = React.useCallback(
    node => {
      if (node !== null) {
        const width = node.getBoundingClientRect().width;
        setGraphWidth(width);
        setDateRange(calcInitialDateRange(width / OPTIMUM_PIXELS_PER_DAY_STEPS));
      }
    },
    [props.plotResizeTimestamp],
  );

  const [mobileTooltipPayload, setMobileTooltipPayload] = useState(undefined);

  useEffect(() => {
    setMobileTooltipPayload(undefined);
  }, [dateRange]);

  const fixDaysOrHours = (rawData, days, offset) => {
    const ret = [];

    for (let index = 0; index <= days.length - 1; index += 1) {
      const thisDay = days[index].unix();
      const daySteps = rawData.filter(x => x.x === thisDay + offset);
      if (daySteps.length > 0) ret.push({ date: days[index], x: thisDay + offset, steps: daySteps[0].steps });
      else ret.push({ date: days[index], x: thisDay + offset });
    }

    return ret;
  };

  const calcYAxisParams = (data, ignoreStepsRecommended) => {
    const dataMin = 0;
    const dataMax = Math.max(...data.map(x => x.steps), ignoreStepsRecommended ? 0 : props.stepsRecommended);

    const axisTick =
      dataMax > 18000
        ? 5000
        : dataMax > 9000
        ? 2000
        : dataMax > 4500
        ? 1000
        : dataMax > 1800
        ? 500
        : dataMax > 900
        ? 200
        : 100;
    const axisMin = 0;
    let axisMax = dataMax - (dataMax % axisTick) + axisTick;
    if (axisMax < 500) axisMax = 500;
    const axisDelta = axisMax - axisMin;

    return {
      dataMin,
      dataMax,
      axisMin,
      axisMax,
      axisTick,
      axisTicks: axisDelta / axisTick + 1,
    };
  };

  const calcXAxisParams_PseudoCat = (dateMinDay, dateMaxDay, graphWidth) => {
    let ret = calcXAxisParams_Time(
      dateMinDay,
      dateMaxDay.clone().add(1, 'days'),
      false,
      graphWidth,
      OPTIMUM_PIXELS_PER_DAY_STEPS * 2,
      undefined,
      ['2h', '4h', '7d', '3m'],
    );

    if (ret.unit !== 'hour') {
      ret = calcXAxisParams_Time(
        dateMinDay,
        dateMaxDay,
        false,
        graphWidth,
        OPTIMUM_PIXELS_PER_DAY_STEPS,
        ['d', 'm', 'y'],
        ['2h', '4h', '7d', '3m'],
      );
    }

    const marginLeft = ret.unit === 'hour' ? 30 * 60 : 0;
    const marginRight = ret.unit === 'hour' ? 30 * 60 : 24 * 60 * 60;
    ret.min = ret.min - marginLeft;
    ret.max = ret.max + marginRight;

    return ret;
  };

  const calcRefLines = (days, xAxisParams) => {
    // hours => 1 day refLines
    if (xAxisParams.unit === 'hour') {
      return days.slice(1, days.length).map(d => d.unix());
    }

    // days => 1 week refLines
    if (xAxisParams.unit === 'day') {
      const ret = [];
      for (
        let i = moment(xAxisParams.min * 1000)
          .startOf('week')
          .add(1, 'week');
        i.unix() < xAxisParams.max;
        i.add(1, 'weeks')
      ) {
        ret.push(i.unix());
      }
      return ret;
    }

    // weeks or months => 1 month refLines
    if (xAxisParams.unit === 'month') {
      const ret = [];
      for (
        let i = moment(xAxisParams.min * 1000)
          .startOf('month')
          .add(1, 'month');
        i.unix() < xAxisParams.max;
        i.add(1, 'months')
      ) {
        ret.push(i.unix());
      }
      return ret;
    }

    // no refLines otherwise
    return [];
  };

  const { graphDataDaily, graphDataHourly, stepsRecommended } = props;
  //console.log(graphData);
  const rawDataDaily = graphDataDaily.map(d => {
    return {
      ...d,
      x: d.date
        .startOf('day')
        .add(12, 'hours')
        .unix(),
    };
  });
  const rawDataHourly = graphDataHourly.map(d => {
    return {
      ...d,
      x: d.date
        .startOf('hour')
        .add(30, 'minutes')
        .unix(),
    };
  });

  let xAxisParams = calcXAxisParams_PseudoCat(dateRange.start, dateRange.end, graphWidth);
  if (isMobile) {
    // && xAxisParams.unit !== 'hour') {
    xAxisParams = calcXAxisParams_TimeMobile(dateRange);
  }

  const switchToHours = xAxisParams.unit === 'hour';

  const days = calcDays(dateRange.start, dateRange.end);
  const hours = calcHours(dateRange.start, dateRange.end);

  const offsetD = 12 * 60 * 60;
  const offsetH = 30 * 60;
  const currentOffset = switchToHours ? offsetH : offsetD;
  const fixedDataHourly = fixDaysOrHours(rawDataHourly, hours, offsetH);
  const fixedDataDaily = fixDaysOrHours(rawDataDaily, days, offsetD);

  const fixedData = switchToHours ? fixedDataHourly : fixedDataDaily;
  const refLines = calcRefLines(days, xAxisParams);

  const yAxisParams = calcYAxisParams(fixedData, switchToHours);

  const badDay = day => {
    if (
      day.date >=
      moment()
        .startOf('day')
        .unix()
    )
      return false;
    return !day.steps || day.steps < stepsRecommended;
  };

  const warningDays = fixedData.filter(d => d.date && badDay(d)).map(d => d.date);

  const CustomTooltip = ({ active, label }) => {
    if (active && fixedData) {
      const payload = fixedData.filter(d => d.x === label && d.steps != null);

      if (payload && payload.length > 0) {
        return (
          <div className="customTooltip">
            <div>
              <p className="customTooltipTitle">
                {moment(payload[0].date).format('ll')}
                {switchToHours && <br />}
                {switchToHours && moment(payload[0].date).format('h a')}
              </p>

              <div className="customTooltipDescr">{payload[0].steps} Steps</div>
            </div>
          </div>
        );
      }
    }
    return null;
  };

  const onBrushChange = (startTimestamp, endTimestamp) => {
    const start = moment(startTimestamp * 1000);
    const end = moment(endTimestamp * 1000);
    if (start.isValid() && end.isValid()) {
      const range = {
        start: start,
        end: end.startOf('day'),
      };
      setDateRange(range);
    }
  };

  const calcMaxSelectionDays = () => {
    return Math.floor(graphWidth / MINIMUM_PIXELS_PER_DAY_STEPS);
  };
  const calcMinSelectionDays = () => {
    return 1;
  };

  const bindGestures = isDesktop
    ? () => {}
    : useGesture(
        {
          onDrag: state =>
            !isMobile
              ? defaultOnDrag(state)
              : defaultOnDrag_Mobile(
                  state,
                  graphWidth,
                  { dateRange, maxRange },
                  newDateRange => setDateRange(newDateRange),
                  false,
                ),
          onDragEnd: state =>
            !isMobile
              ? defaultOnDragEnd(
                  state,
                  1.8,
                  dateRange.start,
                  dateRange.end,
                  maxRange.start,
                  maxRange.end,
                  calcMinSelectionDays() - 1,
                  calcMaxSelectionDays(),
                  newDateRange => setDateRange(newDateRange),
                )
              : defaultOnDragEnd_Mobile(state, dateRange, maxRange, newDateRange => setDateRange(newDateRange)),
        },
        {
          drag: { threshold: 5 },
        },
      );

  const calcMobileTooltipPayload = x => {
    if (x === undefined) return undefined;

    // console.log(fixedData);
    // console.log('searching', x);

    const dayData = fixedData.find(d => d.x === x);
    if (dayData === undefined) return undefined;

    // console.log(dayData);
    return { x, metrics: [{ name: 'steps', y: dayData.steps || 0 }] };
  };

  const onChartClick = e => {
    if (!isMobile) return;

    if (e) {
      if (e.activePayload && e.activePayload.length > 0 && e.activePayload[0].name && e.activePayload[0].payload) {
        const payloadX = e.activePayload[0].payload.x;
        if (payloadX >= dateRange.start.unix() && payloadX <= dateRange.end.unix()) {
          const payloadDifferent = !mobileTooltipPayload || mobileTooltipPayload.x !== payloadX;

          if (payloadDifferent) {
            const tooltipPayload = calcMobileTooltipPayload(payloadX);
            setMobileTooltipPayload(tooltipPayload);
            // console.log('SETTING tooltipPayload', tooltipPayload);
            return;
          }
        }
      }
    }

    setMobileTooltipPayload(undefined);
  };

  const useTinyAxes = isMobile;
  const linesStrokeWidth = isMobile ? 0 : GRAPH_AXIS_LINE_WIDTH;

  return (
    <div
      className={`graphDiv ${props.hasBorder ? 'withBorder' : ''}`}
      style={{
        userSelect: 'none',
        touchAction: 'none',
        WebkitUserSelect: 'none',
      }}
      {...bindGestures()}
    >
      {isMobile && (
        <MobileRangePicker
          dateRange={dateRange}
          maxRange={maxRange}
          tooltipTimestamp={mobileTooltipPayload ? mobileTooltipPayload.x - currentOffset : undefined}
          tooltipDateSkipHour={!switchToHours}
          allowedRangeTypes={[
            mobileDataRangeTypes.day,
            mobileDataRangeTypes.week,
            mobileDataRangeTypes.twoWeeks,
            mobileDataRangeTypes.month,
          ]}
          onChange={newRange => setDateRange(newRange)}
        />
      )}
      <div
        style={{
          height: `calc(100% - ${
            useTinyAxes ? RANGE_PICKER_HEIGHT_MOBILE + X_AXIS_CHART_HEIGHT_TINY : RANGE_PICKER_HEIGHT
          }px)`,
          minHeight: MIN_GRAPH_HEIGHT,
          paddingRight: useTinyAxes ? Y_AXIS_WIDTH_TINY : 0,
          position: 'relative',
        }}
        className={useTinyAxes ? 'graph-surface-background' : undefined}
        ref={graphContainerRef}
      >
        {useTinyAxes && (
          <React.Fragment>
            <div
              style={{
                position: 'absolute',
                top: '-1px',
                height: 1,
                width: '100%',
                borderTop: 'solid 1px #D9D9D9',
              }}
            />
            <div
              style={{
                position: 'absolute',
                bottom: '-1px',
                height: 1,
                width: '100%',
                borderTop: 'solid 1px #D9D9D9',
              }}
            />
            <div
              style={{
                position: 'absolute',
                right: Y_AXIS_WIDTH_TINY - 1,
                height: '100%',
                width: 1,
                borderRight: 'solid 1px #D9D9D9',
              }}
            />
            <div
              style={{
                position: 'absolute',
                left: Y_AXIS_WIDTH_TINY - 1,
                height: '100%',
                width: 1,
                borderRight: 'solid 1px #D9D9D9',
              }}
            />
          </React.Fragment>
        )}
        <ResponsiveContainer className="graph-responsive-container">
          <ComposedChart
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
            data={fixedData}
            defaultShowTooltip={false}
            onClick={onChartClick}
          >
            {/* reference areas to mimic alternate rows */}
            {referenceAreas(yAxisParams, useTinyAxes, X_AXIS_PADDING)}

            {refLines.map(rl => (
              <ReferenceLine
                x={rl}
                stroke={GRAPH_AXIS_LINE_COLOR}
                fill={GRAPH_AXIS_LINE_COLOR}
                key={`refLine_${rl}`}
                shape={<CustomReferenceLine xAxisParams={xAxisParams} skipAxis={isMobile} />}
                yAxisId="yAxis1"
              />
            ))}

            <YAxis
              type="number"
              dataKey="steps"
              name="hours"
              yAxisId="yAxis1"
              orientation="left"
              tick={
                useTinyAxes ? (
                  false
                ) : (
                  <CustomizedYAxisTick
                    axisMax={yAxisParams.axisMax}
                    dateMin={dateRange.start}
                    dateMax={dateRange.end}
                    axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: linesStrokeWidth }}
                    withoutHeader={useTinyAxes}
                  />
                )
              }
              domain={[yAxisParams.axisMin, yAxisParams.axisMax]}
              tickCount={yAxisParams.axisTicks}
              tickSize={0}
              width={useTinyAxes ? Y_AXIS_WIDTH_TINY : Y_AXIS_LEFT_WIDTH}
              axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: linesStrokeWidth }}
            />
            <YAxis
              type="number"
              dataKey="steps"
              name="hours"
              yAxisId="yAxis2"
              orientation="right"
              tick={
                useTinyAxes ? (
                  <CustomizedYAxisTick
                    axisMax={yAxisParams.axisMax}
                    dateMin={dateRange.start}
                    dateMax={dateRange.end}
                    axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: linesStrokeWidth }}
                    withoutHeader={useTinyAxes}
                    isRight
                  />
                ) : (
                  false
                )
              }
              domain={[yAxisParams.axisMin, yAxisParams.axisMax]}
              tickCount={yAxisParams.axisTicks}
              tickSize={0}
              width={useTinyAxes ? Y_AXIS_WIDTH_TINY : 0}
              axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: linesStrokeWidth }}
            />

            {isMobile && mobileTooltipPayload?.x && (
              <ReferenceLine
                x={mobileTooltipPayload.x}
                yAxisId="yAxis1"
                shape={<MobileTooltipReferenceLine customColor={getColorsForColorTheme().mainColor} />}
              />
            )}

            <Tooltip content={<CustomTooltip />} dataKey="x" isAnimationActive={false} />

            {drawXAxis(xAxisParams, useTinyAxes ? 0 : 60, 'top', null, useTinyAxes)}

            {!switchToHours && (
              <ReferenceLine
                y={stepsRecommended}
                stroke={graphReferenceHorizontalLineColor}
                strokeDasharray={GRAPH_REF_LINE_DASH}
                yAxisId="yAxis1"
              />
            )}

            <Bar
              tooltipType="none"
              dataKey="steps"
              radius={3}
              fill={graphBarFill}
              fillOpacity={1}
              maxBarSize={graphMaxBarSize}
              yAxisId="yAxis1"
            >
              {fixedData.map((entry, index) => {
                let isBad = false;
                if (switchToHours) {
                  const theDay = fixedDataDaily.find(
                    d =>
                      d.date
                        .clone()
                        .startOf('day')
                        .unix() ===
                      entry.date
                        .clone()
                        .startOf('day')
                        .unix(),
                  );
                  if (theDay) {
                    isBad = theDay.steps < stepsRecommended;
                  }
                } else {
                  isBad = entry.steps < stepsRecommended;
                }
                const color = isBad ? graphBarFillBad : graphBarFill;
                return <Cell key={`cell-${index}`} stroke={color} fill={color} />;
              })}
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      {!useTinyAxes && <div className="horizontal-rule" />}
      {useTinyAxes && <CompositeGraphXAxis dateRange={dateRange} xAxisParams={xAxisParams} useTinyAxes />}
      {isMobile && mobileTooltipPayload && console.log(mobileTooltipPayload)}
      {isMobile && mobileTooltipPayload && (
        <TooltipWidgets
          tooltipPayload={mobileTooltipPayload}
          metricsOverride={[
            {
              id: 'steps',
              label: Strings.steps,
              format: {
                stroke: mobileTooltipPayload.metrics?.[0]?.y < stepsRecommended ? graphBarFillBad : graphBarFill,
                dotShape: scatterDotShapeEnum.square,
                dotSize: symbolDotSizeEnum.small,
              },
            },
          ]}
        />
      )}
      {!isMobile && (
        <RangePicker
          startTimestamp={dateRange.start.unix()}
          endTimestamp={dateRange.end.unix()}
          dataStartTimestamp={maxRange.start.unix()}
          resolution="days"
          ticks={Math.round((maxRange.end.unix() - maxRange.start.unix()) / (24 * 60 * 60))}
          width="100%"
          leftPadding={Y_AXIS_LEFT_WIDTH + 1}
          rightPadding={Y_AXIS_RIGHT_WIDTH}
          onChange={onBrushChange}
          dateRange={maxRange}
          maxSelectionTicks={calcMaxSelectionDays()}
        />
      )}
    </div>
  );
}

StepsGraph.propTypes = {
  graphDataDaily: PropTypes.array,
  graphDataHourly: PropTypes.array,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  onDayClick: PropTypes.func,
  stepsRecommended: PropTypes.number,
  hasBorder: PropTypes.bool,
};

StepsGraph.defaultProps = { hasBorder: true };
