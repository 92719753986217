import React from 'react';
import { connect } from 'react-redux';

import { MultiSelectField, SelectField } from '../../containers/Form';
import Strings from '../../Strings';
import kitIdImg from '../../images/kitId.png';
import { actions } from '../../pages/SuperUser/Patients/Cohorts/redux/actions';
import { GET_DEVICE_VITALS_RESULT } from '../../pages/SuperUser/Patients/Cohorts/redux/constants';
import { SelectedOptions } from '../../components/Select/Multiselect';
import { RPM } from '../../utils/cmsPrograms';

function KitPage (props) {
  const { enrollment, setEnrollment, validationErrors, highlightInvalidFields, fields, kits, getDeviceVitals } = props;

  const [showKitPicture, setShowKitPicture] = React.useState(false);
  const [vitals, setVitals] = React.useState([]);

  React.useEffect(() => {
    if (enrollment.kit_id) {
      getDeviceVitals({ kit: enrollment.kit_id}).then(resp => {
        if (resp.type === GET_DEVICE_VITALS_RESULT) {
          setVitals(resp.response.vitals);
        }
      });
    }
  }, [enrollment.kit_id]);

  const kitPage = (
    <>
      <div className="control-with-top-right-text double-width-left">
        <SelectField
          id="kit_id"
          name="kit_id"
          label={Strings.kitId}
          placeholder={Strings.scanBarcodeOrEnterId}
          value={enrollment?.kit_id}
          data={kits}
          onChange={option => setEnrollment({ ...enrollment, kit_id: option.value })}
          isSearchable
          isRequired={fields.kit_id.required}
          disabled={fields.kit_id.readOnly}
          errorsForTooltip={validationErrors.find(v => v.property === 'kit_id')?.errors || []}
          highlightInvalid={highlightInvalidFields}
          rowClassName="double-width-left"
        />
        <div className="right-top-text">
          <span className="label">{Strings.capPatient.whereIsKitId}</span>
          <span
            onMouseEnter={() => setShowKitPicture(true)}
            onMouseLeave={() => setShowKitPicture(false)}
            className="icon"
          >
            ?
          </span>
        </div>
      </div>
      {vitals.length > 0 && enrollment.subprogram === RPM && (
        <MultiSelectField
          name="vitals"
          id="vitals"
          label={Strings.vitals}
          placeholder={Strings.selectVitals}
          value={enrollment.vitals || []}
          data={vitals.map(v => ({ value: v, label: v }))}
          onChange={options => setEnrollment({ ...enrollment, vitals: options.map(o => o.value)})}
          isRequired={fields.vitals.required}
          disabled={fields.vitals.readOnly}
          highlightInvalid={highlightInvalidFields}
          errorsForTooltip={validationErrors.find(v => v.property === 'vitals')?.errors || []}
          rowClassName="double-width-right"
          closeMenuOnSelect={false}
        />
      )}
      {enrollment?.vitals?.length > 0 && (
        <SelectedOptions
          items={enrollment.vitals.map(v => ({ value: v, label: v })) || []}
          onRemove={v => setEnrollment(e => ({ ...e, vitals: e.vitals.filter(h => h !== v.value) }))}
          itemsPerRow={2}
          className="double-width-right"
          style={{ gridRow: 'span 2' }}
        />
      )}

      {showKitPicture && (
        <div className="kit-location">
          <img src={kitIdImg} className="hwImg" alt="hardware" />
          {Strings.wheresKitIdText}
        </div>
      )}
    </>
  );

  return kitPage;
};


const mapDispatchToProps = dispatch => ({
  getDeviceVitals: pageRequest => dispatch(actions.getDeviceVitals(pageRequest)),
});

export default connect(null, mapDispatchToProps)(KitPage);
