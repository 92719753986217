import React from 'react';
import PropTypes from 'prop-types';

import { getFiltersDefinition } from '../FiltersDefinition';
import { isBoolean } from 'lodash';

export function getValueText(value, group, state, unit, customFilters) {
  if (!isBoolean(state)) {
    return `${state} ${unit}`;
  }
  const filters = Object.assign({}, getFiltersDefinition(), customFilters);
  return filters[group].values?.find(e => e.value === value)?.text;
}

function BarItem(props) {
  const { filter, updateFilterState, customFilters } = props;
  const filters = getFiltersDefinition(customFilters);
  const mergedFilters = { ...filters, ...customFilters };
  const filterDefinition = mergedFilters[filter.group];
  if (!filterDefinition) {
    return <React.Fragment />;
  }
  const text = `${filterDefinition.text}: ${getValueText(
    filter.value,
    filter.group,
    filter.state,
    filterDefinition.unit,
    customFilters,
  )}`;

  return (
    <div
      className="bar-item"
      onClick={() => {
        updateFilterState({
          group: filter.group,
          value: filter.value,
          state: false,
        }, filterDefinition.type);
      }}
    >
      <div className="item-label">
        <div>{text}</div>
        {filterDefinition.itemRender ? filterDefinition.itemRender(filter) : null}
      </div>
      <div className="remove-icon" />
    </div>
  );
}

BarItem.propTypes = {
  filter: PropTypes.object.isRequired,
  updateFilterState: PropTypes.func.isRequired,
};

export default BarItem;
