import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import Wizard from '../containers/Modal/Wizard';
import Strings from '../Strings';
import { Input, MultiSelectField } from '../containers/Form';
import { notificationActions } from '../components/Notification/redux/actions';
import { closeModal } from '../actions/modal';
import { actions } from '../pages/Organizations/redux/actions';
import userActions, { LIST_USERS_ERROR } from '../actions/user';
import { ORGANIZATION_MANAGER } from '../utils/userRoles';
import { ADD_USER_TO_ORG_GROUP_ERROR, CREATE_ORAGANIZATION_GROUP_ERROR, EDIT_ORAGANIZATION_GROUP_ERROR, ORGANIZATION_LIST_ERROR } from '../pages/Organizations/redux/constants';
import { SelectedOptions } from '../components/Select/Multiselect';

export const EditOrgGroupMode = {
  EDIT: 'edit',
  ADD_ORGS: 'add-orgs',
  ADD_MAINTAINERS: 'add-maintainers',
  ADD_USERS: 'add-users',
};

function EditOrgGroupModal(props) {
  const { data, getOrgManagers, getOrganizations, onSubmit, addUser, showNotification, closeModalWithNextAction } = props;
  const mode = data?.mode || EditOrgGroupMode.EDIT;
  const [group, setGroup] = React.useState({
    ...data?.group,
    maintainers: data?.group?.maintainers?.map(m => m.id),
    organizations: data?.group?.organizations?.map(o => o.id)
  });
  const [users, setUsers] = React.useState(data?.users?.map(m => m.id) || []);
  const [organizations, setOrganizations] = React.useState([]);
  const [orgManagers, setOrgManagers] = React.useState([]);
  const [highlightInvalidFields, setHighlightMissingFields] = React.useState(false);
  const [saveBtnClicked, setSaveBtnClicked] = React.useState(false);

  useEffect(() => {
    getOrgManagers({limit: 1000}).then(response => {
      if (response.type !== LIST_USERS_ERROR && response?.response) {
        let res = response.response.data.map(m => ({...m, label: `${m.username} (${m.organization_name})`, value: m.id}));
        if (data?.group?.maintainers?.some(m => !res.find(om => om.value === m.id))) {
          res = orgManagers.concat(data.group.maintainers.filter(m => !res.find(om => om.value === m.id)).map(m => ({label: m.name, value: m.id})));
        }
        setOrgManagers(res);
      }
    });
    getOrganizations({limit: 1000}).then(response => {
      if (response.type !== ORGANIZATION_LIST_ERROR && response?.response) {
        let orgs = response.response.data.map(o => ({...o, label: o.name, value: o.masked_id}));
        if (data?.group?.organizations?.some(o => !orgs.find(og => og.value === o.id))) {
          orgs = orgs.concat(data?.group?.organizations.filter(o => !orgs.find(og => og.value === o.id)).map(o => ({label: o.name, value: o.id})));
        }
        setOrganizations(orgs);
      }
    });
  }, [mode]);

  const onSave = async () => {
    if (saveBtnClicked) {
        return;
    }
    setSaveBtnClicked(true);

    let fail = false;
    let groupId = data?.group?.id;

    if (group.name != data?.group?.name || !_.isEqual(group.organizations, data?.group?.organizations) || !_.isEqual(group.maintainers, data?.group?.maintainers)) {
      const request = {...group};
      // Remove maintainers, because they need to be added to users first
      if (!data?.group?.id) {
        delete request.maintainers;
      }
      const response = await onSubmit(request);
      if (response) {
        if (response.type === CREATE_ORAGANIZATION_GROUP_ERROR || response.type === EDIT_ORAGANIZATION_GROUP_ERROR) {
          const error = response.response?.data?.standard_error?.message;
          showNotification(error, 5000, true);
          fail = true;
        } else if (!groupId) {
          groupId = response.response.id;
        }
      } 
    }

    if (!fail) {
      const usersToAdd = users.filter(u => !data?.users?.includes(u));
      const addUserPromises = usersToAdd.map(user => addUser(groupId, user));
      const addUserResponses = await Promise.all(addUserPromises);
      addUserResponses.forEach(res => {
        if (res && res.type === ADD_USER_TO_ORG_GROUP_ERROR) {
          showNotification(res.response?.data?.error?.message, 5000, true);
          setSaveBtnClicked(false);
          fail = true;
        }
      });
    }

    // for new group, add maintainers
    if (!fail && !data?.group?.id && group.maintainers?.length > 0) {
      const request = {...group, id: groupId};
      const response = await onSubmit(request);
      if (response?.type === EDIT_ORAGANIZATION_GROUP_ERROR) {
        const error = response.response?.data?.standard_error?.message;
        showNotification(error, 5000, true);
        fail = true;
      }
    }

    if (!fail) {
      showNotification(Strings.formatString(Strings.itemSaved, group.name));
      closeModalWithNextAction();
    }
    setSaveBtnClicked(false);
  };

  const onDataChange = (name, value) => {
    setGroup(u => ({
      ...u,
      [name]: value,
    }));
  };

  const onOrgRemoved = option => {
    const orgId = option.value;
    onDataChange('organizations', group.organizations.filter(c => c !== orgId));
    onDataChange('maintainers', group.maintainers?.filter(m => !orgManagers.find(om => om.value === m && om.org_id === orgId)) || []);
  };

  const pages = [
    {
      id: 'org-group-name',
      title: group?.id ? Strings.editOrganizationGroup : Strings.createOrganizationGroup,
      content: (
        <Input
          name="name"
          id="name"
          label={Strings.name}
          type="text"
          value={group.name || ''}
          onChange={e => onDataChange('name', e.target.value)}
          isRequired
          highlightInvalid={highlightInvalidFields}
        />
      ),
      emptyFieldsCount: group.name ? 0 : 1,
      canGoNext: group.name && !saveBtnClicked,
    },
    {
      id: 'org-group-organizations',
      title: Strings.addRemoveOrganizations,
      content: organizations?.length > 0 ? (
        <>
          <MultiSelectField
            name="orgs"
            id="orgs"
            label={Strings.organizations}
            value={group.organizations || []}
            onChange={o => onDataChange('organizations', o.map(op => op.value))}
            data={organizations}
            highlightInvalid={highlightInvalidFields}
            closeMenuOnSelect={orgManagers?.length <= 1}
          />
          <SelectedOptions
            items={group.organizations?.map(id => {
              const org = organizations?.find(o => o.value === id);
              const disabled = orgManagers?.some(m => m.org_id === org.masked_id && group.maintainers?.includes(m.id)) 
              || orgManagers?.some(u => u.org_id === org.masked_id && users.includes(u.id));
              return org ? {
                ...org,
                disabled,
                tooltip: disabled ? Strings.cantDeleteOrgFromGroup : undefined,
              } : null;
            }) || []}
            onRemove={onOrgRemoved}
            itemsPerRow={1}
          />
        </>
      ) : Strings.noOrganizationsFound,
      emptyFieldsCount: 0,
      canGoNext: true,
    },
    {
      id: 'org-group-users',
      title: Strings.addRemoveUsers,
      content: (
        orgManagers?.length > 0 ? (
          <>
            <MultiSelectField
              name="users"
              id="users"
              label={Strings.users}
              value={users || []}
              onChange={o => setUsers(o.map(op => op.value))}
              data={orgManagers.filter(m => group.organizations?.includes(m.org_id))}
              highlightInvalid={highlightInvalidFields}
              closeMenuOnSelect={false}
            />
            <SelectedOptions
              items={users?.map(id => orgManagers?.find(o => o.value === id)) || []}
              onRemove={e => setUsers(users?.filter(c => c !== e.value))}
              itemsPerRow={1}
            />
          </>
        ) : Strings.noUsersFound
      ),
      emptyFieldsCount: 0,
      canGoNext: true,
    },
    {
      id: 'org-group-mainainers',
      title: Strings.addRemoveMaintainers,
      content: orgManagers?.length > 0 ? (
        <>
          <MultiSelectField
            name="maintainers"
            id="maintainers"
            label={Strings.maintainers}
            value={group.maintainers || []}
            onChange={o => onDataChange('maintainers', o.map(op => op.value))}
            data={orgManagers.filter(om => users.includes(om.id))}
            highlightInvalid={highlightInvalidFields}
            closeMenuOnSelect={orgManagers?.length <= 1}
          />
          <SelectedOptions
            items={group.maintainers?.map(id => orgManagers?.find(o => o.value === id)) || []}
            onRemove={e => onDataChange('maintainers', group.maintainers?.filter(c => c !== e.value))}
            itemsPerRow={1}
          />
        </>
      ) : Strings.noMaintainersFound,
      emptyFieldsCount: 0,
      canGoNext: true,
    },
  ];

  const getPages = () => {
    if (mode === EditOrgGroupMode.EDIT) {
      return pages;
    }
    if (mode === EditOrgGroupMode.ADD_ORGS) {
      return pages.filter(p => p.id === 'org-group-organizations');
    }
    if (mode === EditOrgGroupMode.ADD_MAINTAINERS) {
      return pages.filter(p => p.id === 'org-group-mainainers');
    }
    if (mode === EditOrgGroupMode.ADD_USERS) {
      return pages.filter(p => p.id === 'org-group-users');
    }
    return pages;
  };

  return (
    <Wizard
      name="edit-organization-group"
      pages={getPages()}
      onNextButtonHover={e => setHighlightMissingFields(e)}
      onSubmit={onSave}
      showPagesFilter={false}
    />
  );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  getOrgManagers: () => dispatch(userActions.list({archetype: ORGANIZATION_MANAGER})),
  getOrganizations: pageRequest => dispatch(actions.getOrgs(pageRequest)),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
  onCancel: () => dispatch(closeModal(ownProps.name)),
  onSubmit: data => {
    if (data.id) {
      return dispatch(actions.editOrganizationGroup(data.id, data));
    }
    return dispatch(actions.createOrganizationGroup(data));
  },
  addUser: (groupId, userId) => dispatch(actions.addUserToOrgGroup(groupId, userId)),
  closeModalWithNextAction: () => {
    dispatch(closeModal('edit-organization-group'));
    if (ownProps.data?.onSuccess) ownProps.data.onSuccess();
  },
});

EditOrgGroupModal.propTypes = {
  data: PropTypes.shape({
    mode: PropTypes.string,
    group: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      maintainers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })),
      organizations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })),
    }),
    users: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
  }),
  getOrgManagers: PropTypes.func.isRequired,
  getOrganizations: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  addUser: PropTypes.func.isRequired,
  closeModalWithNextAction: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(EditOrgGroupModal);
