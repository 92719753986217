import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Wizard from '../containers/Modal/Wizard';
import Strings from '../Strings';
import { SelectField } from '../containers/Form';
import { notificationActions } from '../components/Notification/redux/actions';
import { closeModal } from '../actions/modal';
import { actions } from '../pages/Organizations/redux/actions';
import userActions from '../actions/user';
import { ORGANIZATION_MANAGER } from '../utils/userRoles';
import { ADD_USER_TO_ORG_GROUP_ERROR } from '../pages/Organizations/redux/constants';


function AddUserToOrgGroup(props) {
  const { data, getUsers, onSubmit, showNotification, closeModalWithNextAction } = props;
  const { groupId } = data;
  const [users, setUsers] = React.useState([]);
  const [user, setUser] = React.useState();
  const [highlightInvalidFields, setHighlightMissingFields] = React.useState(false);
  const [saveBtnClicked, setSaveBtnClicked] = React.useState(false);

  useEffect(() => {
    getUsers({limit: 1000}).then(response => {
      setUsers(response?.response?.data || []);
    });
  }, []);

  const onSave = () => {
    if (saveBtnClicked) {
      return;
    }
    setSaveBtnClicked(true);

    onSubmit(groupId, user.id).then(response => {
      if (response) {
        if (response.type === ADD_USER_TO_ORG_GROUP_ERROR) {
          const error = response.response?.data?.standard_error?.message;
          showNotification(error, 5000, true);
        } else {
          showNotification(Strings.formatString(Strings.userAddedToOrgGroup, user.username));
          closeModalWithNextAction();
        }
      }
      return response;
    });
    setSaveBtnClicked(false);
  };

  const pages = [
    {
      id: 'add-user-to-org-group',
      title: Strings.addUser,
      content: (
        users?.length > 0 ? (
          <SelectField
            name="user"
            id="user"
            label={Strings.user}
            value={user?.id || ''}
            onChange={setUser}
            data={users.map(u => ({...u, label: u.username, value: u.id}))}
            highlightInvalid={highlightInvalidFields}
          />
        ) : Strings.noUsersFound
      ),
      emptyFieldsCount: user ? 0 : 1,
      canGoNext: user ? 1 : 0,
    },
  ];

  return (
    <Wizard
      name="add-user-to-organization-group"
      pages={pages}
      onNextButtonHover={e => setHighlightMissingFields(e)}
      onSubmit={onSave}
      showPagesFilter={false}
    />
  );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  getUsers: () => dispatch(userActions.list({archetype: ORGANIZATION_MANAGER})),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
  onCancel: () => dispatch(closeModal(ownProps.name)),
  onSubmit: (groupId, userId) => dispatch(actions.addUserToOrgGroup(groupId, userId)),
  closeModalWithNextAction: () => {
    dispatch(closeModal('add-user-to-organization-group'));
    if (ownProps.data?.onSuccess) ownProps.data.onSuccess();
  },
});

AddUserToOrgGroup.propTypes = {
  data: PropTypes.shape({
    groupId: PropTypes.string.isRequired,
  }).isRequired,
  getUsers: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  closeModalWithNextAction: PropTypes.func.isRequired,
};
export default connect(null, mapDispatchToProps)(AddUserToOrgGroup);
