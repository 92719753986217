import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { isMobile } from 'react-device-detect';

import TabView, { Tab } from '../../components/TabView';
import { hasPermission } from '../../utils/userPermissions';
import DeviceView from '../ThirdPartyDevices/ThirdPartyDevices';
import Hub from './Hub';
import Kits from './Kits';
import LTE from './LTE';
import { getActiveTabNameFromUrl } from '../../utils';
import './ShipperCaps.scss';
import { DeviceClass, DeviceTypes } from '../../utils/deviceTypes';
import BLE from './BLE';

export const root = 'devices';

class ShipperCaps extends PureComponent {
  render() {
    const activeTab = this.props.match.params.type;
    const tabArray = [];

    Object.entries(DeviceTypes).forEach(([key, deviceType]) => {
      if (hasPermission(deviceType.permission)) {
        let page;
        switch (deviceType.deviceClass) {
          case DeviceClass.thirdParty:
            page = <DeviceView deviceClass={key} />;
            break;
          case DeviceClass.cap:
            page = <LTE type={key} />;
            break;
          case DeviceClass.ble:
            page = <BLE type={key} />;
            break;
          case DeviceClass.kit:
            page = <Kits />;
            break;
          case DeviceClass.hub:
            page = <Hub />;
            break;
          default:
            page = <React.Fragment />;
        }

        tabArray.push(
          <Tab name={deviceType.displayNamePlural} path={`/${root}/${encodeURIComponent(deviceType.id)}`} key={key}>
            {page}
          </Tab>,
        );
      }
    });

    return (
      <TabView
        key="tabs"
        activeTab={getActiveTabNameFromUrl(
          decodeURIComponent(activeTab),
          tabArray.map(t => {
            return { urlId: t.key, DisplayName: t.props.name };
          }),
        )}
        routeMode
        rightMargin={isMobile ? 45 : 60}
      >
        {tabArray}
      </TabView>
    );
  }
}

ShipperCaps.propTypes = {
  activeTab: PropTypes.any,
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
};

export default ShipperCaps;
