import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { openModalAction } from '../../../../actions/modal';
import TableWithLocalPagination from '../../../../containers/Table/TableWithLocalPagination';
import { Column } from '../../../../containers/Table/TableWithPagination';
import Strings from '../../../../Strings';
import { actions } from '../../redux/actions';

function GroupUsers(props) {
  const { groupDetails, groupUsers, openConfirmModal, onRefresh } = props;

  const removeUserFromGrp = (_, user) => {
    const data = {
      title: Strings.deleteUserFromGroupWarningarning(user.name, groupDetails.name),
      onConfirmAction: actions.removeUserFromOrgGroup(groupDetails.id, user.id),
      onSuccessNotification: Strings.formatString(Strings.itemSaved, groupDetails.name),
      confirmPostAction: onRefresh,
    };
    openConfirmModal(data);
  };

  return (
    <TableWithLocalPagination
      name="users"
      data={groupUsers || []}
      buttons={(groupDetails?.role === 'owner' || groupDetails?.role === 'maintainer') ? 
        [
          {
            icon: 'delete',
            onClick: removeUserFromGrp,
            text: Strings.delete,
            disabled: (_, user) => groupDetails.maintainers.some(u => u.id === user.id),
            disabledTooltip: Strings.cantDeleteMaintainerUser,
          },
        ]
      : undefined}
      infiniteScroll
      columns={[
        <Column key="id" title={Strings.id} value={d => d.id} />,
        <Column key="name" title={Strings.name} value={d => d.name} />,
        <Column key="org" title={Strings.organization} value={d => d.org_name} />,
      ]}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  removeUserFromOrgGroup: (groupId, userId) => dispatch(actions.removeUserFromOrgGroup(groupId, userId)),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
});

GroupUsers.propTypes = {
  groupDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    maintainers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })),
  }).isRequired,
  groupUsers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  onRefresh: PropTypes.func.isRequired,
  openConfirmModal: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(GroupUsers);
