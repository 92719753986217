import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import _ from 'lodash';
import moment from 'moment-timezone';

import { PageHeader } from '../../../../components/PageHeader';
import { TextInput } from '../../../../components/PageHeader/TextInput';
import { DATE_MONTH_DAY_YEAR, PAGE_LIMIT, TIME_FORMAT_12_UPPERCASE } from '../../../../constants';
import Table, { Column } from '../../../../containers/Table/TableWithPagination';
import Strings from '../../../../Strings';
import { actions } from '../redux/actions';
import { ActionWidget, ActionButton, ItemSelectorHelper } from '../../../../components/ActionWidget';
import Select from '../../../../components/Select';
import AdvancedFilters, { Filter } from '../../../../components/AdvancedFilters/AdvancedFilters';
import AdvancedFiltersBar from '../../../../components/AdvancedFilters/AdvancedFiltersBar';
import { getFiltersDefinition } from '../../../../components/AdvancedFilters/FiltersDefinition';
import { getFiltersForRequest, urlInjected } from '../../../../components/AdvancedFilters/helpers';
import { getVitalsData, getVitalsDate, getVitalsStatus } from '../../../../utils';
import { DateFilters } from '../../../../utils/DateFilters';
import RadioButtons from '../../../../components/RadioButtons/RadioButtons';

const defaultStatus = [
  {
    label: Strings.readingStatus.allReadings,
    value: '',
  },
  {
    label: Strings.readingStatus.newReadings,
    value: `newreadings`,
  },
  {
    label: Strings.readingStatus.archivedReadings,
    value: `archivedreadings`,
  },
];

export class Readings extends PureComponent {
  searchTime;
  refreshEnable = true;

  state = {
    keyForItemCheckboxes: false,
    checkedItems: {},
    reviewByPatient: true,
    statusFilter: 'newreadings',
    selectedDateFilter: DateFilters.Last48Hours.value,
    keyForTable: false, // required to re-mount checkboxes in table
    orgs: [],
  };

  itemSelectorHelper = new ItemSelectorHelper();

  pageRequest = {
    offset: 0,
    search: '',
    startDate: moment()
      .subtract(48, 'hour')
      .format(),
    endDate: moment().format(),
    filterBy: 'Archived:false',
    limit: PAGE_LIMIT,
    orgId: this.props.orgFilter,
  };

  componentDidMount() {
    if (!urlInjected(window.location.search)) {
      this.load();
    }
    this.props.getCareProvidedOrganizations().then(resp => {
      this.setState({ orgs: resp?.response?.map(o => ({...o, value: o.uuid, label: o.name})) || [] });
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.refreshTimestamp !== this.props.refreshTimestamp || prevProps.orgFilter !== this.props.orgFilter) {
      this.load();
    }

    const { filters } = this.props;
    if (filters !== prevProps.filters) {
      this.onFiltersChange(filters);
    }
  }

  load() {
    this.props.getReadings(this.pageRequest);
  }

  onSearchQueryChange = query => {
    this.pageRequest.offset = 0;
    this.pageRequest.search = query;
    clearTimeout(this.searchTime);
    this.searchTime = setTimeout(() => {
      this.props.getReadings(this.pageRequest);
    }, 1000);
  };

  onFiltersChange = filters => {
    return this.filtersDebounced(filters);
  };

  filtersDebounced = _.debounce(() => {
    let newFiltersForRequest = getFiltersForRequest(this.props.filters);
    if (JSON.stringify(newFiltersForRequest) !== JSON.stringify(this.pageRequest.filterBy)) {
      newFiltersForRequest = newFiltersForRequest.map(f => f.replace('ReadingStatus', 'Status'));

      if (this.state.statusFilter === 'newreadings') {
        newFiltersForRequest.push('Archived:false');
      } else if (this.state.statusFilter === 'archivedreadings') {
        newFiltersForRequest.push('Archived:true');
      }

      this.pageRequest.filterBy = newFiltersForRequest;
      this.pageRequest.offset = 0;
    }
    this.load();
  }, 1000);

  setReviewBy = value => {
    const isPatientSelected = value === 'patient';
    this.setState(() => {
      return {
        reviewByPatient: isPatientSelected,
        keyForTable: !this.state.keyForTable,
        checkedItems: {},
      };
    });

    this.resetActionWidget();
  };

  getHeaderComponents = () => {
    return (
      <React.Fragment>
        <RadioButtons
          buttons={[
            {
              label: Strings.patient,
              value: 'patient',
            },
            {
              label: Strings.readings,
              value: 'reading',
            },
          ]}
          title={Strings.reviewBy}
          onClick={value => this.setReviewBy(value)}
        />
        <TextInput class="" placeholder={Strings.search} onChange={e => this.onSearchQueryChange(e.target.value)} />
        <Select data={defaultStatus} onChange={this.onStatusChanged} value={this.state.statusFilter} />
        <Select
          data={[
            DateFilters.AllTime,
            DateFilters.Last48Hours,
            DateFilters.ThisMonth,
            DateFilters.Last30Days,
            DateFilters.LastMonth,
            DateFilters.Last6Months,
            DateFilters.ThisYear,
            DateFilters.Last12Months,
          ]}
          onChange={this.onDateRangeChanged}
          value={this.state.selectedDateFilter}
        />
        {this.state.orgs?.length > 0 && (
          <Select
            data={this.state.orgs}
            value={this.props.orgFilter}
            onChange={o => this.props.setOrgFilter(this.props.userId, o?.value)}
            placeholder={Strings.selectOrganization}
          />
        )}
        <AdvancedFilters lastButton>
          <Filter definition={getFiltersDefinition().DeviceType} />
          <Filter definition={getFiltersDefinition().ReadingStatus} />
        </AdvancedFilters>
      </React.Fragment>
    );
  };

  resetActionWidget = () => {
    this.setState({
      keyForItemCheckboxes: !this.state.keyForItemCheckboxes,
    });
  };

  itemChecked = (item, checked) => {
    const { reviewByPatient } = this.state;
    this.setState({
      checkedItems:  { ...this.state.checkedItems, [reviewByPatient ? item.kitId : item.deviceId]: { checked, item } },
    });
  };

  onOffsetChange = offset => {
    this.pageRequest.offset = offset;
    this.props.getReadings(this.pageRequest);
  };

  onSortClick = ({ sortKey, direction }) => {
    this.pageRequest.offset = 0;
    this.pageRequest.sortColumn = sortKey;
    this.pageRequest.sortType = direction;
    this.props.getReadings(this.pageRequest);
  };

  onRefresh = () => {
    this.refreshEnable = false;
    this.turnOffTimeout = setTimeout(() => {
      this.refreshEnable = true;
      this.forceUpdate();
    }, 10000);
    this.load();
  };

  resetActionWidget = () => {
    this.setState({
      keyForItemCheckboxes: !this.state.keyForItemCheckboxes,
      checkedItems: {},
    });
  };

  onDateRangeChanged = option => {
    this.setState({ selectedDateFilter: option.value });
    if (option) {
      this.pageRequest.startDate = option.dates.startDate();
      this.pageRequest.endDate = option.dates.endDate();
    } else {
      this.pageRequest.startDate = '';
      this.pageRequest.endDate = '';
    }
    this.pageRequest.offset = 0;

    this.load();
  };

  onStatusChanged = option => {
    const newFiltersForRequest = getFiltersForRequest(this.props.filters);
    this.setState({ statusFilter: option.value });
    if (option.value === 'newreadings') {
      newFiltersForRequest.push('Archived:false');
    } else if (option.value === 'archivedreadings') {
      newFiltersForRequest.push('Archived:true');
    }
    this.pageRequest.filterBy = newFiltersForRequest;
    this.pageRequest.offset = 0;
    this.load();
  };

  onArchive = () => {
    this.resetActionWidget();
    const { checkedItems, reviewByPatient } = this.state;
    const { toggleArchieved } = this.props;

    Object.values(checkedItems).map(e => {
      const data = { kitId: e.item.kitId, archived: !e.item.archived, deviceId: reviewByPatient ? undefined : e.item.deviceId };
      toggleArchieved(data).then(() => {
        this.load();
      });
    });
    this.resetActionWidget();
  };

  onRowSelection = id => {
    const { readings } = this.props;

    // check if id is in range of readings table
    if (id < 0 || id >= readings.length) {
      return;
    }

    const patientId = readings[id].patientId;
    this.props.onNavigate(`/cap-patients/${patientId}/readings`);
  };

  render() {
    const { isLoading, readings, pagination } = this.props;
    const { reviewByPatient } = this.state;

    const columns = [
      <Column
        key="patientName"
        sortKey="patientName"
        title={Strings.capPatient.patientName}
        className="clickable"
        value={e => (
          <React.Fragment>
            <div className="cell-with-select">
              <div className="selector">
                {reviewByPatient ? (
                  <input
                    type="checkbox"
                    className="item-checkbox"
                    id={e.kitId}
                    name={e.kitId}
                    onChange={event => this.itemChecked(e, event.target.checked)}
                    checked={this.state.checkedItems[e.kitId]?.checked || false}
                    key={this.state.keyForItemCheckboxes}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  />
                ) : (
                  <input
                    type="checkbox"
                    className="item-checkbox"
                    id={e.kitId}
                    data-id={e.deviceId}
                    onChange={event => this.itemChecked(e, event.target.checked)}
                    key={this.state.keyForItemCheckboxes}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  />
                )}
              </div>
              <div className="selector-label">{e.patientName}</div>
            </div>
          </React.Fragment>
        )}
      />,
      <Column key="pharmacyPatientId" title={Strings.capPatient.patientId} value={e => e.pharmacyPatientId} />,
      <Column key="deviceType" sortKey="deviceType" title={Strings.capPatient.deviceType} value={e => e.deviceType} />,
      <Column key="deviceId" sortKey="deviceId" title={Strings.capPatient.deviceId} value={e => e.deviceId} />,
      <Column
        key="status"
        sortKey="status"
        title={Strings.capPatient.status}
        value={e => (!_.isEmpty(e.vitals) ? getVitalsStatus(e.vitals) : Strings.normal)}
      />,
      <Column
        key="date"
        title={Strings.capPatient.date}
        sortKey="date"
        value={e => (!_.isEmpty(e.vitals) ? moment(getVitalsDate(e.vitals)).format(DATE_MONTH_DAY_YEAR) : '-')}
      />,
      <Column
        key="time"
        title={Strings.time}
        sortKey="date"
        value={e => (!_.isEmpty(e.vitals) ? moment(getVitalsDate(e.vitals)).format(TIME_FORMAT_12_UPPERCASE) : '-')}
      />,
      <Column
        key="vitals"
        sortKey="vitals"
        title={Strings.value}
        value={e => (!_.isEmpty(e.vitals) ? getVitalsData(e.vitals) : '-')}
      />,
      <Column key="deviceName" sortKey="deviceName" title={Strings.capPatient.deviceName} value={e => e.deviceName} />,
    ];

    const headerTitle =
      !isLoading && readings && pagination
        ? Strings.formatString(
            Strings.showingXReadings,
            readings.length,
            pagination.totalRecords ? pagination.totalRecords : 0,
          )
        : Strings.showingWait;

    return (
      <React.Fragment>
        <PageHeader right={() => this.getHeaderComponents()} left={headerTitle} noLeftPadding />
        <AdvancedFiltersBar />
        <Table
          isLoading={isLoading}
          name="patient"
          uuid="347938a5-c9a1-4151-b717-f93b3e5f7a82"
          enableColumnFiltering
          data={readings || []}
          onRowSelection={this.onRowSelection}
          onOffsetChange={this.onOffsetChange}
          onSortClick={this.onSortClick}
          pagination={pagination}
          key={this.state.keyForTable}
        >
          {columns}
        </Table>

        <ActionWidget show={Object.values(this.state.checkedItems)?.some(i => i.checked === true)} >
          <ActionButton
            img="action-archive"
            tooltiptext="Archive/Unarchive"
            text="Archive"
            action={this.onArchive}
          />
        </ActionWidget>
      </React.Fragment>
    );
  }
}

Readings.propTypes = {
  getReadings: PropTypes.func,
  isLoading: PropTypes.any,
  onArchive: PropTypes.func,
  onNavigate: PropTypes.func,
  pagination: PropTypes.shape({
    offset: PropTypes.number,
    total: PropTypes.number,
  }),
  readings: PropTypes.array,
  refreshTimestamp: PropTypes.any,
  filters: PropTypes.array,
  toggleArchieved: PropTypes.func,
};

const mapStateToProps = state => {
  const { patients } = state.superUser;
  const userId = state.auth?.profile?.id;
  return {
    readings: patients && patients?.readings,
    isLoading: patients && patients?.isLoading,
    pagination: patients && patients?.pagination,
    filters: state.entities.advancedFilters.filters.items,
    orgFilter: state.superUser?.patients?.orgFilter?.[userId],
    userId,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  getReadings: pageRequest => dispatch(actions.getReadings(pageRequest)),
  toggleArchieved: data => dispatch(actions.archiveToggle(data)),
  getCareProvidedOrganizations: () => dispatch(actions.getCareProvidedOrganizations()),
  setOrgFilter: (userId, orgId) => dispatch(actions.setOrgFilter(userId, orgId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Readings);
