import { PAGE_LIMIT } from '../../../constants';
import { API_CALL } from '../../../middleware/api';
import { GET_ORAGANIZATION } from '../../../pages/Organizations/redux/constants';
import { ADD_ORAGANIZATION, UPDATE_ORAGANIZATION } from './constants';

export const actions = {
  addOrganization: data => ({
    [API_CALL]: {
      method: 'POST',
      endpoint: `/v1/organizations/`,
      data,
    },
    type: ADD_ORAGANIZATION,
  }),
  updateOrganization: (id, data) => ({
    [API_CALL]: {
      method: 'PUT',
      endpoint: `/v1/organizations/${id}`,
      data,
    },
    type: UPDATE_ORAGANIZATION,
  }),
  getOrganizations: pageRequest => {
    return {
      [API_CALL]: {
        method: 'GET',
        endpoint: '/v1/organizations/',
        params: { ...pageRequest, limit: PAGE_LIMIT },
      },
      type: GET_ORAGANIZATION,
    };
  },
};
