import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { PageHeader } from '../../../components/PageHeader';
import Select from '../../../components/Select';
import { GET_DEVICES_REPORT_REQ_RESULT, GET_REPORT_REQ_RESULT, actions } from './redux/actions';
import { HEADER_BUTTON_BLUE } from '../../../constants';
import { Button } from '../../../components/PageHeader/Button';
import Strings from '../../../Strings';
import { notificationActions } from '../../../components/Notification/redux/actions';

import './Report.scss';
import { downloadFileOnly } from '../../../utils';
import ReportDashboard from './ReportDashboard';
import { hasPermission, PERMISSIONS } from '../../../utils/userPermissions';
import { getOrgnization, isOrganizationManager, isSuperUser } from '../../../utils/userRoles';

export function getDefaultPeriods() {
  const currentDate = moment();
  let year = currentDate.year();
  let month = currentDate.month() + 1;

  const periods = [
    {
      label: year.toString() + '/' + month.toString(),
      value: currentDate.format(),
      start: moment()
        .startOf('month')
        .format(),
      end: currentDate.format(),
    },
  ];

  for (let i = 6; i > 0; i -= 1) {
    month -= 1;
    if (month === 0) {
      month = 12;
      year -= 1;
    }

    const timestamp = moment({ year, month: month - 1, day: 1 })
      .endOf('month')
      .format();

    periods.push({
      label: year.toString() + '/' + month.toString(),
      value: timestamp,
      start: moment(timestamp)
        .startOf('month')
        .format(),
      end: timestamp,
    });
  }

  return periods;
}

function Report(props) {
  const [rangeSelected, setRangeSelected] = useState(null);

  const periods = getDefaultPeriods();

  const download = () => {
    props.getReport(rangeSelected).then(resp => {
      if (resp.type === GET_REPORT_REQ_RESULT) {
        const fileName = 'report_' + moment(rangeSelected).format('YYYYMMDD');
        downloadFileOnly(resp, fileName);
      } else {
        props.showNotification('ERROR');
      }
    });
    const downloadDevicesReport = (isOrganizationManager() && getOrgnization() === 'Centor') || isSuperUser();
    if (downloadDevicesReport) {
      props.getDevicesReport(moment(rangeSelected).startOf('month').format(), rangeSelected).then(resp => {
        if (resp.type === GET_DEVICES_REPORT_REQ_RESULT) {
          const fileName = 'devices_report_' + moment(rangeSelected).format('YYYYMMDD');
          downloadFileOnly(resp, fileName);
        } else {
          props.showNotification('ERROR');
        }
      });
    }
  };

  const rightHeader = (
    <>
      <Select data={periods} onChange={e => setRangeSelected(e.value)} />
      <Button disabled={!rangeSelected} class={HEADER_BUTTON_BLUE} onClick={download} title={Strings.download} />
    </>
  );

  return (
    <>
      <PageHeader left={Strings.report} right={rightHeader} />
      {(hasPermission(PERMISSIONS.report) || isSuperUser()) && <ReportDashboard />}
    </>
  );
}

Report.propTypes = {
  getReport: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getReport: endDate => dispatch(actions.getReport(endDate)),
  getDevicesReport: (startDate, endDate) => dispatch(actions.getDevicesReport(startDate, endDate)),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
});

export default connect(null, mapDispatchToProps)(Report);
