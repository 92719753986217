import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { closeModal, updateTimestamp } from '../actions/modal';

let active = '';

class ModalMaster extends React.Component {
  static propTypes = {
    onModalCloseOverlay: PropTypes.func,
    modalsState: PropTypes.any,
    onModalClose: PropTypes.func,
    children: PropTypes.any,
    updateTimestamp: PropTypes.func,
  };

  closeModal = e => {
    // e.preventDefault();
    if (e.target.className === 'dialog-overlay a') {
      this.props.onModalCloseOverlay(active);
    }
  };

  componentDidUpdate(prevProps) {
    const prevModals = prevProps.modalsState;
    const curModals = this.props.modalsState;

    Object.keys(prevModals).forEach(modal => {
      const prevModal = prevModals[modal];
      const curModal = curModals[modal];

      if (!curModal || (prevModal.open === true && curModal.open === false)) {
        if (prevModal.data && typeof prevModal.data.afterClose === 'function') {
          prevModal.data.afterClose();
        }
      }
    });
  }

  onClick(modal) {
    this.props.updateTimestamp(modal);
  }

  getOpenModals() {
    const { modalsState, onModalClose } = this.props;
    let { children } = this.props;
    if (!Array.isArray(children)) {
      children = [children];
    }
    active = Object.keys(modalsState).filter(key => modalsState[key].open);
    const modalsSorted = Object.keys(modalsState).sort((a, b) => modalsState[b].timestamp - modalsState[a].timestamp);

    const ret = (
      <React.Fragment>
        {active.map(a => {
          const activeChild = children.find(
            c => `${c.props.name}${modalsState[a]?.modalId ? modalsState[a]?.modalId : ''}` === a,
          );
          const isTopModal = a === modalsSorted[0];
          return (
            <React.Fragment key={a}>
              {activeChild.props.noOverlay ? (
                React.cloneElement(activeChild, {
                  key: a,
                  onClose: onModalClose,
                  open: true,
                  data: modalsState[a].data,
                  modalId: modalsState[a].modalId,
                  isTopModal,
                  onClick: () => this.onClick(a),
                })
              ) : (
                <div
                  className={
                    activeChild.props.type === 'side-panel' ? 'dialog-overlay a side-panel' : 'dialog-overlay a'
                  }
                  // onClick={this.closeModal}
                >
                  {React.cloneElement(activeChild, {
                    key: a,
                    onClose: onModalClose,
                    open: true,
                    data: modalsState[a].data,
                    modalId: modalsState[a].modalId,
                  })}
                </div>
              )}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
    return ret;
  }

  render() {
    const { modalsState } = this.props;
    let { children } = this.props;
    if (!Array.isArray(children)) {
      children = [children];
    }

    active = Object.keys(modalsState).filter(key => modalsState[key].open);
    if (active && active.length > 0) {
      return this.getOpenModals();
    }
    return <div />;
  }
}

const mapStateToProps = state => ({ modalsState: state.modals || {} });

const mapDispatchToProps = dispatch => {
  return {
    onModalClose: closeModal,
    onModalCloseOverlay: status => dispatch(closeModal(status)),
    updateTimestamp: modalId => dispatch(updateTimestamp(modalId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalMaster);
