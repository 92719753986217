import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import useSupercluster from 'use-supercluster';

import Strings from '../../Strings';
import { DeviceTypes } from '../../utils/deviceTypes';

const CENTER_OF_US_COORDS = { lat: 41.850033, lng: -87.6500523 };
const CLUSTER_RADIUS = 30;

export const calculateSignalColor = signalLevel => {
  return signalLevel === 'Weak'
    ? '#DA547D'
    : signalLevel === 'Medium'
    ? '#F9A452'
    : signalLevel === 'Good' || signalLevel === 'Excellent'
    ? '#4EB067'
    : //      : '#0060AA';
      '#DA547D';
};

function MapGraph(props) {
  const { googleMapsApiKey } = props;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey,
  });

  const mapRef = React.useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(props.markers && props.markers.length === 1 ? 9 : 4);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [selectedCluster, setSelectedCluster] = useState(undefined);
  const [selectedHubs, setSelectedHubs] = useState([]);

  const calcBounds = (markers, margin) => {
    const bounds = new window.google.maps.LatLngBounds();
    if (markers && markers.length > 1) {
      for (let i = 0; i < markers.length; i++) {
        bounds.extend({ lat: markers[i].properties.location.lat, lng: markers[i].properties.location.lng });
      }
      if (margin) {
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();

        if (ne.lat() - sw.lat() < margin * 2) {
          bounds.extend({ lat: ne.lat() + margin, lng: sw.lng() + margin });
          bounds.extend({ lat: ne.lat() - margin, lng: sw.lng() - margin });
        }
        if (ne.lng() - sw.lng() < margin * 2) {
          bounds.extend({ lat: ne.lat() + margin, lng: sw.lng() + margin });
          bounds.extend({ lat: ne.lat() - margin, lng: sw.lng() - margin });
        }
      }
    }
    return bounds;
  };

  const onLoad = useCallback(map => {
    const margin = 7;
    if (markers && markers.length > 1) {
      const bounds = calcBounds(props.markers, margin);
      map.fitBounds(bounds);
    }
    mapRef.current = map;
    setBounds(map.bounds);
    setZoom(map.zoom);
  }, []);

  const onUnmount = useCallback(() => {
    mapRef.current = null;
  }, []);

  const onZoomChanged = useCallback(() => {
    if (mapRef.current) {
      setZoom(mapRef.current.getZoom());
      setBounds(mapRef.current.getBounds() || undefined);
    }
  }, []);

  const onBoundsChanged = useCallback(() => {
    if (mapRef.current) {
      setBounds(mapRef.current.getBounds() || undefined);
    }
  }, []);

  const onMarkerClustererClick = (supercluster, cluster) => {
    const markers = supercluster.getLeaves(cluster.id, Infinity);
    const isMaxZoom = zoom === props.maxZoomLevel;
    if (isMaxZoom) {
      setSelectedCluster(cluster);
      setSelectedHubs(markers);
      setInfoWindowOpen(true);
    } else {
      const newBounds = calcBounds(markers);
      mapRef.current.fitBounds(newBounds);
    }
  };

  const getWorstSignal = markers => {
    const hubsSignal = markers.map(h => h.properties.signalStrength);

    if (hubsSignal.includes('Weak')) return 'Weak';
    if (hubsSignal.includes('Medium')) return 'Medium';
    if (hubsSignal.includes('Good')) return 'Good';
    if (hubsSignal.includes('Excellent')) return 'Excellent';
    if (hubsSignal.includes('Not available')) return 'Not available';
    return undefined;
  };

  const getColorForCluster = (supercluster, cluster) => {
    const markers = supercluster.getLeaves(cluster.id);
    const clusterSignal = getWorstSignal(markers);

    return calculateSignalColor(clusterSignal);
  };

  const closeInfowindow = () => {
    setInfoWindowOpen(false);
  };

  const { markers, maxZoomLevel, onClick } = props;
  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const superclusterBounds = bounds
    ? [
        bounds.getSouthWest().lng(),
        bounds.getSouthWest().lat(),
        bounds.getNorthEast().lng(),
        bounds.getNorthEast().lat(),
      ]
    : [0, 0, 0, 0];

  const { clusters, supercluster } = useSupercluster({
    points: markers,
    bounds: superclusterBounds,
    zoom,
    options: { maxZoom: maxZoomLevel, radius: CLUSTER_RADIUS },
  });

  return isLoaded ? (
    <GoogleMap
      zoom={markers && markers.length === 1 ? 9 : 4}
      center={
        markers && markers.length === 1
          ? { lat: markers[0].properties.location.lat, lng: markers[0].properties.location.lng }
          : CENTER_OF_US_COORDS
      }
      options={{
        styles: mapStyleSilver,
        maxZoom: maxZoomLevel,
        streetViewControl: false,
        zoomControl: true,
        fullscreenControl: true,
        mapTypeControl: false,
      }}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onBoundsChanged={onBoundsChanged}
      onZoomChanged={onZoomChanged}
      mapContainerStyle={containerStyle}
    >
      {clusters.map(cluster => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const { cluster: isCluster, point_count: pointCount } = cluster.properties;
        if (isCluster) {
          const color = getColorForCluster(supercluster, cluster);
          return (
            <Marker
              key={`cluster-${cluster.id}`}
              position={{ lat: latitude, lng: longitude }}
              onClick={() => onMarkerClustererClick(supercluster, cluster)}
              icon={{
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: color,
                fillOpacity: 1,
                strokeColor: color,
                strokeOpacity: 1,
                strokeWeight: 1,
                scale: 9,
              }}
              title={`${pointCount} ${Strings.devicesInThisArea}`}
            />
          );
        } else {
          return (
            <Marker
              key={`cluster-${cluster.properties.id}`}
              position={{ lat: latitude, lng: longitude }}
              icon={{
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: cluster.properties.color,
                fillOpacity: 1,
                strokeColor: cluster.properties.color,
                strokeOpacity: 1,
                strokeWeight: 1,
                scale: 6,
              }}
              onClick={() => {
                onClick(cluster);
              }}
            />
          );
        }
      })}
      {infoWindowOpen && selectedCluster && selectedCluster && (
        <div className="customTooltip">
          <InfoWindow
            position={{ lat: selectedCluster.geometry.coordinates[1], lng: selectedCluster.geometry.coordinates[0] }}
            onCloseClick={closeInfowindow}
          >
            <div>
              <h2>{`${selectedHubs.length} ${Strings.devicesInThisArea}:`}</h2>
              <SimpleBar style={{ maxHeight: 210 }}>
                {selectedHubs.map(h => (
                  <div className="legendContainer left">
                    <div
                      key={`$hubsInArea_${h.properties.id}`}
                      className={
                        h.properties.signalStrength === 'Weak'
                          ? 'dot low'
                          : h.properties.signalStrength === 'Medium'
                          ? 'dot medium'
                          : h.properties.signalStrength === 'Good' || h.properties.signalStrength === 'Excellent'
                          ? 'dot high'
                          : 'dot low'
                      }
                    />
                    <a href={`/devices/${DeviceTypes.Datahub.id}/${h.properties.id}`} className="label">
                      {h.properties.id}
                    </a>
                  </div>
                ))}
              </SimpleBar>
            </div>
          </InfoWindow>
        </div>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}

MapGraph.propTypes = {
  markers: PropTypes.array,
  maxZoomLevel: PropTypes.number,
  onClick: PropTypes.func,
  googleMapsApiKey: PropTypes.string.isRequired,
};

export default React.memo(MapGraph);

const mapStyleSilver = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f7f7f7',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#EDEEEE',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
];
