import { cloneDeep, isArray } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Select from '../../../components/Select';
import { Timezones } from '../../../constants';
import Strings from '../../../Strings';
import { actions } from '../redux/actions';
import './Configuration.scss';
import { colorThemes } from '../../../utils/colorTheme';
import { supportedBrands } from '../../../utils/brand';
import { UPDATE_ORG_ERROR } from '../redux/constants';
import { notificationActions } from '../../../components/Notification/redux/actions';
import Multiselect from '../../../components/Select/Multiselect';

export const fixPermission = (permissions, valueForEmpty) => {
  Object.entries(permissions).forEach(([name, value]) => {
    if (typeof value === 'boolean') {
      permissions[name] = value;
    } else if (_.isEmpty(value)) {
      permissions[name] = valueForEmpty;
    } else {
      fixPermission(value, valueForEmpty);
    }
  });
};

export const permissionsToDTO = permissions => {
  Object.entries(permissions).forEach(([name, value]) => {
    if (typeof value === 'boolean') {
      if (value === false) {
        delete permissions[name];
      } else {
        permissions[name] = {};
      }
    } else {
      permissionsToDTO(value);
      if (_.isEmpty(value)) {
        delete permissions[name];
      }
    }
  });
};

const renderSectionHeader = (name, onClick, sectionsExpanded) => (
  <div className="config-section-header">
    <div className="buttonContainer">
      <button
        className={`minimize-button ${sectionsExpanded?.[name] ? '' : 'collapsed'}`}
        onClick={() => onClick(name)}
      />
    </div>
    <h3>{name}</h3>
  </div>
);

const ConditionalWrapper = ({ condition, wrapper, children }) => 
  condition ? wrapper(children) : children;

export const RecursiveForm = ({recursionLevel, path, data, setData, onHeaderClick, sectionsExpanded}) => {
  if (path[path.length-1] === 'enrollment_activation_modes')
    return (
    <div className={`text-container ${recursionLevel === 2 ? 'config-section-subheader' : ''}`}  style={{marginLeft: recursionLevel * 10}}>
      <label>
        {path[path.length-1]}
      </label>
    <Multiselect
      name="enrollment_activation_modes"
      id="enrollment_activation_modes"
      value={data || []}
      onChange={o => setData(o.map(v => v.value))}
      placeholder={Strings.select}
      data={[
        {value: "Manual", label: "Manual"},
        {value: "FirstEvent", label: "FirstEvent"},
        {value: "OnDayAfterFirstEvent", label: "OnDayAfterFirstEvent"},
      ]}
    />
  </div>
  );
  if (data == null)
    return <></>;
  if (typeof data == "object")
    return (
      <React.Fragment>
        {recursionLevel === 1 ? 
          renderSectionHeader(path[path.length-1], onHeaderClick, sectionsExpanded) : 
          <div className="config-section-subheader" style={{marginLeft: recursionLevel * 10}}>{path[path.length-1]}</div>
        }
        <ConditionalWrapper
          condition={!Object.values(data).some(v => typeof v === 'object')}
          wrapper={children => <div className={`inputs-container ${!Object.values(data).some(v => typeof v !== 'boolean') ? 'checkboxes' : ''}`}>{children}</div>}
        >
          {((recursionLevel === 1 && sectionsExpanded[path[path.length-1]]) || recursionLevel !== 1) && Object.entries(data).map(([key, value]) => (
            <RecursiveForm
              recursionLevel={recursionLevel + 1}
              key={key}
              path={[...path, key]}
              data={value}
              setData={next => setData(prev => update(prev, key, next))}
              onHeaderClick={onHeaderClick}
              sectionsExpanded={sectionsExpanded}
            />
          ))}
        </ConditionalWrapper>
      </React.Fragment>
    )
  else
    return (
      <div className={`text-container ${typeof data === 'boolean' ? 'revrese' : ''} ${recursionLevel === 2 ? 'config-section-subheader' : ''}`}  style={{marginLeft: recursionLevel * 10}}>
        <label>
        {path[path.length-1]}
        </label>
        {path[path.length-1] === 'timezone' ? (
          <Select
            name="timezone"
            id="timezone"
            value={data}
            onChange={o => setData(o.value)}
            data={Object.keys(Timezones).map(key => {
                return { value: key, label: Timezones[key] };
              })}
          />
        )
        : path[path.length-1] === 'brand' ? (
          <Select
            name="brand"
            id="brand"
            value={data}
            onChange={o => setData(o.value)}
            placeholder={Strings.selectBrand}
            data={Object.keys(supportedBrands).map(b => {
              return { value: b, label: supportedBrands[b].name };
            })}
          />
        )
        : path[path.length-1] === 'theme' ? (
          <Select
            name="theme"
            id="theme"
            value={data}
            onChange={o => setData(o.value)}
            placeholder={Strings.selectTheme}
            data={Object.keys(colorThemes).map(theme => {
              return { value: theme, label: Strings.theme[theme] };
            })}
          />
        )
        : (
            <input
            type={typeof data === 'boolean' ? 'checkbox' : 'text'}
            checked={typeof data === 'boolean' ? data : undefined}
            value={data}
            onChange={e => setData(typeof data === 'boolean' ? !data : e.target.value)}
          />
        )}
      </div>
    )
}

function update(data, key, value) {
  if (data != null && typeof data == "object")
    return Object.assign(
      Array.isArray(data) ? [] : {},
      data,
      { [key]: value instanceof Function ? value(data[key]) : value }
    )
  return data;
}

function OrgPermissions(props) {
  const [data, setData] = React.useState()
  const [sectionsExpanded, setSectionsExpanded] = React.useState({})
  const { template, orgConfig, isLoading } = props;

  const pageRequest = { organizationId: decodeURIComponent(props.organizationId) };

  React.useEffect(() => {
    props.getTemplate(pageRequest);
    props.getOrgConfig(pageRequest);
  }, []);

  React.useEffect(() => {
    if (template && orgConfig) {
      const config = _.cloneDeep(template);
      fixPermission(config.permissions, false);
      _.merge(config, orgConfig);
      delete config.settings.pricing;
      fixPermission(config.permissions, true);
      delete config.masked_id;
      delete config.name;
            
      setSectionsExpanded(Object.keys(template).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }
      , {}));
      setData(config);
    }
  }, [template, orgConfig]);


  const onSave = () => {
    const dataToSend = cloneDeep(data);
    permissionsToDTO(dataToSend.permissions);

    props.updateOrg(dataToSend, pageRequest).then(resp => {
      if (resp?.type === UPDATE_ORG_ERROR) {
        props.showNotification(resp.response?.data?.error?.message, 5000, true);
      } else {
        props.showNotification(Strings.orgConfigUpdated);
      }
    });
  };

  const changeSectionExpansion = section => {
    setSectionsExpanded(s => ({
      ...s,
      [section]: !s[section],
    }));
  };

  return (
    isLoading ? <div className="spinner" /> :
    <div className="config-sections">
      {data && (
        <RecursiveForm
          path={[]}
          recursionLevel={0}
          data={data}
          setData={setData}
          onHeaderClick={changeSectionExpansion}
          sectionsExpanded={sectionsExpanded}
        />
      )}
      <button className="brand-blue" key="submit" onClick={onSave}>
        {Strings.submit}
      </button>
    </div>
  );
}

OrgPermissions.propTypes = {
  getTemplate: PropTypes.func,
  getOrgConfig: PropTypes.func,
  orgConfig: PropTypes.shape({
    preferences: PropTypes.shape({
      theme: PropTypes.any,
      timezone: PropTypes.any,
    }),
  }),
  template: PropTypes.any,
  organizationId: PropTypes.any,
  updateOrg: PropTypes.func,
  isLoading: PropTypes.any,
  showNotification: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    orgConfig: state.superUser.organizations?.orgConfig,
    template: state.superUser.organizations?.template,
    isLoading: state.superUser.organizations?.isLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  updateOrg: (data, pageRequest) => dispatch(actions.updateOrg(data, pageRequest)),
  getOrgConfig: pageRequest => dispatch(actions.getOrgConfig(pageRequest)),
  getTemplate: pageRequest => dispatch(actions.getTemplateOrgConfig(pageRequest)),
  showNotification: (message, timeout, error) => dispatch(notificationActions.show(message, timeout, error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgPermissions);
