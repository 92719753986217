import moment from 'moment-timezone';

import { ALERT_DEFINITION_GET_RESULT, actions } from '../../../pages/SuperUser/Patients/Dashboard/actions';
import { actions as patientActions } from '../../../pages/SuperUser/Patients/redux/actions';
import {
  actions as plotActions,
  PLOT_DATA_RESULT,
  PLOT_DATE_RANGE_RESULT,
} from '../../../pages/SuperUser/DeviceManagement/HubDeviceEvents/Graph/actions';
import {
  GET_CAP_PLOT_DATA_RESULT,
  GET_SCHEDULE_NOTIFICATIONS_RESULT,
} from '../../../pages/SuperUser/Patients/redux/constants';
import { store } from '../../../utils/store';
import {
  GLUCOSE_UNITS,
  GlucoseUnitConverter,
  TEMPERATURE_UNITS,
  WEIGHT_UNITS,
  convertCelsiusToFahrenheit,
  convertKilogramToPound,
  roundToDecimal,
} from '../../../utils/unitConverters';
import { graphMetricEnum, graphMetrics } from './Metrics';
import { prepareSimplifiedDataForDataProvider } from './simplifyData';
import { PERMISSIONS, hasPermission } from '../../../utils/userPermissions';
import { HR_DISTRIBUTON_RESOLUTION } from './Constants';
import { DATE_TIME_WITH_MILISECONDS } from '../../../constants';

export const graphDataProvidersEnum = Object.freeze({
  weight: 'weight',
  bp: 'bp',
  pulseox: 'pulseox',
  temp: 'temp',
  gluco: 'gluco',
  spirometer: 'spirometer',
  hr: 'hr',
  sleep: 'sleep',
  airQualityMonitor: 'aqm',
  ptInr: 'ptInr',
});

const getRequestDataType = dp => {
  switch (dp) {
    case graphDataProvidersEnum.weight:
      return 'weight';
    case graphDataProvidersEnum.bp:
      return 'blood_pressure';
    case graphDataProvidersEnum.hr:
      return 'heart_rate';
    case graphDataProvidersEnum.sleep:
      return 'sleep_report';
    case graphDataProvidersEnum.pulseox:
      return 'pulseox';
    case graphDataProvidersEnum.gluco:
      return 'glucometer';
    case graphDataProvidersEnum.temp:
      return 'thermometer';
    case graphDataProvidersEnum.ptInr:
      return 'pt_inr';
    case graphDataProvidersEnum.airQualityMonitor:
      return 'atmocube';
    case graphDataProvidersEnum.spirometer:
      return 'spirometer';
    default:
      return '';
  }
};
export class DataProviders {
  static async getTakes(patientId, med, dtStart, dtEnd) {
    const request = {
      startDate: dtStart.format(),
      endDate: dtEnd.format(),
      patientId,
    };

    const resp = await store?.dispatch(patientActions.getCapPlotData(patientId, med.id, request));
    const respNotif = await store?.dispatch(patientActions.getScheduleNotifications(med.rootId, request));

    if (resp.type === GET_CAP_PLOT_DATA_RESULT && resp.response.length !== 0 && resp.response.events) {
      let data = resp.response.events.map(i => {
        return {
          dt: i.type === 'missed_take' ? moment(i.scheduled * 1000) : moment(i.timestamp * 1000),
          value: i.type === 'scheduled_take' ? (i.timestamp - i.scheduled) / 60 / 60 : 0,
          type: i.type,
        };
      });
      if (respNotif.type === GET_SCHEDULE_NOTIFICATIONS_RESULT && respNotif.response?.reminders?.length > 0) {
        data = data.concat(
          respNotif.response.reminders.map(i => {
            return {
              dt: moment(i.sent_at),
              value: 0,
              type: i.type,
            };
          }),
        );
      }

      return data.sort((a, b) => a.dt.unix() - b.dt.unix());
    }

    return [];
  }

  static async getData(dp, patientId, deviceId, hubId, kitId, dtStart, dtEnd) {
    const ret = {};
    const request = {
      startDate: dtStart.format(DATE_TIME_WITH_MILISECONDS),
      endDate: dtEnd.format(DATE_TIME_WITH_MILISECONDS),
      dataType: getRequestDataType(dp),
    };
    if (patientId) request.patientId = patientId;
    if (deviceId) request.deviceId = deviceId;
    if (hubId) request.hubId = hubId;
    if (kitId) request.kitId = kitId;

    const getThresholds = hasPermission(PERMISSIONS.DASHBOARD_RPM_PATIENT_ALERT_TRESHOLDS) && patientId;
    let resp;

    switch (dp) {
      case graphDataProvidersEnum.weight:
        {
          resp = await store?.dispatch(plotActions.getPlotData(request));

          let thresholdWeightMin, thresholdWeightMax;
          if (getThresholds) {
            const respAlertDef = await store?.dispatch(actions.getAlertDefinition(patientId, 'weight'));
            if (respAlertDef.type === ALERT_DEFINITION_GET_RESULT) {
              thresholdWeightMin = respAlertDef.response.conditions?.find(c => c.field === 'weight')?.min;
              thresholdWeightMax = respAlertDef.response.conditions?.find(c => c.field === 'weight')?.max;
            }
          }

          if (resp.type === PLOT_DATA_RESULT && resp.response.length !== 0) {
            const data = resp.response
              .map(i => {
                return {
                  dt: moment(i.event_timestamp),
                  value:
                    i.unit === WEIGHT_UNITS.kilogram ? roundToDecimal(convertKilogramToPound(i.weight), 2) : i.weight,
                };
              })
              .filter(d => !isNaN(d.value));

            ret[graphMetricEnum.weight] = {
              data: data,
              thresholdMin: thresholdWeightMin,
              thresholdMax: thresholdWeightMax,
              badData: v => v > thresholdWeightMax || v < thresholdWeightMin,
            };

            const dataGroupped = _.groupBy(data, d =>
              d.dt
                .clone()
                .startOf('day')
                .unix(),
            );
            const dataDaily = Object.keys(dataGroupped).map(d => {
              return {
                dt: moment(d * 1000).add(12, 'hours'),
                value: roundToDecimal(
                  _.meanBy(dataGroupped[d], dd => dd.value),
                  2,
                ),
              };
            });

            ret[graphMetricEnum.weightDailyAvg] = {
              data: dataDaily,
              thresholdMin: thresholdWeightMin,
              thresholdMax: thresholdWeightMax,
              badData: v => v > thresholdWeightMax || v < thresholdWeightMin,
            };
          }
        }
        break;
      case graphDataProvidersEnum.bp:
        {
          resp = await store?.dispatch(plotActions.getPlotData(request));

          let thresholdSPMin, thresholdSPMax, thresholdDPMin, thresholdDPMax;
          if (getThresholds) {
            const respAlertDef = await store?.dispatch(actions.getAlertDefinition(patientId, 'blood_pressure'));
            if (respAlertDef.type === ALERT_DEFINITION_GET_RESULT) {
              thresholdSPMin = respAlertDef.response.conditions?.find(c => c.field === 'systolic_pressure')?.min;
              thresholdSPMax = respAlertDef.response.conditions?.find(c => c.field === 'systolic_pressure')?.max;
              thresholdDPMin = respAlertDef.response.conditions?.find(c => c.field === 'diastolic_pressure')?.min;
              thresholdDPMax = respAlertDef.response.conditions?.find(c => c.field === 'diastolic_pressure')?.max;
            }
          }

          if (resp.type === PLOT_DATA_RESULT && resp.response.length !== 0) {
            const dataSP = resp.response.map(i => {
              return {
                dt: moment(i.event_timestamp),
                value: i.systolic_pressure,
              };
            });
            ret[graphMetricEnum.bpSP] = {
              data: dataSP.filter(d => !isNaN(d.value)),
              thresholdMin: thresholdSPMin,
              thresholdMax: thresholdSPMax,
              badData: v => v > thresholdSPMax || v < thresholdSPMin,
            };

            const dataPP = resp.response.map(i => {
              return {
                dt: moment(i.event_timestamp),
                value: i.mean_arterial_pressure,
              };
            });
            ret[graphMetricEnum.bpPP] = { data: dataPP.filter(d => !isNaN(d.value)) };

            const dataDP = resp.response.map(i => {
              return {
                dt: moment(i.event_timestamp),
                value: i.diastolic_pressure,
              };
            });
            ret[graphMetricEnum.bpDP] = {
              data: dataDP.filter(d => !isNaN(d.value)),
              thresholdMin: thresholdDPMin,
              thresholdMax: thresholdDPMax,
              badData: v => v > thresholdDPMax || v < thresholdDPMin,
            };

            const dataPR = resp.response.map(i => {
              return {
                dt: moment(i.event_timestamp),
                value: i.pulse_rate,
              };
            });
            ret[graphMetricEnum.bpPR] = { data: dataPR.filter(d => !isNaN(d.value)) };
          }
        }
        break;
      case graphDataProvidersEnum.hr:
        resp = await store?.dispatch(plotActions.getPlotData(request));

        let thresholdHRMin, thresholdHRMax;
        if (getThresholds) {
          const respAlertDef = await store?.dispatch(actions.getAlertDefinition(patientId, 'heart_rate'));
          if (respAlertDef.type === ALERT_DEFINITION_GET_RESULT) {
            thresholdHRMin = respAlertDef.response.conditions?.find(c => c.field === 'heart_rate')?.min;
            thresholdHRMax = respAlertDef.response.conditions?.find(c => c.field === 'heart_rate')?.max;
          }
        }

        if (resp.type === PLOT_DATA_RESULT && resp.response.length !== 0) {
          {
            const data = resp.response.map(i => {
              return {
                dt: moment(i.timestamp * 1000),
                value: i.heart_rate,
              };
            });

            ret[graphMetricEnum.hr] = {
              data: prepareSimplifiedDataForDataProvider(data),
              thresholdMin: thresholdHRMin,
              thresholdMax: thresholdHRMax,
              badData: v => v > thresholdHRMax || v < thresholdHRMin,
            };
          }

          {
            const dailyHrs = [];
            resp.response.forEach(i => {
              const day = moment(i.timestamp * 1000).startOf('day');
              const dailyHr = dailyHrs.find(i => i.dt.unix() === day.unix());
              if (dailyHr) {
                dailyHr.value += i.heart_rate;
                dailyHr.count++;
              } else
                dailyHrs.push({
                  dt: day,
                  value: i.heart_rate,
                  count: 1,
                });
            });

            dailyHrs.forEach(i => {
              i.value = roundToDecimal(i.value / i.count, 2);
            });

            ret[graphMetricEnum.hrDailyAvg] = { data: dailyHrs.filter(d => !isNaN(d.value)) };
          }

          {
            const dailyHrs = [];
            resp.response.forEach(i => {
              const day = moment(i.timestamp * 1000).startOf('hour');
              const dailyHr = dailyHrs.find(i => i.dt.unix() === day.unix());
              if (dailyHr) {
                dailyHr.value += i.heart_rate;
                dailyHr.count++;
              } else
                dailyHrs.push({
                  dt: day,
                  value: i.heart_rate,
                  count: 1,
                });
            });

            dailyHrs.forEach(i => {
              i.value = roundToDecimal(i.value / i.count, 2);
            });

            ret[graphMetricEnum.hrHourlyAvg] = { data: dailyHrs.filter(d => !isNaN(d.value)) };
          }

          {
            const dailyHrs = [];
            const resolution = HR_DISTRIBUTON_RESOLUTION;
            resp.response.forEach(i => {
              const day = moment(i.timestamp * 1000)
                .startOf('day')
                .add(12, 'hours');
              let dailyHr = dailyHrs.find(i => i.dt.unix() === day.unix());
              const bucket = Math.floor(i.heart_rate / resolution);
              if (!dailyHr) {
                dailyHr = {
                  dt: day,
                  value: bucket * resolution,
                  statistics: {
                    values: [],
                    minValue: i.heart_rate,
                    maxValue: i.heart_rate,
                    count: 0,
                  },
                };
                dailyHrs.push(dailyHr);
              }

              const tenthElement = dailyHr.statistics.values.find(i => i.bucket === bucket);
              if (tenthElement) {
                tenthElement.count++;
              } else {
                dailyHr.statistics.values.push({
                  bucket,
                  count: 1,
                });
              }

              dailyHr.statistics.count++;
              if (i.heart_rate < dailyHr.statistics.minValue) dailyHr.statistics.minValue = i.heart_rate;
              if (i.heart_rate > dailyHr.statistics.maxValue) dailyHr.statistics.maxValue = i.heart_rate;
              dailyHr.statistics.width = dailyHr.statistics.maxValue - dailyHr.statistics.minValue;
            });

            ret[graphMetricEnum.hrDailyDistribution] = {
              data: dailyHrs,
            };

            ret[graphMetricEnum.hrDailyBar] = {
              data: dailyHrs,
            };
          }
        }
        break;
      case graphDataProvidersEnum.sleep: {
        resp = await store?.dispatch(plotActions.getPlotData(request));
        if (resp.type === PLOT_DATA_RESULT && resp.response.length !== 0) {
          const sorted = resp.response.sort((a, b) => a.sleep_start - b.sleep_start);

          const dataSD = sorted.map(i => {
            return {
              dt: moment(i.timestamp * 1000),
              value: roundToDecimal((i.sleep_stop - i.sleep_start) / 60 / 60, 1),
            };
          });
          ret[graphMetricEnum.sleepDuration] = { data: dataSD.filter(d => !isNaN(d.value)) };

          const dataA = sorted.map(i => {
            return {
              dt: moment(i.timestamp * 1000),
              value: i.awake_duration,
            };
          });
          ret[graphMetricEnum.awakeDuration] = { data: dataA.filter(d => !isNaN(d.value)) };

          const dataDS = sorted.map(i => {
            return {
              dt: moment(i.timestamp * 1000),
              value: i.deep_sleep_duration,
            };
          });
          ret[graphMetricEnum.deepSleepDuration] = { data: dataDS.filter(d => !isNaN(d.value)) };

          const dataLS = sorted.map(i => {
            return {
              dt: moment(i.timestamp * 1000),
              value: i.light_sleep_duration,
            };
          });
          ret[graphMetricEnum.lightSleepDuration] = { data: dataLS.filter(d => !isNaN(d.value)) };

          const dataRem = sorted.map(i => {
            return {
              dt: moment(i.timestamp * 1000),
              value: i.rem_duration,
            };
          });
          ret[graphMetricEnum.remDuration] = { data: dataRem.filter(d => !isNaN(d.value)) };
        }
        break;
      }
      case graphDataProvidersEnum.pulseox:
        {
          resp = await store?.dispatch(plotActions.getPlotData(request));

          let thresholdSatMin;
          if (getThresholds) {
            const respAlertDef = await store?.dispatch(actions.getAlertDefinition(patientId, 'saturation'));
            if (respAlertDef.type === ALERT_DEFINITION_GET_RESULT) {
              thresholdSatMin = respAlertDef.response.conditions?.find(c => c.field === 'saturation')?.min;
            }
          }

          if (resp.type === PLOT_DATA_RESULT && resp.response.length !== 0) {
            const dataS = resp.response.map(i => {
              return {
                dt: moment(i.timestamp * 1000),
                value: i.saturation,
              };
            });
            ret[graphMetricEnum.saturation] = {
              data: dataS.filter(d => !isNaN(d.value)),
              thresholdMin: thresholdSatMin,
              badData: v => v < thresholdSatMin,
            };

            const dataPi = resp.response.map(i => {
              return {
                dt: moment(i.timestamp * 1000),
                value: i.pi,
              };
            });
            ret[graphMetricEnum.pi] = { data: dataPi.filter(d => !isNaN(d.value)) };
          }
        }
        break;
      case graphDataProvidersEnum.gluco:
        {
          resp = await store?.dispatch(plotActions.getPlotData(request));

          let thresholdGlucoMin, thresholdGlucoMax;
          if (getThresholds) {
            const respAlertDef = await store?.dispatch(actions.getAlertDefinition(patientId, 'glucose'));
            if (respAlertDef.type === ALERT_DEFINITION_GET_RESULT) {
              thresholdGlucoMin = respAlertDef.response.conditions?.find(c => c.field === 'glucose_concentration')?.min;
              thresholdGlucoMax = respAlertDef.response.conditions?.find(c => c.field === 'glucose_concentration')?.max;
            }
          }

          if (resp.type === PLOT_DATA_RESULT && resp.response.length !== 0) {
            const data = resp.response.map(i => {
              return {
                dt: moment(i.timestamp),
                value: roundToDecimal(
                  GlucoseUnitConverter(i.glucose_concentration, i.glucose_unit, GLUCOSE_UNITS.mg_dl),
                  2,
                ),
              };
            });
            ret[graphMetricEnum.glucose] = {
              data: data.filter(d => !isNaN(d.value)),
              thresholdMin: thresholdGlucoMin,
              thresholdMax: thresholdGlucoMax,
              badData: v => v > thresholdGlucoMax || v < thresholdGlucoMin,
            };
          }
        }
        break;
      case graphDataProvidersEnum.temp:
        {
          resp = await store?.dispatch(plotActions.getPlotData(request));

          let thresholdTempMin, thresholdTempMax;
          if (getThresholds) {
            const respAlertDef = await store?.dispatch(actions.getAlertDefinition(patientId, 'temperature'));
            if (respAlertDef.type === ALERT_DEFINITION_GET_RESULT) {
              thresholdTempMin = roundToDecimal(
                convertCelsiusToFahrenheit(respAlertDef.response.conditions?.find(c => c.field === 'temperature')?.min),
                2,
              );
              thresholdTempMax = roundToDecimal(
                convertCelsiusToFahrenheit(respAlertDef.response.conditions?.find(c => c.field === 'temperature')?.max),
                2,
              );
            }
          }

          if (resp.type === PLOT_DATA_RESULT && resp.response.length !== 0) {
            const data = resp.response.map(i => {
              return {
                dt: moment(i.timestamp * 1000),
                value:
                  i.unit === TEMPERATURE_UNITS.c || !i.unit
                    ? roundToDecimal(convertCelsiusToFahrenheit(i.temperature), 2)
                    : i.temperature,
              };
            });
            ret[graphMetricEnum.temperature] = {
              data: data.filter(d => !isNaN(d.value)),
              thresholdMin: thresholdTempMin,
              thresholdMax: thresholdTempMax,
              badData: v => v > thresholdTempMax || v < thresholdTempMin,
            };
          }
        }
        break;
      //       ? 'steps_report'
      //       ? 'movement_report'
      case graphDataProvidersEnum.airQualityMonitor:
        {
          resp = await store?.dispatch(plotActions.getPlotData(request));

          let thresholdPM25Max;
          if (getThresholds) {
            const respAlertDef = await store?.dispatch(actions.getAlertDefinition(patientId, 'air_quality'));
            if (respAlertDef.type === ALERT_DEFINITION_GET_RESULT) {
              thresholdPM25Max = respAlertDef.response.conditions?.find(c => c.field === 'pm2_5')?.max;
            }
          }

          if (resp.type === PLOT_DATA_RESULT && resp.response.length !== 0) {
            const dataPM25 = resp.response.map(i => {
              return {
                dt: moment(i.timestamp * 1000),
                value: i.pm2_5?.value,
              };
            });
            const dataPM1 = resp.response.map(i => {
              return {
                dt: moment(i.timestamp * 1000),
                value: i.pm1.value,
              };
            });
            const dataPM10 = resp.response.map(i => {
              return {
                dt: moment(i.timestamp * 1000),
                value: i.pm10?.value,
              };
            });
            const dataBarometricPressure = resp.response.map(i => {
              return {
                dt: moment(i.timestamp * 1000),
                value: i.barometric_pressure?.value,
              };
            });
            const dataCh2o = resp.response.map(i => {
              return {
                dt: moment(i.timestamp * 1000),
                value: i.ch2o?.value,
              };
            });
            const dataCo2 = resp.response.map(i => {
              return {
                dt: moment(i.timestamp * 1000),
                value: i.co2?.value,
              };
            });
            const dataLuminocity = resp.response.map(i => {
              return {
                dt: moment(i.timestamp * 1000),
                value: i.luminocity?.value,
              };
            });
            const dataNoise = resp.response.map(i => {
              return {
                dt: moment(i.timestamp * 1000),
                value: i.noise?.value,
              };
            });
            const dataNox = resp.response.map(i => {
              return {
                dt: moment(i.timestamp * 1000),
                value: i.nox_index?.value,
              };
            });
            const dataRelativeHumidity = resp.response.map(i => {
              return {
                dt: moment(i.timestamp * 1000),
                value: i.relative_humidity?.value,
              };
            });
            const dataAirTemperature = resp.response.map(i => {
              return {
                dt: moment(i.timestamp * 1000),
                value: i.temperature?.value,
              };
            });
            const dataVoc = resp.response.map(i => {
              return {
                dt: moment(i.timestamp * 1000),
                value: i.voc_index?.value,
              };
            });

            ret[graphMetricEnum.pm25] = {
              data: prepareSimplifiedDataForDataProvider(dataPM25),
              thresholdMax: thresholdPM25Max,
              badData: v => v > thresholdPM25Max,
            };
            ret[graphMetricEnum.pm1] = {
              data: prepareSimplifiedDataForDataProvider(dataPM1),
            };
            ret[graphMetricEnum.pm10] = {
              data: prepareSimplifiedDataForDataProvider(dataPM10),
            };
            ret[graphMetricEnum.barometricPressure] = {
              data: prepareSimplifiedDataForDataProvider(dataBarometricPressure),
            };
            ret[graphMetricEnum.ch2o] = {
              data: prepareSimplifiedDataForDataProvider(dataCh2o),
            };
            ret[graphMetricEnum.co2] = {
              data: prepareSimplifiedDataForDataProvider(dataCo2),
            };
            ret[graphMetricEnum.luminocity] = {
              data: prepareSimplifiedDataForDataProvider(dataLuminocity),
            };
            ret[graphMetricEnum.noise] = {
              data: prepareSimplifiedDataForDataProvider(dataNoise),
            };
            ret[graphMetricEnum.nox] = {
              data: prepareSimplifiedDataForDataProvider(dataNox),
            };
            ret[graphMetricEnum.relativeHumidity] = {
              data: prepareSimplifiedDataForDataProvider(dataRelativeHumidity),
            };
            ret[graphMetricEnum.airTemperature] = {
              data: prepareSimplifiedDataForDataProvider(dataAirTemperature),
            };
            ret[graphMetricEnum.voc] = {
              data: prepareSimplifiedDataForDataProvider(dataVoc),
            };
          }
        }
        break;

      case graphDataProvidersEnum.ptInr:
        {
          resp = await store?.dispatch(plotActions.getPlotData(request));

          let thresholdPtMin, thresholdPtMax, thresholdInrMin, thresholdInrMax;
          if (getThresholds) {
            const respAlertDef = await store?.dispatch(actions.getAlertDefinition(patientId, 'coagulation'));
            if (respAlertDef.type === ALERT_DEFINITION_GET_RESULT) {
              thresholdPtMin = respAlertDef.response.conditions?.find(c => c.field === 'pt')?.min;
              thresholdPtMax = respAlertDef.response.conditions?.find(c => c.field === 'pt')?.max;
              thresholdInrMin = respAlertDef.response.conditions?.find(c => c.field === 'inr')?.min;
              thresholdInrMax = respAlertDef.response.conditions?.find(c => c.field === 'inr')?.max;
            }
          }

          if (resp.type === PLOT_DATA_RESULT && resp.response.length !== 0) {
            const dataPt = resp.response.map(i => {
              return {
                dt: moment(i.timestamp * 1000),
                value: i.pt_value,
              };
            });
            ret[graphMetricEnum.pt] = {
              data: dataPt.filter(d => !isNaN(d.value)),
              thresholdMin: thresholdPtMin,
              thresholdMax: thresholdPtMax,
              badData: v => v < thresholdPtMin || v > thresholdPtMax,
            };

            const dataInr = resp.response.map(i => {
              return {
                dt: moment(i.timestamp * 1000),
                value: i.inr_value,
              };
            });
            ret[graphMetricEnum.inr] = {
              data: dataInr.filter(d => !isNaN(d.value)),
              thresholdMin: thresholdInrMin,
              thresholdMax: thresholdInrMax,
              badData: v => v < thresholdInrMin || v > thresholdInrMax,
            };
          }
        }
        break;

      case graphDataProvidersEnum.spirometer:
        {
          resp = await store?.dispatch(plotActions.getPlotData(request));

          let thresholdFev1Min, thresholdFev1Max, thresholdFvcMin, thresholdFvcMax;
          if (getThresholds) {
            const respAlertDef = await store?.dispatch(actions.getAlertDefinition(patientId, 'spirometer'));
            if (respAlertDef.type === ALERT_DEFINITION_GET_RESULT) {
              thresholdFev1Min = respAlertDef.response.conditions?.find(c => c.field === 'fev1')?.min;
              thresholdFev1Max = respAlertDef.response.conditions?.find(c => c.field === 'fev1')?.max;
              thresholdFvcMin = respAlertDef.response.conditions?.find(c => c.field === 'fvc')?.min;
              thresholdFvcMax = respAlertDef.response.conditions?.find(c => c.field === 'fvc')?.max;
            }
          }

          if (resp.type === PLOT_DATA_RESULT && resp.response.length !== 0) {
            const dataFev1 = resp.response.map(i => {
              return {
                dt: moment(i.eventTimestamp),
                value: i.serie.fev1,
              };
            });
            ret[graphMetricEnum.fev1] = {
              data: dataFev1.filter(d => !isNaN(d.value)),
              thresholdMin: thresholdFev1Min,
              thresholdMax: thresholdFev1Max,
              badData: v => v < thresholdFev1Min || v > thresholdFev1Max,
            };

            const dataFvc = resp.response.map(i => {
              return {
                dt: moment(i.eventTimestamp),
                value: i.serie.fvc,
              };
            });
            ret[graphMetricEnum.fvc] = {
              data: dataFvc.filter(d => !isNaN(d.value)),
              thresholdMin: thresholdFvcMin,
              thresholdMax: thresholdFvcMax,
              badData: v => v < thresholdFvcMin || v > thresholdFvcMax,
            };

            const dataSpirometrySeries = resp.response.map(i => {
              return {
                dt: moment(i.eventTimestamp),
                value: i.serie,
              };
            });
            ret['spirometrySeries'] = { data: dataSpirometrySeries };
          }
        }
        break;

      default:
        break;
    }
    Object.values(ret).forEach(i => {
      i.firstEvent = dtStart;
      i.lastEvent = dtEnd;
    });
    return ret;
  }

  static getEmptyData(dp) {
    const ret = {};
    graphMetrics
      .filter(m => m.dataProvider === dp)
      .forEach(m => {
        ret[m.id] = { data: [] };
      });
    return ret;
  }
  static async getDateRange(dp, patientId, deviceId, hubId, kitId) {
    const request = { dataType: getRequestDataType(dp) };
    if (patientId) request.patientId = patientId;
    if (deviceId) request.deviceId = deviceId;
    if (hubId) request.hubId = hubId;
    if (kitId) request.kitId = kitId;

    const resp = await store?.dispatch(plotActions.getPlotDateRange(request));

    if (resp.type === PLOT_DATE_RANGE_RESULT && resp.response.length !== 0 && resp.response.first_event && resp.response.last_event) {
      return {
        startDate: moment(resp.response.first_event),
        endDate: moment(resp.response.last_event),
      };
    }

    return {
      startDate: null,
      endDate: null,
    };
  }
}
