import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { actions } from './redux/actions';
import '../Widgets.scss';
import Strings from '../../../../Strings';

function TotalKits(props) {
  const { isLoading, totalKits, orgFilter, refreshTimestamp, getData, onClick } = props;
  
  React.useEffect(() => {
    getData(orgFilter);
  }, [orgFilter, refreshTimestamp]);

  return isLoading ? <></> : (
    <div className="boxWithVl-box">
      <div className="boxWithVl-valueBox black">{totalKits}</div>
      <div onClick={() => onClick('/devices/Kit')} className="boxWithVl-labelBox clickable">
        {Strings.totalKits}
      </div>
    </div>
  );
}

TotalKits.widgetId = 'TotalKitsWidget';

TotalKits.propTypes = {
  getData: PropTypes.func,
  totalKits: PropTypes.any,
  orgFilter: PropTypes.string,
  refreshTimestamp: PropTypes.number,
  isLoading: PropTypes.any,
  onClick: PropTypes.func,
};

const mapStateToProps = state => {
  const { widgetTotalKitsReducer } = state.dashboardWidgets;
  const userId = state.auth?.profile?.id;
  return {
    totalKits: widgetTotalKitsReducer && widgetTotalKitsReducer?.totalKits,
    isLoading: widgetTotalKitsReducer && widgetTotalKitsReducer?.isLoading,
    orgFilter: state.superUser?.patients?.orgFilter?.[userId],
  };
};

const mapDispatchToProps = dispatch => ({
  getData: orgFilter => dispatch(actions.getTotalKits(orgFilter)),
  onClick: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TotalKits);
