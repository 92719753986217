import React from 'react';
import { connect } from 'react-redux';

import { SelectField, TextArea } from '../../containers/Form';
import Strings from '../../Strings';
import { Timezones } from '../../constants';
import DateTimeField from './DateTimeField';

const WelcomeMessagePage = props => {
  const { patient, welcomeMessage, setWelcomeMessage, defaultWelcomeMessage, validationErrors, highlightInvalidFields, fields } = props;

  const patientTimezoneInfo = patient?.timezone !== props.timezone || patient?.timezone !== props.orgTimezone ? ` (${Strings.formatString(Strings.enrollmentDateTimezoneTooltip, Timezones[patient?.timezone], Strings.patient)})` : '';
  const welcomeSmsPage = (
    <React.Fragment>
      <SelectField
        name="welcome_sms"
        id="welcome_sms"
        label={Strings.capPatient.enableMessage}
        data={[
          { value: true, label: Strings.enabled },
          { value: false, label: Strings.disabled },
        ]}
        value={welcomeMessage.set}
        onChange={option => setWelcomeMessage(m => ({...m, set: option.value}))}
        rowClassName="double-width-left"
      />
      {welcomeMessage.set && (
        <React.Fragment>
          <div className="reminder-text double-width-right" style={{ gridRow: 'span 4' }}>
            <TextArea
              name="welcomeMessageText"
              id="welcomeMessageText"
              label={Strings.capPatient.welcomeMessage}
              value={welcomeMessage.text}
              timezone={patient?.timezone}
              rows={8}
              onChange={e => setWelcomeMessage(m => ({...m, text: e.target.value}))}
              isRequired={fields.welcomeMessageText.required}
              disabled={fields.welcomeMessageText.readOnly}
              errorsForTooltip={validationErrors.find(v => v.property === 'welcomeMessageText')?.errors || []}
              highlightInvalid={highlightInvalidFields}
            />
            {welcomeMessage.text !== defaultWelcomeMessage && (
              <button
                onClick={() => setWelcomeMessage(m => ({...m, text: defaultWelcomeMessage}))}
                className="reset-reminder-text brand-white-gray"
              >
                {Strings.setDefault}
              </button>
            )}
          </div>
          <DateTimeField
            value={welcomeMessage.dateTime}
            onChange={date => setWelcomeMessage(m => ({...m, dateTime: date}))}
            timezone={patient?.timezone}
            dateInputProps={{
              name: 'welcome_sms_date',
              id: 'welcome_sms_date',
              label: `${Strings.scheduledDate}${patientTimezoneInfo}`,
              highlightInvalid: highlightInvalidFields,
              isRequired: fields.welcomeMessageDate.isRequired,
              disabled: fields.welcomeMessageDate.readOnly,
              errorsForTooltip: validationErrors.find(v => v.property === 'welcomeMessageDate')?.errors || [],
              rowClassName: 'double-width-left',
            }}
            timeInputProps={{
              id: 'welcome_sms_time',
              name: 'welcome_sms_time',
              label: Strings.time,
              highlightInvalid: highlightInvalidFields,
              isRequired: fields.welcomeMessageDate.isRequired,
              disabled: fields.welcomeMessageDate.readOnly,
              errorsForTooltip: validationErrors.find(v => v.property === 'welcomeMessageDate')?.errors || [],
            }}
            timeAmPmSelectProps={{
              name: 'welcome_sms_time_am_pm',
              id: 'welcome_sms_time_am_pm',
              label: ' ',
              highlightInvalid: highlightInvalidFields,
              isRequired: fields.welcomeMessageDate.isRequired,
              disabled: fields.welcomeMessageDate.readOnly,
              errorsForTooltip: validationErrors.find(v => v.property === 'welcomeMessageDate')?.errors || [],
            }}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );

  return welcomeSmsPage;
};

const mapStateToProps = state => {
  return {
    timezone: state.auth?.profile?.preferences?.timezone,
    orgTimezone: state.auth?.ui_config?.timezone,
  };
};

export default connect(mapStateToProps, null)(WelcomeMessagePage);
