import PropTypes from 'prop-types';
import { resetForm } from 'actions/forms';
import { closeModal } from 'actions/modal';
import { notificationActions } from 'components/Notification/redux/actions';

import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import LoadingRenderer from '../components/LoadingRenderer';
import { Modal } from '../containers';
import { OrgOutboundAPIForm } from '../features/OrgOutboundAPIForm/OrgOutboundAPIForm';
import {
  ADD_ORGANIZATION_OUTBOUND_API_RESULT,
  UPDATE_ORGANIZATION_OUTBOUND_API_RESULT,
} from '../pages/Organizations/redux/constants';
import './editStudyModal.scss';

const mapStateToProps = state => {
  const { organizations } = state.superUser;

  return {
    isLoading: organizations ? organizations.isLoading : false,
    error: organizations ? organizations?.error : null,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
  onCancel: () => {
    dispatch(ownProps.data.clearErrors()), dispatch(closeModal(ownProps.name));
  },
  onOpen: () => dispatch(resetForm(ownProps.name, ownProps.data)),
  onSubmit: data =>
    dispatch(ownProps.data.action(data, ownProps.data)).then(response => {
      if (
        (response && response.type === ADD_ORGANIZATION_OUTBOUND_API_RESULT) ||
        response.type === UPDATE_ORGANIZATION_OUTBOUND_API_RESULT
      ) {
        dispatch(ownProps.data && ownProps.data.nextAction(ownProps.data.pageRequest));
        dispatch(closeModal(ownProps.name));
      }
      return response;
    }),
});
class OutboundAPIModal extends PureComponent {
  onSubmit = values => {
    const formValues = {};
    if (this.props.data?.data) {
      const { data } = this.props.data;
      if (data.name !== values.name) {
        formValues.name = values.name;
      }
      if (data.host !== values.host) {
        formValues.host = values.host;
      }
      if (data.url !== values.url) {
        formValues.url = values.url;
      }
      if (data.auth_key !== values.auth_key) {
        formValues.auth_key = values.auth_key;
      }
      if (data.auth_key_header_parameter !== values.auth_key_header_parameter) {
        formValues.auth_key = values.auth_key_header_parameter;
      }
      if (data.version == values.version) {
        formValues.paused = values.version;
      }
      if (data.paused !== values.paused?.paused) {
        formValues.paused = values.paused?.paused;
      }
      if (data.max_batch_size !== values.max_batch_size) {
        formValues.max_batch_size = +values.max_batch_size;
      }
      if (data.max_time_without_events !== values.max_time_without_events) {
        formValues.max_time_without_events = +values.max_time_without_events;
      }
      if (data.success_field_required !== values.success_field_required?.success_field_required) {
        formValues.success_field_required = values.success_field_required?.success_field_required;
      }
    } else {
      formValues.host = values.host;
      formValues.url = values.url;
      formValues.auth_key = values.auth_key;
      formValues.name = values.name;
      formValues.organization_id = decodeURIComponent(this.props.data.organization_id);
      formValues.auth_key_created_at = moment().unix();
      formValues.paused = values.paused?.paused;
      formValues.auth_key_header_parameter = values.auth_key_header_parameter;
      formValues.version = values.version;
      formValues.max_batch_size = +values.max_batch_size;
      formValues.max_time_without_events = +values.max_time_without_events;
      formValues.success_field_required = values.success_field_required?.success_field_required;
    }
    this.props.onSubmit(formValues);
  };

  render() {
    const { onOpen, onCancel, editPatientModalLoading, name, error, isLoading, data, ...props } = this.props;
    const doctor = data.data;

    return (
      <Modal onOpen={onOpen} name={name} additionalClasses={['form-modal', 'user-invite']} withoutContainer {...props}>
        <LoadingRenderer loading={editPatientModalLoading}>
          <OrgOutboundAPIForm
            admin={doctor}
            onSubmit={this.onSubmit}
            onCancel={onCancel}
            errors={error ? (typeof error === 'string' ? [error] : error) : []}
            isLoading={isLoading}
          />
        </LoadingRenderer>
      </Modal>
    );
  }
}

OutboundAPIModal.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({
      auth_key: PropTypes.any,
      host: PropTypes.any,
      id: PropTypes.any,
      name: PropTypes.any,
      url: PropTypes.any,
    }),
    organization_id: PropTypes.any,
  }),
  editPatientModalLoading: PropTypes.any,
  error: PropTypes.any,
  isLoading: PropTypes.any,
  name: PropTypes.any,
  onCancel: PropTypes.any,
  onOpen: PropTypes.any,
  onSubmit: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(OutboundAPIModal);
