import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import moment from 'moment-timezone';
import { cloneDeep, debounce } from 'lodash';
import axios from 'axios';
import _ from 'lodash';

import { closeModal, openModalAction } from '../../actions/modal';
import {
  AsyncSelectField,
  DateInput,
  Input,
  AsyncMultiSelectField,
  PhoneInputField,
  SelectField,
  TextArea,
  TimeInput,
} from '../../containers/Form';
import { actions as cohortActions } from '../../pages/SuperUser/Patients/Cohorts/redux/actions';
import { notificationActions } from '../../components/Notification/redux/actions';
import Strings from '../../Strings';
import { validateRpmPatient } from '../../utils/validators/rpmPatient';
import './EditRpmPatientModal.scss';
import Wizard, { wizardTypes } from '../../containers/Modal/Wizard';
import { SelectedOptions } from '../../components/Select/Multiselect';
import { ASSIGN_PATIENT_TO_FACILITY_ERROR, CREATE_CONDITION_ERROR } from '../../pages/SuperUser/Patients/Cohorts/redux/constants';
import { DATE_FORMAT_YEAR_MONTH_DAY, GetTimezoneTextMapping } from '../../constants';
import { RpmPatientModalPages } from '../_ModalsMetadata/ModalsMetadata';
import { actions } from '../../pages/SuperUser/Patients/redux/actions';
import { ADD_NOTE_ERROR } from '../../pages/SuperUser/Patients/redux/constants';
import { renderPatientMedication } from '../../pages/SuperUser/Patients/PatientInfo/MedicalHistory';
import { servicesActions } from '../../actions/services';

export const stages = {
  onboarding: 'onboarding',
  registration: 'registration',
  edit: 'edit',
  enrolling: 'enrolling',
  activating: 'activating',
  settingEndDate: 'settingEndDate',
  startingEnrollment: 'startingEnrollment',
  rescheduleTelevisit: 'rescheduleTelevisit',
};

export const getLabelTextForCondition = option => {
  return `${option.code ? '* ' : ''}${option.condition_icd10cm || option.code ? `(${option.condition_icd10cm || option.code}) ` : ''}${option.label}`
};

function EditRpmPatientModal(props) {
  const getPatientFromProps = patient => {
    return {
      ...patient,
      dateOfBirth: patient.dateOfBirth ? new Date(moment(patient.dateOfBirth, 'YYYY-MM-DD').format()) : '',
      conditions: Object.keys(patient.conditions),
      medicalHistory: null,
      transplantDetails: patient.transplantDetails.map(td => ({
        ...td,
        transplantDate: td.transplantDate ? new Date(moment(td.transplantDate, 'YYYY-MM-DD').format()) : '',
      })),
      medications: patient.medications?.filter(m => !m.ndc && _.isEmpty(m.prescriber)).map(m => m.name),
      medicationsWithExtraData: patient.medications?.filter(m => m.ndc || !_.isEmpty(m.prescriber)),
      televisitChecked: patient.enrollmentSchedules?.length > 0,
      televisitDate: patient.enrollmentSchedules?.length > 0 ? new Date(moment.tz(patient.enrollmentSchedules[0].enrollAt, patient.timezone).format()) : null,
      televisitTime: patient.enrollmentSchedules?.length > 0 ? moment.tz(patient.enrollmentSchedules[0].enrollAt, patient.timezone).format('hh:mm') : null,
      televisitTimeAmPm: patient.enrollmentSchedules?.length > 0 ? moment.tz(patient.enrollmentSchedules[0].enrollAt, patient.timezone).format('A') : null,
    };
  };

  const [patient, setPatient] = useState(
    props.data.patient
      ? getPatientFromProps(props.data.patient)
      : {
          centerDetails: [{}],
          insurance: [{}],
          transplantDetails: [{}],
          language: Object.keys(Strings.languagesEnum)[0],
          timezone: props.orgTimezone,
        },
  );
  const [registrationNote, setRegistrationNote] = useState();
  const [highlightInvalidFields, setHighlightMissingFields] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [saveBtnClicked, setSaveBtnClicked] = useState(false);
  const timezones = GetTimezoneTextMapping();

  useEffect(() => {
    props.getConditions();
    props.getFacilities();
    if (props.data?.patient?.id) {
      props.getMedications(props.data.patient.id);
    }
  }, []);

  useEffect(() => {
    if (!props.conditions || props.conditions?.length === 0) {
      return;
    }
    setPatient(p => ({
      ...p,
      conditionsObjects: p.conditions?.map(c => {
        const condition = props.conditions.find(con => con.id === c);
        return {
          ...condition,
          value: condition?.id,
          label: condition?.title,
        };
      }),
    }));
  }, [props.conditions]);

  useEffect(() => {
    let errors = [];
    if (props.facilities && props.conditions) {
      errors = validateRpmPatient(patient, props.conditions, props.facilities, false);
    }
    if (props.data?.stage === stages.registration && !patient.textPhoneNo) {
      errors.push({ property: 'textPhoneNo', missing: true });
    }
    if (props.data?.stage === stages.registration && !patient.consentForProvider) {
      errors.push({ property: 'consentForProvider', missing: true });
    }
    const televisitDateTime = calcDateTime(patient.televisitDate, patient.televisitTime, patient.televisitTimeAmPm);
    if ((televisitDateTime && moment(televisitDateTime).unix() < moment().unix()) || moment(patient.televisitDate).unix() < moment().unix()) {
      errors.push({ property: 'televisitDate', errors: [Strings.errors.dateInThePast], missing: false });
    }
    if (patient.televisitChecked && !patient.televisitDate) {
      errors.push({ property: 'televisitDate', errors: [], missing: true });
    }
    if (patient.televisitChecked && !patient.televisitTime) {
      errors.push({ property: 'televisitTime', errors: [], missing: true });
    }
    if (patient.televisitChecked && !patient.televisitTimeAmPm) {
      errors.push({ property: 'televisitTimeAmPm', errors: [], missing: true });
    }
    setValidationErrors(errors);
  }, [patient, props.conditions, props.facilities]);

  const onTextChange = event => {
    const { name, value } = event.target;
    setPatient(p => ({
      ...p,
      [name]: value,
    }));
  };

  const onZipCodeSelected = option => {
    setPatient(p => ({
      ...p,
      homeAddress: option.address,
    }));
  };

  const onDateChange = (date, field) => {
    setPatient(p => ({
      ...p,
      [field]: date,
    }));
  };

  const onPhoneChange = (event, field) => {
    setPatient(p => ({
      ...p,
      // in case we want to autopolulate primary phone number with text phone number
      // primaryPhoneNo: !p.primaryPhoneNo && field === 'textPhoneNo' && validatePhoneNo(event.target.value)?.length === 0 ? event.target.value : p.primaryPhoneNo,
      [field]: event.target.value,
    }));
  };

  const onSelectedOption = (option, field) => {
    if (option.value) {
      setPatient(p => ({
        ...p,
        [field]: option.value,
      }));
    }
  };

  const onConditionsChange = options => {
    setPatient(p => ({
      ...p,
      conditionsObjects: options,
    }));
  };

  const onRemoveCondition = id => {
    setPatient(p => ({
      ...p,
      conditionsObjects: p.conditionsObjects.filter(h => h.value !== id),
    }));
  };

  const addListItem = list => {
    setPatient(p => ({
      ...p,
      [list]: p[list].concat({}),
    }));
  };

  const editListItem = (event, list, index) => {
    const field = event.target.name;
    const value = event.target.value;
    setPatient(p => ({
      ...p,
      [list]: p[list].map((h, i) => {
        if (i === index) {
          return {
            ...h,
            [field]: value,
          };
        }
        return h;
      }),
    }));
  };

  const removeListItem = (list, index) => {
    setPatient(p => ({
      ...p,
      [list]: p[list].filter((_, i) => i !== index),
    }));
  };

  const getDataForSelect = response => {
    if (!response?.results) {
      return [];
    }
    return response.results.map(d => {
      return {
        label: d.formatted,
        value: d.place_id,
        address: `${d.address_line1}\n${d.address_line2}`,
      };
    });
  };
  const _loadOptions = (value, callback) => {
    props.getGeoapifyApiKey().then(r => {
      const apiKey = r?.response?.apiKey;
      if (!apiKey) {
        return [];
      }
      axios
        .get(
          `https://api.geoapify.com/v1/geocode/autocomplete?text=${value}&apiKey=${apiKey}&format=json`,
        )
        .then(response => {
          callback(getDataForSelect(response.data, value));
        });
    });
  };
  const loadOptions = debounce(_loadOptions, 500);

  const saveNote = async note => {
    if (note) {
      await props.addNote(patient.id, { content: note }).then(resp => {
        if (resp.type === ADD_NOTE_ERROR) {
          const error = resp.response?.data?.error?.message;
          props.showNotification(error, 5000, true);
        }
      });
    }
  };

  const calcDateTime = (date, time, ampm) => {
    if (date && time && ampm) {
      const t = moment(time, 'hh:mm').format('HH:mm');
      const d = moment.tz(date, props.data?.patient?.timezone).format('YYYY-MM-DD');
      return moment.tz(`${d} ${t} ${ampm}`, 'YYYY-MM-DD HH:mm A', props.data?.patient?.timezone).format();
    }
    return null;
  };
  
  const onSave = async () => {
    if (saveBtnClicked) {
      return;
    }
    setSaveBtnClicked(true);

    let fail = false;
    const newConditions = [];
    if (patient.conditionsObjects?.filter(c => c.code).length > 0) {
      const addCondition = async condition => {
        const request = { title: condition.title, icd10cm: condition.code };
        const response = await props.createCondition(request);
        if (response && response.type === CREATE_CONDITION_ERROR) {
          props.showNotification(response.response?.data?.error?.message, 5000, true);
          setSaveBtnClicked(false);
          fail = true;
        } else {
          newConditions.push({...condition, id: response.response?.id});
        }
      }
      for (const [_, condition] of patient.conditionsObjects.filter(c => c.code).entries()) {
        await addCondition(condition);
      }
    }

    if (fail) {
      return;
    }

    const data = cloneDeep(patient);
    const editedPatient = props.data.patient;

    data.dateOfBirth = moment(patient.dateOfBirth).format(DATE_FORMAT_YEAR_MONTH_DAY);
    data.homeAddress = patient.homeAddress?.trim();

    /* eslint-disable */
    data.transplantDetails.map(transplantDetails => {
      transplantDetails.transplantDate = transplantDetails.transplantDate
        ? moment(transplantDetails.transplantDate).format(DATE_FORMAT_YEAR_MONTH_DAY)
        : null;
    });
    /* eslint-enable */
    data.medications = patient.medications?.filter(m => m.trim().length > 0).map(m => ({
      name: m.trim(),
    })).concat(patient.medicationsWithExtraData || []);
    const patientConditionIds = patient.conditionsObjects ? patient.conditionsObjects.map(c => {
      const id = c.id || newConditions.find(nc => nc.code === c.code)?.id;
      return id}) : [];
    data.conditions = patientConditionIds;

    if (patient.televisitChecked) {
      const televisitDateTime = calcDateTime(patient.televisitDate, patient.televisitTime, patient.televisitTimeAmPm);
      if (televisitDateTime) {
        data.initiateEnrollmentByProviderAt = televisitDateTime;
      }
    } else {
      data.initiateEnrollmentByProviderAt = null;
    }

    if (editedPatient && _.isEqual(editedPatient, data)) {
      return;
    }

    const onSuccess = patientData => {
      props.showNotification(Strings.formatString(Strings.capPatient.patientSaved, patientData.patientName));
      props.closeModalWithNextAction(patientData);
    };

    props.onSubmit(data).then(response => {
      if (response) {
        if (response.type === `${props.data.actionType}/error`) {
          const error = response.response?.data?.error?.message;
          props.showNotification(error, 5000, true);
        } else if (patient.facilityId && patient.facilityId !== props.data.patient?.facilityId) {
          props.assignPatientToFacility(parseInt(response.response?.id, 10), patient.facilityId).then(resp => {
            if (resp && resp.type === ASSIGN_PATIENT_TO_FACILITY_ERROR) {
              const assignError = resp.response.data?.error?.message;
              props.showNotification(assignError, 5000, true);
            } else {
              onSuccess({ ...response.response, facilityId: patient.facilityId });
            }
            return resp;
          });
        } else {
          const patientHasReminders = props.patientMedications?.some(m => m.sms_reminder);
          if (props.data?.patient?.id && props.data?.patient?.language !== patient.language && patientHasReminders) {
            const data = {
              title: <span>{Strings.remindersChangeRequired}</span>,
              caption: Strings.remindersChangeRequiredCaption,
              hideCaution: true,
              onConfirmAction: () => {
                props.onNavigate(`/cap-patients/${props.data.patient.id}/medications`),
                  props.closeModal('confirmation-modal');
              },
              onCancelAction: null,
            };
            props.openConfirmModal(data);
          }
          onSuccess(response.response);
        }
      }
      return response;
    });

    if (registrationNote && patient?.id) {
      saveNote(registrationNote);
    }
    setSaveBtnClicked(false);
  };

  const patientDetailsPage = (
    <React.Fragment>
      <Input
        name="firstName"
        id="firstName"
        label={Strings.firstName}
        type="text"
        value={patient.firstName || ''}
        onChange={onTextChange}
        isRequired
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'firstName')?.errors || []}
      />
      <Input
        name="lastName"
        id="lastName"
        label={Strings.lastName}
        type="text"
        value={patient.lastName || ''}
        onChange={onTextChange}
        isRequired
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'lastName')?.errors || []}
      />
      <Input
        name="pharmacyPatientId"
        id="pharmacyPatientId"
        label={Strings.capPatient.patientId}
        type="text"
        value={patient.pharmacyPatientId || ''}
        onChange={onTextChange}
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'pharmacyPatientId')?.errors || []}
      />
      <Input
        name="mrn"
        id="mrn"
        label={Strings.capPatient.mrn}
        type="text"
        value={patient.mrn || ''}
        onChange={onTextChange}
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'mrn')?.errors || []}
      />
      <DateInput
        name="dateOfBirth"
        id="dateOfBirth"
        label={Strings.dateOfBirth}
        value={patient?.dateOfBirth || ''}
        format="MM / dd / yyyy"
        maxDate={new Date()}
        onChange={e => onDateChange(e, 'dateOfBirth')}
        isRequired
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'dateOfBirth')?.errors || []}
      />
      <SelectField
        name="gender"
        id="gender"
        value={patient.gender}
        label={Strings.gender}
        placeholder={Strings.SelectField}
        isRequired
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'gender')?.errors || []}
        onChange={option => onSelectedOption(option, 'gender')}
        data={Object.values(Strings.genders).map(v => ({ value: v, label: v }))}
      />
      <SelectField
        name="language"
        id="language"
        value={patient.language}
        label={Strings.language}
        placeholder={Strings.SelectField}
        isRequired
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'language')?.errors || []}
        onChange={option => onSelectedOption(option, 'language')}
        data={Object.entries(Strings.languagesEnum).map(([k, v]) => ({ value: k, label: v }))}
      />
      <SelectField
        name="timezone"
        id="timezone"
        value={patient.timezone}
        label={Strings.timezone}
        placeholder={Strings.SelectField}
        onChange={option => onSelectedOption(option, 'timezone')}
        data={[{ value: '', label: Strings.selectTimezone }].concat(
          Object.keys(timezones).map(key => {
            return { value: key, label: timezones[key] };
          }),
        )}
      />
    </React.Fragment>
  );

  const contactInformationPage = (
    <React.Fragment>
      <PhoneInputField
        align="left"
        name="textPhoneNo"
        id="textPhoneNo"
        value={patient.textPhoneNo}
        onChange={e => onPhoneChange(e, 'textPhoneNo')}
        label={Strings.capPatient.mobileNumber}
        isRequired={props.data?.stage === stages.registration}
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'textPhoneNo')?.errors || []}
        rowClassName="double-width-left"
      />
      {/* {!patient.homeAddress && (
        <AsyncSelectField
          id="zip-code"
          name="zip-code"
          label="Search address"
          placeholder={Strings.search}
          loadOptions={loadOptions}
          onChange={onZipCodeSelected}
          isRequired
          highlightInvalid={highlightInvalidFields}
        />
      )} */}
      <TextArea
        name="homeAddress"
        id="homeAddress"
        rows={4}
        value={patient.homeAddress || ''}
        label={Strings.capPatient.address}
        onChange={onTextChange}
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'homeAddress')?.errors || []}
        rowClassName="gridspan-3 double-width-right"
      />
      <Input
        style={{ gridColumn: 1 }}
        name="email"
        id="email"
        label={Strings.email}
        type="text"
        value={patient.email || ''}
        onChange={onTextChange}
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'email')?.errors || []}
        isRequired={patient.last_login}
        rowClassName="double-width-left"
      />
    </React.Fragment>
  );

  const loadOptionsConditions = (value, callback) => {
    if (value?.length < 3) {
      return;
    }
    setTimeout(() => {
      props.getIdcConditions({ search: value }).then(resp => {
        callback(() => {
          if (!resp.response.data || resp.response.data.length === 0) {
            return [];
          }
          const idcConditions = resp.response.data.map(e => {
            return {
              ...e,
              value: e.code,
              label: e.title,
            };
          });
          const orgConditions = props.conditions.filter(c => c.title.includes(value) || c.condition_icd10cm?.includes(value)).map(c => ({ ...c, value: c.id, label: c.title })) || [];
          return orgConditions.concat(idcConditions.filter(d => !props.conditions.some(p => p.condition_icd10cm === d.code)));
        });
      });
    }, 1000);
  };

  const getLabelForCondition = option => (
    <React.Fragment key={`option_${option.value}_${patient.conditionsObjects?.some(c => c.value === option.value)}`}>
      {getLabelTextForCondition(option)}
      <input type="checkbox" defaultChecked={patient.conditionsObjects?.some(c => c.value === option.value)} disabled={option.isDisabled} />
    </React.Fragment>
  );

  const medicalHistoryPage = (
    <React.Fragment>
      {props.conditions && (
        <AsyncMultiSelectField
          name="primaryCondition"
          id="primaryCondition"
          label={Strings.condition}
          placeholder={Strings.selectCondition}
          value={patient.conditionsObjects || []}
          defaultOptions={props.conditions.map(c => ({ ...c, value: c.id, label: c.title })).concat(patient.conditionsObjects?.filter(o => o.code) || [])}
          loadOptions={loadOptionsConditions}
          onChange={onConditionsChange}
          highlightInvalid={highlightInvalidFields}
          checkboxFirst
          rowClassName="double-width-left"
          isSearchable
          getLabel={getLabelForCondition}
        />
      )}
      {patient.conditionsObjects?.length > 0 && props.conditions ? (
        <SelectedOptions
          className="double-width-left"
          items={patient.conditionsObjects?.map(c => {return {...c, label: getLabelTextForCondition(c)}}) || []}
          onRemove={e => onRemoveCondition(e.value)}
          itemsPerRow={1}
        />
      ) : (
        <div>{Strings.noConditionsSelectFielded}</div>
      )}
      <div className="control-with-top-right-text gridspan-3">
        <TextArea
          name="medications"
          id="medications"
          rows={5}
          value={patient.medications?.join('\n') || ''}
          label={Strings.medications}
          onChange={e =>
            onTextChange({ ...e, target: { ...e.target, value: e.target.value.split('\n'), name: 'medications' } })
          }
        />
        <div
          className="right-top-text"
          data-tooltip-content={Strings.capPatient.medicationsTooltip}
          data-tooltip-id="tooltip"
        >
          <span className="icon">?</span>
        </div>
        {patient.medicationsWithExtraData?.length > 0 && (
          <div>{patient.medicationsWithExtraData.map(m => renderPatientMedication(m))?.join('\n') || ''}</div>
        )}
      </div>
      {patient.conditionsObjects?.length > 0 && props.conditions ? (
        <SelectedOptions
          className="double-width-left"
          items={patient.conditionsObjects?.map(c => {return {...c, label: getLabelTextForCondition(c)}}) || []}
          onRemove={e => onRemoveCondition(e.value)}
          itemsPerRow={1}
        />
      ) : (
        <div>{Strings.noConditionsSelectFielded}</div>
      )}
    </React.Fragment>
  );

  const centerDetailsPage = (
    <React.Fragment>
      {props.facilities && props.facilities.length > 0 && (
        <SelectField
          name="facility"
          id="facility"
          label={Strings.providerFacility}
          value={patient.facilityId}
          onChange={option => onSelectedOption(option, 'facilityId')}
          placeholder={Strings.selectFacility}
          data={props.facilities.map(f => {
            return { value: f.id, label: f.title };
          })}
          highlightInvalid={highlightInvalidFields}
          errorsForTooltip={validationErrors.find(v => v.property === 'facilityId')?.errors || []}
        />
      )}
      {patient.centerDetails &&
        patient.centerDetails.length > 0 &&
        patient.centerDetails.map((c, i) => (
          <React.Fragment key={`centerDetails_${i}`}>
            {i > 0 && <div />}
            <Input
              name="orderingProvider"
              id="orderingProvider"
              label={Strings.capPatient.orderingProvider}
              type="text"
              value={c.orderingProvider || ''}
              onChange={e => editListItem(e, 'centerDetails', i)}
              errorsForTooltip={
                validationErrors.find(
                  v => v.property === 'centerDetails' && v.index === i && v.field === 'orderingProvider',
                )?.errors || []
              }
            />
            <PhoneInputField
              align="left"
              name="clinicContact"
              id="clinicContact"
              label={Strings.capPatient.clinicContact}
              type="text"
              value={c.clinicContact || ''}
              onChange={e => editListItem({ ...e, target: { ...e.target, name: 'clinicContact' } }, 'centerDetails', i)}
              errorsForTooltip={
                validationErrors.find(
                  v => v.property === 'centerDetails' && v.index === i && v.field === 'clinicContact',
                )?.errors || []
              }
            />
            <div className="button-container mt-20">
              <button
                className="minus-button high"
                onClick={() => removeListItem('centerDetails', i)}
                disabled={patient.centerDetails.length === 1}
              />
            </div>
          </React.Fragment>
        ))}
      <div className="button-container ">
        <button className="plus-button" onClick={() => addListItem('centerDetails')}></button>
      </div>
    </React.Fragment>
  );

  const insurancePage = (
    <React.Fragment>
      {patient.insurance &&
        patient.insurance.length > 0 &&
        patient.insurance.map((ins, i) => (
          <React.Fragment key={`ins_${i}`}>
            <Input
              name="insuranceName"
              id="insuranceName"
              label={Strings.capPatient.insuranceName}
              type="text"
              value={ins.insuranceName || ''}
              onChange={e => editListItem(e, 'insurance', i)}
              errorsForTooltip={
                validationErrors.find(v => v.property === 'insurance' && v.index === i && v.field === 'insuranceName')
                  ?.errors || []
              }
            />
            <Input
              name="policyId"
              id="policyId"
              label={Strings.capPatient.policyId}
              type="text"
              value={ins.policyId || ''}
              onChange={e => editListItem(e, 'insurance', i)}
              errorsForTooltip={
                validationErrors.find(v => v.property === 'insurance' && v.index === i && v.field === 'policyId')
                  ?.errors || []
              }
            />
            <Input
              name="groupNumber"
              id="groupNumber"
              label={Strings.capPatient.groupNumber}
              type="text"
              value={ins.groupNumber || ''}
              onChange={e => editListItem(e, 'insurance', i)}
              errorsForTooltip={
                validationErrors.find(v => v.property === 'insurance' && v.index === i && v.field === 'groupNumber')
                  ?.errors || []
              }
            />
            <div className="button-container ">
              <button
                className="minus-button high"
                onClick={() => removeListItem('insurance', i)}
                disabled={patient.insurance.length === 1}
              />
            </div>
          </React.Fragment>
        ))}
      <div className="button-container ">
        <button className="plus-button" onClick={() => addListItem('insurance')}></button>
      </div>
    </React.Fragment>
  );

  const transplantDetailsPage = (
    <React.Fragment>
      {patient.transplantDetails &&
        patient.transplantDetails.length > 0 &&
        patient.transplantDetails.map((t, i) => (
          <React.Fragment key={`transplantDet_${i}`}>
            {i > 0 && <div />}
            <Input
              name="organType"
              id="organType"
              label={Strings.capPatient.transplantOrganType}
              type="text"
              value={t.organType || ''}
              onChange={e => editListItem(e, 'transplantDetails', i)}
              errorsForTooltip={
                validationErrors.find(
                  v => v.property === 'transplantDetails' && v.index === i && v.field === 'organType',
                )?.errors || []
              }
            />
            <DateInput
              name="transplantDate"
              id="transplantDate"
              label={Strings.capPatient.transplantDate}
              placeholder="MM / DD / YYYY"
              value={t.transplantDate || ''}
              format="MM / dd / yyyy"
              onChange={e => editListItem({ target: { value: e, name: 'transplantDate' } }, 'transplantDetails', i)}
              highlightInvalid={highlightInvalidFields}
              errorsForTooltip={
                validationErrors.find(
                  v => v.property === 'transplantDetails' && v.index === i && v.field === 'transplantDate',
                )?.errors || []
              }
            />
            <div className="button-container">
              <button
                className="minus-button high"
                onClick={() => removeListItem('transplantDetails', i)}
                disabled={patient.transplantDetails.length === 1}
              />
            </div>
          </React.Fragment>
        ))}
      <div className="button-container" style={{ gridColumnStart: 1 }}>
        <button className="plus-button" onClick={() => addListItem('transplantDetails')}></button>
      </div>
    </React.Fragment>
  );

  const patientTimezoneInfo = patient?.timezone !== props.timezone || patient?.timezone !== props.orgTimezone ? ` (${Strings.formatString(Strings.enrollmentDateTimezoneTooltip, timezones[patient?.timezone], Strings.patient)})` : '';
  const registerSection = (
    <React.Fragment>
      <div className="page-info-header">{Strings.agreementToSpeakWithProvider}</div>
      <SelectField
        name="consentForProvider"
        id="consentForProvider"
        label={Strings.select}
        value={patient?.consentForProvider}
        onChange={option => {
          onTextChange({ target: { value: option.value, name: 'consentForProvider' } });
          onTextChange({ target: { value: true, name: 'outreachCompleted' } });
        }}
        placeholder={Strings.noOptionSelected}
        data={Object.entries(Strings.outreachStatusEnum).map(([k, v]) => ({ value: k, label: v }))}
        isRequired
        disabled={props.data?.stage === stages.rescheduleTelevisit}
        highlightInvalid={highlightInvalidFields}
        rowClassName="double-width-left"
      />
      <TextArea
        name="notes_registration"
        id="notes_registration"
        rows={1}
        value={registrationNote}
        label={Strings.pharmacyNotesToProvider}
        onChange={e => setRegistrationNote(e.target.value)}
        rowClassName="double-width-right gridspan-4"
      />
      <SelectField
        name="televisit"
        id="televisit"
        label={Strings.capPatient.scheduleTelevisit}
        data={[
          { value: true, label: Strings.enabled },
          { value: false, label: Strings.disabled },
        ]}
        value={patient.televisitChecked}
        disabled={patient.consentForProvider !== 'set'}
        onChange={option => onTextChange({ target: { value: option.value, name: 'televisitChecked' } })}
        rowClassName="double-width-left"
      />
      {patient.televisitChecked && (
        <React.Fragment>
          <DateInput
            name="televisit_date"
            id="televisit_date"
            label={`${Strings.scheduledDate}${patientTimezoneInfo}`}
            placeholder="MM / DD / YYYY"
            value={patient.televisitDate || ''}
            timezone={patient?.timezone}
            format="MM / dd / yyyy"
            onChange={date => onTextChange({ target: { value: date, name: 'televisitDate' } })}
            highlightInvalid={highlightInvalidFields}
            isRequired={patient.televisitChecked}
            errorsForTooltip={validationErrors.find(v => v.property === 'televisitDate')?.errors || []}
            rowClassName="double-width-left"
          />
          <div className="horizontal-flex double-width-left">
            <div className="horizontal-flex">
              <TimeInput
                id="televisit_time"
                name="televisit_time"
                label={Strings.time}
                format="hh:mm"
                value={patient.televisitTime}
                onChange={date => onTextChange({ target: { value: date, name: 'televisitTime' } })}
                highlightInvalid={highlightInvalidFields}
                isRequired={patient.televisitChecked}
                errorsForTooltip={validationErrors.find(v => v.property === 'televisitTime')?.errors || []}
              />
              <SelectField
                name="televisit_time_am_pm"
                id="televisit_time_am_pm"
                label=" "
                value={patient.televisitTimeAmPm}
                onChange={option => onTextChange({ target: { value: option.value, name: 'televisitTimeAmPm' } })}
                data={[
                  { value: 'AM', label: Strings.patient_medications.am },
                  { value: 'PM', label: Strings.patient_medications.pm },
                ]}
                highlightInvalid={highlightInvalidFields}
                isRequired={patient.televisitChecked}
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );

  const patientDetailsFields = ['firstName', 'lastName', 'mrn', 'dateOfBirth', 'gender'];
  const contactInformationFields = ['primaryPhoneNo', 'textPhoneNo', 'email', 'homeAddress', 'gender'];
  const medicalHistoryFields = ['conditions'];
  const insuranceFields = ['insurance'];
  const centerDetailsFields = ['facility', 'centerDetails'];
  const transplantDetailsFields = ['transplantDetails'];
  let allFields = [
    ...patientDetailsFields,
    ...contactInformationFields,
    ...medicalHistoryFields,
    ...insuranceFields,
    ...centerDetailsFields,
    ...transplantDetailsFields,
  ];

  if (props.data?.stage === stages.registration) {
    allFields = allFields.concat(['consentForProvider', 'televisitDate', 'televisitTime', 'televisitTimeAmPm']);
  }

  const pages = [
    {
      id: RpmPatientModalPages.patientDetails.id,
      title: RpmPatientModalPages.patientDetails.name,
      content: patientDetailsPage,
      emptyFieldsCount: validationErrors.filter(v => patientDetailsFields.includes(v.property) && v.missing).length,
      canGoNext: validationErrors.filter(v => patientDetailsFields.includes(v.property)).length === 0,
    },
    {
      id: RpmPatientModalPages.contactInformation.id,
      title: RpmPatientModalPages.contactInformation.name,
      content: contactInformationPage,
      emptyFieldsCount: validationErrors.filter(v => contactInformationFields.includes(v.property) && v.missing).length,
      canGoNext: validationErrors.filter(v => contactInformationFields.includes(v.property)).length === 0,
    },
    {
      id: RpmPatientModalPages.medicalHistory.id,
      title: RpmPatientModalPages.medicalHistory.name,
      content: medicalHistoryPage,
      emptyFieldsCount: validationErrors.filter(v => medicalHistoryFields.includes(v.property) && v.missing).length,
      canGoNext: validationErrors.filter(v => medicalHistoryFields.includes(v.property)).length === 0,
    },
    {
      id: RpmPatientModalPages.centerDetails.id,
      title: RpmPatientModalPages.centerDetails.name,
      content: centerDetailsPage,
      emptyFieldsCount: validationErrors.filter(v => centerDetailsFields.includes(v.property) && v.missing).length,
      canGoNext: validationErrors.filter(v => centerDetailsFields.includes(v.property)).length === 0,
    },
    {
      id: RpmPatientModalPages.insurance.id,
      title: RpmPatientModalPages.insurance.name,
      content: insurancePage,
      emptyFieldsCount: validationErrors.filter(v => insuranceFields.includes(v.property) && v.missing).length,
      canGoNext: validationErrors.filter(v => insuranceFields.includes(v.property)).length === 0,
    },
    {
      id: RpmPatientModalPages.transplantDetails.id,
      title: RpmPatientModalPages.transplantDetails.name,
      content: transplantDetailsPage,
      emptyFieldsCount: validationErrors.filter(v => transplantDetailsFields.includes(v.property) && v.missing).length,
      canGoNext: validationErrors.filter(v => transplantDetailsFields.includes(v.property)).length === 0,
    },
    {
      id: RpmPatientModalPages.summary.id,
      title: `${props.data?.stage === stages.registration ? Strings.register : ''} ${
        patient?.patientName || (patient?.firstName && patient?.lastName)
          ? `${patient.firstName} ${patient.lastName}`
          : Strings.addPatient
      }`,
      content: (
        <React.Fragment>
          {(props.data?.stage === stages.registration || props.data?.stage === stages.rescheduleTelevisit) && registerSection}
          <div className="page-info-header">{Strings.capPatient.patientDetails}</div>
          {patientDetailsPage}
          <div className="page-info-header">{Strings.capPatient.contactInformation}</div>
          {contactInformationPage}
          <div className="page-info-header">{Strings.capPatient.medicalHistory}</div>
          {medicalHistoryPage}
          <div className="page-info-header">{Strings.capPatient.providerCenterDetails}</div>
          {centerDetailsPage}
          {props.pagesVisibility.find(p => p.id === RpmPatientModalPages.insurance.id)?.visible && (
            <React.Fragment>
              <div className="page-info-header">{Strings.capPatient.insurance}</div>
              {insurancePage}
            </React.Fragment>
          )}
          {props.pagesVisibility.find(p => p.id === RpmPatientModalPages.transplantDetails.id)?.visible && (
            <React.Fragment>
              <div className="page-info-header">{Strings.capPatient.transplantDetails}</div>
              {transplantDetailsPage}
            </React.Fragment>
          )}
        </React.Fragment>
      ),
      emptyFieldsCount: validationErrors.filter(v => allFields.includes(v.property) && v.missing).length,
      canGoNext: validationErrors.filter(v => allFields.includes(v.property)).length === 0,
    },
  ];

  const getpagesToShow = () => {
    if (
      props.data?.stage === stages.registration ||
      props.data?.stage === stages.edit ||
      props.data?.stage === stages.onboarding ||
      props.data?.stage === stages.rescheduleTelevisit
    ) {
      return [pages[6]];
    }
    const ret =
      props.data.pages && props.data.pages.length > 0
        ? props.data.pages.map(p => pages.find(pg => pg.id === p.id))
        : pages;

    if (props.data.ignoreFilters || !props.pagesVisibility) {
      return ret;
    }
    return ret.filter(p => props.pagesVisibility?.some(pg => pg.id === p.id && pg.visible));
  };

  return (
    <Wizard
      name="edit-rpm-patient"
      pages={getpagesToShow()}
      onNextButtonHover={e => setHighlightMissingFields(e)}
      onSubmit={onSave}
      showPagesFilter={!props.data.ignoreFilters}
      type={wizardTypes.horizontal}
    />
  );
}

EditRpmPatientModal.propTypes = {
  data: PropTypes.shape({
    pages: PropTypes.array,
    patient: PropTypes.any,
    action: PropTypes.func,
    ignoreFilters: PropTypes.bool,
    workflow: PropTypes.any,
    onSuccess: PropTypes.func,
  }),
  onCancel: PropTypes.any,
  onSubmit: PropTypes.func,
  getConditions: PropTypes.func,
  conditions: PropTypes.array,
  getFacilities: PropTypes.func,
  facilities: PropTypes.array,
  assignPatientToFacility: PropTypes.func,
  showNotification: PropTypes.func,
  closeModalWithNextAction: PropTypes.func,
  pagesVisibility: PropTypes.array,
  getMedications: PropTypes.func,
  openConfirmModal: PropTypes.func,
  closeModal: PropTypes.func,
  onNavigate: PropTypes.func,
  patientMedications: PropTypes.array,
  addNote: PropTypes.func,
};

EditRpmPatientModal.defaultProps = { data: { ignoreFilters: false } };

const mapStateToProps = state => {
  return {
    conditions: state.superUser.cohorts?.conditions,
    facilities: state.superUser.cohorts?.facilities,
    patientMedications: state.superUser?.patients?.patientMedications?.medications,
    pagesVisibility: state.modalsVisibility.pagesVisibility.find(m => m.id === 'edit-rpm-patient').pages,
    orgTimezone: state.auth?.ui_config?.timezone,
    timezone: state.auth?.profile?.preferences?.timezone,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('edit-rpm-patient')),
  closeModalWithNextAction: patient => {
    dispatch(closeModal('edit-rpm-patient'));
    if (ownProps.data.nextAction) dispatch(ownProps.data.nextAction);
    if (ownProps.data.onSuccess) {
      ownProps.data.onSuccess(patient);
    }
  },
  onSubmit: data => {
    return dispatch(ownProps.data.action(data, data.id));
  },
  getConditions: () => dispatch(cohortActions.getConditions()),
  getFacilities: () => dispatch(cohortActions.getFacilities()),
  assignPatientToFacility: (patientId, facilityId) =>
    dispatch(cohortActions.assignPatientToFacility(patientId, facilityId)),
  getMedications: patientId => dispatch(actions.getMedications(patientId)),
  showNotification: (message, timeout, isError) => dispatch(notificationActions.show(message, timeout, isError)),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  closeModal: modal => dispatch(closeModal(modal)),
  onNavigate: path => dispatch(push(path)),
  addNote: (patientId, data) => dispatch(actions.addNote(patientId, data)),
  getIdcConditions: pageRequest => dispatch(cohortActions.getIdcConditions(pageRequest)),
  createCondition: data => dispatch(cohortActions.createCondition(data)),
  getGeoapifyApiKey: () => dispatch(servicesActions.getGeoapifyApiKey()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditRpmPatientModal);
