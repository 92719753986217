import React from 'react';

import ReadingsWidget, { WidgetTypes } from '../../pages/Dashboards/Widgets/ReadingsWidget';
import { graphMetrics } from './CompositeGraph/Metrics';
import { renderMetric } from './CompositeGraph/Legend';

export const TooltipWidgets = React.forwardRef((props, ref) => {
  const { metrics } = props.tooltipPayload;
  const { takes } = props.tooltipPayload;
  const { metricsOverride } = props;

  return (
    <div ref={ref} className="tooltip-widget">
      {metrics?.map(({ name, y }) => {
        const metric =
          metricsOverride?.find(metric => metric.id === name) || graphMetrics.find(metric => metric.id === name);
        return (
          <ReadingsWidget
            key={name}
            title={renderMetric(metric)}
            value={`${y}${metric?.unit ? metric?.unit : ''}`}
            type={WidgetTypes.mobileTooltip}
            alerted={false}
          />
        );
      })}
      {takes?.map(({ name, y }) => {
        return (
          <ReadingsWidget
            key={`${name}_${y}`}
            title={renderMetric(metricsOverride?.find(metric => metric.id === name))}
            value={y}
            type={WidgetTypes.mobileTooltip}
            alerted={false}
          />
        );
      })}
    </div>
  );
});
