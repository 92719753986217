import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Modal } from '../containers';
import { closeModal } from '../actions/modal';
import { Input } from '../containers/Form';
import { Switch2 } from '../components/Switch2/Switch2';

export const EditCareProviderApiRouteModalName = 'e48c5842-148f-4b31-966e-0ee4c042a979';


function EditCareProviderApiRoute({ data: { route, url, enabled }, onAction, onClose }) {

  const [ newUrl, setNewUrl ] = React.useState(url)
  const [ newEnabled, setNewEnabled ] = React.useState(enabled);


  return (
    <Modal name={EditCareProviderApiRouteModalName} additionalClasses={['form-modal', 'user-invite']} caption={route}>
      <div className='care-provider-api-modal'>
        <div className='care-provider-enable-row'>
          <div>Enabled</div>
          <Switch2
            checked={newEnabled}
            name="enabled"
            onChange={(sw, state) => setNewEnabled(state)}
          />
        </div>
        <Input
          name="url"
          id="url"
          label="URL"
          type="text"
          value={newUrl}
          onChange={e => setNewUrl(e.target.value)}
          isRequired
        />

      </div>
      <div className="button-bar reverse extend-button" key="button-bar">
        <button type="button" className='brand-blue' onClick={() => onAction(newEnabled, newUrl)}>Save</button>
        <button type="button" onClick={onClose}>Close</button>

      </div>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClose: () => dispatch(closeModal(EditCareProviderApiRouteModalName)),
  onAction: (enabled, url) => {
    ownProps.data.action(ownProps.data.route, enabled, url);
    dispatch(closeModal(EditCareProviderApiRouteModalName));
  },
});

EditCareProviderApiRoute.propTypes = {
  data: PropTypes.shape({
    route: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    action: PropTypes.func.isRequired,
  }).isRequired,
  onAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(EditCareProviderApiRoute);