import { API_CALL } from "../middleware/api";

export const GET_GEOAPIFY_API_KEY = 'get-geoapify';
export const GET_GEOAPIFY_API_KEY_RESULT = `${GET_GEOAPIFY_API_KEY}/result`;
export const GET_GEOAPIFY_API_KEY_ERROR = `${GET_GEOAPIFY_API_KEY}/error`;

export const GET_GMAPS_API_KEY = 'get-gmaps';
export const GET_GMAPS_API_KEY_RESULT = `${GET_GMAPS_API_KEY}/result`;
export const GET_GMAPS_API_KEY_ERROR = `${GET_GMAPS_API_KEY}/error`;

export const GET_SPEC = 'get-spec';
export const GET_SPEC_RESULT = `${GET_SPEC}/result`;
export const GET_SPEC_ERROR = `${GET_SPEC}/error`;

export const servicesActions = {
  getGeoapifyApiKey: () => ({
    [API_CALL]: {
      endpoint: '/services/geoapify',
      method: 'GET',
    },
    type: GET_GEOAPIFY_API_KEY,
  }),

  getGoogleMapsApiKey: () => ({
    [API_CALL]: {
      endpoint: '/services/gmapsapikey',
      method: 'GET',
    },
    type: GET_GMAPS_API_KEY,
  }),

  getExternalSpec: () => ({
    [API_CALL]: {
      endpoint: '/spec/external',
      method: 'GET',
    },
    type: GET_SPEC,
  }),
};
