import {
  DefaultLayouts as PatientInfoDefaultLayouts,
  WidgetMetadata as PatientInfoWidgetMetadata,
  DASHBOARD_ID as PATIENT_INFO_DASHBOARD_ID,
} from '../SuperUser/Patients/PatientInfo/PatientInfo';
import {
  getDefaultLayouts as getDevicesDefaultLayouts,
  getWidgetsMetadata as getDevicesWidgetMetadata,
  DASHBOARD_ID as DEVICES_DASHBOARD_ID,
} from './DevicesDashboard';
import {
  DefaultLayouts as RPMDefaultLayouts,
  WidgetMetadata as RPMWidgetMetadata,
  DASHBOARD_ID as RPM_DASHBOARD_ID,
} from './DashboardRPM';
import {
  EmptyDashboadDefaultLayouts as MedicationsEmptyDefaultLayouts,
  EmptyDashboadWigetMetadata as MedicationsEmptyWigetMetadata,
  EMPTY_DASHBOARD_ID as MEDICATIONS_EMPTY_DASHBOARD_ID,
  getDefaultLayouts as getMedicationsDefaultLayouts,
  getWidgetsMetadata as getMedicationsWidgetsMetadata,
  PATIENT_MEDICATION_DASHBOARD_ID_PREFIX,
} from '../SuperUser/Patients/PatientMedications/PatientMedications';
import {
  DefaultLayouts as CohortDetailsDefaultLayouts,
  WidgetMetadata as CohortDetailsWidgetMetadata,
  DASHBOARD_ID as COHORT_DETAILS_DASHBOARD_ID,
} from '../SuperUser/Patients/Cohorts/Cohort';
import {
  DefaultLayouts as SpirometryDefaultLayouts,
  WidgetMetadata as SpirometryWidgetMetadata,
  DASHBOARD_ID as SPIROMETRY_DASHBOARD_ID,
} from '../SuperUser/DeviceManagement/HubDeviceEvents/Graph/Spirometry/SpirometryDashboard';
import {
  DefaultLayouts as AdherenceDefaultLayouts,
  WidgetMetadata as AdherenceWidgetMetadata,
  DASHBOARD_ID as ADHERENCE_DASHBOARD_ID,
} from '../Dashboard/Dashboard';
import {
  PATIENT_DASHBOARD_ID_PREFIX,
  getDefaultLayouts as getPatientDefaultLayouts,
  getWidgetsMetadata as getPatientWidgetsMetadata,
} from '../SuperUser/Patients/Dashboard/PatientDashboard';
import {
  DefaultLayouts as MedicationStatsLayouts,
  MedicationStatsWidgetsMetadata,
  DASHBOARD_ID as MEDICATION_STATS_DASHBOARD_ID,
} from '../SuperUser/Patients/Dashboard/MedicationStats';
import {
  getDefaultLayouts as getReportLayouts,
  getWidgetMetadata as getReportWidgetMetadata,
  DASHBOARD_ID as REPORT_DASHBOARD_ID,
} from '../SuperUser/Report/ReportDashboard';
import {
  DefaultLayouts as AnalyticsDefaultLayouts,
  WidgetMetadata as AnalyticsWidgetMetadata,
  DASHBOARD_ID as ANALYTICS_DASHBOARD_ID,
} from './Analytics';

const dashoards = {
  [PATIENT_INFO_DASHBOARD_ID]: {
    defaultLayouts: PatientInfoDefaultLayouts,
    widgets: PatientInfoWidgetMetadata,
  },
  [DEVICES_DASHBOARD_ID]: {
    getLayouts: getDevicesDefaultLayouts,
    getWidgetsMetadata: getDevicesWidgetMetadata,
  },
  [RPM_DASHBOARD_ID]: {
    defaultLayouts: RPMDefaultLayouts,
    widgets: RPMWidgetMetadata,
  },
  [MEDICATIONS_EMPTY_DASHBOARD_ID]: {
    defaultLayouts: MedicationsEmptyDefaultLayouts,
    widgets: MedicationsEmptyWigetMetadata,
  },
  [COHORT_DETAILS_DASHBOARD_ID]: {
    defaultLayouts: CohortDetailsDefaultLayouts,
    widgets: CohortDetailsWidgetMetadata,
  },
  [SPIROMETRY_DASHBOARD_ID]: {
    defaultLayouts: SpirometryDefaultLayouts,
    widgets: SpirometryWidgetMetadata,
  },
  [ADHERENCE_DASHBOARD_ID]: {
    defaultLayouts: AdherenceDefaultLayouts,
    widgets: AdherenceWidgetMetadata,
  },
  [MEDICATION_STATS_DASHBOARD_ID]: {
    defaultLayouts: MedicationStatsLayouts,
    widgets: MedicationStatsWidgetsMetadata,
  },
  [REPORT_DASHBOARD_ID]: {
    getLayouts: getReportLayouts,
    getWidgetsMetadata: getReportWidgetMetadata,
  },
  [PATIENT_MEDICATION_DASHBOARD_ID_PREFIX]: {
    getWidgetsMetadata: getMedicationsWidgetsMetadata,
    getLayouts: getMedicationsDefaultLayouts,
  },
  [PATIENT_DASHBOARD_ID_PREFIX]: {
    getWidgetsMetadata: getPatientWidgetsMetadata,
    getLayouts: getPatientDefaultLayouts,
  },
  [ANALYTICS_DASHBOARD_ID]: {
    defaultLayouts: AnalyticsDefaultLayouts,
    widgets: AnalyticsWidgetMetadata,
  },
};

function getDashboard(dashboardId) {
  return dashoards[dashboardId] || Object.entries(dashoards).find(([key, value]) => dashboardId.startsWith(key))?.[1];
}

function getAllWidgets(dashboardId, additionalData) {
  const dashboard = getDashboard(dashboardId);
  return dashboard.widgets || dashboard.getWidgetsMetadata(additionalData);
}

export function GetWidgetsVisibility(dashboardId, filters, additionalData, permissions) {
  const allWidgets = getAllWidgets(dashboardId, additionalData);
  const ret = [];
  allWidgets?.forEach(w => {
    const currentState = filters?.find(f => f.id === w.id);
    if (!w.permission || permissions?.includes(w.permission)) {
      ret.push({
        id: w.id,
        label: w.name,
        checked: currentState ? currentState.checked : true,
        group: w.group,
      });
    }
  });
  return ret;
}

export function GetDefaultFilters(dashboardId, additionalData) {
  const allWidgets = getAllWidgets(dashboardId, additionalData);
  return allWidgets?.map(w => {
    return {
      id: w.id,
      label: w.name,
      checked: true,
      group: w.group,
    };
  });
}

export function GetDefaultLayouts(dashboardId, additionalData, permissions) {
  const dashboard = getDashboard(dashboardId);
  return dashboard.defaultLayouts || dashboard.getLayouts(additionalData, permissions);
}

export function GetDefaultConfiguration(dashboardId, additionalData) {
  const allWidgets = getAllWidgets(dashboardId, additionalData);
  const ret = {};
  allWidgets?.forEach(w => {
    if (w.configuration) {
      ret[w.id] = {
        ...Object.entries(w.configuration).reduce(
          (acc, [key, value]) => ({ ...acc, [key]: { ...value, value: value.defaultValue } }),
          {},
        ),
      };
    }
  });
  return ret;
}

export function HandleFilterChange(dashboardId, filters, widgetId, visible, additionalData, permissions) {
  const ret = GetWidgetsVisibility(dashboardId, filters, additionalData, permissions);
  ret.find(w => w.id === widgetId).checked = visible;
  return ret;
}

export function GetLayouts(dashboardId, currentFilters, currentLayouts, additionalData, permissions) {
  const defaultLayouts = GetDefaultLayouts(dashboardId, additionalData, permissions);

  const filters = GetWidgetsVisibility(dashboardId, currentFilters, additionalData, permissions);
  const ret = { l: [], m: [], s: [] };

  function getWidget(widget, currentLayout, defaultLayout) {
    const currentWidget = currentLayout && currentLayout.find(w => w.i === widget.id);
    if (currentWidget) {
      if (currentWidget.h < currentWidget.minH) {
        currentWidget.h = currentWidget.minH;
      }
      if (currentWidget.w < currentWidget.minW) {
        currentWidget.w = currentWidget.minW;
      }
      return currentWidget;
    }
    return defaultLayout.find(wi => wi.i === widget.id);
  }

  filters
    .filter(w => w.checked === true)
    .forEach(widget => {
      ret.l.push(getWidget(widget, currentLayouts?.l, defaultLayouts.l));
      ret.m.push(getWidget(widget, currentLayouts?.m, defaultLayouts.m));
      ret.s.push(getWidget(widget, currentLayouts?.s, defaultLayouts.s));
    });
  return ret;
}

export function addWidgetToLayouts(dashboardId, layouts, widgetId, additionalData) {
  const allWidgets = getAllWidgets(dashboardId, additionalData);
  const widgetToAdd = allWidgets.find(w => w.id === widgetId);
  const ret = { ...layouts };
  if (widgetToAdd !== undefined && layouts.l.find(l => l.i === widgetToAdd.id) === undefined) {
    ret.l.push({ x: 0, y: 100, ...widgetToAdd.defProps });
    ret.m.push({ x: 0, y: 100, ...widgetToAdd.defProps });
    ret.s.push({ x: 0, y: 100, ...widgetToAdd.defProps });
  }
  return ret;
}
