import React from 'react';
import PropTypes from 'prop-types';
import AsyncReactSelect from 'react-select/async';

import './style.scss';

const AsyncSelect = React.forwardRef((props, ref) => {
  const {
    label,
    id,
    className,
    menuPositionFixed,
    clearValueAfterSelection,
    getLabel,
    value,
    ...otherProps
  } = props;

  return (
    <>
      <label htmlFor={id} className="visuallyhidden">
        {label}
      </label>
      <AsyncReactSelect
        id={id}
        ref={ref}
        className={`${className || ''} react-select-container`}
        classNamePrefix="react-select"
        onChange={props.onChange}
        unstyled
        menuPosition={menuPositionFixed ? 'fixed' : 'absolute'}
        getOptionLabel={getLabel}
        value={value ? value : clearValueAfterSelection ? '' : undefined}
        isDisabled={props.disabled}
        {...otherProps}
      />
    </>
  );
});

export default AsyncSelect;

AsyncSelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  menuPositionFixed: PropTypes.bool,
  loadOptions: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  cacheOptions: PropTypes.bool,
  defaultOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any,
    }),
  ),
  clearValueAfterSelection: PropTypes.bool,
};

AsyncSelect.defaultProps = { menuPositionFixed: true, cacheOptions: true, clearValueAfterSelection: false };
