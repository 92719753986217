import { API_CALL } from '../../../../../middleware/api';
import { WIDGET_GET_PENDING_CALLS_MESSAGES } from './constants';

export const actions = {
  getPendingCallsMessages: orgId => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/rpm/dashboard/pending-calls-and-message-counters',
      params: { orgId },
    },
    type: WIDGET_GET_PENDING_CALLS_MESSAGES,
  }),
};
