import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import moment from 'moment';
import _ from 'lodash';
import { isBrowser, isMobile } from 'react-device-detect';

import PatientChart from './PatientChart';
import PatientLog from './PatientLog';
import LimitsSection from './LimitsSection';
import DashboardLayout from '../../../Dashboards/DashboardLayout';
import PatientLastReading from './PatientLastReading';
import { actions as patientActions } from '../redux/actions';
import { GET_AVERAGE_READINGS_RESULT, GET_MEDICATIONS_RESULT, PATIENT_DASHBOARD_INFO_RESULT } from '../redux/constants';
import { BLOOD_PRESSURE } from '../../DeviceManagement/constant';
import { DevicesAndMedsWidget, DocumentsWidget, EnrollmentInfoWidget, getProgramWidgetDataLength, PatientInfoWidget } from './PatientInfo';
import Strings from '../../../../Strings';
import { PERMISSIONS, hasPermission } from '../../../../utils/userPermissions';
import ReadingsWidget, { WidgetTypes } from '../../../Dashboards/Widgets/ReadingsWidget';
import LoadingRenderer from '../../../../components/LoadingRenderer';
import CompositeGraph from '../../../../components/Graphs/CompositeGraph/CompositeGraph';
import { ReadingsEnum } from '../../../../utils';
import { PatientDashboardAction } from '../../../../actions/patient-dashboard';
import { DATE_FORMAT_MONTH_NAME, TIME_FORMAT_12_LOWERCASE } from '../../../../constants';
import { roundToDecimal } from '../../../../utils/unitConverters';
import { getProgramsFromPatient } from '../../../../utils/cmsPrograms';
import { graphTypeEnum } from '../../../../components/Graphs/CompositeGraph/GraphTypes';
import { DeviceTypes } from '../../../../utils/deviceTypes';

//#region Widget Metadata
const infoMetadata = {
  id: 'info',
  name: 'General Information',
  permission: PERMISSIONS.DASHBOARD_RPM_PATIENT_PATIENT_INFO,
  defProps: { i: 'info', w: 1, h: 1, minW: 1, minH: 1 },
};
const medsAndDevicesMetadata = {
  id: 'medsAndDevices',
  name: 'Meds and Divices Count',
  permission: PERMISSIONS.DASHBOARD_RPM_PATIENT_DEVICES_MEDS_COUNT,
  defProps: { i: 'medsAndDevices', w: 1, h: 1, minW: 1, minH: 1 },
};
const documentsMetadata = {
  id: 'documents',
  name: 'Documents Count',
  permission: PERMISSIONS.DASHBOARD_RPM_PATIENT_DOCUMENTS,
  defProps: { i: 'documents', w: 1, h: 1, minW: 1, minH: 1 },
};
const enrollmentInfoMetadata = {
  id: 'enrollment',
  name: 'Enrollment Info',
  permission: PERMISSIONS.DASHBOARD_RPM_PATIENT_PATIENT_INFO,
  defProps: { i: 'enrollment', w: 1, h: 1, minW: 1, minH: 1 },
};
const plotMetadata = {
  id: 'plot',
  name: 'Selected Reading Chart',
  permission: PERMISSIONS.DASHBOARD_RPM_PATIENT_READINGS,
  defProps: { i: 'plot', w: 5, h: 6, minW: 1, minH: 4 },
};
const patientLogMetadata = {
  id: 'patientLog',
  name: 'Patient Log',
  permission: PERMISSIONS.PATIENTS_RPM_PATIENT_LOG,
  defProps: { i: 'patientLog', w: 1, h: 1, minW: 1, minH: 4 },
};
const limitsMetadata = {
  id: 'limits',
  name: 'Limits',
  permission: PERMISSIONS.DASHBOARD_RPM_PATIENT_ALERT_TRESHOLDS,
  defProps: { i: 'limits', w: 1, h: 1, minW: 1, minH: 1 },
};
const overlayPlotMetadata = {
  id: 'overlayPlot',
  name: 'Overlay Plot',
  permission: PERMISSIONS.PATIENTS_RPM_PATIENT_OVERLAY_PLOTS,
  defProps: { i: 'overlayPlot', w: 5, h: 6, minW: 1, minH: 4 },
};

const staticWidgetMetadata = [
  infoMetadata,
  medsAndDevicesMetadata,
  documentsMetadata,
  plotMetadata,
  patientLogMetadata,
  limitsMetadata,
  overlayPlotMetadata,
];

function getWidgetMetadataForMed(medication) {
  return {
    id: `MedicationWidget_${medication.id}`,
    name: medication.medicationName,
    permission: PERMISSIONS.DASHBOARD_RPM_PATIENT_MEDICATIONS,
    group: Strings.medications,
    defProps: {
      i: `MedicationWidget_${medication.id}`,
      w: 1,
      h: 1,
      minW: 1,
      minH: 1,
    },
  };
}

function getWidgetMetadataForProgram(program) {
  return {
    id: `DaysComplied_${program.subprogram}`,
    name: Strings.widgets.daysComplied,
    permission: PERMISSIONS.DASHBOARD_RPM_PATIENT_BILLABLE_TIME,
    defProps: {
      i: `DaysComplied_${program.subprogram}`,
      w: 1,
      h: 1,
      minW: 1,
      minH: 1,
    },
  };
}

export function getWidgetsMetadata(args) {
  if (!args) {
    return [];
  }
  const { meds, readingsWidgetMetadata, patientDetails } = args;
  const rtmActivePrograms = getProgramsFromPatient(patientDetails)?.filter(p => p.name === 'RTM');
  const allPrograms = getProgramsFromPatient(patientDetails, true);
  const ret = staticWidgetMetadata
    .concat(meds?.length > 0 ? meds.map(medication => getWidgetMetadataForMed(medication)) : [])
    .concat(readingsWidgetMetadata)
    .concat(rtmActivePrograms?.length > 0 ? rtmActivePrograms.map(program => getWidgetMetadataForProgram(program)) : [])
    .concat(allPrograms?.length > 0 ? [enrollmentInfoMetadata] : []);
  return ret;
}

const L_WIDGET_COUNT = 5;
const M_WIDGET_COUNT = 3;
const S_WIDGET_COUNT = 1;

export const staticWidgetLayout = {
  l: [
    { ...infoMetadata.defProps, x: 0, y: 0, w: 2, h: 1 },
    { ...medsAndDevicesMetadata.defProps, x: 2, y: 0, w: 2, h: 1 },
    { ...documentsMetadata.defProps, x: 4, y: 0, w: 1, h: 1 },
    { ...limitsMetadata.defProps, x: 0, y: 10, w: 5, h: 1 },
    { ...plotMetadata.defProps, x: 0, y: 11, w: 5, h: 6 },
    { ...patientLogMetadata.defProps, x: 0, y: 17, w: 5, h: 4 },
    { ...overlayPlotMetadata.defProps, x: 0, y: 15, w: 5, h: 6 },
  ],
  m: [
    { ...infoMetadata.defProps, x: 0, y: 0, w: 3, h: 1 },
    { ...medsAndDevicesMetadata.defProps, x: 0, y: 1, w: 2, h: 1 },
    { ...documentsMetadata.defProps, x: 2, y: 1, w: 1, h: 1 },
    { ...limitsMetadata.defProps, x: 0, y: 15, w: 3, h: 1 },
    { ...plotMetadata.defProps, x: 0, y: 16, w: 3, h: 8 },
    { ...patientLogMetadata.defProps, x: 0, y: 20, w: 3, h: 4 },
    { ...overlayPlotMetadata.defProps, x: 0, y: 17, w: 3, h: 6 },
  ],
  s: [
    { ...infoMetadata.defProps, x: 0, y: 0, w: 2, h: 1 },
    { ...medsAndDevicesMetadata.defProps, x: 0, y: 1, w: 1, h: 1 },
    { ...documentsMetadata.defProps, x: 0, y: 2, w: 1, h: 1 },
    { ...limitsMetadata.defProps, x: 0, y: 30, w: 1, h: 1 },
    { ...plotMetadata.defProps, x: 0, y: 31, w: 1, h: 4 },
    { ...patientLogMetadata.defProps, x: 0, y: 35, w: 1, h: 4 },
    { ...overlayPlotMetadata.defProps, x: 0, y: 34, w: 1, h: 6 },
  ],
};

export function getDefaultLayouts(args, permissions) {
  const widgets = getWidgetsMetadata(args);
  const l = [...staticWidgetLayout.l];
  const m = [...staticWidgetLayout.m];
  const s = [...staticWidgetLayout.s];

  const programWidget = widgets.find(w => w.id === enrollmentInfoMetadata.id);
  const programWidgetDataLength = args?.patientDetails?.patientEnrollment?.enrollments
    ? getProgramWidgetDataLength(Object.values(args?.patientDetails?.patientEnrollment.enrollments)[0])
    : 0;
  if (programWidget && permissions?.includes(programWidget.permission)) {
    l.push({ ...programWidget.defProps, x: 0, y: 1, w: programWidgetDataLength, h: 1 });
    m.push({ ...programWidget.defProps, x: 0, y: 1, w: programWidgetDataLength, h: 1 });
    s.push({ ...programWidget.defProps, x: 0, y: 1, w: programWidgetDataLength, h: 1 });
  }
  
  widgets
    .filter(w => !staticWidgetMetadata.some(sw => sw.id === w.id) && w.id !== enrollmentInfoMetadata.id && w.id !== programWidget.id)
    .filter(w => permissions?.includes(w.permission))
    .forEach((w, i) => {
      l.push({ ...w.defProps, x: (i + programWidgetDataLength) % L_WIDGET_COUNT, y: Math.floor((i + programWidgetDataLength) / L_WIDGET_COUNT) + 1, w: 1, h: 1 });
      m.push({ ...w.defProps, x: (i + programWidgetDataLength) % M_WIDGET_COUNT, y: Math.floor((i + programWidgetDataLength) / M_WIDGET_COUNT) + 3, w: 1, h: 1 });
      s.push({ ...w.defProps, x: (i + programWidgetDataLength) % S_WIDGET_COUNT, y: Math.floor((i + programWidgetDataLength) / S_WIDGET_COUNT) + 5, w: 1, h: 1 });
    });
  return { l, m, s };
}
//#endregion

const chartTypeDeviceMap = {
  [DeviceTypes.Spirometer.id]: [ReadingsEnum.spirometer],
  [DeviceTypes['Blood Pressure Monitor'].id]: [ReadingsEnum.bloodPressure, ReadingsEnum.bloodPressure7avg],
  [DeviceTypes.Glucometer.id]: [ReadingsEnum.glucose],
  [DeviceTypes.Thermometer.id]: [ReadingsEnum.temperature],
  [DeviceTypes['Weighing Machine'].id]: [ReadingsEnum.weight],
  [DeviceTypes['Pulse Oximeter'].id]: [ReadingsEnum.heartRate, ReadingsEnum.pulseox],
  [DeviceTypes['Wrist Band'].id]: [
    ReadingsEnum.steps,
    ReadingsEnum.sleep,
    ReadingsEnum.activity,
    ReadingsEnum.heartRate,
  ],
  [DeviceTypes['Air Quality Monitor'].id]: [ReadingsEnum.pm25],
  [DeviceTypes['PT/INR Monitor'].id]: [ReadingsEnum.coagulation],
};

export function getReadingsWidgetMetadata(patientDevices) {
  const ret = [];
  if (patientDevices?.length > 0) {
    Object.values(ReadingsEnum).forEach(c => {
      const deviceTypes = Object.entries(chartTypeDeviceMap)
        .filter(([deviceType, readings]) => readings.includes(c))
        .map(([deviceType, readings]) => deviceType);
      if (c === ReadingsEnum.billableTime || deviceTypes?.some(deviceType => patientDevices.includes(deviceType))) {
        ret.push({
          id: `lastReadings_${c}`,
          name: Strings[c],
          permission:
            c === ReadingsEnum.billableTime
              ? PERMISSIONS.DASHBOARD_RPM_PATIENT_BILLABLE_TIME
              : PERMISSIONS.DASHBOARD_RPM_PATIENT_READINGS,
          group: Strings.latestReadings,
          defProps: { i: `lastReadings_${c}`, w: 1, h: 1, minW: 1, minH: 1 },
        });
      }
    });
  }
  return ret;
}

export const PATIENT_DASHBOARD_ID_PREFIX = 'dashboardPatient2_';

function PatientDashboard(props) {
  const { refreshTimestamp } = props;
  const patientId = props.patientId || props.userId;
  const DASHBOARD_ID = `${PATIENT_DASHBOARD_ID_PREFIX}${patientId}`;
  const ROW_HEIGHT = 83;

  const [selectedReading, setSelectedReading] = useState('');
  const [selectedMed, setSelectedMed] = useState('');
  const [expandedWidgets, setExpandedWidgets] = useState({});
  const [lastReadings, setLastReadings] = useReducer((state, newState) => ({ ...state, ...newState }), {
    loaded: false,
    data: null,
  });
  const [lastReadingsAvg, setLastReadingsAvg] = useReducer((state, newState) => ({ ...state, ...newState }), {
    loaded: false,
    data: null,
  });
  const [patientInfo, setPatientInfo] = useState();
  const [medications, setMedications] = useReducer((state, newState) => ({ ...state, ...newState }), {
    loaded: false,
    data: [],
  });
  const [adherenceAlerts, setAdherenceAlerts] = useState([]);
  const [adherence, setAdherence] = useState([]);
  const [plotResizeTimestamp, setPlotResizeTimestamp] = useState(moment());
  const [patientDevices, setPatientDevices] = useReducer((state, newState) => ({ ...state, ...newState }), {
    loaded: false,
    data: [],
  });
  const [reminders, setReminders] = useState([]);
  const [unbilledEvidences, setUnbilledEvidences] = useState();
  const scrollbarRef = React.useRef();

  useEffect(() => {
    if (!patientId) {
      return;
    }

    getPatientInfo();
    getDevices();
    getMeds();
    getLastReadingsAndAlerts();
    getAverageReadings();

    !props.patientVersion &&
      props.getUnbilledEvidences(patientId).then(resp => {
        setUnbilledEvidences(resp.response);
      });
  }, [patientId, props.refreshTimestamp]);

  useEffect(() => {
    if (!medications.loaded || !patientDevices.loaded) {
      return;
    }

    // Select first active medication
    const medToSelect = medications.data.find(m => !m.format?.end_dt || m.format.end_dt > moment().unix());
    if (medToSelect) {
      setSelectedMed(medToSelect.id);
      setSelectedReading('');
      return;
    }
    // ...or first device reading
    if (patientDevices.data?.length > 0 && !medToSelect) {
      const devices = patientDevices.data.filter(dv => Object.keys(chartTypeDeviceMap).includes(dv));
      if (devices?.length > 0) {
        setSelectedReading(chartTypeDeviceMap[devices[0]][0]);
        setSelectedMed('');
        return;
      }
    }
    // ...or any ended medication
    if (medications.data.length > 0) {
      setSelectedMed(medications.data[0].id);
      setSelectedReading('');
    }
  }, [medications, patientDevices]);

  const getPatientInfo = () => {
    props.getPatientInfo(patientId).then(resp => {
      setPatientInfo(resp.response);
    });
  };

  const getDevices = () => {
    setPatientDevices({ loaded: false, data: [] });
    const maxExpectedDevicesCountForPatient = 100;
    const pageRequest = {
      offset: 0,
      search: '',
      limit: maxExpectedDevicesCountForPatient,
    };
    props.getDevices(pageRequest, patientId).then(response => {
      setPatientDevices({ loaded: true, data: response.response?.data?.map(d => d.deviceType) || [] });
    });
  };

  const getLastReadingsAndAlerts = () => {
    setLastReadings({ loaded: false, data: null });
    props.getPatientLastReadings(patientId).then(resp => {
      let lastReadings = {};
      if (resp.type === PATIENT_DASHBOARD_INFO_RESULT) {
        lastReadings = resp.response;
        setAdherenceAlerts(resp.response.alerts.schedule_missed_doses_alerts);
      }
      setLastReadings({ loaded: true, data: lastReadings });
    });
  };

  const getAverageReadings = () => {
    setLastReadingsAvg({ loaded: false, data: null });
    props.getAverageReadings(patientId, BLOOD_PRESSURE, { limit: 7 }).then(resp => {
      let data = {};
      if (resp.type === GET_AVERAGE_READINGS_RESULT) {
        data = { blood_pressure: resp.response };
      }
      setLastReadingsAvg({ loaded: true, data });
    });
  };

  const getMeds = () => {
    setMedications({ loaded: false, data: [] });
    props.getMedications(patientId).then(r => {
      let meds = [];
      if (r.type === GET_MEDICATIONS_RESULT) {
        meds = r.response.medications;
      }
      setMedications({ loaded: true, data: meds });
    });

    props.loadDashboard(patientId).then(r => {
      setReminders(r.response?.reminders);
    });
    props.getPatientAdherence(patientId).then(r => {
      setAdherence(r.response);
    });
  };

  const onAlertCleared = () => {
    getLastReadingsAndAlerts();
    getMeds();
  };

  const readingsWidgetMetadata = getReadingsWidgetMetadata(patientDevices.data);

  const onSelectReading = (reading, medId) => {
    if (reading === ReadingsEnum.billableTime) {
      return;
    }

    if (isBrowser) {
      setSelectedReading(reading);
      setSelectedMed(medId);
    }
    if (isMobile && props.patientVersion) {
      const searchString = reading ? `?reading=${reading}` : `?medId=${medId}`;
      props.onNavigate('/health-center/details', null, searchString);
    }
    if (isMobile && !props.patientVersion) {
      const widget = reading || medId;
      setExpandedWidgets(w => {
        return {
          ...w,
          [widget]: !Boolean(w[widget]),
        };
      });
    }
  };

  const getValueForMedWidget = med => {
    if (med.scheduleType === 'as_needed') {
      return Strings.na;
    }

    if (!props.patientVersion) {
      const adherenceForMed = [];
      Object.values(adherence).forEach(meds => {
        const medAdherence = meds.find(m => m.scheduleId === med.id.toString())?.stats?.adherence;
        if (!isNaN(medAdherence)) {
          adherenceForMed.push(medAdherence);
        }
      });
      const weeklyAdherence = adherenceForMed.length > 0 ? `${roundToDecimal(_.mean(adherenceForMed), 0)}%` : '-';
      return isNaN(med.analytics?.adherence) ? '-' : `${med.analytics.adherence}% | ${weeklyAdherence}`;
    }

    const scheduleEnded = med.format?.end_dt && med.format.end_dt < moment().unix();
    if (scheduleEnded) {
      return Strings.scheduleEnded;
    }

    const nextReminder = reminders?.find(r => r.id === med.id);
    return !nextReminder?.reminder_at
      ? `${Strings.next} -`
      : `${Strings.next} ${moment(nextReminder?.reminder_at).format(TIME_FORMAT_12_LOWERCASE)}`;
  };

  const patientInfoWidget = {
    ...infoMetadata,
    render: <PatientInfoWidget data={patientInfo} patientId={patientId} />,
  };
  const medsAndDevicesWidget = {
    ...medsAndDevicesMetadata,
    render: <DevicesAndMedsWidget data={patientInfo} patientId={patientId} />,
  };
  const documentsWidget = {
    ...documentsMetadata,
    render: <DocumentsWidget data={patientInfo} patientId={patientId} />,
  };
  const enrollmentWidget = {
    ...enrollmentInfoMetadata,
    render: props.patientDetails?.patientEnrollment?.enrollments
    ? <EnrollmentInfoWidget enrollment={Object.values(props.patientDetails?.patientEnrollment?.enrollments)[0]} />
    : <> </>,
  };

  const plotWidget = {
    ...plotMetadata,
    noPadding: true,
    noBorder: true,
    render: (
      <PatientChart
        patientId={patientId}
        selectedChart={selectedReading}
        selectedMed={selectedMed}
        plotResizeTimestamp={plotResizeTimestamp}
        patientVersion={props.patientVersion}
      />
    ),
  };
  const patientLogWidget = {
    ...patientLogMetadata,
    render: <PatientLog patientId={patientId} refreshTimestamp={refreshTimestamp} />,
    noPadding: true,
  };
  const limitsWidget = {
    ...limitsMetadata,
    render: (
      <LimitsSection
        selectedChart={selectedReading}
        selectedMed={selectedMed}
        medInfo={medications.data?.find(m => m.id === selectedMed)}
        patientId={patientId}
        onAlertCleared={onAlertCleared}
      />
    ),
  };
  const overlayPlotWidget = {
    ...overlayPlotMetadata,
    noPadding: true,
    noBorder: true,
    render: (
      <div className="dashboard-chart">
        <div className="boxStatsWithGraph">
          <div className="boxStatsWithGraph-graph noStats no-header">
            <CompositeGraph
              patientId={patientId}
              graphType={graphTypeEnum.composite}
              plotResizeTimestamp={plotResizeTimestamp}
            />
          </div>
        </div>
      </div>
    ),
  };

  const rtmPrograms = getProgramsFromPatient(props.patientDetails)?.filter(p => p.name === 'RTM');
  const allPrograms = getProgramsFromPatient(props.patientDetails, false);
  const widgets = [
    patientInfoWidget,
    medsAndDevicesWidget,
    documentsWidget,
    plotWidget,
    patientLogWidget,
    limitsWidget,
    overlayPlotWidget,
  ]
    .concat(
      readingsWidgetMetadata.map(w => {
        const reading = w.id.replace('lastReadings_', '');
        return {
          ...w,
          render: (
            <PatientLastReading
              reading={reading}
              selected={isBrowser && selectedReading === reading}
              onSelect={
                reading !== ReadingsEnum.billableTime && reading !== ReadingsEnum.bloodPressure7avg
                  ? () => onSelectReading(reading)
                  : undefined
              }
              data={reading === ReadingsEnum.bloodPressure7avg ? lastReadingsAvg.data : lastReadings.data}
              type={props.patientVersion ? WidgetTypes.colorfullWithIcon : WidgetTypes.withIcon}
              isCollapsible={
                isMobile && reading !== ReadingsEnum.billableTime && reading !== ReadingsEnum.bloodPressure7avg
                  ? true
                  : false
              }
              expanded={expandedWidgets[reading]}
            />
          ),
          noPadding: true,
          noBorder: true,
        };
      }),
    )
    .concat(
      medications.data
        ? medications.data
            .sort(m => m.rootId)
            .map(medication => {
              const widgetMetadata = getWidgetMetadataForMed(medication);
              const widget = {
                ...widgetMetadata,
                render: (
                  <ReadingsWidget
                    title={medication.medicationName}
                    value={getValueForMedWidget(medication)}
                    selected={isBrowser && selectedMed === medication.id}
                    alerted={adherenceAlerts?.some(a => a.schedule_id === medication.id)}
                    onClick={() => onSelectReading(null, medication.id)}
                    icon="medication"
                    type={props.patientVersion ? WidgetTypes.colorfullWithIcon : WidgetTypes.withIcon}
                    valueTooltip={
                      props.patientVersion
                        ? undefined
                        : getValueForMedWidget(medication) === Strings.na
                        ? undefined
                        : Strings.weeklyTotalAdherence
                    }
                    isCollapsible={isMobile}
                    expanded={expandedWidgets[medication.id]}
                  />
                ),
                noPadding: true,
                noBorder: true,
              };
              return widget;
            })
        : [],
    )
    .concat(
      rtmPrograms
        ? rtmPrograms.map(program => {
            const widgetMetadata = getWidgetMetadataForProgram(program);
            const data = unbilledEvidences?.unbilledRTMMonitoringActivities?.find(
              a => a.enrollmentId === program.enrollmentId,
            );
            const date = data?.lastClaimDtOr30DaysAgo
              ? moment(data.lastClaimDtOr30DaysAgo).format(DATE_FORMAT_MONTH_NAME)
              : '?';
            const widget = {
              ...widgetMetadata,
              render: (
                <ReadingsWidget
                  title={Strings.widgets.daysComplied}
                  value={isNaN(data?.activityCount) ? '-' : data?.activityCount}
                  icon="billable_time"
                  type={props.patientVersion ? WidgetTypes.colorfullWithIcon : WidgetTypes.withIcon}
                  valueTooltip={Strings.formatString(
                    Strings.widgetsTooltips.numberOfDaysComplied,
                    date,
                    data?.category,
                  )}
                />
              ),
              noPadding: true,
              noBorder: true,
            };
            return widget;
          })
        : [],
    )
    .concat(allPrograms?.length > 0 ? [enrollmentWidget] : []);

  const getMobileWidgets = () => {
    const ret = [];
    widgets
      .filter(w => w.id !== 'plot')
      .forEach(w => {
        if (w.permission && !hasPermission(w.permission)) {
          return;
        }
        ret.push(w);
        if (w.id.startsWith('lastReadings_') && expandedWidgets[w.id.replace('lastReadings_', '')]) {
          ret.push({
            ...plotWidget,
            id: `${plotWidget.id}_${w.id}`,
            render: (
              <PatientChart
                patientId={patientId}
                selectedChart={w.id.replace('lastReadings_', '')}
                plotResizeTimestamp={plotResizeTimestamp}
                patientVersion={props.patientVersion}
              />
            ),
          });
        }
        if (w.id.startsWith('MedicationWidget_') && expandedWidgets[w.id.replace('MedicationWidget_', '')]) {
          ret.push({
            ...plotWidget,
            id: `${plotWidget.id}_${w.id}`,
            defProps: { ...plotWidget.defProps, h: 5 },
            render: (
              <PatientChart
                patientId={patientId}
                selectedMed={parseInt(w.id.replace('MedicationWidget_', ''), 10)}
                plotResizeTimestamp={plotResizeTimestamp}
                patientVersion={props.patientVersion}
              />
            ),
          });
        }
      });

    const dashboardHasData = ret.length > 1 || ret.some(w => w.id !== 'plot');
    return (
      <React.Fragment>
        <h2>{dashboardHasData ? Strings.latestReadings : Strings.noDataAvailable}</h2>
        {ret.map(w => (
          <div
            key={w.id}
            className={`widgetContainer ${w.noPadding ? 'widgetContainerNoPadding' : ''} ${
              w.noBorder ? 'widgetContainerNoBorder' : ''
            } ${w.defProps.i} ${
              w.defProps.i === 'plot' || w.defProps.i === 'overlayPlot' ? 'widgetContainerExtendMargins' : ''
            }`}
            style={{ height: w.defProps.h * ROW_HEIGHT }}
          >
            {w.render}
          </div>
        ))}
      </React.Fragment>
    );
  };

  const getBrowserDashboard = () => {
    const effectiveWidgets = widgets.filter(w => w.permission && hasPermission(w.permission));
    const dashboardHasData = effectiveWidgets.length > 1 || effectiveWidgets.some(w => w.id !== 'plot');
    return dashboardHasData ? (
      <DashboardLayout
        dashboardId={DASHBOARD_ID}
        widgets={widgets.filter(w => w)}
        defaultLayouts={getDefaultLayouts(
          {
            meds: medications.data,
            readingsWidgetMetadata,
            patientDetails: props.patientDetails,
          },
          props.permissions,
        )}
        rowHeight={ROW_HEIGHT}
        breakpoints={{ l: 1150, m: 400, s: 0 }}
        cols={{ l: L_WIDGET_COUNT, m: M_WIDGET_COUNT, s: S_WIDGET_COUNT }}
        additionalData={{ meds: medications.data, readingsWidgetMetadata, patientDetails: props.patientDetails }}
        onResize={onResize}
        isReadOnly={isMobile}
        scrollbarRef={scrollbarRef}
      />
    ) : (
      <h2>{Strings.noDataAvailable}</h2>
    );
  };

  const onResize = _.debounce((layouts, oldItem, newItem) => {
    if (newItem.i === 'plot' || newItem.i === 'overlayPlot') {
      setPlotResizeTimestamp(moment());
    }
  }, 30);

  const areWidgetsReady = lastReadings.loaded && patientDevices.loaded && medications.loaded && lastReadingsAvg.loaded && props.patientDetails;

  return (
    <LoadingRenderer loading={!areWidgetsReady}>
      {areWidgetsReady ? (
        isMobile ? (
          <div className="patient-dashboard-mobile">{getMobileWidgets()}</div>
        ) : (
          <OverlayScrollbarsComponent
            defer
            className="patient-info-dashboard scrollbar-right-margin"
            options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100' }, paddingAbsolute: true }}
            ref={scrollbarRef}
          >
            {getBrowserDashboard()}
          </OverlayScrollbarsComponent>
        )
      ) : (
        <React.Fragment />
      )}
    </LoadingRenderer>
  );
}

PatientDashboard.propTypes = {
  patientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  getPatientLastReadings: PropTypes.func,
  getMedications: PropTypes.func,
  getDevices: PropTypes.func,
  getAverageReadings: PropTypes.func,
  loadDashboard: PropTypes.func,
  refreshTimestamp: PropTypes.any,
  onNavigate: PropTypes.func,
  getPatientAdherence: PropTypes.func,
  getUnbilledEvidences: PropTypes.func,
  patientDetails: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    userId: state.auth.profile?.id,
    permissions: state.auth?.permissions,
  };
};

const mapDispatchToProps = dispatch => ({
  getPatientLastReadings: patientId => dispatch(patientActions.getPatientLastReadings(patientId)),
  getMedications: patientId => dispatch(patientActions.getMedications(patientId)),
  getPatientInfo: patientId => dispatch(patientActions.getPatientDahsboardInfo(patientId)),
  getAverageReadings: (patientId, dataType, request) =>
    dispatch(patientActions.getAverageReadings(patientId, dataType, request)),
  getDevices: (request, patientId) => dispatch(patientActions.getPatientDevices(request, patientId)),
  loadDashboard: id => dispatch(PatientDashboardAction.getDashboard(id)),
  onNavigate: (path, params, search) => {
    dispatch(
      push({
        pathname: path,
        state: { params },
        search,
      }),
    );
  },
  getPatientAdherence: patientId => dispatch(patientActions.getPatientAdherence(patientId)),
  getUnbilledEvidences: patientId => dispatch(patientActions.getUnbilledEvidences(patientId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientDashboard);
