/* eslint-disable no-useless-constructor */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actionsAdvancedFilters } from '../actions';
import { VALUE_FOR_NO_FILTER } from '../FiltersDefinition';

function Checkbox(props) {
  const { group, filters, value, text, children, definition, updateFilterState } = props;

  const getMyStateFromProps = () => {
    if (definition.type === 'radio' && value === VALUE_FOR_NO_FILTER && !filters.some(f => f.group === group && f.state)) {
      return true;
    }

    let myState;
    filters.forEach(e => {
      if (e.group === group && e.value === value) myState = e.state;
    });

    return myState;
  };

  useEffect(() => {
    // if state is not defined in filters from props, lets publish as cleared
    if (getMyStateFromProps() === undefined) {
      updateFilterState({
        group,
        value,
        state: false,
      });
    }
  }, [group, value, filters]);

  const onClick = () => {
    const checked = getMyStateFromProps();

    const event = {
      group,
      value,
      state: definition.type ? true : !checked,
    };
    updateFilterState(event, definition.type);
  };

  const checked = getMyStateFromProps();

  return (
    <div className="list-item" onClick={onClick}>
      <div className="label-selector">
        {children ? <div className="label-list-item">{children}</div> : null}
        {text.length !== 0 ? <div className="label-list-item">{text}</div> : null}
        <input type={definition.type || "checkbox"} checked={checked} />
      </div>
    </div>
  );
}

Checkbox.propTypes = {
  group: PropTypes.string.isRequired,
  updateFilterState: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  filters: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  children: PropTypes.any,
  definition: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    filters: state.entities.advancedFilters.filters.items,
  };
};

const mapDispatchToProps = dispatch => ({
  updateFilterState: (data, filterType) => dispatch(actionsAdvancedFilters.updateFilterState(data, filterType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkbox);
