import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { openModalAction } from '../../../../actions/modal';
import TableWithLocalPagination from '../../../../containers/Table/TableWithLocalPagination';
import { Column } from '../../../../containers/Table/TableWithPagination';
import Strings from '../../../../Strings';
import { actions } from '../../redux/actions';

function GroupMaintainers(props) {
  const { groupDetails, onRefresh, openConfirmModal } = props;

  const removeMaintainerFromGrp = (_, maintener) => {
    const data = {
      title: Strings.deleteMaintainerFromGroupWarningarning(maintener.name, groupDetails.name),
      onConfirmAction: actions.editOrganizationGroup(
        groupDetails.id,
        {
          ...groupDetails,
          maintainers: groupDetails.maintainers.map(o => o.id).filter(o => o !== maintener.id),
          organizations: groupDetails.organizations.map(o => o.id),
        },
      ),
      onSuccessNotification: Strings.formatString(Strings.itemSaved, groupDetails.name),
      confirmPostAction: onRefresh,
    };
    openConfirmModal(data);
  };

  return (
    <TableWithLocalPagination
      name="maintainers"
      data={groupDetails?.maintainers || []}
      buttons={(groupDetails?.role === 'owner' || groupDetails?.role === 'maintainer') ?
        [{ icon: 'delete', onClick: removeMaintainerFromGrp, text: Strings.delete }]
      : undefined}
      infiniteScroll
      columns={[
        <Column key="id" title={Strings.id} value={d => d.id} />,
        <Column key="name" title={Strings.name} value={d => d.name} />,
        <Column key="org" title={Strings.organization} value={d => d.org_name} />,
        ]}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  editOrganizationGroup: data => dispatch(openModalAction('edit-organization-group', data)),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
});

GroupMaintainers.propTypes = {
  groupDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    maintainers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    organizations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  onRefresh: PropTypes.func.isRequired,
  openConfirmModal: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(GroupMaintainers);
