import { API_CALL } from '../middleware/api';

export const LIST_USERS = 'list-users';
export const LIST_USERS_RESULT = `${LIST_USERS}/result`;
export const LIST_USERS_ERROR = `${LIST_USERS}/error`;

export const INVITE_USER = 'invite-user';
export const INVITE_USER_RESULT = `${INVITE_USER}/result`;
export const INVITE_USER_ERROR = `${INVITE_USER}/error`;

export const RESEND_INVITE = 'resend-invite';
export const RESEND_INVITE_RESULT = `${RESEND_INVITE}/result`;
export const RESEND_INVITE_ERROR = `${RESEND_INVITE}/error`;

export const EDIT_USER = 'edit-user';
export const EDIT_USER_RESULT = `${EDIT_USER}/result`;
export const EDIT_USER_ERROR = `${EDIT_USER}/error`;

export const DELETE_USER = 'delete-user';
export const DELETE_USER_RESULT = `${DELETE_USER}/result`;
export const DELETE_USER_ERROR = `${DELETE_USER}/error`;

export const RESET_USER_PASSWORD = 'reset-user-password';
export const RESET_USER_PASSWORD_RESULT = `${RESET_USER_PASSWORD}/result`;
export const RESET_USER_PASSWORD_ERROR = `${RESET_USER_PASSWORD}/error`;

export const userActions = {
  list: params => ({
    [API_CALL]: {
      endpoint: '/v1/organizations/users/',
      method: 'get',
      params,
    },
    type: LIST_USERS,
  }),
  invite: data => ({
    [API_CALL]: {
      endpoint: '/v1/organizations/users/invite/',
      method: 'post',
      data,
    },
    type: INVITE_USER,
  }),
  edit: data => ({
    [API_CALL]: {
      endpoint: '/v1/organizations/users/',
      method: 'put',
      data,
    },
    type: EDIT_USER,
  }),
  delete: user_uuid => ({
    [API_CALL]: {
      endpoint: `/v1/organizations/users/?user_uuid=${user_uuid}`,
      method: 'delete',
    },
    type: DELETE_USER,
  }),
  resetPassword: data => ({
    [API_CALL]: {
      endpoint: `/accounts/user/password/change/`,
      method: 'POST',
      data,
    },
    type: RESET_USER_PASSWORD,
  }),
  resendInvite: data => ({
    [API_CALL]: {
      endpoint: '/v1/organizations/users/invite-resend',
      method: 'post',
      data,
    },
    type: INVITE_USER,
  }),
};

export default userActions;
