import { API_CALL } from '../../../../../middleware/api';
import { WIDGET_GET_PATIENTS_WITH_NEW_NOTES } from './constants';

export const actions = {
  getPatientsWithNewNotes: (startTimestamp, endTimestamp, orgId) => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/patients/notes/stats',
      params: { startTimestamp, endTimestamp, orgId},
    },
    type: WIDGET_GET_PATIENTS_WITH_NEW_NOTES,
  }),
};
