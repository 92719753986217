import Strings from '../../Strings';
import { enrollmentFieldsEnum } from '../../utils/enrollmentFields';
import { patientFieldsEnum } from '../../utils/rpmPatientFields';
import { PERMISSION_GROUPS } from '../../utils/userPermissions';
import { welcomeMessageFieldsEnum } from '../../utils/welcomeMessageFields';

export const RpmPatientModalPages = {
  patientDetails: {
    id: 'rpm-patient-details',
    name: Strings.capPatient.patientDetails,
    isRequired: true,
    fields: [
      patientFieldsEnum.firstName,
      patientFieldsEnum.lastName,
      patientFieldsEnum.mrn,
      patientFieldsEnum.dateOfBirth,
      patientFieldsEnum.gender,
      patientFieldsEnum.language,
      patientFieldsEnum.timezone,
    ],
  },
  contactInformation: {
    id: 'rpm-patient-contact-info',
    name: Strings.capPatient.contactInformation,
    isRequired: true,
    fields: [
      patientFieldsEnum.textPhoneNo,
      patientFieldsEnum.email,
      patientFieldsEnum.homeAddress,
    ],
  },
  medicalHistory: {
    id: 'rpm-patient-medical-history',
    name: Strings.capPatient.medicalHistory,
    isRequired: true,
    fields: [
      patientFieldsEnum.conditions,
      patientFieldsEnum.medications,
    ],
  },
  centerDetails: {
    id: 'rpm-patient-center-details',
    name: Strings.capPatient.providerCenterDetails,
    isRequired: true,
    fields: [
      patientFieldsEnum.facility,
      patientFieldsEnum.centerDetails,
    ],
  },
  insurance: {
    id: 'rpm-patient-insurance',
    name: Strings.capPatient.insurance,
    isRequired: false,
    fields: [patientFieldsEnum.insurance],
  },
  transplantDetails: {
    id: 'rpm-patient-transplant-details',
    name: Strings.capPatient.transplantDetails,
    isRequired: false,
    fields: [patientFieldsEnum.transplantDetails],
  },
};


export const OnboardPatientModalPages = {
  patientDetails: {
    id: 'rpm-patient-details',
    name: Strings.capPatient.patientDetails,
    isRequired: true,
    fields: [
      patientFieldsEnum.firstName,
      patientFieldsEnum.lastName,
      patientFieldsEnum.mrn,
      patientFieldsEnum.pharmacyPatientId,
      patientFieldsEnum.dateOfBirth,
      patientFieldsEnum.gender,
      patientFieldsEnum.language,
      patientFieldsEnum.timezone,
    ],
  },
  contactInformation: {
    id: 'rpm-patient-contact-info',
    name: Strings.capPatient.contactInformation,
    isRequired: true,
    fields: [
      patientFieldsEnum.textPhoneNo,
      patientFieldsEnum.email,
      patientFieldsEnum.homeAddress,
    ],
  },
  medicalHistory: {
    id: 'rpm-patient-medical-history',
    name: Strings.capPatient.medicalHistory,
    isRequired: true,
    fields: [
      patientFieldsEnum.conditions,
      patientFieldsEnum.medications,
    ],
  },
  centerDetails: {
    id: 'rpm-patient-center-details',
    name: Strings.capPatient.providerCenterDetails,
    isRequired: true,
    fields: [
      patientFieldsEnum.facilityId,
      patientFieldsEnum.centerDetails,
    ],
  },
  insurance: {
    id: 'rpm-patient-insurance',
    name: Strings.capPatient.insurance,
    isRequired: false,
    fields: [patientFieldsEnum.insurance],
  },
  transplantDetails: {
    id: 'rpm-patient-transplant-details',
    name: Strings.capPatient.transplantDetails,
    isRequired: false,
    fields: [patientFieldsEnum.transplantDetails],
  },
  agreementToSpeakToProvider: {
    id: 'agreement-to-speak-to-provider',
    name: Strings.agreementToSpeakWithProvider,
    fields: [patientFieldsEnum.consentForProvider, patientFieldsEnum.initiateEnrollmentByProviderAt],
  },
  enrollmentProgram: {
    id: 'enroll-patient-program',
    name: Strings.program,
    fields: [
      enrollmentFieldsEnum.subprogram,
      enrollmentFieldsEnum.conditionId,
      enrollmentFieldsEnum.default_program,
      enrollmentFieldsEnum.enrollmentConsents,
      enrollmentFieldsEnum.care_provider,
      enrollmentFieldsEnum.medication,
    ],
  },
  enrollmentKit: {
    id: 'enroll-patient-kit',
    name: Strings.kit,
    fields: [enrollmentFieldsEnum.kit_id, enrollmentFieldsEnum.vitals],
  },
  enrollmentDates: {
    id: 'enroll-patient-dates',
    name: `${Strings.enrollment} ${Strings.dates}`,
    fields: [enrollmentFieldsEnum.enrollmentStart, enrollmentFieldsEnum.enrollmentEnd, enrollmentFieldsEnum.activation_method],
  },
  welcomeMessage: {
    id: 'enroll-patient-welcome-message',
    name: Strings.capPatient.welcomeMessage,
    fields: Object.values(welcomeMessageFieldsEnum),
  },
};

export const getRequiredFieldsForPage = (pageId, object, stage) => {
  const page = Object.values(OnboardPatientModalPages).find(p => p.id === pageId);
  if (!page) {
    return [];
  }
  return page.fields.filter(f => f.required(object, stage));
};

export const getFieldsForPage = (pageId, object, stage) => {
  const page = Object.values(OnboardPatientModalPages).find(p => p.id === pageId);
  if (!page) {
    return [];
  }
  return page.fields.map(f => ({
    ...f,
    required: f.required ? f.required(object, stage) : false,
    readOnly: f.readOnly ? f.readOnly(object, stage) : false,
  }))
};

export const getWrongFieldsCountForPage = (fields, validationErrors) => {
  const ret = [];
  fields.forEach(f => {
    const fieldError = validationErrors.find(v => v.property === f.fieldId);
    if (fieldError) {
      ret.push(fieldError);
    }
  }
  );
  return ret?.length;
};

export const EnrollPatientModalPages = {
  programs: {
    id: 'enroll-patient-programs',
    name: Strings.program,
    isRequired: true,
  },
  kit: {
    id: 'enroll-patient-kit',
    name: Strings.kit,
    isRequired: false,
  },
  dates: {
    id: 'enroll-patient-dates',
    name: Strings.dates,
    isRequired: false,
  },
  welcomeMessage: {
    id: 'enroll-patient-welcome-message',
    name: Strings.capPatient.welcomeMessage,
    isRequired: false,
  },
};

export const EditRpmScheduleModalPages = {
  medication: {
    id: 'schedule-medication',
    name: Strings.addMedicine,
    isRequired: true,
  },
  linkProgram: {
    id: 'schedule-link-program',
    name: Strings.capPatient.linkWithProgram,
    isRequired: false,
  },
  schedule: {
    id: 'schedule',
    name: Strings.patient_medications.schedule,
    isRequired: true,
  },
  reminders: {
    id: 'schedule-reminders',
    name: Strings.reminders,
    isRequired: false,
  },
  assignCap: {
    id: 'schedule-assign-cap',
    name: Strings.capPatient.assignSmartCap,
    isRequired: false,
  },
};

export const EditCohortModalPages = {
  data: {
    id: 'cohort-data',
    name: Strings.editCohort,
    isRequired: true,
  },
  patients: {
    id: 'patients',
    name: Strings.addRemovePatients,
    isRequired: false,
  },
};

export const modals = [
  {
    id: 'edit-rpm-patient',
    name: Strings.addPatient,
    pages: RpmPatientModalPages,
    isRequired: true,
  },
  {
    id: 'enroll-patient',
    name: 'Enroll Patient',
    pages: EnrollPatientModalPages,
  },
  {
    id: 'edit-rpm-schedule',
    name: Strings.addMedicine,
    pages: EditRpmScheduleModalPages,
  },
  {
    id: 'you-are-all-done',
    name: Strings.youAreAllDoneName,
    pages: {},
  },
  {
    id: 'edit-cohort',
    name: Strings.editCohort,
    pages: EditCohortModalPages,
  },
  {
    id: 'edit-conditions',
    name: Strings.editConditionList,
    pages: {},
    isRequired: true,
  },
  {
    id: 'edit-facilities',
    name: Strings.editFacilityList,
    pages: {},
    isRequired: true,
  },
  {
    id: 'done-rpm-setup',
    name: Strings.youAreAllDone,
    pages: {},
  },
];

export const workflows = {
  patientOnboarding: {
    id: 'patientOnboarding',
    name: 'Patient onboarding',
    modals: [modals[0], modals[1], modals[2], modals[3]],
    permission: null,
    permissionGroup: PERMISSION_GROUPS.PATIENTS_RPM,
  },
  setupRpm: {
    id: 'setupRpm',
    name: 'Setup Organization',
    modals: [modals[5], modals[6], modals[4], modals[7]],
    permission: null,
    permissionGroup: PERMISSION_GROUPS.PATIENTS_RPM,
  },
};

export function GetDefaultPagesVisibility() {
  const ret = [];
  modals.forEach((m, i) => {
    ret.push({ id: m.id, pages: [], visible: true });
    if (m.pages) {
      Object.keys(m.pages)?.forEach(key => {
        ret[i].pages.push({ id: m.pages[key].id, visible: true });
      });
    }
  });
  return ret;
}

export function SetDefaultPagesVisibility(currentState, modalId) {
  if (!currentState) {
    return;
  }
  const ret = [];
  currentState.forEach(modal => {
    if (modal.id === modalId) {
      ret.push({
        ...modal,
        pages: modal.pages.map(page => {
          return { ...page, visible: true };
        }),
      });
    } else {
      ret.push(modal);
    }
  });
  if (!currentState.some(m => m.id === modalId)) {
    const defModal = modals.find(m => m.id === modalId);
    if (defModal) {
      ret.push({
        id: defModal.id,
        pages: defModal.pages
          ? Object.keys(defModal.pages)?.map(key => {
              return { id: defModal.pages[key].id, visible: true };
            })
          : [],
        visible: true,
      });
    }
  }
  return ret;
}

export function GetDefaultModalsVisibility() {
  const ret = [];
  Object.values(workflows).forEach((flow, i) => {
    ret.push({ id: flow.id, visible: true, modals: [] });
    flow.modals.forEach(m => {
      ret[i].modals.push({ id: m.id, visible: true });
    });
  });
  return ret;
}

export function GetDefaultModalsVisibilityForWorkflow(workflowId) {
  if (!workflows[workflowId]) {
    return;
  }
  return {
    id: workflowId,
    visible: true,
    modals: workflows[workflowId].modals.map(m => {
      return {
        id: m.id,
        visible: true,
      };
    }),
  };
}

export function ChangePageVisibility(currentState, modalId, pageId) {
  if (!currentState) {
    return;
  }
  const ret = [];
  currentState.forEach(modal => {
    if (modal.id === modalId) {
      ret.push({
        ...modal,
        pages: modal.pages.map(page => {
          if (page.id === pageId) {
            return { ...page, visible: !page.visible };
          }
          return page;
        }),
      });
    } else {
      ret.push(modal);
    }
  });
  return ret;
}

export function ChangeModalVisibility(currentState, workflowId, modalId) {
  if (!currentState) {
    return;
  }
  const ret = [];
  currentState.forEach(workflow => {
    if (workflow.id === workflowId) {
      ret.push({
        ...workflow,
        modals: workflow.modals.map(modal => {
          if (modal.id === modalId) {
            return { ...modal, visible: !modal.visible };
          }
          return modal;
        }),
      });
    } else {
      ret.push(workflow);
    }
  });
  return ret;
}

export function getModalsDetails(currentState) {
  if (!currentState) {
    return;
  }
  const ret = [];
  currentState.forEach((flow, i) => {
    const refFlow = Object.values(workflows).find(w => w.id === flow.id);
    ret.push({
      ...flow,
      name: refFlow.name,
      modals: [],
      permission: refFlow.permission,
      permissionGroup: refFlow.permissionGroup,
    });
    flow.modals.forEach(modal => {
      const refModal = Object.values(modals).find(m => m.id === modal.id);
      ret[i].modals.push({ ...modal, isRequired: refModal.isRequired, name: refModal.name });
    });
  });
  return ret;
}

export function getPagesDetails(currentState) {
  if (!currentState) {
    return;
  }
  const modal = modals.find(m => m.id === currentState.id);
  if (!modal || !modal.pages) {
    return null;
  }
  return Object.values(modal.pages).map(p => {
    const pageState = currentState.pages.find(page => page.id === p.id);
    return { ...p, visible: pageState ? pageState.visible : true };
  });
}
