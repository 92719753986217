import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Papa from 'papaparse';
import readXlsxFile, { readSheetNames } from 'read-excel-file';
import axios from 'axios';
import _, { cloneDeep } from 'lodash';

import { closeModal } from '../../actions/modal';
import { Input, SelectField } from '../../containers/Form';
import { actions } from '../../pages/SuperUser/Patients/redux/actions';
import { actions as cohortActions } from '../../pages/SuperUser/Patients/Cohorts/redux/actions';
import { ADD_PATIENT_ERROR, EDIT_PATIENT_ERROR } from '../../pages/SuperUser/Patients/redux/constants';
import Strings from '../../Strings';
import './BulkAddPatientsModal.scss';
import Table, { Column } from '../../containers/Table/TableWithPagination';
import { cleanPhoneNumber, makeValid } from '../../utils';
import { validateRpmPatient } from '../../utils/validators/rpmPatient';
import {
  ASSIGN_PATIENT_TO_FACILITY_ERROR,
  CREATE_CONDITION_ERROR,
  CREATE_FACILITY_ERROR,
} from '../../pages/SuperUser/Patients/Cohorts/redux/constants';
import { languageValueToId } from '../../utils/language';
import Wizard from '../../containers/Modal/Wizard';
import { notificationActions } from '../../components/Notification/redux/actions';
import { documentsActions, GET_UPLOAD_URL_RESULT } from '../../pages/CloudDocuments/actions';
import { DATE_FORMAT_YEAR_MONTH_DAY } from '../../constants';

const UNKNOWN_CONDITION = 'Unknown';
const UNKNOWN_FACILITY = 'Unknown';
const MEDICARE_INSURANCE_NAME = 'Medicare';

function BulkAddPatientsModal(props) {
  const [loadingFile, setLoadingFile] = useState(false);
  const [file, setFile] = useState(null);
  const [fileContent, setFileContent] = useState(null);
  const [excelSheets, setExcelSheets] = useState([]);
  const [selectedExcelSheet, setSelectedExcelSheet] = useState(null);
  const [sheetErrors, setSheetErrors] = useState(null);
  const [fileErrors, setFileErrors] = useState([]);
  const [columnsInFile, setColumnsInFile] = useState([]);
  const [missingColumns, setMissingColumns] = useState([]);

  const [validateErrorsCount, setValidateErrorsCount] = useState(0);
  const [uploadErrorsCount, setUploadErrorsCount] = useState(0);
  const [patientsSent, setPatientsSent] = useState(null);
  const [conditionsSent, setConditionsSent] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [anyPatientAdded, setAnyPatientAdded] = useState(false);
  const [saveBtnClicked, setSaveBtnClicked] = useState(false);
  const [highlightInvalidFields, setHighlightInvalidFields] = useState(false);
  const [forceNextPage, setForceNextPage] = useState(0);

  const [fetchingConditions, setFetchingConditions] = useState(false);
  const [conditionsNotInOrg, setConditionsNotInOrg] = useState([]);

  const [patients, setPatients] = useState([]);
  const [newConditionsVerified, setNewConditionsVerified] = useState([]);
  const [newConditionsUnknown, setNewConditionsUnknown] = useState([]);
  const [existingPatients, setExistingPatients] = useState([]);

  const clearAllData = exclude => {
    if (!exclude?.includes('file')) {
      setFile(null);
      setLoadingFile(false);
    }
    setFileContent(null);
    if (!exclude?.includes('excelSheets')) {
      setExcelSheets([]);
    }
    if (!exclude?.includes('selectedExcelSheet')) {
      setSelectedExcelSheet(null);
    }
    setSheetErrors(null);
    setFileErrors([]);
    setColumnsInFile([]);
    setMissingColumns([]);
    setValidateErrorsCount(0);
    setUploadErrorsCount(0);
    setPatientsSent(null);
    setUploading(false);
    setAnyPatientAdded(false);
    setPatients([]);
    setNewConditionsVerified([]);
    setNewConditionsUnknown([]);
  };

  React.useEffect(() => {
    props.getConditions();
    props.getFacilities();

    const patientCountToFetch = 500;
    props.getPatientsForSuggestion({limit: patientCountToFetch}).then(resp => {
      if (resp?.response?.pagination?.totalRecords > patientCountToFetch) {
        props.getPatientsForSuggestion({limit: resp.response.pagination.totalRecords}).then(resp2 => {
          setExistingPatients(resp2?.response?.data);
        });
      } else {
        setExistingPatients(resp?.response?.data);
      }
    });
  }, []);

  React.useEffect(() => {
    const newConditions = [];
    setFetchingConditions(true);
    const fetchConditions = async conditions => {
      const found = [];
      const notFound = [];
      for (const c of conditions) {
        const res = await props.getIdcConditions({ search: c });
        const match = res.response?.data?.find(e => e.code?.toLowerCase() === c.toLowerCase());
        if (match) {
          found.push(match);
        } else {
          notFound.push(c);
        }
        setConditionsNotInOrg(prev => prev.map(e => {
          if (e.conditionFromExcel === c) {
            return match 
            ? { ...match, conditionFromExcel: c, found: true, checked: true } 
            : { conditionFromExcel: c, found: false, checked: true };
          }
          return e;
        }));
      }
      setFetchingConditions(false);
      setNewConditionsVerified(found);
      setNewConditionsUnknown(notFound);
    };

    const validateIdcCodes = conditions => {
      const matched = [];
      const unmatched = [];
      const icd10cmRegex = /^[A-Z][0-9][0-9A-Z](?:\.[0-9A-Z]{1,4})?$/;
      conditions.forEach(c => {
        const match = icd10cmRegex.test(c);
        if (match) {
          matched.push(c);
        } else {
          unmatched.push(c);
        }
        setConditionsNotInOrg(prev => prev.map(e => {
          if (e.conditionFromExcel === c) {
            return match 
            ? { conditionFromExcel: c, found: true, checked: true } 
            : { conditionFromExcel: c, found: false, checked: true };
          }
          return e;
        }));
      });
      setFetchingConditions(false);
      setNewConditionsVerified(matched);
      setNewConditionsUnknown(unmatched);
    };

    if (uploading) {
      return;
    }
    patients
      ?.forEach(p => {
        p.conditions
        ?.filter(c => c !== UNKNOWN_CONDITION)
        ?.forEach(c => {
          if (
            !props.conditions.some(
              cond =>
                cond.id?.toLowerCase() === c.toLowerCase() ||
                cond.title?.toLowerCase() === c.toLowerCase() ||
                cond.condition_icd10cm?.toLowerCase() === c.toLowerCase(),
            ) &&
            !newConditions.includes(c)
          ) {
            newConditions.push(c);
          }
        });
      });
    setConditionsNotInOrg(newConditions.map(c => ({ conditionFromExcel: c })));
    validateIdcCodes(newConditions);
    // fetchConditions(newConditions);
  }, [patients]);

  React.useEffect(() => {
    if (patients.length > 0) {
      setValidateErrorsCount(validatePatients(patients));
    }
  }, [patients]);

  React.useEffect(() => {
    const fetchRows = async sheet => {
      const rows = await readXlsxFile(file, { sheet });
      if (rows.length === 0) {
        setSheetErrors([Strings.bulkAddTexts.noRows]);
      }
      setFileContent({
        meta: { fields: rows[0] || []},
        errors: [],
        data: rows.length > 1 ? rows.slice(1).map(r => {
          const ret = new Map();
          r.forEach((c, j) => {
            ret.set(rows[0][j], c);
            // ret[rows[0][j]] = c;
          });
          return ret;
        }) : [],
      });
    };
    setSheetErrors([]);
    if (selectedExcelSheet) {
      setLoadingFile(true);
      clearAllData(['file', 'excelSheets', 'selectedExcelSheet']);
      fetchRows(selectedExcelSheet).then(() => {
        setLoadingFile(false);
      });
    }
  }, [selectedExcelSheet]);

  React.useEffect(() => {
    if (fileContent) {
      parseFileContent(fileContent);
    }
  }, [fileContent]);

  React.useEffect(() => {
    if (!uploading && anyPatientAdded) {
      if (props.data.onSuccess) {
        props.data.onSuccess();
      }
    }
  }, [uploading]);

  const fieldTypes = {
    simpleField: 'simpleField',
    combinedField: 'combinedField',
    simpleArray: 'simpleArray',
    complexArray: 'complexArray',
  };

  const fieldList = {
    firstName: {
      fieldType: fieldTypes.simpleField,
      columnNames: ['firstname', 'first name', 'primary name', 'patient first name'],
      required: true,
      transform: val => trimOrUndefined(val),
    },
    lastName: {
      fieldType: fieldTypes.simpleField,
      columnNames: ['lastname', 'last name', 'patient last name'],
      required: true,
      transform: val => trimOrUndefined(val),
    },
    pharmacyPatientId: {
      fieldType: fieldTypes.simpleField,
      columnNames: ['patientid', 'patient id', 'patient serial number', 'patient central id'],
      required: false,
      transform: val => trimOrUndefined(val?.toString()),
    },
    mrn: {
      fieldType: fieldTypes.simpleField,
      columnNames: ['mrn', 'medical record number'],
      required: false,
      transform: val => trimOrUndefined(val),
    },
    dateOfBirth: {
      fieldType: fieldTypes.simpleField,
      columnNames: ['dob', 'date of birth', 'patient dob', 'patient date of birth'],
      required: true,
      transform: val => formatDate(val),
    },
    textPhoneNo: {
      fieldType: fieldTypes.simpleField,
      columnNames: ['textnumber', 'alt. phone number(s)', 'mobile phone', 'patient mobile number', 'patient primary phone number', 'phonenumber', 'primary phone number', 'mobile phone', 'patient mobile number'],
      required: false,
      transform: val => calcPhoneNumber(val),
    },
    email: {
      fieldType: fieldTypes.simpleField,
      columnNames: ['email', 'personal email', 'patient email'],
      required: false,
      transform: val => trimOrUndefined(val),
    },
    homeAddress: {
      fieldType: fieldTypes.simpleField,
      columnNames: ['address', 'patient address'],
      required: false,
      transform: val => trimOrUndefined(val),
    },
    address: {
      fieldType: fieldTypes.combinedField,
      columnNames: ['address 1', 'address 2', 'city', 'state', 'zip code', 'patient primary address',	'patient primary city',	'patient primary state',	'patient primary zip code'],
      required: false,
      transform: (fields, val) => getAddressFromFields(fields, val),
    },
    facility: {
      fieldType: fieldTypes.simpleField,
      columnNames: ['facility'],
      required: false,
      transform: val => trimOrUndefined(val),
    },

    insurance: {
      fieldType: fieldTypes.complexArray,
      columnNames: [
        ['insurancename', 'insurance name', 'ins name', 'third party'],
        ['policyid', 'policy id', 'card holder id', 'cardholderid'],
        ['groupnumber', 'group #', 'group number'],
      ],
      transform: (n, id, gn) => {
        return {
          insuranceName: n,
          policyId: id,
          groupNumber: gn,
        };
      },
    },

    centerDetails: {
      fieldType: fieldTypes.complexArray,
      columnNames: [
        ['centername', 'center/practice name'],
        ['orderingprovider', 'ordering provider'],
        ['cliniccontact', 'name of person completing form'],
      ],
      transform: (n, p, cc) => {
        return {
          centerName: n,
          orderingProvider: p,
          clinicContact: cc,
        };
      },
    },

    conditions: {
      fieldType: fieldTypes.simpleArray,
      columnNames: ['condition', 'icd10 code'],
      transform: c => {
        return props.conditions.find(cnd => cnd.title === c || cnd.condition_icd10cm === c)?.id || c;
      },
    },

    transplantDetails: {
      fieldType: fieldTypes.complexArray,
      columnNames: [
        ['transplantorgantype', 'transplant organ'],
        ['transplantdate', 'transplant date'],
      ],
      transform: (ot, d) => {
        return {
          organType: ot,
          transplantDate: formatDate(d),
        };
      },
    },

    timezone: { columnNames: ['timezone'], transform: val => trimOrUndefined(val) },
    gender: { columnNames: ['gender', 'sex', 'patient gender'], required: true, transform: val => getGender(val) },
    language: {
      columnNames: ['language', 'patient language'],
      required: false,
      transform: val => {
        const v = trimOrUndefined(val);
        const lang = languageValueToId(v);
        return lang || v;
      },
    },
    medications: {
      fieldType: fieldTypes.complexArray,
      columnNames: [
        ['medication', 'medication name', 'drug name', 'dispensed item name'],
        ['ndc', 'ndc code', 'dispensed item ndc'],
        ['rxnumber', 'rx number'],
        ['prescribed medication', 'prescribed item'],
        ['dispensed medication', 'dispensed item', 'dispensed item print name'],
        ['filldate', 'fill date', 'date filled'],
        ['prescriberfirstname', 'prescriber first name'],
        ['prescriberlastname', 'prescriber last name', 'prescriber full name', 'prescriberfullname'],
        ['prescriberprimaryphone', 'prescriber primary phone'],
        ['prescribertype', 'prescriber type'],
        ['prescribernpi', 'prescriber npi'],
        ['prescriberfaxnumber', 'prescriber fax number'],
        ['prescriberemail', 'prescriber email'],
      ],
      required: false,
      transform: (m, ndc, rx, pm, dm, fd, fn, ln, pp, pt, npi, fnn, e) => {
        return {
          name: m,
          ndc: ndc?.toString(),
          rxNumber: rx?.toString(),
          prescribedName: pm,
          dispensedName: dm,
          fillDate: formatDate(fd, false),
          prescriber : {
            firstName: fn,
            lastName: ln?.includes(`, ${fn}`) ? ln.split(`, ${fn}`)[0] : ln,
            primaryPhone: pp?.toString(),
            type: pt,
            NPI: npi?.toString(),
            fax: fnn?.toString(),
            email: e,
          }
        };
      }
    },
  };

  const getGender = gender => {
    switch (gender) {
      case 'M':
      case 'Male':
        return 'Male';
      case 'F':
      case 'Female':
        return 'Female';
      default:
        return trimOrUndefined(gender);
    }
  };

  function getMissingFields(fields) {
    const ret = Object.values(fieldList)
      .filter(x => x.required === true && !fields.some(y => x.columnNames.includes(y.toLowerCase())))
      .map(f => f.columnNames);
    if (
      !fields.some(f => fieldList.homeAddress.columnNames.includes(f.toLowerCase())) &&
      !fields.some(f => fieldList.address.columnNames.includes(f.toLowerCase()))
    ) {
      ret.push('Address');
    }
    return ret;
  }

  function validatePatients(patients) {
    let validateErrorsCount = 0;
    patients.forEach(patient => {
      const ok = validatePatient(patient);
      if (!ok) validateErrorsCount += 1;
    });

    return validateErrorsCount;
  }

  function groupBy(list, keyGetter, valueGetter) {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [valueGetter(item)]);
      } else {
        collection.push(valueGetter(item));
      }
    });
    return map;
  }

  const parseFileContent = text => {
    const res = cloneDeep(text);
    setColumnsInFile(res.meta?.fields || []);
    const missingFields = getMissingFields(res.meta?.fields || []);
    setMissingColumns(missingFields);

    if (res.errors.length > 0 || missingFields.length > 0) {
      const validationErrorsMap = groupBy(
        res.errors,
        err => err.message,
        err => err.row + 2,
      );
      const validationErrors = Array.from(validationErrorsMap, ([key, val]) => `${key} (lines: ${val.join(', ')})`);
      const fileErrors = [];
      if (missingFields.length > 0)
        fileErrors.push(`${Strings.capPatient.errorRequiredFields} (${missingFields.join(', ')})`);
      if (validationErrors.length > 0) fileErrors.push(...validationErrors);
      setFileErrors(fileErrors);
    } else {
      const patientsRead = res.data.map((p, i) => preparePatient(Object.fromEntries(p.entries()), i, res.data.length, p));
      const mergedPatients = mergePatients(patientsRead);
      setPatients(mergedPatients);
      setUploadErrorsCount(validateErrorsCount);
    }
  };

  const mergePatients = newPatients => {
    const ret = [];
    newPatients.forEach(np => {
      const found = ret.find(r => matchPatients(r, np));
      if (found) {
        Object.entries(found).forEach(([key, value]) => {
          if (Array.isArray(value) && !_.isEqual(np[key], found[key])) {
            found[key] = _.unionWith(found[key], np[key], _.isEqual);
          } else {
            found[key] = np[key] ? np[key] : found[key];
          }
        });
      } else {
        ret.push(np);
      }
    });
    return ret;
  };

  const onFileChangeForS3Upload = async event => {
    setFileErrors([]);
    const sizeInMb = event.target.files[0].size / 1048576;
    if (sizeInMb >= 50) {
      setFile(null);
      setFileErrors([`${Strings.fileExceedMaximumSizeOf} 50 MB`]);
      return false;
    }
    setFile(event.target.files[0]);
  };

  const onFileChange = async event => {
    clearAllData();
    const file = event.target.files[0];
    setFile(file);
    if (file) {
      const validExts = new Array('.csv', '.xlsx', '.xls');
      const fileExt = file.name.substring(file.name.lastIndexOf('.'));
      if (!validExts.includes(fileExt)) {
        setFileErrors([`${Strings.capPatient.errorInvalidFile} ${validExts.toString()}`]);
        setFile(null);
        setPatients([]);
        setValidateErrorsCount(0);
        setUploadErrorsCount(0);
        setUploading(false);
        setPatientsSent(0);
        setColumnsInFile([]);
        setMissingColumns([]);
        return false;
      }
      const reader = new FileReader();
      reader.onload = async e => {
        if (fileExt === '.xlsx' || fileExt === '.xls') {
          try {
            const sheetNames = await readSheetNames(file);
            if (sheetNames.length === 1) {
              setSelectedExcelSheet(sheetNames[0]);
            } else {
              setExcelSheets(sheetNames);
            }
            setForceNextPage(prev => prev + 1);
          } catch (e) {
            setFileErrors([`${Strings.bulkAddTexts.errorReadingFile}: ${e.message}`]);
          }
        } else {
          setLoadingFile(true);
          const res = Papa.parse(e.target.result, { header: true, skipEmptyLines: true });
          res.data = res.data.map(r => {
            const ret = new Map();
            res.meta.fields.forEach(field => {
              ret.set(field, r[field]);
            });
            return ret;
          });
          setFileContent(res);
          setLoadingFile(false);
          setForceNextPage(prev => prev + 1);
        }
      };
      reader.onerror = () => {
        setFileErrors([`${Strings.capPatient.errorReadingFile} ${file.fileName}`]);
        setPatients([]);
      };
      reader.readAsText(file);
    }
  };

  const onS3Upload = async () => {
    if (saveBtnClicked) {
      return;
    }

    setSaveBtnClicked(true);

    props.getUploadPatientListUrl(file.name).then(resp => {
      setUploading(true);
      if (resp.type === GET_UPLOAD_URL_RESULT) {
        axios
          .put(resp.response.url, file, {})
          .then(resp => {
            if (resp.status === 200) {
              props.showNotification(Strings.fileUploadedSuccessfully);
              setUploading(false);
              props.onClose();
              return;
            } else {
              props.showNotification(Strings.fileUploadFail);
              setUploading(false);
              return;
            }
          })
          .catch(() => {
            props.showNotification(Strings.fileUploadFail);
            setUploading(false);
            return;
          });
      } else {
        props.showNotification(Strings.documentsGeneralProblem);
        setUploading(false);
      }
    });
  };

  const shouldAddUnknownCondition =
    !props.conditions?.some(c => c.title === UNKNOWN_CONDITION) &&
    (patients?.filter(p => p.conditions?.[0] === UNKNOWN_CONDITION).length > 0 || newConditionsUnknown?.length > 0);

  const addConditions = async () => {
    let counter = 0;
    setConditionsSent(counter);
    const conditionsToAdd = newConditionsVerified.concat(
      shouldAddUnknownCondition ? [UNKNOWN_CONDITION] : [],
    );
    const newConditionsData = [];
    for (const condition of conditionsToAdd) {
      const request = {};
      if (condition === UNKNOWN_CONDITION) {
        request.title = UNKNOWN_CONDITION;
      } else {
        request.icd10cm = condition;
      }
      const response = await props.createCondition(request);  
      if (response && response.type === CREATE_CONDITION_ERROR) {
        props.showNotification(response.response?.data?.error?.message, 5000, true);
      } else {
        newConditionsData.push({
          condition_icd10cm: condition,
          id: response.response.id,
          title: condition === UNKNOWN_CONDITION ? UNKNOWN_CONDITION : undefined,
        });
      }
      counter += 1;
      setConditionsSent(counter);
    }
    return newConditionsData;
  };

  const checkForUnknownFacilityAndAdd = async () => {
    let ret = props.facilities?.find(c => c.title === UNKNOWN_FACILITY)?.id;
    const shouldAddUnknownFacility = patients?.filter(p => p.facility === UNKNOWN_FACILITY).length > 0 && !ret;
    if (shouldAddUnknownFacility) {
      const response = await props.createFacility({ title: UNKNOWN_FACILITY });
      if (response && response.type === CREATE_FACILITY_ERROR) {
        props.showNotification(response.response?.data?.error?.message, 5000, true);
        setSaveBtnClicked(false);
      } else {
        ret = response.response.id;
      }
    }
    return ret;
  };

  const onUpload = async () => {
    if (saveBtnClicked) {
      return;
    }
    setSaveBtnClicked(true);
    let counter = 0;
    let failed = 0;
    setUploadErrorsCount(failed);
    setPatientsSent(counter);
    setUploading(true);

    const newConditionsData = await addConditions();
    const unknownConditionId = props.conditions.concat(newConditionsData).find(c => c.title === UNKNOWN_CONDITION)?.id;
    const unknownFacilityId = await checkForUnknownFacilityAndAdd();

    const processSinglePatient = async patient => {
      if (!patient.facilityId) {
        patient.facilityId = unknownFacilityId;
      }
      if (patient.conditions?.some(c => c === UNKNOWN_CONDITION || newConditionsUnknown.includes(c))) {
        patient.conditions.forEach((c, i) => {
          if (c === UNKNOWN_CONDITION || newConditionsUnknown.includes(c)) {
            patient.conditions[i] = unknownConditionId;
          }
        });
      }
      if (patient.conditions?.some(c => newConditionsVerified.some(cn => cn === c))) {
        patient.conditions.forEach((c, i) => {
          if (newConditionsVerified.some(cn => cn === c)) {
            patient.conditions[i] = newConditionsData.find(nc => nc.condition_icd10cm === c)?.id || unknownConditionId;
          }
        });
      }
      patient.conditions = _.uniq(patient.conditions);
      const patientToSend = preparePatientDTO(patient);
      const response = await props.onUploadPatient(patientToSend);
      if (response) {
        if (response.type === ADD_PATIENT_ERROR || response.type === EDIT_PATIENT_ERROR) {
          patient.uploadErrors = [response.response?.data?.standard_error?.message];
          failed += 1;
        } else {
          patient.uploaded = true;
          setAnyPatientAdded(true);
          const existingPatient = existingPatients.find(p => p.id === patient.id);
          if (patient.facilityId && patient.facilityId !== existingPatient?.facilityId) {
            const resp = await props.assignPatientToFacility(parseInt(response.response?.id, 10), patient.facilityId);
            if (resp && resp.type === ASSIGN_PATIENT_TO_FACILITY_ERROR) {
              failed += 1;
              patient.uploadErrors = [response.response?.data?.standard_error?.message];
            }
          }
        }
        counter += 1;
        setPatientsSent(counter);
        setUploadErrorsCount(failed);
        return patient;
      }
    };

    for (const [index, patient] of patients.entries()) {
      const patientResult = await processSinglePatient(patient);
      setPatients(p => p.map((p, i) => (i === index ? patientResult : p)));
    }

    setUploading(false);
  };

  const getColumns = () => {
    const columns = [];
    columns.push(
      <Column
        key="pharmacyPatientId"
        sortKey="pharmacyPatientId"
        title={Strings.capPatient.patientId}
        value={d => (
          <React.Fragment>
            <div className="selector-label">{d.pharmacyPatientId}</div>
          </React.Fragment>
        )}
      />,
    );
    columns.push(
      <Column
        key="patientName"
        sortKey="patientName"
        title={Strings.capPatient.patientName}
        value={d => makeValid(`${d.firstName} ${d.lastName}`)}
      />,
    );
    columns.push(
      <Column
        key="status"
        title="Status"
        value={d =>
          d.errors.length > 0 ? (
            <div className="patient-error-cell">{d.errors.join(', ')}</div>
          ) : d.uploadErrors?.length > 0 ? (
            <div className="patient-error-cell">{d.uploadErrors.join(', ')}</div>
          ) : d.uploaded ? (
            <div className="patient-added-cell">{Strings.capPatient.uploadSucceeded}</div>
          ) : d.id ? (
            <div className="patient-exists-cell">{Strings.capPatient.patientAlreadyExists}</div>
          ) : (
            Strings.capPatient.readyForUpload
          )
        }
      />,
    );
    return columns;
  };

  const sufixes = ['primary', 'secondary', 'tertiary', 'quaternary', 'quinary', 'senary', 'septenary', 'octonary', 'nonary', 'denary'];

  const prepareForComplexArray = (patient, fields, packer) => {
    const ret = [];
    const maxCount = 10;
    const patientKeys = Object.keys(patient);
    for (let i = 1; i <= maxCount; i += 1) {
      const fieldValues = [];
      fields.forEach(fs => {
        let fv;
        fs.forEach(f => {
          const key = patientKeys.find(k => i === 1 && k.toLowerCase() === f || k.toLowerCase() === `${f} ${i}` || k.toLowerCase() === `${f}${i}`);
          if (key) {
            fv = patient[key];
          }
        });

        const fvFinal = trimOrUndefined(fv);
        fieldValues.push(fvFinal);
      });

      if (fieldValues.filter(v => v).length > 0) {
        ret.push(packer(...fieldValues));
      }
    }
    sufixes.forEach(sufix => {
      const fieldValues = [];
      fields.forEach(fs => {
        let fv;
        fs.forEach(f => {
          const key = patientKeys.find(k => k.toLowerCase() === `${sufix} ${f}` || k.toLowerCase() === `${sufix}${f}`);
          if (key) {
            fv = patient[key];
          }
        });

        const fvFinal = trimOrUndefined(fv);
        fieldValues.push(fvFinal);
      });

      if (fieldValues.filter(v => v).length > 0) {
        ret.push(packer(...fieldValues));
      }
    });
    return ret;
  };

  const prepareForSimpleArray = (patient, fields, packer) => {
    const ret = [];
    const maxCount = 10;
    const patientKeys = Object.keys(patient);
    for (let i = 1; i <= maxCount; i += 1) {
      fields.forEach(f => {
        let fv;
        const key = patientKeys.find(k => i === 1 && k.toLowerCase() === f || k.toLowerCase() === `${f} ${i}` || k.toLowerCase() === `${f}${i}`);
        if (key) {
          fv = patient[key];
        }
        const fvFinal = trimOrUndefined(fv);
        if (fvFinal) {
          ret.push(packer(fvFinal));
        }
      });
    }

    sufixes.forEach(sufix => {
      fields.forEach(f => {
        let fv;
        const key = patientKeys.find(k => k.toLowerCase() === `${sufix} ${f}` || k.toLowerCase() === `${sufix}${f}`);
        if (key) {
          fv = patient[key];
        }
        const fvFinal = trimOrUndefined(fv);
        if (fvFinal) {
          ret.push(packer(fvFinal));
        }
      });
    });
    return ret;
  };

  const prepareForCombinedValue = (patient, fields, packer) => {
    const patientKeys = Object.keys(patient);
    const args = fields.map(f => {
      const key = patientKeys.find(k => k.toLowerCase() === f);
      if (key) {
        const trimmedValue = trimOrUndefined(patient[key]);
        return {
          field: f,
          value: trimmedValue || undefined,
        };
      }
    });
    return packer(fields, args);
  };

  const trimOrUndefined = v => {
    if (!v) return undefined;
    if (typeof v !== 'string') return v;
    return v.trim();
  };

  const getAddressFromFields = (formatFields, fields) => {
    const addressArray = [];
    formatFields.forEach(ff => {
      const f = fields.find(z => z && z.field === ff);
      if (f && f.value) {
        addressArray.push(f.value);
      }
    });
    return addressArray.join(', ');
  };

  const formatDate = (date, formatShort = true) => {
    const format = formatShort ? DATE_FORMAT_YEAR_MONTH_DAY : undefined;
    if (typeof date === 'string') {
      let m = moment(date).tz(moment.tz.guess());
      if (m.isValid()) {
        return m.format(format);
      }
      m = moment(date, "DD.MM.YYYY").tz(moment.tz.guess());
      if (m.isValid()) {
        return m.format(format);
      }
      return date;
    }
    const d = new Date(date);
    if (!format) {
      return d.toISOString();
    }
    const yyyy = d.getFullYear();
    let mm = d.getMonth() + 1; // Months start at 0!
    let dd = d.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    return `${yyyy}-${mm}-${dd}`;
  };

  const calcPhoneNumber = phone => {
    if (!phone) return null;
    const val = trimOrUndefined(phone.toString());
    const cleanPhone = cleanPhoneNumber(val);
    if (val.startsWith('+')) return `+${cleanPhone}`;
    if (cleanPhone.length === 10) return `+1${cleanPhone}`;
    return cleanPhone;
  };

  const getFacilityId = centerName => {
    return props.facilities.find(f => f.title === centerName)?.id;
  };

  const matchPatients = (newP, existingP) => {
    if (!newP || !existingP) {
      return false;
    }
    if (newP.firstName !== existingP.firstName || newP.lastName !== existingP.lastName || newP.dateOfBirth !== existingP.dateOfBirth) {
      return false;
    }
    if (newP.mrn && existingP.mrn && newP.mrn !== existingP.mrn) {
      return false;
    }
    const patientAMedicareInsurance = newP.insurance?.find(i => i.insuranceName === MEDICARE_INSURANCE_NAME);
    const patientBMedicareInsurance = existingP.insurance?.find(i => i.insuranceName === MEDICARE_INSURANCE_NAME);
    if (patientAMedicareInsurance && patientBMedicareInsurance && patientAMedicareInsurance.policyId !== patientBMedicareInsurance.policyId) {
      return false;
    }
    const patientIdPattern = new RegExp('PATIENT_\\d{8}_\\d{6}_\\d{1,2}');
    if (newP.pharmacyPatientId && existingP.pharmacyPatientId && !patientIdPattern.test(existingP.pharmacyPatientId) && newP.pharmacyPatientId !== existingP.pharmacyPatientId) {
      return false;
    }
    return true;
  };

  const preparePatient = (patient, i, allPatientsCount, rawData) => {
    const ret = {};
    Object.entries(fieldList).forEach(([key, value]) => {
      if (value.fieldType === fieldTypes.complexArray) {
        ret[key] = prepareForComplexArray(patient, value.columnNames, value.transform);
      } else if (value.fieldType === fieldTypes.simpleArray) {
        ret[key] = prepareForSimpleArray(patient, value.columnNames, value.transform);
      } else if (value.fieldType === fieldTypes.combinedField) {
        ret[key] = prepareForCombinedValue(patient, value.columnNames, value.transform);
      } else {
        value.columnNames.forEach(c => {
          const field = Object.keys(patient).find(z => z.toLowerCase() === c);
          if (field && patient[field] !== undefined && patient[field] !== '' && !ret[key]) {
            ret[key] = value.transform ? value.transform(patient[field]) : patient[field];
          }
        });
      }
    });
    if (ret.centerDetails?.length > 0 || ret.facility) {
      const facilityField = ret.facility || ret.centerDetails[0].centerName;
      ret.facilityId = getFacilityId(facilityField);
    } else {
      ret.facility = UNKNOWN_FACILITY;
    }
    if (!ret.homeAddress && ret.address) {
      ret.homeAddress = ret.address;
    }
    delete ret.address;

    if (!ret.pharmacyPatientId) {
      ret.pharmacyPatientId = `PATIENT_${moment().format('YYYYMMDD_HHmmss')}_${i.toLocaleString('en', {
        minimumIntegerDigits: (allPatientsCount - 1) / 10 + 1,
        minimumFractionDigits: 0,
        useGrouping: false,
      })}`;
    }
    if (ret.conditions?.length < 1) {
      ret.conditions = [UNKNOWN_CONDITION];
    }

    const patientMBI = Object.keys(patient).find(k => k.toLowerCase() === 'patient mbi');
    if (patientMBI && patient[patientMBI]) {
      ret.insurance.push({
        insuranceName: MEDICARE_INSURANCE_NAME,
        policyId: patient[patientMBI],
      });
    }

    if (ret.medications?.length === 1) {
      if (ret.conditions?.length === 1) {
        ret.medications[0].conditions = [ret.conditions[0]];
      }
      if (ret.insurance?.length === 1) {
        ret.medications[0].insurance = [ret.insurance[0]];
      }
      if (ret.medications[0]?.name && !ret.medications[0]?.dispensedName) {
        ret.medications[0].dispensedName = ret.medications[0].name;
      }
    }

    const matchedPatient = existingPatients?.find(p => matchPatients(ret, p));
    if (matchedPatient) {
      ret.id = matchedPatient.id;
    }

    ret.rawPatientImportData = [rawData];
    return ret;
  };

  const preparePatientDTO = patient => {
    const dto = {
      ...patient,
      rawPatientImportData: [
        {columns: patient.rawPatientImportData?.length > 0 ? [...patient.rawPatientImportData[0].keys()] : []}
      ].concat(patient.rawPatientImportData.map(d => Object.fromEntries(d.entries()))),
    };

    return dto;
  };

  const validatePatient = patient => {
    const errors = validateRpmPatient(patient, [], [], false);
    patient.errors = [];
    errors.forEach(e => {
      if (e.missing) {
        patient.errors.push(Strings.formatString(Strings.capPatient.missingFieldFormat, e.property));
      }
      patient.errors = patient.errors.concat(
        e.errors.map(err => `${e.field ? `${e.field}${e.index + 1}` : e.property}: ${err}`),
      );
    });

    return patient.errors.length === 0;
  };

  const uploadEnabled =
    fileErrors.length === 0 &&
    validateErrorsCount === 0 &&
    patients?.length > 0 &&
    uploadErrorsCount === 0 &&
    saveBtnClicked === false;

  let errors = [];
  validateErrorsCount > 0 && errors.push(`${validateErrorsCount} ${Strings.capPatient.invalidRecords}`);
  fileErrors.length > 0 && errors.push(...fileErrors);
  uploadErrorsCount > 0 && errors.push(`${uploadErrorsCount} ${Strings.capPatient.patientNotAdded}`);

  const progress = (patientsSent / patients.length) * 100;

  const getPagesForS3Upload = () => {
    return [
      {
        id: 'upload-patient-list',
        title: Strings.uploadPatientList,
        canGoNext: file,
        content: (
          <>
            <label
              htmlFor="file"
              className={`upload-documents-drag-area ${fileErrors.length > 0 ? 'error' : ''}`}
              style={{
                backgroundColor:
                  (fileErrors?.length > 0 || !file) && highlightInvalidFields ? 'rgba(218, 84, 125, 0.1)' : undefined,
              }}
            >
              <Input
                onChange={onFileChangeForS3Upload}
                type="file"
                id="file"
                onClick={event => {
                  event.target.value = '';
                }}
                isRequired
                highlightInvalid={highlightInvalidFields}
                errorsForTooltip={fileErrors}
              />
              <div
                className="progress-border"
                onDragEnter={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  return false;
                }}
                onDragOver={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  return false;
                }}
              >
                <div className="progress-container">
                  <div className="upload-icon" />
                </div>
              </div>
              <p>{Strings.dragFile}</p>
              <button className="brand-blue" key="chooseFiles">
                {Strings.chooseFile}
              </button>
            </label>
            <div>{Strings.manualUploadProcessInfo}</div>
          </>
        ),
      },
    ];
  };

  const getPagesForBulkAdd = () => {
    const pages = [];
    pages.push({
      id: 'bulk-add-patients-file',
      title: Strings.chooseFile,
      emptyFieldsCount: file ? 0 : 1,
      canGoNext: file,
      content: (
        <>
          <label
            htmlFor="file"
            className={`upload-documents-drag-area ${fileErrors.length > 0 ? 'error' : ''}`}
            style={{
              '--progress': `${progress}%`,
              backgroundColor:
                (fileErrors?.length > 0 || !file) && highlightInvalidFields ? 'rgba(218, 84, 125, 0.1)' : undefined,
            }}
          >
            <Input
              onChange={onFileChange}
              type="file"
              accept=".csv, .xlsx, .xls"
              id="file"
              // multiple="multiple"
              onClick={event => {
                event.target.value = '';
              }}
              isRequired
              highlightInvalid={highlightInvalidFields}
              errorsForTooltip={fileErrors}
            />
            <div
              className="progress-border"
              onDragEnter={e => {
                e.preventDefault();
                e.stopPropagation();
                return false;
              }}
              onDragOver={e => {
                e.preventDefault();
                e.stopPropagation();
                return false;
              }}
            >
              <div className="progress-container">
                <div className="upload-icon" />
              </div>
            </div>
            <p>{Strings.dragFile}</p>
            <button className="brand-blue" key="chooseFiles">
              {Strings.chooseFile}
            </button>
          </label>
          <div className="horizontal-flex">
            <a
              href="https://storage.googleapis.com/static.rxcap.com/docs/sampleTemplates/SampleBulkUploadPatientData.csv"
              download="SampleBulkUploadPatientData.csv"
            >
              {Strings.downloadTemplateCsv}
            </a>
            <a
              href="https://storage.googleapis.com/static.rxcap.com/docs/sampleTemplates/SampleBulkUploadPatientData.xlsx"
              download="SampleBulkUploadPatientData.xlsx"
            >
              {Strings.downloadTemplateXslx}
            </a>
          </div>
        </>
      ),
    });

    const specialFields = [{
      columnNames: ['patient mbi'],
    }];

    const sufixesRegex = new RegExp (`^(${sufixes.join('|')})`, 'g');
    const checkIfColumnMatches = c => {
      return Object.values(fieldList).concat(specialFields)
        .map(f => f.columnNames)
        .map(arr => (Array.isArray(arr) ? arr.flat() : arr))
        .some(arr => arr.includes(c.toLowerCase().replace(/\d+$/g, '').trim()) || arr.includes(c.toLowerCase().replace(sufixesRegex, '').trim()));
    };

    const matchedColumns = columnsInFile.filter(c => checkIfColumnMatches(c));
    const unmatchedColumns = columnsInFile.filter(c => !checkIfColumnMatches(c));

    pages.push({
      id: 'bulk-add-patients-columns',
      title: Strings.bulkAddTexts.columns,
      emptyFieldsCount: file ? 0 : 1,
      canGoNext: file && patients.length > 0,
      content: (
        <>
          {excelSheets.length > 1 && (
            <SelectField
              name="excelSheet"
              id="excelSheet"
              label={Strings.bulkAddTexts.multipleSheets}
              placeholder={Strings.bulkAddTexts.selectSheet}
              value={selectedExcelSheet}
              data={excelSheets.map(s => ({ value: s, label: s }))}
              onChange={option => setSelectedExcelSheet(option.value)}
              isRequired
              highlightInvalid={highlightInvalidFields}
              errorsForTooltip={sheetErrors}
            />
          )}
          {loadingFile && <label className="header">{Strings.readingDataFromFile}</label>}
          {selectedExcelSheet && patients?.length === 0 && !loadingFile && (
            <label className="header">{Strings.bulkAddTexts.noPatientsInFile}</label>
          )}
          {missingColumns?.length > 0 && (
            <div>
              <label className="header">{Strings.bulkAddTexts.missingColumns}</label>
              <ul>
                {missingColumns.map((c, i) => (
                  <li key={i}>{typeof c === 'string' ? c : c.join(' / ')}</li>
                ))}
              </ul>
            </div>
          )}
          {matchedColumns?.length > 0 && (
            <div>
              <label className="header">{Strings.bulkAddTexts.matchedColumns}</label>
              <ul>
                {matchedColumns.map((c, i) => (
                  <li key={i}>{c}</li>
                ))}
              </ul>
            </div>
          )}
          {unmatchedColumns?.length > 0 && (
            <div>
              <label className="header">{Strings.bulkAddTexts.unmatchedColumns}</label>
              <ul>
                {unmatchedColumns.map((c, i) => (
                  <li key={i}>{c}</li>
                ))}
              </ul>
            </div>
          )}
        </>
      ),
    });

    const noConditionCount = patients?.filter(p => p.conditions?.[0] === UNKNOWN_CONDITION).length;
    if (newConditionsVerified?.length > 0 || newConditionsUnknown?.length > 0 || noConditionCount > 0) {
      pages.push({
        id: 'bulk-add-patients-conditions',
        title: Strings.conditions,
        canGoNext: !fetchingConditions,
        content: (
          <>
            {noConditionCount > 0 && (
              <>
                <label className="header">{Strings.bulkAddTexts.patientsWithNoCondition}</label>
                <div>
                  {Strings.formatString(Strings.bulkAddTexts.unknownCondition, noConditionCount, UNKNOWN_CONDITION)}
                </div>
              </>
            )}
            {conditionsNotInOrg?.length > 0 && (
              <>
                {/* <label className="header">{`Validating conditions: ${conditionsNotInOrg?.filter(c => c.checked)?.length} / ${conditionsNotInOrg?.length}`}</label> */}
                <label className="header">Conditions found in file that are not organization conditions:</label>
                {conditionsNotInOrg.map((c, i) => (
                  // <div key={c.conditionFromExcel}>
                  //   {!c.checked ? "🔍 " : c.found? "✅ " : "❌ "}
                  //   {!c.checked ? c.conditionFromExcel : c.found ? `${c.conditionFromExcel} → ${c.title} (${c.code})` : `${c.conditionFromExcel} (not found)`}
                  // </div>
                  <div key={c.conditionFromExcel}>
                    {!c.checked ? "🔍 " : c.found? "✅ " : "❌ "}
                    {!c.checked ? c.conditionFromExcel : c.found ? c.conditionFromExcel : `${c.conditionFromExcel} (wrong format)`}
                  </div>
                ))}
              </>
            )}
          </>
        ),
      });
    }
    const noFacilityCount = patients?.filter(p => !p.facilityId).length;
    if (noFacilityCount > 0) {
      pages.push({
        id: 'bulk-add-patients-facilities',
        title: Strings.facilities,
        canGoNext: true,
        content: (
          <React.Fragment>
            {noFacilityCount > 0 && (
              <React.Fragment>
                <label className="header">{Strings.bulkAddTexts.patientsWithNoFacility}</label>
                <div>
                  {Strings.formatString(
                    Strings.bulkAddTexts.noFacility,
                    noFacilityCount,
                    props.facilities?.length === 1 ? props.facilities[0].title : UNKNOWN_FACILITY,
                  )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        ),
      });
    }
    pages.push({
      id: 'bulk-add-patients-patients',
      title: Strings.patients,
      canGoNext: uploadEnabled,
      closeButton: { disabled: uploading },
      noScrollbar: true,
      content: (
        <div className="cohort-patient-page">
          {uploading && (
            <>
              <label className="header">
                {`${conditionsSent} / ${newConditionsVerified?.length + shouldAddUnknownCondition ? 1 : 0} conditions added`}
              </label>
              <label className="header">{`${patientsSent} / ${patients?.length} patients uploaded`}</label>
            </>
          )}
          <Table name="patientsToAdd" data={patients}>
            {getColumns()}
          </Table>
        </div>
      ),
    });
    return pages;
  };

  return (
    <Wizard
      name="bulk-add-patients"
      pages={props.data.uploadPatientListMode ? getPagesForS3Upload() : getPagesForBulkAdd()}
      onNextButtonHover={e => setHighlightInvalidFields(e)}
      onSubmit={props.data.uploadPatientListMode ? onS3Upload : onUpload}
      showPagesFilter={false}
      forceNextPage={forceNextPage}
    />
  );
}

BulkAddPatientsModal.propTypes = {
  onCancel: PropTypes.any,
  getConditions: PropTypes.func,
  getFacilities: PropTypes.func,
  onUploadPatient: PropTypes.func,
  conditions: PropTypes.array,
  facilities: PropTypes.array,
  assignPatientToFacility: PropTypes.func,
  getIdcConditions: PropTypes.func,
  createCondition: PropTypes.func,
  createFacility: PropTypes.func,
  showNotification: PropTypes.func,
  getUploadPatientListUrl: PropTypes.func,
  uploadPatientListMode: PropTypes.bool,
  getPatientsForSuggestion: PropTypes.func,
  data: PropTypes.any,
  onClose: PropTypes.func,
  showNotification: PropTypes.func,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('bulk-add-patients')),
  onUploadPatient: data => {
    if (data.id) {
      return dispatch(actions.editPatient(data, data.id)).then(response => {
        return response;
      });
    } else {
      return dispatch(actions.addPatient(data)).then(response => {
        return response;
      });
    }
  },
  assignPatientToFacility: (patientId, facilityId) =>
    dispatch(cohortActions.assignPatientToFacility(patientId, facilityId)),
  onClose: () => {
    // dispatch(ownProps.data.action(ownProps.data.pageRequest));
    dispatch(closeModal('bulk-add-patients'));
  },
  getConditions: () => dispatch(cohortActions.getConditions()),
  getFacilities: () => dispatch(cohortActions.getFacilities()),
  getIdcConditions: pageRequest => dispatch(cohortActions.getIdcConditions(pageRequest)),
  createCondition: data => dispatch(cohortActions.createCondition(data)),
  createFacility: data => dispatch(cohortActions.createFacility(data)),
  getUploadPatientListUrl: fileName => dispatch(documentsActions.getS3UploadPatientListUrl(fileName)),
  showNotification: (message, timeout, isError) => dispatch(notificationActions.show(message, timeout, isError)),
  getPatientsForSuggestion: pageRequest => dispatch(actions.getPatientsForSuggestion(pageRequest)),
});

const mapStateToProps = state => {
  return {
    conditions: state.superUser.cohorts?.conditions,
    facilities: state.superUser.cohorts?.facilities,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkAddPatientsModal);
