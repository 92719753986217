import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';

import TableWithPagination, { Column } from '../../containers/Table/TableWithPagination';
import Strings from '../../Strings';
import { PAGE_LIMIT } from '../../constants';
import { actions, DELETE_ALERT_DEFINITION_RESULT, GET_ALERTS_DEFINITIONS_RESULT } from './redux/actions';
import { convertKilogramToPound, roundToDecimal, convertCelsiusToFahrenheit } from '../../utils/unitConverters';
import { PageHeader } from '../../components/PageHeader';
import { ActionButton, ActionWidget, ItemSelectorHelper } from '../../components/ActionWidget';
import { notificationActions } from '../../components/Notification/redux/actions';
import { priorityRenderer } from './helpers';

function AlertsConfigurations(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [alertsDefinitions, setAlertsDefinitions] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentShowingCount, setCurrentShowingCount] = useState(0);
  const [showActionWidget, setShowActionWidget] = useState(false);

  const itemSelectorHelper = useRef(new ItemSelectorHelper());

  const pageRequest = {
    offset: 0,
    limit: PAGE_LIMIT,
  };

  const getData = () => {
    setIsLoading(true);
    setAlertsDefinitions([]);
    props.getAlertsDefinitions(pageRequest).then(resp => {
      if (resp.type === GET_ALERTS_DEFINITIONS_RESULT) {
        setAlertsDefinitions(resp.response.alerts_definitions);
        setCurrentShowingCount(resp.response.alerts_definitions.length);
        setPagination(resp.response.pagination);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, [props.refreshTimestamp]);

  const onPrevClick = () => {
    pageRequest.offset = pagination.offset - PAGE_LIMIT;
    getData();
  };

  const onNextClick = () => {
    pageRequest.offset = pagination.offset + PAGE_LIMIT;
    getData();
  };

  const onCustomPage = page => {
    pageRequest.offset = (page - 1) * PAGE_LIMIT;
    getData();
  };

  const onRowClick = (_, item) => {
    const maskedId = encodeURIComponent(item.patient_id);
    props.onNavigate(`/cap-patients/${maskedId}`);
  };

  const eventTypeRenderer = e => {
    switch (e) {
      case 'weight':
        return Strings.weight;
      case 'blood_pressure':
        return Strings.bloodPressure;
      case 'glucose':
        return Strings.glucose;
      case 'spirometer':
        return Strings.spirometer;
      case 'saturation':
        return Strings.oxygenSaturation;
      case 'heart_rate':
        return Strings.heartRate;
      case 'air_quality':
        return Strings.airQuality;
      case 'coagulation':
        return Strings.coagulation;
      default:
        return e;
    }
  };

  const conditionRenderer = e => {
    let ret = '';
    switch (e.event_type) {
      case 'weight':
        {
          ret += `${Strings.weight} `;
          const max = e.event_value_conditions[0].max
            ? roundToDecimal(convertKilogramToPound(e.event_value_conditions[0].max), 2)
            : '-';
          const min = e.event_value_conditions[0].min
            ? roundToDecimal(convertKilogramToPound(e.event_value_conditions[0].min), 2)
            : '-';
          ret += `${Strings.min}: ${min} lbs, ${Strings.max}: ${max} lbs`;
        }
        break;
      case 'blood_pressure':
        e.event_value_conditions.forEach(condition => {
          ret += condition.field === 'systolic_pressure' ? 'SYS ' : 'DIA ';
          const max = condition.max ? condition.max : '-';
          const min = condition.min ? condition.min : '-';
          ret += `${Strings.min}: ${min}, ${Strings.max}: ${max} `;
        });
        break;
      case 'glucose':
        {
          ret += `${Strings.glucoseConcentration} `;
          const max = e.event_value_conditions[0].max ? e.event_value_conditions[0].max : '-';
          const min = e.event_value_conditions[0].min ? e.event_value_conditions[0].min : '-';
          ret += `${Strings.min}: ${min} mg/dL, ${Strings.max}: ${max} mg/dL`;
        }
        break;
      case 'spirometer':
        e.event_value_conditions.forEach(condition => {
          ret += condition.field === 'fev1' ? 'FEV1 ' : 'FVC ';
          const max = condition.max ? condition.max : '-';
          const min = condition.min ? condition.min : '-';
          ret += `${Strings.min}: ${min}, ${Strings.max}: ${max} `;
        });
        break;
      case 'temperature':
        {
          ret += `${Strings.temperature} `;
          const max = e.event_value_conditions[0].max
            ? roundToDecimal(convertCelsiusToFahrenheit(e.event_value_conditions[0].max), 2)
            : '-';
          const min = e.event_value_conditions[0].min
            ? roundToDecimal(convertCelsiusToFahrenheit(e.event_value_conditions[0].min), 2)
            : '-';
          ret += `${Strings.min}: ${min} F, ${Strings.max}: ${max} F`;
        }
        break;
      case 'saturation':
        {
          ret += `${Strings.oxygenSaturation} `;
          const min = e.event_value_conditions[0].min || '-';
          ret += `${Strings.min}: ${min}%`;
        }
        break;
      case 'heart_rate':
        {
          ret += `${Strings.heartRate} `;
          const max = e.event_value_conditions[0].max || '-';
          const min = e.event_value_conditions[0].min || '-';
          ret += `${Strings.min}: ${min} ${Strings.bpm_unit}, ${Strings.max}: ${max} ${Strings.bpm_unit}`;
        }
        break;
      case 'air_quality':
        {
          ret += 'PM 2.5 ';
          const max = e.event_value_conditions[0].max || '-';
          ret += `${Strings.max}: ${max}%`;
        }
        break;
      case 'coagulation':
        e.event_value_conditions.forEach(condition => {
          ret += condition.field === 'pt' ? 'PT ' : 'INR ';
          const max = condition.max ? `${condition.max}${condition.field === 'pt' ? 's' : ''}` : '-';
          const min = condition.min ? `${condition.min}${condition.field === 'pt' ? 's' : ''}` : '-';
          ret += `${Strings.min}: ${min}, ${Strings.max}: ${max} `;
        });
      default:
        break;
    }
    return ret;
  };

  const itemChecked = ({ target }) => {
    if (target.checked) {
      itemSelectorHelper.current.addItem(target.id);
    } else {
      itemSelectorHelper.current.removeItem(target.id);
    }

    if (itemSelectorHelper.current.getItems().length !== 0) {
      setShowActionWidget(true);
    } else {
      setShowActionWidget(false);
    }
  };

  const deleteAlerts = () => {
    const alerts_ids = itemSelectorHelper.current.getItems();
    alerts_ids.forEach(alert_id => {
      props.deleteAlertDefinition(alert_id).then(resp => {
        if (resp.type !== DELETE_ALERT_DEFINITION_RESULT) {
          props.showNotification('Failed to delete alert definition');
        }
        setShowActionWidget(false);
        itemSelectorHelper.current.clearItems();
        getData();
      });
    });
  };

  const actionWidget = (
    <ActionWidget show={showActionWidget}>
      <ActionButton img="action-edit" tooltiptext={Strings.edit} text={Strings.edit} disabled />
      <ActionButton img="action-delete" tooltiptext={Strings.delete} text={Strings.delete} action={deleteAlerts} />
    </ActionWidget>
  );

  const table = (
    <TableWithPagination
      isLoading={isLoading}
      name="alerts-definitions"
      data={alertsDefinitions}
      pagination={pagination}
      onNextClick={onNextClick}
      onPrevClick={onPrevClick}
      onCustomPage={onCustomPage}
      onRowSelection={onRowClick}
    >
      <Column
        key="id"
        title={Strings.id}
        value={e => (
          <React.Fragment>
            <div className="cell-with-select">
              <div className="selector">
                <input type="checkbox" className="item-checkbox" id={e.id} onChange={itemChecked} />
              </div>
              <div className="selector-label">{e.id}</div>
            </div>
          </React.Fragment>
        )}
      />
      <Column key="patinetName" title={Strings.name} value={e => e.patient_name} className="clickable" />
      <Column key="device_id" title={Strings.deviceId} value={e => (e.device_id ? e.device_id : '*')} />
      <Column key="priority" title={Strings.priority} value={e => priorityRenderer(e.priority)} />
      <Column key="eventType" title={Strings.eventType} value={e => eventTypeRenderer(e.event_type)} />
      <Column key="max" title={Strings.condition} value={e => conditionRenderer(e)} />
    </TableWithPagination>
  );

  const pageHeader = (
    <PageHeader
      left={!isLoading ? Strings.formatString(Strings.showingXAlerts, currentShowingCount) : Strings.showingWait}
    />
  );

  return (
    <div className="alerts">
      {pageHeader}
      {table}
      {actionWidget}
    </div>
  );
}

AlertsConfigurations.propTypes = {
  getAlertsDefinitions: PropTypes.func.isRequired,
  refreshTimestamp: PropTypes.any.isRequired,
  deleteAlertDefinition: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getAlertsDefinitions: pageRequest => dispatch(actions.getAlertsDefinitions(pageRequest)),
  deleteAlertDefinition: id => dispatch(actions.deleteAlertDefinition(id)),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
  onNavigate: path => dispatch(push(path)),
});

export default connect(null, mapDispatchToProps)(AlertsConfigurations);
