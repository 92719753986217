import React from 'react';
import { connect } from 'react-redux';

import { DateInput, SelectField } from '../../containers/Form';
import Strings from '../../Strings';
import { OnboardStages } from './OnboardPatientWizard';
import { Timezones } from '../../constants';
import { ACTIVATION_METHODS } from '../../utils/enrollmentFields';

function EnrollmentDatesPage(props) {
  const { enrollment, setEnrollment, validationErrors, highlightInvalidFields, fields, stage, orgConfig } = props;

  const orgTimezoneInfo = props.timezone !== props.orgTimezone ? ` (${Strings.formatString(Strings.enrollmentDateTimezoneTooltip, Timezones[props.orgTimezone], Strings.organization)})` : '';
  const showStartDateInput = (
    enrollment?.activation_method === ACTIVATION_METHODS.Manual && orgConfig?.enrollment_activation_modes?.includes(ACTIVATION_METHODS.Manual))
    || !orgConfig?.enrollment_activation_modes;
  const datesPage = (
    <>
      {enrollment.subprogram && stage !== OnboardStages.settingEndDate && (
        <>
          {orgConfig?.enrollment_activation_modes?.length > 1 && (
            <SelectField
              name="activation_method"
              id="activation_method"
              label={Strings.capPatient.activateEnrollment}
              value={enrollment.activation_method || ''}
              data={orgConfig?.enrollment_activation_modes.map(mode => ({ value: mode, label: Strings.capPatient.enrollmentActivationModesEnum[mode] }))}
              onChange={option => setEnrollment(e => ({...e, activation_method: option.value}))}
              highlightInvalid={highlightInvalidFields}
              isRequired={fields.activation_method.required}
              disabled={fields.activation_method.readOnly}
            />
          )}
          {showStartDateInput && (
            <DateInput
              name="enrollmentStart"
              id="enrollmentStart"
              label={`${Strings.startDate}${orgTimezoneInfo}`}
              placeholder="MM / DD / YYYY"
              value={enrollment.enrollmentStart || ''}
              timezone={props.orgTimezone}
              format="MM / dd / yyyy"
              onChange={value => setEnrollment(e => ({...e, enrollmentStart: value}))}
              highlightInvalid={highlightInvalidFields}
              isRequired={fields.enrollmentStart.required}
              disabled={fields.enrollmentStart.readOnly}
              errorsForTooltip={validationErrors.find(v => v.property === 'enrollmentStart')?.errors || []}
              rowClassName="double-width-left"
            />
          )}
        </>
      )}
      {enrollment.subprogram && stage === OnboardStages.settingEndDate && (
        <DateInput
          name="enrollmentEnd"
          id="enrollmentEnd"
          label={`${Strings.endDate}${orgTimezoneInfo}`}
          placeholder="MM / DD / YYYY"
          value={enrollment.enrollmentEnd || ''}
          timezone={props.orgTimezone}
          format="MM / dd / yyyy"
          onChange={value => setEnrollment(e => ({...e, enrollmentEnd: value}))}
          highlightInvalid={highlightInvalidFields}
          isRequired={fields.enrollmentEnd.required}
          disabled={fields.enrollmentEnd.readOnly}
          errorsForTooltip={validationErrors.find(v => v.property === 'enrollmentEnd')?.errors || []}
          rowClassName="double-width-left"
        />
      )}
    </>
  );

  return datesPage;
};

const mapStateToProps = state => {
  return {
    timezone: state.auth?.profile?.preferences?.timezone,
    orgTimezone: state.auth?.ui_config?.timezone,
  };
};

export default connect(mapStateToProps, null)(EnrollmentDatesPage);
