import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { actions } from './redux/actions';
import '../Widgets.scss';
import { DeviceClass, DeviceTypes } from '../../../../utils/deviceTypes';
import { ColorPalette } from '../../DashboardColorPalettes';
import Strings from '../../../../Strings';
import GenericCategoryGraph, { chartTypeEnum } from '../../../../components/Graphs/GenericCategoryGraph';
import { graphContainer } from '../graphContainers';
import { hasPermission } from '../../../../utils/userPermissions';

export const DevicesMetadata = {
  id: 'DevicesWidget',
  name: Strings.widgets.numberOfDevices,
  permission: '',
  defProps: { i: 'DevicesWidget', w: 4, h: 2, minW: 2, minH: 2 },
  configuration: {
    chartType: {
      type: 'select',
      name: 'chartType',
      label: 'Chart Type',
      defaultValue: chartTypeEnum.pie,
      options: [
        { label: 'Numbers', value: 'numbers' },
        { label: 'Pie chart', value: chartTypeEnum.pie },
        { label: 'Bar chart', value: chartTypeEnum.bar },
        { label: 'Area chart', value: chartTypeEnum.area },
        { label: 'Line chart', value: chartTypeEnum.line },
      ],
      canBeSetByDashboard: false,
    },
  },
};

function Devices(props) {
  const { getData, onClick, refreshTimestamp, colorPalette, graphType } = props;
  const [devicesCount, setDevicesCount] = React.useState([]);

  useEffect(() => {
    getData().then(resp => {
      if (!resp?.response?.devices_count) {
        return [];
      }
      setDevicesCount(Object.values(DeviceTypes).filter(t => t.deviceClass !== DeviceClass.kit && hasPermission(t.permission)).map(t => {
        return {
          deviceType: t.id,
          count: resp?.response.devices_count.find(d => decodeURIComponent(d.device_type) === t.id)?.count || 0,
        };
      }));
    });
  }, [refreshTimestamp]);

  const numericWidget = (
    <div className="box2colsWithVl vncols">
      {devicesCount.map(devType => (
        <div className="box2colsWithVl-box">
          <div className="box2colsWithVl-valueBox blue">{devType.count}</div>
          <div onClick={() => onClick(`/devices/${encodeURIComponent(DeviceTypes[devType.deviceType].id)}`)} className="box2colsWithVl-labelBox clickable">
            {DeviceTypes[devType.deviceType].displayNamePlural}
          </div>
        </div>
      ))}
    </div>
  );

  const dataSeries = [
    {
      name: Strings.widgets.numberOfDevices,
      color: colorPalette?.interpolate(0.73),
      selector: item => item.count,
    },
  ];

  return graphType === 'numbers' ? numericWidget : graphContainer(
    <GenericCategoryGraph
      graphData={devicesCount}
      hasBorder
      forceCondensed
      xAxisTopPosition={false}
      yAxisWidth={40}
      yAxisUnit=""
      tooltipTitle={Strings.devices}
      dataSeries={dataSeries}
      categorySelector={item => item.deviceType}
      graphType={graphType}
      colorPalette={colorPalette}
      onCategoryClicked={deviceType => onClick(`/devices/${encodeURIComponent(deviceType)}`)}
    />,
    Strings.widgets.numberOfDevices,
  );
}

Devices.propTypes = {
  getData: PropTypes.func,
  onClick: PropTypes.func,
  refreshTimestamp: PropTypes.any.isRequired,
  colorPalette: PropTypes.any,
  graphType: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    colorPalette:
      ColorPalette[state.dashboardState.configuration?.allDashboards?.colorPalette] || Object.values(ColorPalette)[0],
  };
};

const mapDispatchToProps = dispatch => ({
  getData: () => dispatch(actions.getDevicesCount()),
  onClick: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Devices);
