import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import moment from 'moment-timezone';

import './Dashboard.scss';
import Strings from '../../../../Strings';
import { DATE_MONTH_DAY_YEAR } from '../../../../constants';
import { datesText, getProgramDisplayName } from '../../../../utils/cmsPrograms';

function genderDecoder(e) {
  if (e === 'Female') return Strings.genders.female;
  else if (e === 'Male') return Strings.genders.male;
  else if (e === 'Other') return Strings.genders.other;
  return '-';
}

function languageDecoder(lang) {
  if (lang === 'en') return Strings.english;
  if (lang === 'es') return Strings.spanish;
  return '-';
}

function PatientInfo(props) {
  return (
    <div className="vertical-layout-widet col">
      <div className="vertical-layout-widet">
        <div className="vertical-layout-widet info-label">{Strings.dob}</div>
        <div className="vertical-layout-widet info-value">
          {props.data?.dob ? moment.tz(props.data.dob, 'UTC').format(DATE_MONTH_DAY_YEAR) : '-'}
        </div>
      </div>
      <div className="vertical-layout-widet">
        <div className="vertical-layout-widet info-label">{Strings.gender}</div>
        <div className="vertical-layout-widet info-value">{genderDecoder(props.data?.gender)}</div>
      </div>
      <div className="vertical-layout-widet">
        <div className="vertical-layout-widet info-label">{Strings.language}</div>
        <div className="vertical-layout-widet info-value">{languageDecoder(props.data?.language)}</div>
      </div>
    </div>
  );
}
PatientInfo.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.shape({
    dob: PropTypes.any,
    gender: PropTypes.string,
    language: PropTypes.string,
  }),
  refreshTimestamp: PropTypes.any,
};

function DevicesAndMeds(props) {
  return (
    <div className="vertical-layout-widet row">
      <div
        className="vertical-layout-widet row clickable"
        onClick={() => props.onClick(`/cap-patients/${props.patientId}/devices`)}
      >
        <div className="vertical-layout-widet value">{props.data?.devices}</div>
        <div className="vertical-layout-widet descr">{Strings.devices}</div>
        <div className="nav-icon" />
      </div>
      <div className="vertical-layout-widet col-sep"></div>
      <div
        className="vertical-layout-widet row clickable"
        onClick={() => props.onClick(`/cap-patients/${props.patientId}/medications`)}
      >
        <div className="vertical-layout-widet value">{props.data?.medications}</div>
        <div className="vertical-layout-widet descr">{Strings.medications}</div>
        <div className="nav-icon" />
      </div>
    </div>
    /* TODO: Lack of defintion what new mean */
    /* <div className="red-badge">+1 {Strings.new}</div> */
  );
}
DevicesAndMeds.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.shape({
    devices: PropTypes.number,
    medications: PropTypes.number,
  }),
  refreshTimestamp: PropTypes.any,
};

function Documents(props) {
  return (
    <div
      className="vertical-layout-widet row clickable"
      onClick={() => props.onClick(`/cap-patients/${props.patientId}/documents`)}
    >
      <div className="vertical-layout-widet value">{props.data?.documents}</div>
      <div className="vertical-layout-widet descr">{Strings.documents}</div>
      <div className="nav-icon" />
    </div>
  );
}
DevicesAndMeds.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.shape({
    documents: PropTypes.number,
  }),
  refreshTimestamp: PropTypes.any,
};

const mapDispatchToProps = dispatch => ({
  onClick: path => dispatch(push(path)),
});

function EnrollmentInfo({enrollment}) {
  return (
    <div className="vertical-layout-widet col">
      <div className="vertical-layout-widet">
        <div className="vertical-layout-widet info-label">{Strings.program}</div>
        <div className="vertical-layout-widet info-value">
          {enrollment?.program ? getProgramDisplayName(enrollment.program, enrollment.subprogram) : '-'}
        </div>
      </div>
      {(enrollment?.enrollmentStart || enrollment?.enrollmentEnd) && (
        <div className="vertical-layout-widet">
          <div className="vertical-layout-widet info-label">{Strings.enrollmentStartEnd}</div>
          <div className="vertical-layout-widet info-value">{datesText(enrollment)}</div>
        </div>
      )}
      {enrollment?.careProvider && (
        <div className="vertical-layout-widet">
          <div className="vertical-layout-widet info-label">{Strings.capPatient.careProvider}</div>
          <div className="vertical-layout-widet info-value">{enrollment?.careProvider?.name}</div>
        </div>
      )}
      {enrollment?.allowedVitals && (
        <div className="vertical-layout-widet">
          <div className="vertical-layout-widet info-label">{Strings.vitals}</div>
          <div className="vertical-layout-widet info-value">{enrollment?.allowedVitals.join(", ")}</div>
        </div>
      )}
      {enrollment?.conditionTitle && (
        <div className="vertical-layout-widet">
          <div className="vertical-layout-widet info-label">{Strings.condition}</div>
          <div
            data-tooltip-id="tooltip"
            data-tooltip-content={enrollment?.conditionTitle}
            className="vertical-layout-widet info-value"
          >
            {enrollment?.conditionTitle}
          </div>
        </div>
      )}
      {enrollment?.medications && (
        <div className="vertical-layout-widet">
          <div className="vertical-layout-widet info-label">{Strings.medications}</div>
          <div
            data-tooltip-id="tooltip"
            data-tooltip-content={enrollment?.medications.map(m => m.name).join(", ")}
            className="vertical-layout-widet info-value"
          >
            {enrollment?.medications.map(m => m.name).join(", ")}
          </div>
        </div>
      )}
    </div>
  );
}
EnrollmentInfo.propTypes = {
  enrollment: PropTypes.object,
};

export const getProgramWidgetDataLength = enrollment => {
  let count = 0;
  if (enrollment?.program) count++;
  if (enrollment?.enrollmentStart || enrollment?.enrollmentEnd) count++;
  if (enrollment?.careProvider) count++;
  if (enrollment?.allowedVitals) count++;
  if (enrollment?.conditionTitle) count++;
  if (enrollment?.medications) count++;
  return count;
}

export const PatientInfoWidget = connect(null, mapDispatchToProps)(PatientInfo);
export const DevicesAndMedsWidget = connect(null, mapDispatchToProps)(DevicesAndMeds);
export const DocumentsWidget = connect(null, mapDispatchToProps)(Documents);
export const EnrollmentInfoWidget = EnrollmentInfo;
